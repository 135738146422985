import React, { useState } from 'react';
import Header from '../Component/Header';
import Municipal from './../../../../../img/Municipal.svg';
import Content from '../Component/Content';
import Paginator from '../Component/Paginator';
import './Index.css';
import { useEffect } from 'react';
import { fetch_request } from '../../../../../service/request';
import Loader from '../../../../../components/Loader/Loader';
import { FormattedMessage, useIntl } from 'react-intl';

const Index = () => {
  const intl = useIntl();
  const [searchInputValue, setSearchInputValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [municipalitiesCount, setMunicipalitiesCount] = useState(0);
  const [municipalitiesList, setMunicipalitiesList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch_request(`/api/municipalities_count`)
      .then((response) => response.json())
      .then((data) => {
        setMunicipalitiesCount(data.count);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchInputValue]);

  useEffect(() => {
    fetchMunicipalities();
  }, [searchInputValue, currentPage]);

  const fetchMunicipalities = () => {
    setLoading(true);
    fetch_request(
      `/api/search_municipalities?input=${searchInputValue}&page=${currentPage}`
    )
      .then((response) => response.json())
      .then((data) => {
        setMunicipalitiesList(data.municipalities);
        setTotalPages(data.total_pages);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const Description = () => {
    return (
      <span className='search-graves_none-text'>
        <FormattedMessage id='it_is' />{' '}
        <span>
          <FormattedMessage
            id='find_grave_count'
            values={{ count: municipalitiesCount }}
          />
        </span>{' '}
        <FormattedMessage id='municipality_description' />
      </span>
    );
  };

  return (
    <div className='municipality-index'>
      <Header
        setSearchInputValue={setSearchInputValue}
        title={<FormattedMessage id='find_municipality' />}
        description={<Description />}
        placeholder={intl.formatMessage({ id: 'search_municipality' })}
      />
      {municipalitiesCount !== 0 && (
        <Paginator
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          totalPages={totalPages}
        />
      )}
      {!loading ? (
        <Content
          list={municipalitiesList}
          searchInputValue={searchInputValue}
          url={`/finn-en-grav`}
          logo={Municipal}
        />
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default Index;
