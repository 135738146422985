import React from 'react';
import { FormattedMessage } from 'react-intl';
import AddIcon from '@mui/icons-material/Add';
import styles from './CardHeader.module.css';

const CardHeader = ({ titleKey, setOpenForm, isPublic, isGraveUser }) => {
  return (
    <div className={styles['container']}>
      <div className={styles['heading']}>
        <FormattedMessage id={titleKey} />
      </div>
      {!isPublic && isGraveUser && (
        <div
          className={styles['btn-container']}
          onClick={() => setOpenForm(true)}
        >
          <AddIcon style={{ color: '#404d56' }} />
          <button>
            <FormattedMessage id='add_dates' />
          </button>
        </div>
      )}
    </div>
  );
};

export default CardHeader;
