import React, { useState, useEffect } from 'react';
import styles from './UserConnections.module.css';
import { CONSTANTS } from '../../../../service/constants';
import { useParams, useNavigate } from 'react-router-dom';
import { Avatar } from '@mui/material';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Loader from '../../../../components/Loader/Loader';

const UserConnections = ({ setSelectedMenuKey, selectedMenuKey, user }) => {
  const [connectedUsersList, setConnectedUsersList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalUsers, setTotalUsers] = useState(0);
  const { slug } = useParams();
  const navigate = useNavigate();
  const isAuth = useSelector((state) => state.auth.isAuthorized);

  useEffect(() => {
    if (isAuth) {
      fetchList().then((apiResponse) => {
        if (selectedMenuKey === 3) {
          setConnectedUsersList(apiResponse.user_connections);
        } else {
          const displayedGraveList = apiResponse.user_connections.slice(0, 6);
          setConnectedUsersList(displayedGraveList);
        }
        setTotalUsers(apiResponse.user_connections.length);
        setLoading(false);
      });
    }
  }, [slug]);

  const fetchList = async () => {
    setLoading(true);
    const params = {
      slug: slug,
    };

    const stringQueryParams = new URLSearchParams(params).toString();

    const response = await fetch(
      `${CONSTANTS.baseUrl}/api/user_connections?${stringQueryParams}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );

    if (response.status === 200) {
      const apiResponse = await response.json();
      return apiResponse;
    } else {
      console.log(response.error);
    }
  };

  const handleUserAvatarClicked = (item) => {
    const path = `/member/${item.connected_user_slug}`;
    navigate(path);
  };

  const isUserCurrentUser = () => {
    return (
      user.slug === localStorage.getItem('user-slug') ||
      user.slug === sessionStorage.getItem('user-slug')
    );
  };

  return (
    <div className={styles['user-connections-container']}>
      <div className={styles['user-connections-header']}>
        <div className={styles['user-connections-header_title']}>
          <span>
            <FormattedMessage
              id='love_ones'
              values={{ name: user?.full_name }}
            />
          </span>
        </div>
      </div>
      {connectedUsersList.length === 0 && !loading ? (
        <div className={styles['empty-grave-container']}>
          <div className={styles['empty-grave ']}>
            <FormattedMessage
              id={isUserCurrentUser() ? 'close_relation' : 'no_relation_set'}
            />
          </div>
        </div>
      ) : loading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <div className={styles['user-connections_grid']}>
            {connectedUsersList.map((item, index) => {
              return (
                <div
                  className={styles['user-connections_item']}
                  key={index}
                  onClick={() => handleUserAvatarClicked(item)}
                >
                  <Avatar src={item.avatar} className={styles['avatar']} />
                  <div className={styles['user_info']}>
                    <span className={styles['user_name']}>
                      {item?.user_name}
                    </span>
                    <span className={styles['user_relation']}>
                      <FormattedMessage id={item?.relation_key} />
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
          {selectedMenuKey === 1 && totalUsers > 6 && (
            <div
              className={styles['view-all']}
              onClick={() => setSelectedMenuKey(3)}
            >
              <FormattedMessage id='view_all' />
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default UserConnections;
