import React from 'react';
import './HomepageInvestigateSecond.css';
import TreeHand from '../../../../img/TreeHand.svg';
import ManGrowth from '../../../../img/ManGrowth.svg';
import ManRespect from '../../../../img/investRespect.svg';

const HomepageInvestigateSecond = (props) => {
    return(
        <div className='homepage-investigate_second'>
            <div className="homepage-investigate_second-content">
                <div className="homepage-investigate_second-content_header">
                    <span className='homepage-investigate_second-content_header-text'>Vår misjon er å utvikle <span>sjelen</span></span>
                    <span className='homepage-investigate_second-content_header-subtext'><span>Å møte </span>livet og gjøre mer av det som virkelig betyr</span>
                    <span className='homepage-investigate_second-content_header-title'>Nettgrav.no er graven på nett. Siden graven har sin kultur og tradisjon viderefører vi denne til våre nettliv. Med digital teknologi og den rike kulturen graven omfavner skapes et viktig utviklingsløp. </span>
                </div>
                <div className="homepage-investigate_second-content_main">
                    <div className="homepage-investigate_second-content_main-block">
                        <div className='homepage-investigate_second-content_main-block_image'>
                            <img loading='lazy' src={TreeHand} alt='TreeHand'/>
                        </div>
                        <span className='homepage-investigate_second-content_main-block_header'>Forbedre den menneskelige opplevelsen</span>
                        <span className='homepage-investigate_second-content_main-block_subheader'>Vi har som mål å forbedre sosiale forbindelser fra et dødsperspektiv. Hva er viktig for oss når vi reflekterer over våre liv fra vår dødsseng?</span>
                    </div>
                    <div className="homepage-investigate_second-content_main-block">
                        <div className='homepage-investigate_second-content_main-block_image'>
                            <img loading='lazy' src={ManGrowth} alt='ManGrowth'/>
                        </div>
                        <span className='homepage-investigate_second-content_main-block_header'>Skap og invester i verdier</span>
                        <span className='homepage-investigate_second-content_main-block_subheader'>Nettgrav er et betalt produkt med målet om å finansiere et meningsfullt kunnskapssenter. Det viktigste fremover er å vite hvilke spørsmål vi ønsker besvart.</span>
                    </div>
                    <div className="homepage-investigate_second-content_main-block">
                        <div className='homepage-investigate_second-content_main-block_image'>
                            <img loading='lazy' src={ManRespect} alt='ManRespect'/>
                        </div>
                        <span className='homepage-investigate_second-content_main-block_header'>Oppdag Meningsfullhet og Mestring</span>
                        <span className='homepage-investigate_second-content_main-block_subheader'>Mange tror feilaktig at Nettgrav.no kun er et fysisk gravsted. Faktum er at portalen utvikles til å bli et globalt samfunn hvor mennesker kan samles for å finne meningsfulle relasjoner, leve i fred gjennom åpenhet og sannhet.</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomepageInvestigateSecond;