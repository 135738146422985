import React from 'react';
import './PlansPage.css';
import PlansPageRouting from '../../containers/Plans/PlansPageRouting';

const PlansPage = (props) => {
  return (
    <div className='plans-page'>
      <PlansPageRouting />
    </div>
  );
};

export default PlansPage;
