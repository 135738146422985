import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import isEmpty from 'lodash.isempty';
import { useDispatch } from 'react-redux';
import CustomModal from '../../../../components/CustomModal';
import Heading from './Heading';
import SetRelationOrClaimDialog from '../RelationOrClaimModals/SetRelationOrClaimDialog';
import SetRelationFooter from './SetRelationFooter';
import { request, fetch_request } from '../../../../service/request';
import {
  setGraveCurrentUserRelation,
  updateCurrentUserInClaimQueue,
} from '../../../../redux/actions';
import showUserNotification from '../../../../components/UserNotification/showUserNotification';

const ConnectRelationsModal = ({
  grave,
  user,
  graveInvitation = null,
  graveUser = null,
  handleCloseRelationDialog,
  openRelationDialog,
  isClaimed,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [relations, setRelations] = useState([]);
  const [chosenRelation, setChosenRelation] = useState({});
  const [reciprocalRelation, setReciprocalRelation] = useState({});
  const [directionsList, setDirectionsList] = useState([]);
  const [activeTab, setActiveTab] = useState('relative');
  const [friendNote, setFriendNote] = useState('');
  const [buttonText, setButtonText] = useState('confirm_relation_member');
  const [selectedDocument, setSelectedDocument] = useState('death_certificate');
  const [selectedFile, setSelectedFile] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const [wantToClaim, setWantToClaim] = useState(false);
  const [formErrors, setFormErrors] = useState({
    relation: false,
    reciprocalRelation: false,
  });

  const handelSelectedDocument = (value) => {
    setSelectedDocument(value);
  };

  const handelSetDocumentFile = (file) => {
    setSelectedFile(file);
  };

  useState(() => {
    if (user?.is_subscribed) {
      setButtonText('set_relation');
    } else {
      setButtonText('confirm_relation_member');
    }
  }, [user]);

  useEffect(() => {
    if (graveUser) {
      setChosenRelation(graveUser?.relation);
      setReciprocalRelation(graveUser?.reciprocal_relation);
    } else if (graveInvitation) {
      setChosenRelation(graveInvitation?.relation);
      setReciprocalRelation(graveInvitation?.reciprocal_relation);
    }
  }, [graveUser, graveInvitation]);

  const updateButtonText = () => {
    if (window.innerWidth > 520 && !user.is_subscribed) {
      setButtonText('confirm_relation_member');
    } else if (!user.is_subscribed) {
      setButtonText('confirm_membership');
    }
  };

  useEffect(() => {
    updateButtonText();
    window.addEventListener('resize', updateButtonText);

    return () => {
      window.removeEventListener('resize', updateButtonText);
    };
  }, []);

  useEffect(() => {
    request('/relation_directions')
      .then((res) => {
        setDirectionsList(res.data?.list || []);
      })
      .catch((error) => console.log(error));
  }, []);

  const getRelationId = (type) => {
    return relations.find(
      (item) => item?.key.toLowerCase() === type.toLowerCase()
    )?.id;
  };

  const handleOnSelectRelation = (event, value, reason) => {
    if (reason === 'clear') {
      setChosenRelation({});
    } else if (reason === 'selectOption') {
      setChosenRelation(value);
    }
    setReciprocalRelation({});
    setFormErrors((prev) => ({
      ...prev,
      relation: false,
    }));
  };

  const handleOnSelectReciprocalRelation = (event, value, reason) => {
    if (reason === 'clear') {
      setReciprocalRelation({});
    } else if (reason === 'selectOption') {
      setReciprocalRelation(value);
      setFormErrors((prev) => ({
        ...prev,
        reciprocalRelation: false,
      }));
    }
  };

  const handleSetRelation = async () => {
    if (!isDisabled()) {
      if (!chosenRelation?.id) {
        setFormErrors((prev) => ({
          ...prev,
          relation: true,
        }));
      }
      if (!reciprocalRelation?.id) {
        setFormErrors((prev) => ({
          ...prev,
          reciprocalRelation: true,
        }));
      }
      return;
    }
    const data = {
      grave_user: {
        grave_id: grave.id,
      },
    };

    if (activeTab === 'friend' || activeTab === 'other') {
      const relationId = getRelationId(activeTab);

      if (relationId) {
        data.grave_user.relation_id = relationId;
        data.grave_user.reciprocal_relation_id = relationId;
      }
    } else {
      data.grave_user.relation_id = chosenRelation.id;
      data.grave_user.reciprocal_relation_id = reciprocalRelation.id;
    }

    if (activeTab === 'friend') {
      data.grave_user.notes_attributes = [{ description: friendNote }];
    }

    if (!grave.current_user_relation) {
      createGraveRelationRequest(data);
    } else {
      navigate('/pricing/payment?payment_method_type=vipps');
    }
  };

  const createGraveRelationRequest = async (data) => {
    await request('/grave_relations', data, 'post')
      .then((res) => res.data)
      .then((data) => {
        dispatch(setGraveCurrentUserRelation(data));
        handleCloseRelationDialog();

        if (!user.is_subscribed) {
          localStorage.setItem('slug_for_redirect', grave.slug);
          navigate('/pricing/payment?payment_method_type=vipps');
        }
      })
      .catch((error) => {
        showUserNotification('Kan ikke oppdatere relasjonen', 'error');
        console.log(error);
      });
  };

  const filterDirectionList = (selectedRelation) => {
    if (isEmpty(selectedRelation)) return [];

    let filteredList = [];

    directionsList.map((item) => {
      if (item.from_relation.id === selectedRelation.id) {
        filteredList.push(item.to_relation);
      }

      return false;
    });

    if (filteredList.length === 0) {
      return [selectedRelation];
    } else {
      return filteredList;
    }
  };

  let formInputs = [
    {
      label: 'relation_label',
      extraLabels: { grave: grave.name },
      type: 'autocomplete',
      placeholder: 'select_relationship',
      value: chosenRelation,
      options: relations,
      autoComplete: 'off',
      error: formErrors.relation,
      errorKey: 'select_relation_error',
      disabled: graveUser || graveInvitation,
      labelStyle: {
        fontSize: '18px',
      },
      action: handleOnSelectRelation,
    },
    {
      label: 'reciprocal_label',
      extraLabels: { grave: grave.name },
      type: 'autocomplete',
      placeholder: 'select_relationship',
      value: reciprocalRelation,
      options: filterDirectionList(chosenRelation),
      autoComplete: 'off',
      error: formErrors.reciprocalRelation,
      errorKey: 'select_relation_error',
      disabled: graveUser || graveInvitation,
      labelStyle: {
        fontSize: '18px',
      },
      action: handleOnSelectReciprocalRelation,
    },
  ];

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    fetch_request('/api/relations', requestOptions)
      .then((response) => response.json())
      .then((relations) => {
        setRelations(relations);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleClaimGrave = async () => {
    if (
      chosenRelation.id &&
      reciprocalRelation.id &&
      !isEmpty(selectedFile) &&
      selectedDocument &&
      isChecked
    ) {
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      try {
        if (!isEmpty(selectedFile)) {
          const formData = new FormData();
          formData.append('death_certificate[grave_id]', grave.id);
          formData.append('death_certificate[image]', selectedFile.file);
          formData.append(
            'death_certificate[certificate_type]',
            selectedDocument
          );
          formData.append('relation_id', chosenRelation.id);
          formData.append('reciprocal_relation_id', reciprocalRelation.id);
          const response = await request(
            `/grave_claims`,
            formData,
            'post',
            headers
          );
          if (response.data) {
            dispatch(updateCurrentUserInClaimQueue());
            dispatch(setGraveCurrentUserRelation(response.data));
            handleCloseRelationDialog();
            if (!user.is_subscribed) {
              localStorage.setItem('slug_for_redirect', grave.slug);
              navigate('/pricing/payment?payment_method_type=vipps');
            }
          }
        }
      } catch (err) {
        showUserNotification('noe gikk galt, prøv igjen', 'error');
        console.log(err);
      }
    } else {
      showUserNotification('Alle felt må fylles ut', 'warning');
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleFriendNoteChange = (event) => {
    setFriendNote(event.target.value);
  };

  const isDisabled = () => {
    return (
      (chosenRelation.id &&
        reciprocalRelation.id &&
        activeTab === 'relative') ||
      activeTab === 'friend' ||
      activeTab === 'other'
    );
  };

  return (
    <CustomModal
      isOpen={openRelationDialog}
      closeModal={handleCloseRelationDialog}
      children={
        <SetRelationOrClaimDialog
          user={user}
          grave={grave}
          isChecked={isChecked}
          isClaimed={isClaimed}
          activeTab={activeTab}
          graveUser={graveUser}
          formInputs={formInputs}
          friendNote={friendNote}
          wantToClaim={wantToClaim}
          selectedFile={selectedFile}
          graveInvitation={graveInvitation}
          selectedDocument={selectedDocument}
          setWantToClaim={setWantToClaim}
          setIsChecked={setIsChecked}
          handleTabClick={handleTabClick}
          handleFriendNoteChange={handleFriendNoteChange}
          handelSelectedDocument={handelSelectedDocument}
          handelSetDocumentFile={handelSetDocumentFile}
        />
      }
      footer={
        <SetRelationFooter
          user={user}
          graveUser={graveUser}
          buttonText={buttonText}
          wantToClaim={wantToClaim}
          graveInvitation={graveInvitation}
          handleClose={handleCloseRelationDialog}
          handleSetRelation={handleSetRelation}
          handleClaimGrave={handleClaimGrave}
        />
      }
      style={{
        width: 600,
      }}
      title={<Heading grave={grave} />}
    />
  );
};

export default ConnectRelationsModal;
