import React from 'react';
import { Modal } from '@mui/material';
import { makeStyles } from '@mui/styles';
import closeIcon from '../../../../../img/CloseSmallIcon.svg';
import styles from './InvalidNumberModal.module.css';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    position: 'relative',
    width: '500px',
    backgroundColor: 'white',
    padding: '32px',
    borderRadius: '8px',
    outline: 'none',
  },
  modalBackground: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}));

const InvalidNumberModal = ({ open, handleClose }) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modal}
      BackdropProps={{
        className: classes.modalBackground,
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.modalContent}>
        <button className={styles['close-btn']} onClick={() => handleClose()}>
          <img src={closeIcon} alt='close' />
        </button>
        <div className={styles['true-icon']}>
          <div className={styles['saved-message']}>
            Vennligst oppgi et gyldig telefonnummer
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default InvalidNumberModal;
