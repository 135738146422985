import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import styles from './GraveInvitation.module.css';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { CONSTANTS } from '../../service/constants';
import { setGraveInvitationToken } from '../../redux/actions';

const GraveInvitation = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const invitationToken = searchParams.get('token');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user);
  const params = useParams();

  if (user && user?.slug) {
    navigate(-1);
  } else if (!invitationToken || !params.slug) {
    navigate('/auth/sign-in');
  }

  useEffect(() => {
    if (invitationToken) {
      axios
        .get(`${CONSTANTS.baseUrl}/api/grave_invitations/${invitationToken}`)
        .then((response) => {
          const data = response.data;
          if ((data?.user?.id || data?.user?.slug) && params.slug) {
            localStorage.setItem('slug_for_redirect', params.slug);
            dispatch(setGraveInvitationToken(data));
            navigate(`/deceased/${params.slug}`);
          } else if (params.slug) {
            localStorage.setItem('slug_for_redirect', params.slug);
            dispatch(setGraveInvitationToken(data));
            navigate(`/registration?token=${invitationToken}`);
          }
          setLoading(false);
        })
        .catch((error) => {
          navigate(`/auth/sign-in`);
          setLoading(false);
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [invitationToken]);

  return (
    loading && (
      <div className={styles['loading']}>
        <Loader />
      </div>
    )
  );
};

export default GraveInvitation;
