import React from 'react';
import IframeComponent from '../../../components/Iframe/IframeComponent';

const FeaturesLifeLine = () => {
  return (
    <IframeComponent
      title='Features life line'
      source='https://www.wix.nettgrav.no/features-livslinjen'
    />
  );
};

export default FeaturesLifeLine;
