import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Avatar } from '@mui/material';
import { request } from '../../../../../service/request';
import { makeStyles } from '@mui/styles';
import styles from './Member.module.css';
import BirthIcon from '../../../../../img/Birth.svg';
import { FormattedMessage, useIntl } from 'react-intl';
import showUserNotification from '../../../../../components/UserNotification/showUserNotification';
import UserConnectionModal from '../../../../../pages/NewUserProfile/Components/UserConnectionModal/UserConnectionModal';

const useStyles = makeStyles(() => ({
  large: {
    width: '93px !important',
    height: '93px !important',
    borderRadius: '10px !important',
  },
}));

const Member = ({
  member,
  relationList,
  updateSubscriptionMembers,
  subscriptionMembers,
  familyOwner,
  directionsList,
}) => {
  const intl = useIntl();
  const user = useSelector((state) => state.user.user || {});
  const classes = useStyles();
  const [openSetUserConnectionModal, setOpenUserConnectionModal] =
    useState(false);
  const [memberRelation, setMemberRelation] = useState(null);

  const handleNewConnection = () => {
    setOpenUserConnectionModal(true);
  };

  useEffect(() => {
    if (member?.connection) {
      const relation = relationList.find(
        (relation) => relation.id === member.connection.relation_id
      );
      if (relation) {
        setMemberRelation(relation.name);
      }
    }
  }, [member]);

  const handleCloseUserConnectionModal = () =>
    setOpenUserConnectionModal(false);

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}/${formattedMonth}/${year}`;
  };

  const setRelation = async (relation, reciprocal) => {
    const formData = {
      slug: member.slug,
      relation_id: relation.id,
      reciprocal_relation_id: reciprocal.id,
    };

    await request('/subscription_member_connections', formData, 'post')
      .then((res) => res.data)
      .then((data) => {
        showUserNotification(
          intl.formatMessage({ id: 'relation_request_send' }),
          'success'
        );
        const updatedSubscription =
          subscriptionMembers.subscription_members.map((user) => {
            if (user.user_id === member.user_id) {
              return { ...user, connection: { ...data } };
            } else {
              return user;
            }
          });
        updateSubscriptionMembers(updatedSubscription);
        handleCloseUserConnectionModal();
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      <div className={styles['member-card']}>
        <div className={styles['member-container']}>
          <div className={styles['user-avatar']}>
            <Avatar src={member.avatar} className={classes.large} />
          </div>
          <div className={styles['user-container']}>
            <div className={styles['user-name']}>{member.full_name}</div>
            {member?.date_of_birth && (
              <div className={styles['date-of-birth-container']}>
                <span>
                  <img src={BirthIcon} alt='date-of-birth' />
                  <span className={styles['date-of-birth']}>
                    {member?.date_of_birth
                      ? formatDate(member.date_of_birth)
                      : ''}
                  </span>
                </span>
              </div>
            )}

            <React.Fragment>
              {memberRelation ? (
                <div className={styles['relation-container']}>
                  <span className={styles['relation']}>{memberRelation}</span>
                  <span className={styles['current-user-relation']}>
                    {memberRelation} av {familyOwner.owner_name}
                  </span>
                </div>
              ) : member.user_id === user.id ? (
                <div className={styles['current-user']}>
                  <FormattedMessage id='settings_you' />
                </div>
              ) : (
                familyOwner.user_id === user.id &&
                !memberRelation && (
                  <button
                    className={styles['connect-btn']}
                    onClick={handleNewConnection}
                  >
                    <FormattedMessage id='settings_connect' />
                  </button>
                )
              )}
            </React.Fragment>
          </div>
        </div>
      </div>
      {openSetUserConnectionModal && (
        <UserConnectionModal
          openModal={openSetUserConnectionModal}
          relationsList={relationList}
          directionsList={directionsList}
          closeModal={handleCloseUserConnectionModal}
          user={member}
          onConfirm={setRelation}
        />
      )}
    </>
  );
};

export default Member;
