import React from 'react';
import styles from './SubscriptionInviteModal.module.css';
import { FormattedMessage, useIntl } from 'react-intl';
import { planKey } from '../../service/PlanKey';
import { request } from '../../service/request';
import showUserNotification from '../UserNotification/showUserNotification';
import { useDispatch } from 'react-redux';
import { setInformationAboutUser } from '../../redux/actions';
import CustomModal from '../CustomModal';

const SubscriptionInviteModal = ({ onOpen, onClose, currentUser }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const handelAction = (value) => {
    if (!currentUser) {
      return;
    }

    request(
      `/subscription_invitations/${currentUser.subscription_invitation.id}/${value}?user_id=${currentUser.id}`,
      {},
      'post'
    )
      .then((response) => {
        const { subscription_invitation, ...updatedUser } = currentUser;
        if (response.data.message) {
          showUserNotification(response.data.message, 'warning');
        } else {
          showUserNotification(
            intl.formatMessage({ id: 'accepted_message' }),
            'success'
          );
        }
        dispatch(setInformationAboutUser({ user: { ...updatedUser } }));
      })
      .catch((error) => {
        showUserNotification(
          error.response.data.errors || error.message,
          'error'
        );
      });
    onClose();
  };

  return (
    <CustomModal
      isOpen={onOpen}
      closeModal={onClose}
      children={
        <div className={styles['message-container']}>
          <span className={styles['message']}>
            <FormattedMessage
              id='subscription_invitation_message'
              values={{
                subscription: planKey(
                  currentUser.subscription_invitation?.subscription_key
                ),
                name: currentUser.subscription_invitation?.invited_by,
              }}
            />
          </span>
        </div>
      }
      title={
        <FormattedMessage id='hi' values={{ name: currentUser.full_name }} />
      }
      footer={
        <div className={styles['grave-information_content-verify']}>
          <button
            className={styles['rootDiscard']}
            onClick={() => handelAction('decline')}
          >
            <FormattedMessage id='decline' />
          </button>
          <button
            className={styles['rootVerify']}
            onClick={() => handelAction('accept')}
          >
            <FormattedMessage id='accept' />
          </button>
        </div>
      }
    />
  );
};

export default SubscriptionInviteModal;
