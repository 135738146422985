import React, { useState, useEffect } from 'react';
import styles from './SelectedImage.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { addCommentOnToggle } from '../../../../../redux/actions';
import DeleteIcon from '../../../../../img/DeleteIcon.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const SelectedImages = ({ photo }) => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState('');
  const currentComment = useSelector((state) => state.grave.commentOnToggle);

  useEffect(() => {
    if (photo.title) {
      setTitle(photo.title);
    }
  }, [photo]);

  const handelOnTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleAddTitle = () => {
    const newEmbeddedImages = [...currentComment.photos];
    const index = currentComment.photos.findIndex(
      (image) => image.id === photo.id
    );
    newEmbeddedImages.splice(index, 1, { ...photo, title: title });
    dispatch(
      addCommentOnToggle({
        ...currentComment,
        photos: [...newEmbeddedImages],
      })
    );
  };

  const handleRemoveImage = (id) => {
    const newImages = currentComment.photos.filter((image) => image.id !== id);
    dispatch(
      addCommentOnToggle({
        ...currentComment,
        photos: [...newImages],
      })
    );
  };

  const handleKeyDown = (e) => {
    const keyCode = e.keyCode || e.which;
    if (keyCode === 13) {
      e.preventDefault();
      handleAddTitle();
    }
  };

  return (
    <React.Fragment>
      <div className={styles['input-element']}>
        <div className={styles['title']}>
          Bildetittel <span>{`(valgfritt)`}</span>
        </div>
        <div className={styles['input-wrapper']}>
          <input
            type='text'
            value={title}
            placeholder='Bildetittel'
            onChange={handelOnTitleChange}
            onBlur={handleAddTitle}
            onKeyDown={(e) => handleKeyDown(e)}
          />
        </div>
      </div>
      <div className={styles['image-wrapper']}>
        <LazyLoadImage
          src={photo.image}
          alt='Image'
          className={styles['image']}
          effect='blur'
          onLoad={() => window.dispatchEvent(new Event('resize'))}
        />
        <button onClick={() => handleRemoveImage(photo.id)}>
          <img src={DeleteIcon} alt='Delete' />
        </button>
      </div>
    </React.Fragment>
  );
};

export default SelectedImages;
