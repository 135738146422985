import React from 'react';
import IframeComponent from "../../../components/Iframe/IframeComponent";

const HowToFindGrave = () => {
  return (
    <IframeComponent 
      title="How to Find Grave" 
      source="https://www.wix.nettgrav.no/find"
    />
  );
};

export default HowToFindGrave;
