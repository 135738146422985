import React from 'react';
import './HomepageScientistsFifth.css';
import Commas from '../../../../img/Commas.svg';
import { Button } from '@mui/material';
import Logo from '../../../../img/ScientistsLogo.svg';
import Respect from '../../../../img/ScientistsRespect.svg';
import Card from './Card';
import Research from '../../../../img/ScientistsResearch.svg';
import Teotc from '../../../../img/ScientistsTeotc.svg';
import TheEnd from '../../../../img/ScientistsTheEnd.svg';
import Grave from '../../../../img/ScientistsGrave.svg';
import Goals from '../../../../img/ScientistsGoals.svg';
import FLower from '../../../../img/ScientistsLastFlower.svg';

const HomepageScientistsFifth = () => {
   
    return(
        <div className='homepage-scientists_fifth'
        >
            <div className="homepage-scientists_fifth-content">
                <div className="homepage-scientists_fifth-content_header">
                    <div className='homepage-scientists_fifth-content_header-text'>
                        <img loading='lazy' src={Commas} alt='Commas'/>
                        <span>Nettgrav ønsker å inspirere til ny viten og fred ved å bli en betydelig kilde for både.</span>
                        <img loading='lazy' src={Commas} alt='Commas'/>
                    </div>
                    {/* <div className="homepage-scientists_fifth-content_header-input">
                        <input type='text' placeholder='Enter your email'/>
                        <Button className='homepage-scientists_fifth-content_header-input_button'>Send</Button>
                    </div> */}
                </div>
                <img loading='lazy' src={FLower} alt='FLower' className='homepage-scientists_fifth-content_main-flower'/>
                <div className="homepage-scientists_fifth-content_main">
                    <img loading='lazy' src={FLower} alt='FLower' className='homepage-scientists_fifth-content_main-flower mobile'/>
                    <span className='homepage-scientists_fifth-content_main-header'>La oss <span>vise</span> en større bevissthet</span>
                    <div className="homepage-scientists_fifth-content_main-blocks">
                        <div className="homepage-scientists_fifth-content_main-first first">
                            <Card height={408} image={Logo} header={'Nettgrav'} title={'Mens Nettgrav.no har som mål å fokusere på spirituell velvære og den sosiale forbindelsen mellom de avdøde og de som fortsatt lever, retter den andre fasen av TEOTC-utviklingen seg mot det vitenskapelige aspektet av livet vårt og tar sikte på å fullstendig løse bevissthetsproblemet i universet og forståelsen av vårt livsmål.'}/>
                            <Card height={412} image={Respect} header={'Døden'} title={'Døden er en prosess vi alle må møte på et tidspunkt. Når vi står overfor eksistensielle utfordringer, psykologiske utfordringer eller følelsesmessige reaksjoner knyttet til tap og dødelighet, er det viktig å se livet fra et større perspektiv.'}/>
                        </div>
                        <div className="homepage-scientists_fifth-content_main-first">
                            <Card height={356} image={Research} header={'Vitensenter'} title={'Etableringen av et forskningssenter er en nødvendig fase for å finne de skjulte svar vi søker i dag.'}/>
                            <Card height={464} image={Teotc} header={'Teotc'} title={'TEOTC (uttale: teo-tech) tror på at universet har bevissthet, og at vitenskapen er en del av dets kommunikasjon. Målet til TEOTC er å gi folk en vei til økt bevissthet og oppmerksomhet gjennom sine produkter. TEOTC er anerkjent for å ha en bærekraftig påvirkning på de økosystemene de opererer innenfor.'}/>
                        </div>
                        <div className="homepage-scientists_fifth-content_main-first">
                            <Card height={297} image={TheEnd} header={'The End Of Time'} title={'End of Time Corporation har som mål å bringe universet til deg.'}/>
                            <Card height={269} image={Grave} header={'Gravlunden'} title={'Frakobling fra fysiske gravlunder er et av målene, da de kun har praktisk betydning uten tilknytning til menneskets sjel.'}/>
                        </div>
                        <div className="homepage-scientists_fifth-content_main-first last">
                            <Card height={577} image={Goals} header={'Mål'} title={'Et gravsted skal være en kilde til fornyelse, slik at det levde liv fortsetter å ha mening for familien og det eksisterende samfunnet.'}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomepageScientistsFifth;
