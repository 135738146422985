import React, { useEffect, useState } from 'react';
import styles from './BillingCycle.module.css';
import { FormattedMessage, useIntl } from 'react-intl';
import { request } from '../../../../service/request';
import showUserNotification from '../../../../components/UserNotification/showUserNotification';
import { PaymentType } from '../../../../service/constants';
import { addMoreMembers } from '../../../Payment/atoms/Vipps/AddMembers';
import { createPayments } from '../../../Payment/atoms/CardPaymentMethod/CreatePayments';
import ProcessModal from '../../../Payment/atoms/ProcessModal';

const SaveButton = ({
  selectedPeriod,
  currentSubscription,
  fetchSubscriptionData,
  setOpenOptionsModal,
  paymentMethod,
}) => {
  const intl = useIntl();
  const userCount = 0;
  const [openModal, setOpenModal] = useState(false);

  const updateSubscription = () => {
    const requestPayload = {
      subscription: {
        period: selectedPeriod.key,
      },
    };
    request(
      `/subscriptions/${currentSubscription.id}.json`,
      requestPayload,
      'put'
    )
      .then((res) => {
        showUserNotification(
          intl.formatMessage({ id: 'update_billing' }),
          'success'
        );
        setOpenOptionsModal(false);
        setOpenModal(false);
        fetchSubscriptionData();
      })
      .catch((error) => {
        console.log(error);
        showUserNotification(error.response.data.error, 'error');
        setOpenModal(false);
      });
  };

  const updateSubscriptionCycle = () => {
    if (currentSubscription.period === selectedPeriod.key) {
      showUserNotification(
        intl.formatMessage({ id: 'different_cycle' }),
        'warning'
      );
    } else {
      setOpenModal(true);
      updateSubscription();
    }
  };

  return (
    <div>
      <button onClick={updateSubscriptionCycle} className={styles['save']}>
        <FormattedMessage id='confirm_billing_cycle' />
      </button>
      {openModal && (
        <ProcessModal
          open={openModal}
          messageKey={'waiting_update_billing_cycle'}
        />
      )}
    </div>
  );
};

export default SaveButton;
