import React from 'react';
import styles from './Plans.module.css';
import { FormattedMessage } from 'react-intl';

const UserCount = ({ labelKey, value }) => {
  return (
    <div className={styles['user-count-container']}>
      <div className={styles['user-count']}>
        <FormattedMessage id={labelKey} />
      </div>
      <div className={styles['user-count']}>{value}</div>
    </div>
  );
};

export default UserCount;
