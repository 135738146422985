import React, { useState, useEffect } from 'react';
import './Comment.css';
import DotsMenu from '../../../../img/DotsMenu.svg';
import Reply from '../../../../img/Reply.svg';
import LoveButton from '../../../../img/LoveButton.svg';
import { useSelector } from 'react-redux';
import { Avatar, Button, Fade, Menu, MenuItem } from '@mui/material';
import MenuItemBlock from '../MemoriesPostDetails/icon/MenuItemBlock';
import MenuItemBlockLast from '../MemoriesPostDetails/icon/MenuItemBlockLast';
import EditPen from '../../../../img/EditPen.svg';
import MenuBin from '../../../../img/MenuBin.svg';
import ReplyCreateForm from './ReplyCreateForm';
import ReplyComponent from './Reply';

const Comment = (props) => {
  const commentAuthor = useSelector((state) =>
    state.grave.grave.grave_users.find(
      (u) => u.user?.id === props.comment.user_id
    )
  );
  const userId = useSelector((state) => state.user.user.id);
  const [menuAnchorElem, setMenuAnchorElem] = useState(null);
  const [edit, setEdit] = useState(false);
  const [showReplies, setShowReplies] = useState(false);
  const [newValueOfComment, setNewValueOfComment] = useState(
    props.comment.comment ? props.comment.comment : ''
  );
  const [text, setText] = useState('');
  const toggleReplies = () => setShowReplies(!showReplies);

  const handleOpenEdit = () => setEdit(true);
  const handleCloseEdit = () => setEdit(false);

  const handleOpenMenu = (e) => setMenuAnchorElem(e.currentTarget);
  const handleCloseMenu = () => setMenuAnchorElem(null);

  const openEdit = () => {
    handleOpenEdit();
    handleCloseMenu();
  };

  useEffect(() => {
    setText(props.comment.comment);
  }, []);

  const editComment = (e) => {
    try {
      e.preventDefault();
      setText(newValueOfComment);
      props.onCommentEdit(props.comment, newValueOfComment);
      handleCloseEdit();
    } catch (err) {
      console.log(err);
    }
  };

  const deleteComment = () => {
    try {
      props.onCommentDelete(props.comment);
      handleCloseMenu();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className='comment'>
      <Avatar src={commentAuthor && commentAuthor.user?.user_avatar} />
      <div className='comment-info'>
        <div className='comment-info_content'>
          <div className='comment-info_header'>
            {commentAuthor && commentAuthor?.user?.full_name ? (
              <span>{commentAuthor.user?.full_name}</span>
            ) : null}
            {(userId === props.comment.user_id ||
              props.comment.can_destroy ||
              props.comment.can_edit) && (
              <div className='comment-info_header-details'>
                <Button
                  className='comment-info_header-details_dots'
                  onClick={handleOpenMenu}
                >
                  <img loading='lazy' src={DotsMenu} alt='DotsMenu' />
                </Button>
              </div>
            )}
          </div>
          <div className='comment-info_main'>
            {!edit && <span>{props.comment.comment}</span>}
            {edit && (
              <form
                onSubmit={editComment}
                className='music-post_content-title_form'
              >
                <textarea
                  value={newValueOfComment}
                  placeholder='Skriv her'
                  onChange={(e) => setNewValueOfComment(e.target.value)}
                  className='music-post_content-title_textarea_editing'
                />
                <div className='grave-btn-container'>
                  <div className='grave-information_content-verify'>
                    <button
                      type='button'
                      className='rootDiscard'
                      onClick={() => {
                        handleCloseEdit();
                      }}
                    >
                      Avbryt
                    </button>
                    <button className='rootVerify' type='submit'>
                      Lagre
                    </button>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
        <div className='image_comment-content_main-options'>
          <Button
            onClick={toggleReplies}
            className='image_comment-content_main-options_button'
          >
            <img
              loading='lazy'
              src={Reply}
              alt=''
              className='image_comment-content_main-options_button-image'
            />
            Svar
          </Button>
          {props.comment.replies?.length > 0 && (
            <span onClick={toggleReplies}>
              {props.comment.replies.length} Svar
            </span>
          )}
        </div>
        {showReplies && (
          <>
            <ReplyCreateForm
              onCreate={props.onReplyCreate}
              comment={props.comment}
            />
            {props.comment.replies?.map((rep, i) => {
              return (
                <ReplyComponent
                  reply={rep}
                  key={rep.id}
                  onEdit={props.onReplyEdit}
                  onDelete={props.onReplyDelete}
                />
              );
            })}
          </>
        )}
      </div>
      <Menu
        id='fade-menu'
        anchorEl={menuAnchorElem}
        keepMounted
        open={Boolean(menuAnchorElem)}
        onClose={handleCloseMenu}
        TransitionComponent={Fade}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {props.comment.can_edit && (
          <MenuItem onClick={openEdit}>
            <MenuItemBlock
              image={EditPen}
              title='Rediger'
              description='Legg til, endre eller fjern tekst'
            />
          </MenuItem>
        )}
        {props.comment.can_destroy && (
          <MenuItem onClick={deleteComment}>
            <MenuItemBlockLast
              image={MenuBin}
              title='Slett'
              description='Legg til, endre eller fjern tekst'
            />
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default Comment;
