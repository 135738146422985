import React from 'react';
import Icon from '../../../img/BenefitsIcon.svg';
import './AdditionalBenefits.css';
import { FormattedMessage } from 'react-intl';

const benefitsData = [
  {
    id: 1,
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
  {
    id: 4,
  },
];

const AdditionalBenefits = () => {
  return (
    <div className='benefits'>
      <div className='additional-benefits-heading'>
        <FormattedMessage id='benefits_title' />
      </div>
      <div className='additional-benefits'>
        {benefitsData.map((item) => (
          <div className='additional-benefits-details' key={item.id}>
            <span className='additional-benefits-icon'>
              <img src={Icon} alt='icon' />
            </span>
            <span className='additional-benefits-title'>
              <FormattedMessage id={`benefits_${item.id}_title`} />
            </span>
            <span className='additional-benefits-desc'>
              <FormattedMessage id={`benefits_${item.id}_description`} />
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdditionalBenefits;
