import React from 'react';
import { FormattedMessage } from 'react-intl';
import DatePickerInput from '../DatePicker/DatePicker';
import styles from './Input.module.css';
import InputField from './InputField';
import SelectInput from './SelectInput';
import PhoneNumber from '../PhoneNumberInput/PhoneNumber';
import isEmpty from 'lodash.isempty';
import TimePickerInput from '../TimePicker/TimerPicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import DateTimePickerInput from '../DateTimePicker/DateTimePicker';
import AutoComplete from './AutoComplete';
import GenderGroup from '../GenderButton/GenderGroup';

dayjs.extend(utc);
dayjs.extend(timezone);

const Input = ({ item }) => {
  return (
    <div className={styles['input-container']}>
      <div
        className={
          item.isVerified
            ? styles['info-label-container']
            : styles['label-container']
        }
      >
        <div className={styles['label']} style={item.labelStyle}>
          <FormattedMessage id={item.label} values={{ ...item?.extraLabels }} />
        </div>
        {item.changeInput && (
          <div
            className={styles['change-input']}
            onClick={() => item.changeInput.action(item.type)}
          >
            <FormattedMessage id={item.changeInput.label} />
          </div>
        )}
        {item.isVerified && (
          <div className={styles['info-label']}>
            <FormattedMessage id={item.infoLabel} />
          </div>
        )}
      </div>
      {item.type === 'gender' ? (
        <GenderGroup item={item} />
      ) : item.type === 'select' ? (
        <SelectInput item={item} />
      ) : item.type === 'datePicker' ? (
        <DatePickerInput
          date={item.value}
          setDatePickerValue={item.action}
          disableFuture={item.disableFuture}
          disablePast={item.disablePast}
          item={item}
        />
      ) : item.type === 'dateTimePicker' ? (
        <DateTimePickerInput
          date={item.value}
          setDatePickerValue={item.action}
          disableFuture={item.disableFuture}
          disablePast={item.disablePast}
        />
      ) : !isEmpty(item.country) ? (
        <PhoneNumber
          country={item.country.country}
          setCountry={item.country.setCountry}
          phoneNumber={item.value}
          onNumberChange={item.action}
          item={item}
        />
      ) : item.type === 'timePicker' ? (
        <TimePickerInput
          time={item.value ? dayjs(item.value).utc() : null}
          setTimePickerValue={item.action}
        />
      ) : item.type === 'autocomplete' ? (
        <AutoComplete item={item} />
      ) : (
        <InputField item={item} />
      )}
    </div>
  );
};

export default Input;
