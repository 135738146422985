import React, { useState } from 'react';
import styles from './TopHeader.module.css';
import { Button, Avatar } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import CustomModal from '../../../../../../components/CustomModal';
import CreateSparks from './CreateSparks';

const TopHeader = ({ user, sparkCategories, loading }) => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <div className={styles['container']}>
      <div className={styles['header']}>
        <Avatar src={user?.avatar} className={styles['avatar']} />
        <button className={styles['btn']} onClick={() => setOpenModal(true)}>
          <FormattedMessage
            id='in_your_mind'
            values={{ name: user?.full_name }}
          />
        </button>
      </div>
      {openModal && (
        <CustomModal
          isOpen={openModal}
          children={
            <CreateSparks
              user={user}
              sparkCategories={sparkCategories}
              loading={loading}
              closeModal={setOpenModal}
            />
          }
          closeModal={() => setOpenModal(false)}
          title={<FormattedMessage id='create_spark' />}
        />
      )}
    </div>
  );
};

export default TopHeader;
