import React from 'react';
import styles from './SparkFooter.module.css';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import ReactionButton from './ReactionButton';
import { FormattedMessage } from 'react-intl';

const FooterButtons = ({
  reactionList,
  handelOpenDrawer,
  spark,
  handelReaction,
  handelRemoveReaction,
  handelUpdateReaction,
  renderSparkModal,
}) => {
  return (
    <div className={styles['group-button']}>
      <ReactionButton
        reactionList={reactionList}
        spark={spark}
        handelReaction={handelReaction}
        handelRemoveReaction={handelRemoveReaction}
        handelUpdateReaction={handelUpdateReaction}
      />
      <button
        className={styles['comment']}
        onClick={() => {
          if (!renderSparkModal) handelOpenDrawer();
        }}
      >
        <ChatBubbleIcon style={{ fontSize: '16px' }} />
        <FormattedMessage id='comment' />
      </button>
    </div>
  );
};

export default FooterButtons;
