import React from 'react';
import BackIcon from '../../../../../img/BackIcon.svg';
import styles from './Header.module.css';
import { useDispatch } from 'react-redux';
import {
  setSelectedEvent,
  setSelectedEventYear,
  setOpenSelectedEvent,
  setEventPhotoIndex,
} from '../../../../../redux/actions';

const Header = () => {
  const dispatch = useDispatch();

  const handelClick = () => {
    dispatch(setSelectedEvent({}));
    dispatch(setSelectedEventYear(''));
    dispatch(setOpenSelectedEvent(false));
    dispatch(setEventPhotoIndex(0));
  };

  return (
    <div className={styles['header']}>
      <div className={styles['header-container']} onClick={() => handelClick()}>
        <img src={BackIcon} alt='Back' />
        Tilbake
      </div>
    </div>
  );
};

export default Header;
