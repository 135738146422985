import React from 'react';
import styles from './ImagesList.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { setEventPhotoIndex } from '../../../../../redux/actions';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const ImagesList = ({ photo, photoIndex, currentEventPhotoIndex }) => {
  const dispatch = useDispatch();
  console.log(photoIndex === currentEventPhotoIndex);

  return (
    <LazyLoadImage
      src={photo.file_url}
      alt='Image'
      className={
        currentEventPhotoIndex === photoIndex
          ? styles['selected-image']
          : styles['image']
      }
      effect='blur'
      onClick={() => {
        dispatch(setEventPhotoIndex(photoIndex));
      }}
    />
  );
};

export default ImagesList;
