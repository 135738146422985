import React from 'react';
import IframeComponent from '../../../components/Iframe/IframeComponent';

const SendMessage = () => {
  return (
    <IframeComponent
      title='Send a message'
      source='https://www.wix.nettgrav.no/contact-8'
    />
  );
};

export default SendMessage;
