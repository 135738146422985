import React from 'react';
import './HomepageInvestigateSixth.css';
import Image from '../../../../img/InvestigateLastPhoto.png';
import img223 from '../../../../img/223.png';

const HomepageInvestigateSixth = ({ButtonMailto}) => {
    return(
        <div className='homepage-investigate_sixth'>
            <div className="homepage-investigate_sixth-content">
                <img loading='lazy' src={document.body.clientWidth < 500 ? img223 : Image} alt='investigate' className='homepage-investigate_sixth-content_image' style = {{width: "100%"}}/>
                <div className="homepage-investigate_sixth-content_text">
                    <span>Ser frem til å møte deg.</span>
                    <ButtonMailto label="Kontakt" mailto="mailto:arekamark@nettgrav.no" />
                </div>
            </div>
        </div>
    )
}

export default HomepageInvestigateSixth;
