import React from 'react';
import styles from './ReactionIcon.module.css';
import { Tooltip } from '@mui/material';

const SmallReactionIcon = ({
  placement = 'top',
  title,
  icon,
  reactionType,
  handelOnClick,
  style,
  isClickAble = false,
}) => {
  return (
    <Tooltip title={title} placement={placement}>
      <img
        src={icon}
        className={styles[`${reactionType}-small`]}
        style={style}
        alt={reactionType}
        onClick={() => {
          if (isClickAble) handelOnClick(reactionType);
        }}
      />
    </Tooltip>
  );
};

export default SmallReactionIcon;
