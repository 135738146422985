import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import showUserNotification from '../../UserNotification/showUserNotification';
import styles from './CommentsArea.module.css';
import SendIcon from '@mui/icons-material/Send';

const CommentCreateForm = (props) => {
  const [messageText, setMessageText] = useState('');
  const user = useSelector((state) => state.user.user);
  const can_comment = useSelector((state) => state.grave.grave.can_comment);
  const handleCreateComment = () => {
    if (messageText?.trim()) {
      props.createComment(messageText);
      setMessageText('');
    } else showUserNotification('Skriv inn en kommentar', 'warning');
  };

  return (
    <div className={styles['memories_comments-area']}>
      {can_comment && (
        <div className={styles['memories_comments-area_content']}>
          <div className={styles['memories_comments-area_content-input']}>
            <textarea
              type='text'
              placeholder='Skriv her'
              value={messageText}
              onChange={(e) => setMessageText(e.target.value)}
            />
            <button
              onClick={handleCreateComment}
              className={styles['memories_comments-area_content-input_button']}
            >
              <SendIcon style={{ color: '#fff' }} />
            </button>
          </div>
          {/* </div> */}
        </div>
      )}
    </div>
  );
};
export default CommentCreateForm;
