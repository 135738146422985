import React from 'react';
import './AddNewCard.css';
import { FormattedMessage } from 'react-intl';

const AddNewCard = ({ handleShowCard, showCard, handleShowPayment }) => {
  return (
    <div
      className='user-payment-card'
      onClick={() => {
        handleShowCard(!showCard);
        handleShowPayment({});
      }}
    >
      <div className='payment-wrapper'>
        <div className='select'>
          <input type='checkbox' checked={showCard} readOnly />
          <label>
            <FormattedMessage id='add_payment_method' />
          </label>
        </div>
      </div>
    </div>
  );
};

export default AddNewCard;
