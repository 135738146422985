import React from 'react';
import styles from './Plans.module.css';

const SubscriptionButton = ({ children, onClickHandel, disabled }) => {
  return (
    <div className={styles['plan-subscribe-btn']}>
      <button
        disabled={disabled}
        onClick={() => {
          onClickHandel();
        }}
      >
        {children}
      </button>
    </div>
  );
};

export default SubscriptionButton;
