import React, { useEffect, useState } from 'react';
import styles from './PhotoComment.module.css';
import DeleteIcon from '../../../../img/DeleteIcon.svg';
import { useSelector, useDispatch } from 'react-redux';
import NextPhotoPointer from '../../../../img/OrangePointerNext.svg';
import PrevPhotoPointer from '../../../../img/OrangePointerPrev.svg';
import EditIcon from '../../../../img/EditIconWhite.svg';
import isEmpty from 'lodash.isempty';
import { addCommentOnToggle } from '../../../../redux/actions';

const PhotoComment = ({ images, deletePhoto, isUploading }) => {
  const [isHovered, setIsHovered] = useState(false);
  const dispatch = useDispatch();
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const [canEdit, setCanEdit] = useState(false);
  const currentComment = useSelector((state) => state.grave.commentOnToggle);

  const prevPhoto = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (currentPhotoIndex === 0) {
      setCurrentPhotoIndex(images.length - 1);
    } else {
      setCurrentPhotoIndex(currentPhotoIndex - 1);
    }
  };

  const nextPhoto = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (currentPhotoIndex === images.length - 1) {
      setCurrentPhotoIndex(0);
      return 0;
    } else {
      setCurrentPhotoIndex(currentPhotoIndex + 1);
    }
  };

  const changeTitle = (value, id) => {
    if (currentComment.photos && currentComment.photos.length > 0) {
      const photos = [...currentComment.photos];
      const index = currentComment.photos.findIndex((photo) => photo.id === id);
      photos.splice(index, 1, { ...images[index], title: value });
      dispatch(
        addCommentOnToggle({
          ...currentComment,
          photos: [...photos],
        })
      );
    } else if (
      currentComment.embeddedPhotos &&
      currentComment.embeddedPhotos.length > 0
    ) {
      const photos = [...currentComment.embeddedPhotos];
      const index = currentComment.embeddedPhotos.findIndex(
        (photo) => photo.id === id
      );
      photos.splice(index, 1, { ...images[currentPhotoIndex], title: value });
      dispatch(
        addCommentOnToggle({
          ...currentComment,
          embeddedPhotos: [...photos],
        })
      );
    }
  };

  return (
    <React.Fragment>
      {images.length > 0 && (
        <div className={styles['grave-picture-gallery_photos-photo']}>
          <img
            className={styles['gallery_photos-photo']}
            loading='lazy'
            src={images[currentPhotoIndex].image}
            alt='Display-image'
          />
          {!isUploading && (
            <button
              className={styles['delete-btn']}
              onClick={() => {
                deletePhoto(images[currentPhotoIndex].id);
                setCurrentPhotoIndex(0);
              }}
            >
              <img loading='lazy' src={DeleteIcon} alt='Delete' />
            </button>
          )}
          {images.length > 1 && (
            <React.Fragment>
              <button
                className={styles['buttons-prev']}
                onClick={(event) => prevPhoto(event)}
              >
                <img
                  loading='lazy'
                  src={PrevPhotoPointer}
                  alt='PrevPhotoPointer'
                />
              </button>
              <button
                className={styles['buttons-next']}
                onClick={(event) => nextPhoto(event)}
              >
                <img
                  loading='lazy'
                  src={NextPhotoPointer}
                  alt='PrevPhotoPointer'
                />
              </button>
            </React.Fragment>
          )}
        </div>
      )}
      {!isEmpty(images[currentPhotoIndex].title) && (
        <div className={styles['input-wrapper']}>
          <input
            type='text'
            value={images[currentPhotoIndex].title}
            placeholder='Bildetittel'
            onChange={(event) =>
              changeTitle(event.target.value, images[currentPhotoIndex].id)
            }
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default PhotoComment;
