import React from 'react';

const TabIcon = (props) => {
  return (
    <img
      loading='lazy'
      src={props.img}
      alt='tab'
      style={{
        height: '20px',
      }}
    />
  );
};

export default TabIcon;
