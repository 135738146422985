import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NextPhotoPointer from '../../../../../img/OrangePointerNext.svg';
import PrevPhotoPointer from '../../../../../img/OrangePointerPrev.svg';
import EmptyDot from '../../../../../img/EmptyDot.svg';
import styles from './EventImages.module.css';
import OrangeDot from '../../../../../img/FilledDot.svg';
import ManAvatar from '../../../../../img/BoyAvatar.svg';
import EventImageModal from '../AddEventImageModal/AddEventImageModal';
import {
  setSelectedEvent,
  setSelectedEventYear,
  setOpenSelectedEvent,
  addLifeEventPhotos,
  addLifeEventVideos,
} from '../../../../../redux/actions';

const EventImages = ({
  photos,
  event,
  year,
  handelSaveModal,
  setOpenUpgradeModal,
}) => {
  const dispatch = useDispatch();
  const [isHovered, setIsHovered] = useState(false);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const [touchStartX, setTouchStartX] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const user = useSelector((state) => state.user.user);

  const findDisplayImageIndex = async () => {
    try {
      const displayImageIndex = await photos.findIndex(
        (image) => image.category === 'display'
      );
      if (displayImageIndex > 0) {
        setCurrentPhotoIndex(displayImageIndex);
      } else {
        setCurrentPhotoIndex(0);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    findDisplayImageIndex();
  }, [photos]);

  const prevPhoto = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (currentPhotoIndex === 0) {
      setCurrentPhotoIndex(photos.length - 1);
    } else {
      setCurrentPhotoIndex(currentPhotoIndex - 1);
    }
  };

  const nextPhoto = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (currentPhotoIndex === photos.length - 1) {
      setCurrentPhotoIndex(0);
      return 0;
    } else {
      setCurrentPhotoIndex(currentPhotoIndex + 1);
    }
  };

  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchEnd = (e) => {
    const touchEndX = e.changedTouches[0].clientX;
    const touchDistance = touchEndX - touchStartX;

    const swipeThreshold = 50;

    if (Math.abs(touchDistance) > swipeThreshold) {
      if (touchDistance > 0) {
        prevPhoto();
      } else {
        nextPhoto();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchend', handleTouchEnd);

    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, [currentPhotoIndex]);

  const handelChangeImage = (event, index) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setCurrentPhotoIndex(index);
  };

  const handelStopPagination = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const handelOpenModal = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (user.is_subscribed) {
      setOpenModal(true);
    } else {
      setOpenUpgradeModal(true);
    }
  };

  const handelClick = (e) => {
    e.preventDefault();
    dispatch(setSelectedEvent(event));
    dispatch(setSelectedEventYear(year));
    dispatch(setOpenSelectedEvent(true));
  };

  return (
    <React.Fragment>
      <div
        className={styles['grave-picture-gallery_photos-photo']}
        onClick={(e) => handelClick(e)}
      >
        {photos.length > 0 ? (
          <img
            className={styles['gallery_photos-photo']}
            loading='lazy'
            src={
              currentPhotoIndex === 0
                ? photos[0].file_url
                : photos[currentPhotoIndex].file_url
            }
            alt='Display-image'
          />
        ) : (
          <div className={styles['empty-image']}>
            {event.creator_id === user.id && (
              <button
                onClick={(event) => {
                  handelOpenModal(event);
                }}
              >
                Legg til bilde/video
              </button>
            )}
          </div>
        )}
        {photos.length > 1 && (
          <React.Fragment>
            <button
              className={`${styles['buttons-prev']} ${styles['show-buttons']}`}
              onClick={(event) => prevPhoto(event)}
            >
              <img
                loading='lazy'
                src={PrevPhotoPointer}
                alt='PrevPhotoPointer'
              />
            </button>
            <button
              className={`${styles['buttons-next']} ${styles['show-buttons']}`}
              onClick={(event) => nextPhoto(event)}
            >
              <img
                loading='lazy'
                src={NextPhotoPointer}
                alt='PrevPhotoPointer'
              />
            </button>
          </React.Fragment>
        )}
        {photos.length > 1 && (
          <div
            className={styles['dots-container']}
            onClick={(event) => handelStopPagination(event)}
          >
            {photos.map((photo, index) => (
              <img
                style={{
                  height: '10px',
                  width: '10px',
                }}
                onClick={(event) => handelChangeImage(event, index)}
                key={index}
                loading='lazy'
                src={currentPhotoIndex === index ? OrangeDot : EmptyDot}
                alt='dot'
              />
            ))}
          </div>
        )}
      </div>
      {openModal && (
        <EventImageModal
          onOpen={openModal}
          onClose={() => {
            setOpenModal(false);
            dispatch(addLifeEventPhotos([]));
            dispatch(addLifeEventVideos([]));
          }}
          event={event}
          title='Legg til bilder'
          handelSaveModal={handelSaveModal}
        />
      )}
    </React.Fragment>
  );
};

export default EventImages;
