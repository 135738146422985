import React, { useState, useEffect } from 'react';
import GraveMenuBar from './atoms/GraveWall/GraveMenuBar/GraveMenuBar';
import GraveWall from './atoms/GraveWall/GraveWall';
import { useSelector } from 'react-redux';
import styles from './DeceasedTopHeader.module.css';

const DeceasedGraveWall = () => {
  const grave = useSelector((state) => state.grave.grave);
  const isAuth = useSelector((state) => state.auth.isAuthorized);
  const user = useSelector((state) => state.user) || {};
  const [hideMenu, setHideMenu] = useState(false);
  const [graveWallMenuKey, setGraveWallMenuKey] = useState(1);

  const handelGraveWallMenuKey = (key) => {
    setGraveWallMenuKey(key);
  };

  const handleResize = () => {
    if (window.innerWidth >= 1020) {
      setHideMenu(true);
    } else {
      setHideMenu(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [hideMenu, graveWallMenuKey]);

  return (
    <div className={styles['about-container']}>
      {hideMenu && (
        <GraveMenuBar
          handelAboutMenuKey={handelGraveWallMenuKey}
          aboutMenuKey={graveWallMenuKey}
        />
      )}
      <GraveWall
        user={user}
        isAuth={isAuth}
        grave={grave}
        hideMenu={hideMenu}
        aboutMenuKey={graveWallMenuKey}
        handelAboutMenuKey={handelGraveWallMenuKey}
      />
    </div>
  );
};

export default DeceasedGraveWall;
