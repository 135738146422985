import React from 'react';
import styles from './Alert.module.css';

const Alert = ({ children, style }) => {
  return (
    <div className={styles['subscription-alert']} style={style}>
      {children}
    </div>
  );
};

export default Alert;
