export const makeFileFromURL = async (imageUrl, fileName) => {
  try {
    const response = await fetch(imageUrl);
    const blob = await response.blob(); // Create a Blob object from the fetched content
    const file = new File([blob], fileName, { type: blob.type }); // Create a File object using the Blob
    return file;
  } catch (error) {
    console.error('Error creating file from URL:', error);
    throw error;
  }
};

export const dataURLtoBlob = (dataURL) => {
  const arr = dataURL.split(',');
  const mime = arr[0].match(/:(.*?);/)?.[1] || '';
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};
