import { CONSTANTS } from '../../../../service/constants';
import showUserNotification from '../../../../components/UserNotification/showUserNotification';

export const createAgreement = (
  phoneNumber,
  productId,
  subscriptionCount,
  token,
  period,
  discount
) => {
  return new Promise((resolve, reject) => {
    if (phoneNumber.trim().length !== 8) {
      const errorMessage = 'Vennligst oppgi et gyldig telefonnummer';
      showUserNotification(errorMessage, 'error');
      reject(new Error(errorMessage));
      return;
    }

    const requestPayload = {
      agreement: {
        phone_number: phoneNumber,
        currency: 'NOK',
      },
      subscription: {
        user_count: parseInt(subscriptionCount),
        product_id: productId,
        period,
        discount,
      },
    };

    fetch(`${CONSTANTS.baseUrl}/api/vipps/subscriptions.json`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestPayload),
    })
      .then((response) => {
        return response.json().then((data) => {
          if (!response.ok) {
            const errorMessage =
              data.error || 'Noe gikk galt. Vennligst prøv igjen senere.';
            reject(new Error(errorMessage));
          } else {
            resolve(data.agreement);
          }
        });
      })
      .catch((err) => {
        reject(new Error(err.message));
      });
  });
};
