import React from 'react';
import IframeComponent from '../../../components/Iframe/IframeComponent';

const TheEndOfTime = () => {
  return (
    <IframeComponent
      title='The end of time center'
      source='https://www.wix.nettgrav.no/the-end-of-time-center'
    />
  );
};

export default TheEndOfTime;
