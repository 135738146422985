import React from 'react';
import './Notifications.css';
import NotificationRequestAccepted from '../../../img/NotificationRequestAccepted.svg';
import NotificationRequestRejected from '../../../img/NotificationRequestRejected.svg';

const RequestActionLabels = ({ requestStatus }) => {
  return (
    <>
      {requestStatus === 'accepted' && (
        <div className='buttons_status accepted'>
          <img
            loading='lazy'
            src={NotificationRequestAccepted}
            alt='accepted'
          />{' '}
          Godkjent
        </div>
      )}

      {requestStatus === 'rejected' && (
        <div className='buttons_status rejected'>
          <img
            loading='lazy'
            src={NotificationRequestRejected}
            alt='rejected'
          />{' '}
          Avvis
        </div>
      )}
    </>
  );
};

export default RequestActionLabels;
