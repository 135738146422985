import React, { useState } from 'react';
import EndMore from '../../../img/EndMore.svg';
import EndLess from '../../../img/EndLess.svg';
import './FrequentlyAskedQuestions.css';
import { FormattedMessage } from 'react-intl';

const FrequentlyAskedQuestions = ({ question }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div className='faq-container' key={question.id}>
      <div className='faq-question' onClick={handleClick}>
        <span className='question'>
          <FormattedMessage id={`faqs_${question.id}_question`} />
        </span>
        <span>
          {open ? (
            <img src={EndLess} alt='Less' />
          ) : (
            <img src={EndMore} alt='More' />
          )}
        </span>
      </div>
      {open && (
        <div className='answer'>
          <span>
            <FormattedMessage id={`faqs_${question.id}_answer`} />
          </span>
        </div>
      )}
    </div>
  );
};

export default FrequentlyAskedQuestions;
