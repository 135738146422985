import React, { useEffect, useState } from 'react';
import styles from './PaymentMethod.module.css';
import { FormattedMessage } from 'react-intl';
import vippsLogo from '../../../../img/VippsLogo.svg';
import Visa from '../../../../img/Visa.svg';
import MaterCard from '../../../../img/MasterCard.svg';
import {
  PaymentType,
  SubscriptionAlertsKeys,
} from '../../../../service/constants';
import Status from '../../../../components/Status/Status';
import TitleRow from '../TitleRow/TitleRow';

const PaymentMethod = ({
  paymentMethod,
  dueDate,
  setOpenOptionsModal,
  messageKey,
  setActionKey,
  action,
  setOpenAddPaymentModal,
}) => {
  const [cardType, setCardType] = useState(null);

  useEffect(() => {
    if (paymentMethod.card_type === 'visa') {
      setCardType(Visa);
    } else if (paymentMethod.card_type === 'mastercard') {
      setCardType(MaterCard);
    } else if (paymentMethod.payment_type === PaymentType.vipps) {
      setCardType(vippsLogo);
    }
  }, [paymentMethod]);

  const getActionObject = () => {
    const { active, paymentDue, cancelled } = SubscriptionAlertsKeys;

    if (!action || action.key === active || action.key === paymentDue) {
      return {
        action: <FormattedMessage id='change' />,
        handelAction: () => setOpenAddPaymentModal(true),
      };
    }

    if (
      !(
        action.key === cancelled ||
        action.key === active ||
        action.key === paymentDue
      )
    ) {
      return action;
    }

    return null;
  };

  const getPaymentMethod = () => (
    <div className={styles['payment-value-container']}>
      {cardType && <img src={cardType} alt='card' style={{ height: 25 }} />}
      {paymentMethod.payment_type === PaymentType.card && (
        <span className={styles['value']}>
          <FormattedMessage id='settings_cardEnd' /> {paymentMethod.card_number}
        </span>
      )}
      {paymentMethod.payment_type === PaymentType.vipps && (
        <span className={styles['value']}>+47{paymentMethod.phone_number}</span>
      )}
      {paymentMethod.destroyed_at && (
        <Status statusKey='payment_method_inactive' />
      )}
    </div>
  );

  return (
    <div className={styles['payment-details']}>
      <TitleRow
        value={getPaymentMethod()}
        title={<FormattedMessage id='settings_billingMethod' />}
        action={getActionObject()}
        setOpenOptionsModal={setOpenOptionsModal}
        messageKey={messageKey}
        setActionKey={setActionKey}
      />
      <div className={styles['detail-due-date']}>
        <div className={styles['title']}>
          <FormattedMessage id='settings_nextDue' />:
        </div>
        <div className={styles['due-date']}>{dueDate}</div>
      </div>
    </div>
  );
};

export default PaymentMethod;
