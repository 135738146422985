import React from 'react';
import './LearnMore.css';
import LearnMoreFirst from './LearnMore/LearnMoreFirst';
import LearnMoreFourth from './LearnMore/LearnMoreFourth';
import LearnMoreSecond from './LearnMore/LearnMoreSecond';
import LearnMoreThird from './LearnMore/LearnMoreThird';

const LearnMore = (props) => {
    return(
        <div className='learn-more'>
            <LearnMoreFirst/>
            <div className='learn-more_road'>
                <LearnMoreSecond/>
                <LearnMoreThird/>
                <LearnMoreFourth/>
            </div>
        </div>
    )
}

export default LearnMore;