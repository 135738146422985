import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './ConnectionRelation.module.css';

const totalFeatures = [1, 2, 3, 4, 5];

const Features = () => {
  return (
    <div>
      <ul className={styles['list']}>
        {totalFeatures.map((id) => (
          <li key={id} className={styles['list-item']}>
            <span className={styles['title']}>
              <FormattedMessage id={`feature_title_${id}`} />
            </span>
            <span>
              : <FormattedMessage id={`feature_description_${id}`} />
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Features;
