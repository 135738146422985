import React from 'react';
import styles from './ManageMembers.module.css';
import { FormattedMessage } from 'react-intl';

const SubscriptionButton = ({ onClickHandler, titleKey }) => {
  return (
    <div className={styles['subscribe-btn']}>
      <button variant='contained' onClick={() => onClickHandler()}>
        <FormattedMessage id={titleKey} />
      </button>
    </div>
  );
};

export default SubscriptionButton;
