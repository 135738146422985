import React from 'react';
import { FormattedMessage } from 'react-intl';
import MembersCounter from '../../../Payment/atoms/PlanPaymentDetails/MembersCounter';
import PlanDetailsRow from '../../../Payment/atoms/PlanPaymentDetails/PlanDetailsRow';

const TotalAdded = ({
  totalCount,
  planAction,
  addMembers,
  removeMembers,
  currentSubscription,
}) => {
  const getSubtotal = () => {
    return (
      totalCount *
      currentSubscription.plan_price *
      currentSubscription.period_length
    );
  };

  return (
    totalCount > 0 &&
    planAction === 'add' && (
      <React.Fragment>
        <MembersCounter
          addMembers={addMembers}
          removeMembers={removeMembers}
          count={totalCount}
          messageKey={'added_members'}
        />
        <PlanDetailsRow
          messageKey='current_members'
          value={currentSubscription.user_count}
        />
        <PlanDetailsRow
          messageKey='total_members'
          value={totalCount + currentSubscription.user_count}
        />
        <PlanDetailsRow
          messageKey='subscription_length'
          value={<FormattedMessage id={currentSubscription.period} />}
        />
        <PlanDetailsRow
          messageKey='total'
          value={`NOK ${getSubtotal().toFixed(2)}`}
        />
      </React.Fragment>
    )
  );
};

export default TotalAdded;
