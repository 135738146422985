import React from 'react';
import './HomepageGraveFirst.css';
import Main from '../../../../img/HomepageGraveFirst.jpg';
import Line from '../../../../img/HomepageGraveFirstLine.svg';
import Mobile from '../../../../img/MobileHomepageBG.jpg';

const HomepageGraveFirst = (props) => {
    return(
        <div className='homepage-grave_first'>
            <div className="homepage-grave_first-content">
                <div className='homepage-grave_first-content_header'>
                    <img loading='lazy' src={Main} alt='main' className='homepage-grave_first-content_header-main'/>
                    <img loading='lazy' src={Line} alt='Line' className='homepage-grave_first-content_header-line'/>
                    <img loading='lazy' src={Mobile} alt='Mobile' className='homepage-grave_first-content_header-mobile'/>
                    <span className='homepage-grave_first-content_header-text'>Nettgrav.no er graven på nett</span>
                </div>
            </div>
        </div>
    )
}

export default HomepageGraveFirst;