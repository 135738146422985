import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './LoggedIn.css';
import Notifications from './Notifications';
import Avatar from '@mui/material/Avatar';
import { Menu, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PlusIcon from '../../../img/plusIcon.svg';
import IconButton from './IconButton';
import LanguageSelector from '../../LanguageSelector';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  setConfirmSignOut,
  setInformationAboutUser,
  setSubscription,
  setUserNotificationsRedux,
  setSubscriptionInvitationData,
  setTranslation,
  setPendingRelation,
} from '../../../redux/actions';
import isEmpty from 'lodash.isempty';
import ArrowDarkDown from '../../../img/ArrowDarkDown.svg';
import question from '../../../img/HelAndSupport.svg';
import Settings from '../../../img/Settings.svg';
import SignOut from '../../../img/SginOut.svg';
import { setScreenRedux } from '../../../redux/actions';
import Tooltip from '@mui/material/Tooltip';
import { fetch_request, request } from '../../../service/request';
import { saveLastVisitedPage } from '../../../service/NavigationService';
import { FormattedMessage } from 'react-intl';
import { LanguageKeys } from '../../../service/constants';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import UpgradePlanModal from '../../Modals/UpgradePlanModal';

const useStyles = makeStyles(() => ({
  white: {
    color: '#36434B !important',
    backgroundColor: '#fafafa !important',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.161) !important',
  },
  children: {
    marginBottom: '8px !important',
    whiteSpace: 'normal !important',
    display: 'flex !important',
    width: '250px',
    backgroundColor: '#fff !important',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)!important',
    },
  },
  circleAndQuestion: {
    backgroundImage: `url(${question})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  rootTooltip: {
    backgroundColor: '#fff',
    boxShadow: '0px 4px 10px rgba(33, 32, 32, 0.18)',
    borderRadius: '6px',
    maxWidth: 'none',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '150%',
    color: '#36434B',
    boxSizing: 'border-box',
    padding: '19px 14px 18px 16px',
  },
  rootArrow: {
    color: '#fff',
  },
  nettgravDefaultBg: {
    color: '#FFF',
    backgroundColor: '#36434B',
  },
  avatarMenu: {
    backgroundColor: '#36434B !important',
    color: '#FFF',
    marginLeft: '25px',
    '@media (max-width: 700px)': {
      margin: '0 7px',
    },
  },
}));

const LoggedIn = ({ navigate, ...props }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuValue, setMenuValue] = React.useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const [openNotification, setOpenNotification] = React.useState(false);
  const [language, setLanguage] = useState('');
  const [languages, setLanguages] = useState([]);
  const user = useSelector((state) => state.user.user);
  const userSlag = localStorage.getItem('user-slag');
  const isAuth = useSelector((state) => state.auth.isAuthorized);
  const [openUpgradeModal, setOpenUpgradeModal] = useState(false);

  useEffect(() => {
    if (!isEmpty(user) && !isEmpty(user.preferred_language)) {
      setLanguage(user.preferred_language.key);
    }
  }, [user]);

  //set user data
  useEffect(() => {
    const id =
      localStorage.getItem('user-slug') || sessionStorage.getItem('user-slug');

    if (id) {
      fetch_request(`/api/users/${id}`, {
        method: 'get',
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      })
        .then((response) => response.json())
        .then((data) => {
          props.setInformationAboutUser(data);
          localStorage.setItem('user-slug', data.user?.slug);
          const user = data.user;
          if (!isEmpty(user.preferred_language)) {
            setLanguage(user.preferred_language.key);
          }
          if (user && user.subscription) {
            dispatch(setSubscription(user.subscription));
          }
        });
    }
    return () => setMenuValue(0);
  }, [localStorage.getItem('user-slug'), sessionStorage.getItem('user-slug')]);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!isAuth) return;
    fetch_request('/api/languages', {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    })
      .then((res) => res.json())
      .then((data) => setLanguages(data.languages));
  }, [userSlag]);

  const signOut = () => {
    handleClose(6);
    props.setConfirmSignOut();
    saveLastVisitedPage(null);
    props.setUserNotificationsRedux([]);
    dispatch(setSubscriptionInvitationData({}));
    dispatch(setTranslation(LanguageKeys.Norwegian));
    dispatch(setPendingRelation({}));
    localStorage.clear();
    navigate('/');
  };

  const handleClose = (value) => {
    setAnchorEl(null);
    setMenuValue(value);
  };

  const handleAvatarClick = () => {
    const slug =
      localStorage.getItem('user-slug') || sessionStorage.getItem('user-slug');
    navigate(`/member/${slug}`);
    dispatch(setScreenRedux(''));
    setAnchorEl(null);
  };

  const userAvatar = () => {
    if (props.user?.avatar) {
      return (
        <img
          loading='lazy'
          src={props.user.avatar}
          alt='user gender men avatar'
          style={{ width: '100%' }}
        />
      );
    } else return props.user?.full_name?.charAt(0)?.toUpperCase();
  };

  const handleChange = async (value) => {
    if (language.trim() !== '' && language === value.key) return;
    const formData = new FormData();
    formData.append(
      'user[preferred_language_attributes][language_id]',
      value.id
    );
    try {
      const response = await request(`/users/${user.slug}`, formData, 'put');
      if (response.status >= 200 && response.status < 300 && response.data) {
        try {
          dispatch(setInformationAboutUser(response.data));
          localStorage.setItem('user-slug', response.data.user?.slug);
          setLanguage(response.data.user.preferred_language.key);
        } catch (err) {
          console.log(err);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const menuItem = [
    {
      key: 'life-page',
      title: <FormattedMessage id='the_life_page' />,
      description: <FormattedMessage id='life_page_description' />,
      action: handleAvatarClick,
      avatar: props.user?.avatar
        ? props.user.avatar
        : props.user?.full_name?.charAt(0)?.toUpperCase(),
    },
    {
      key: 'settings',
      title: <FormattedMessage id='settings' />,
      description: <FormattedMessage id='settings_description' />,
      action: () => {
        handleClose(3);
        navigate('/settings');
      },
      avatar: Settings,
    },
    {
      key: 'create-grave',
      title: <FormattedMessage id='create_new_grave' />,
      description: <FormattedMessage id='create_grave_description' />,
      action: () => {
        handleClose(3);
        if (user.is_subscribed) {
          navigate('/ny-grav');
        } else {
          setOpenUpgradeModal(true);
        }
      },
      avatar: PlusIcon,
    },
    {
      key: 'logout',
      title: <FormattedMessage id='logout' />,
      description: <FormattedMessage id='logout_description' />,
      action: signOut,
      avatar: SignOut,
    },
  ];

  return (
    <React.Fragment>
      <UpgradePlanModal
        isOpen={openUpgradeModal}
        onClose={() => setOpenUpgradeModal(false)}
        body='create_grave_body'
        title='upgrade_modal_heading'
      />
      <div className='loged-in'>
        <div>
          <LanguageSelector
            languages={languages}
            onChanged={handleChange}
            preferredLanguage={language}
          />
        </div>
        <div className='hide-on-mobile'>
          <Tooltip
            arrow
            placement='bottom'
            title={<FormattedMessage id='the_life_page' />}
            classes={{ tooltip: classes.rootTooltip, arrow: classes.rootArrow }}
          >
            <Avatar
              onClick={handleAvatarClick}
              style={{ borderRadius: '35%' }}
              className={classes.avatarMenu}
            >
              {userAvatar()}
            </Avatar>
          </Tooltip>
        </div>

        <div className='hide-on-mobile'>
          <Tooltip
            arrow
            placement='bottom'
            title={<FormattedMessage id='create_new_grave' />}
            classes={{ tooltip: classes.rootTooltip, arrow: classes.rootArrow }}
          >
            <div
              onClick={() => {
                if (user.is_subscribed) {
                  navigate('/ny-grav');
                } else {
                  setOpenUpgradeModal(true);
                }
              }}
            >
              <IconButton icon={PlusIcon} />
            </div>
          </Tooltip>
        </div>

        <div onClick={() => setOpenNotification(true)}>
          <Notifications
            open={openNotification}
            handleCloseNotification={() => setOpenNotification(false)}
          />
        </div>
        <div>
          <IconButton
            icon={ArrowDarkDown}
            handleClick={(event) => setAnchorEl(event.currentTarget)}
            aria-controls='customized-menu'
            aria-haspopup='true'
            variant='contained'
          />
          <Menu
            id='long-menu'
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            elevation={3}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {menuItem.map((item, index) => {
              if (item.key === 'create-grave' && screenWidth > 500) {
                return null;
              }
              return (
                <MenuItem
                  key={index}
                  onClick={item.action}
                  className={classes.children}
                >
                  <Avatar className={classes.white}>
                    {item.key === 'life-page' ? (
                      userAvatar()
                    ) : (
                      <img
                        loading='lazy'
                        src={item.avatar}
                        style={{ width: index === 0 ? '100%' : 'auto' }}
                      />
                    )}
                  </Avatar>
                  <div className='menu-settings_text'>
                    <div className='menu-settings_text-header'>
                      {item.title}
                    </div>
                    <div className='menu-settings_text-subheader'>
                      {item.description}
                    </div>
                  </div>
                </MenuItem>
              );
            })}
          </Menu>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  id: state.auth.userId,
});

const mapDispatchToProps = {
  setConfirmSignOut,
  setInformationAboutUser,
  setUserNotificationsRedux,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoggedIn);
