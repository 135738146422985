import userNotification from './channels/userNotification';

import store from '../redux/store';
import isEmpty from 'lodash.isempty';

const subscribe = (user) => {
  if (!user) {
    return;
  }

  const { user: userState } = store.getState();
  if (isEmpty(userState)) {
    return;
  }

  const cable = userState.actionCableConsumer;
  if (isEmpty(cable)) {
    return;
  }

  userNotification(cable, user.id);
};

export default subscribe;
