import React from 'react';
import styles from './DocumentSubmission.module.css';
import greenTickIcon from '../../../../../img/GreenTickIcon.svg';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../../../../../components/PrimaryButton';

const buttonStyles = {
  backgroundColor: '#e4f3fd',
  color: '#404d56',
};

const DocumentSubmission = ({ grave, user }) => {
  const navigate = useNavigate();
  return (
    <div className={styles['submission-container']}>
      <img src={greenTickIcon} alt='success' className={styles['image']} />
      <div className={styles['heading']}>Dokumentet er sendt inn</div>
      <div className={styles['sub-heading']}>
        Du vil motta en bekreftelsesmelding når bevis på forholdet er godkjent
        av Nettgrav
      </div>
      <div className={styles['btn-container']}>
        <PrimaryButton
          onClick={() => navigate(`/member/${user.slug}`)}
          style={buttonStyles}
        >
          Gå til livssiden
        </PrimaryButton>
        <PrimaryButton onClick={() => navigate(`/deceased/${grave.slug}`)}>
          Tilbake til graven
        </PrimaryButton>
      </div>
    </div>
  );
};

export default DocumentSubmission;
