import React, { useState, useEffect } from 'react';
import Filter from '../../img/Filter.svg';
import './SubMenu.css';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import SearchGraves from '../../containers/Search/SearchGraves';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setReturnPage } from '../../redux/actions';
import PrimaryButton from '../PrimaryButton';

const SubMenu = (props) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(1);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const user = useSelector((state) => state.user.user);
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClick = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const onClickNewGrave = () => {
    dispatch(setReturnPage('/ny-grav'));
    if (!user || !user.is_subscribed) {
      setRedirect(false);
      navigate('/pricing');
    } else {
      setRedirect(true);
      navigate('/ny-grav');
    }
  };

  useEffect(() => {
    props.searchInput === '' && !isFilterOpen ? setValue(0) : setValue(1);
  }, [props.searchInput, isFilterOpen]);

  const FilterButton = () => (
    <React.Fragment>
      <img loading='lazy' src={Filter} alt='Filter' className='sub-menu_img' />
      Filter
    </React.Fragment>
  );

  return (
    <div className='sub-menu'>
      <TabPanel value={1} index={1}>
        <div className='button-container'>
          <PrimaryButton onClick={handleClick} children={<FilterButton />} />
          <PrimaryButton
            onClick={onClickNewGrave}
            children={'Opprett ny nettgrav'}
          />
        </div>
        <SearchGraves
          searchInput={props.searchInput}
          isFilterOpen={isFilterOpen}
        />
      </TabPanel>
    </div>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const mapStateToProps = (state) => {
  return {
    searchInput: state.search.searchInput,
    peopleArr: state.search.peopleArr,
  };
};

export default connect(mapStateToProps, null)(SubMenu);
