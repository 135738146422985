import { setUserUnReadNotifications } from '../../redux/actions';

import store from '../../redux/store';

const userNotification = (cable, userId) => {
  if (!isAlreadySubscribed(cable, userId)) {
    cable.subscriptions.create(
      {
        channel: 'NotificationChannel',
        user_id: userId,
      },
      {
        connected: () => {
          // console.log('connected');
          // store.dispatch(setNotificationChannelSubscribedRedux(true))
        },
        disconnected: () => {
          // console.log('disconnected');
          // store.dispatch(setNotificationChannelSubscribedRedux(false))
        },
        received: (message) => {
          const messagesList = JSON.parse(message);
          store.dispatch(setUserUnReadNotifications(messagesList));
        },
      }
    );
  }
};

const isAlreadySubscribed = (cable, userId) => {
  const subscriptions =
    (cable.subscriptions && cable.subscriptions?.subscriptions) || [];

  if (subscriptions.length === 0) {
    return false;
  }

  return subscriptions.some((item) => {
    const parsedItem = JSON.parse(item.identifier);
    return (
      parsedItem.channel === 'NotificationChannel' &&
      parsedItem.user_id === userId
    );
  });
};
export default userNotification;
