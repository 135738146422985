import React from 'react';
import Feature from './Feature';
import styles from './Plans.module.css';

const Features = ({ plan }) => {
  return (
    <div className={styles['plan-features']}>
      <Feature plan={plan} />
    </div>
  );
};

export default Features;
