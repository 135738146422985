import { ProductKeys } from './constants';

const planKey = (key) => {
  if (key === ProductKeys.conscious_plus) {
    return key.replace(/-./g, (match) => match.charAt(1).toUpperCase());
  } else {
    return key;
  }
};

const period = (key) => {
  let result = key.replace(/^\d+_/, '');
  result = result.replace(/_./g, (match) => match.charAt(1).toUpperCase());
  return result;
};

const getPeriodLength = (key) => {
  const match = key.match(/^\d+/);
  return match ? match[0] : null;
};

const daysInYear = 365;
const daysInMonth = 30;

const getValidityUnit = (validityInDays) => {
  if (validityInDays >= daysInYear) {
    let years = Math.floor(validityInDays / daysInYear);
    return `year${years > 1 ? 's' : ''}`;
  } else if (validityInDays >= daysInMonth) {
    let months = Math.floor(validityInDays / daysInMonth);
    return `month${months > 1 ? 's' : ''}`;
  } else {
    return 'month';
  }
};

export { getPeriodLength, planKey, period, getValidityUnit };
