import React from 'react';
import { FormattedMessage } from 'react-intl';
import PlanDetailsRow from '../../../Payment/atoms/PlanPaymentDetails/PlanDetailsRow';
import MembersCounter from '../../../Payment/atoms/PlanPaymentDetails/MembersCounter';

const TotalRemove = ({
  addMembers,
  removeMembers,
  totalCount,
  planAction,
  currentSubscription,
}) => {
  return (
    planAction === 'remove' && (
      <React.Fragment>
        <MembersCounter
          addMembers={addMembers}
          removeMembers={removeMembers}
          count={totalCount}
          messageKey={'removed_members'}
        />
        <PlanDetailsRow
          messageKey='allowed_members'
          value={currentSubscription.user_count}
        />
        <PlanDetailsRow
          messageKey='total_members'
          value={currentSubscription.user_count - totalCount}
        />
        <PlanDetailsRow
          messageKey='subscription_length'
          value={<FormattedMessage id={currentSubscription.period} />}
        />
      </React.Fragment>
    )
  );
};

export default TotalRemove;
