import React, { useState } from 'react';
import { Popover, MenuItem } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import isEmpty from 'lodash.isempty';
import { privacyList } from '../CreateSparks/CreateSparks';
import styles from './Categories.module.css';
import { FormattedMessage } from 'react-intl';

const Visibility = ({ privacy, setPrivacy }) => {
  const [anchorElement, setAnchorElement] = useState(null);
  const open = Boolean(anchorElement);
  const id = open ? 'simple-popover' : undefined;

  const handleClose = () => {
    setAnchorElement(null);
  };

  const clickOnPrivacy = (privacy) => {
    setPrivacy(privacy);
    handleClose();
  };
  return (
    <React.Fragment>
      <div
        className={styles['list']}
        onClick={(event) => setAnchorElement(event.currentTarget)}
      >
        <div className={styles['item']}>
          {!isEmpty(privacy) ? (
            <FormattedMessage id={privacy.key} />
          ) : (
            'Privacy'
          )}
        </div>
        <ArrowDropDownIcon
          className={styles['icon']}
          style={{ rotate: open ? '180deg' : '' }}
        />
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorElement}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'bottom',
        }}
      >
        {privacyList &&
          privacyList.map((privacy) => (
            <MenuItem
              key={privacy.id}
              value={privacy.id}
              onClick={() => clickOnPrivacy(privacy)}
              className={styles['menu-item']}
            >
              <FormattedMessage id={privacy.key} />
            </MenuItem>
          ))}
      </Popover>
    </React.Fragment>
  );
};

export default Visibility;
