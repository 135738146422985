import React from 'react';
import { useNavigate } from 'react-router-dom';
import IframeComponent from '../../../components/Iframe/IframeComponent';
import styles from './Contact.module.css';
import { ProductKeys } from '../../../service/constants';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

const Contact = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user);
  return (
    <React.Fragment>
      {user && user.subscription.key === ProductKeys.conscious_plus ? (
        <div className={styles['contact']}>
          <span className={styles['header']}>
            <FormattedMessage id='contact_person' />
          </span>
          <IframeComponent
            title='Contact'
            source='https://www.wix.nettgrav.no/contact-8'
          />
        </div>
      ) : (
        navigate('/settings')
      )}
    </React.Fragment>
  );
};

export default Contact;
