import { gql } from '@apollo/client';

export const COMMENTS_QUERY = gql`
  query Comments($sparkId: ID!, $parentId: ID, $first: Int, $after: String) {
    comments(
      sparkId: $sparkId
      parentId: $parentId
      first: $first
      after: $after
    ) {
      edges {
        node {
          id
          description
          repliesCount
          commentableType
          commentableId
          parentId
          user {
            id
            fullName
            slug
            displayPicture
          }
          createdAt
          updatedAt
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;
export const COMMENT_QUERY = gql`
  query Comment($id: ID!) {
    comment(id: $id) {
      id
      description
      repliesCount
      commentableType
      commentableId
      user {
        id
        fullName
        slug
        displayPicture
      }
      createdAt
      updatedAt
    }
  }
`;
