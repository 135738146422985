import React from 'react';
import styles from './ResetPassword.module.css';
import ResetForm from '../../containers/NewAuth/ResetForm/ResetForm';

const ResetPassword = () => {
  return (
    <div className={styles['reset-page']}>
      <ResetForm />
    </div>
  );
};

export default ResetPassword;
