import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './CreateMembership.module.css';

const Header = ({ messageKey, headingKey }) => {
  return (
    <div className={styles['header-container']}>
      <div className={styles['welcome']}>
        <FormattedMessage id={headingKey} />
      </div>
      <div className={styles['create-account']}>
        <FormattedMessage id={messageKey} />
      </div>
    </div>
  );
};

export default Header;
