import React from 'react';
import { FormattedMessage } from 'react-intl';
import Features from '../../../containers/NewDeceased/atoms/ConnectRelationsModal/Features';
import styles from './SetSelfRelation.module.css';
import PrimaryButton from '../../../components/PrimaryButton';

const SetSelfRelation = ({ grave, handelOpenModal }) => {
  return (
    <React.Fragment>
      <div className={styles['heading']}>
        <FormattedMessage
          id='do_you_know'
          values={{ graveName: grave?.name }}
        />
      </div>
      <div className={styles['sub-heading']}>
        <FormattedMessage
          id='set_relation_free_description'
          values={{ graveName: grave?.name }}
        />
      </div>
      <div className={styles['sub-heading']}>
        <FormattedMessage id='set_relation_access' />
      </div>
      <Features />
      <div className={styles['sub-heading']}>
        <FormattedMessage id='set_relation_info_message' />
      </div>
      <div className={styles['sub-heading']}>
        <FormattedMessage id='click_button_below' />
      </div>
      <div className={styles['btn-container']}>
        <PrimaryButton
          onClick={handelOpenModal}
          children={<FormattedMessage id='set_relation' />}
        />
      </div>
    </React.Fragment>
  );
};

export default SetSelfRelation;
