import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PrimaryButton from '../../../../../components/PrimaryButton/index';
import Input from '../../../../../components/Input/Input';
import showUserNotification from '../../../../../components/UserNotification/showUserNotification';
import styles from './AnniversaryFormModal.module.css';
import isEmpty from 'lodash.isempty';

const AnniversaryForm = ({
  onCreate,
  anniversary,
  handleUpdateAnniversary,
}) => {
  const intl = useIntl();
  const [formData, setFormData] = useState({
    name: '',
    dateTime: null,
  });

  useEffect(() => {
    if (anniversary && !isEmpty(anniversary)) {
      setFormData({
        name: anniversary.name,
        dateTime: anniversary.datetime,
      });
    } else {
      setFormData({
        name: '',
        date: null,
      });
    }
  }, [anniversary]);

  let formInputs = [
    {
      label: 'settings_name',
      type: 'text',
      placeholder: 'settings_name',
      value: formData.name,
      error: false,
      disabled: false,
      action: (value) => {
        if (value[0] !== ' ') {
          setFormData({
            ...formData,
            name: value
              .replace(/[0-9]/g, '')
              .replace(/[\"'@#!$%^&*()_+|~=`{}\[\]:;'<>?,.\/]/g, ''),
          });
        }
      },
    },
    {
      label: 'date',
      type: 'dateTimePicker',
      disableFuture: false,
      error: false,
      disablePast: false,
      value: formData.dateTime,
      action: (value) => {
        setFormData({ ...formData, dateTime: value });
      },
    },
  ];

  const handleSubmit = (event) => {
    event.preventDefault();
    const { name, dateTime } = formData;
    const isValid = name.trim() !== '' && dateTime !== null;

    if (isValid) {
      if (anniversary && !isEmpty(anniversary)) {
        handleUpdateAnniversary(formData, anniversary.id);
      } else {
        onCreate(formData);
      }
    } else {
      showUserNotification(intl.formatMessage({ id: 'filled_out' }), 'warning');
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {formInputs.map((item, index) => (
          <Input item={item} key={index} />
        ))}
        <div className={styles['save-container']}>
          <PrimaryButton
            type='submit'
            children={<FormattedMessage id='auth_save' />}
          />
        </div>
      </form>
    </div>
  );
};

export default AnniversaryForm;
