import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './ClaimMessage.module.css';

const ClaimQueueMessage = ({ grave }) => {
  return (
    <div className={styles['claim-message-container']}>
      <div className={styles['claim-message']}>
        <FormattedMessage
          id='claim_request_queue'
          values={{ graveName: grave.name }}
        />
      </div>
    </div>
  );
};

export default ClaimQueueMessage;
