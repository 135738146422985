import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setBillingInformation } from '../../../../redux/actions';
import isEmpty from 'lodash.isempty';
import { ProductKeys } from '../../../../service/constants';
import BillingInputs from '../NewCard/BillingInputs';

const BillingInformation = ({
  handleDisable,
  billingInformation,
  isCardValue,
  countries,
}) => {
  const dispatch = useDispatch();
  const [isValidEmail, setIsValidEmail] = useState(null);
  const [isValid, setIsValid] = useState(null);
  const selectedPlan = useSelector((state) => state.selectedPlan || null);
  const currentSubscriptionPlan = useSelector(
    (state) => state.currentSubscriptionPlan.currentSubscriptionPlan || null
  );

  useEffect(() => {
    const areAllValuesFilled = Object.values(billingInformation).every(
      (value) => value !== ''
    );
    if (
      areAllValuesFilled &&
      !isValidEmail &&
      !isValid &&
      isCardValue &&
      !isEmpty(selectedPlan) &&
      !isEmpty(currentSubscriptionPlan)
    ) {
      const newSubscription = selectedPlan.key !== ProductKeys.free;
      if (newSubscription) {
        handleDisable(false);
      }
    } else if (
      areAllValuesFilled &&
      !isValidEmail &&
      !isValid &&
      isCardValue &&
      !isEmpty(selectedPlan)
    ) {
      handleDisable(false);
    } else if (selectedPlan.key !== ProductKeys.free) {
      handleDisable(true);
    }
  }, [
    billingInformation,
    isValidEmail,
    isValid,
    isCardValue,
    currentSubscriptionPlan,
    selectedPlan,
  ]);

  const onSetBillingInfo = (value) => {
    dispatch(setBillingInformation(value));
  };

  return (
    <BillingInputs
      billingInformation={billingInformation}
      setBillingInformation={onSetBillingInfo}
      setIsValidEmail={setIsValidEmail}
      isValidEmail={isValidEmail}
      isValid={isValid}
      setIsValid={setIsValid}
      countries={countries}
    />
  );
};

export default BillingInformation;
