import React, { useEffect, useState } from 'react';
import styles from './Event.module.css';
import EventImages from '../../EventImages/EventImages';
import Tabs from '../Tabs/Tabs';
import LifeEventDetails from '../../LifeEventDetails/LifeEventDetails';
import EventVideos from '../../EventVideos/EventVideos';

const Event = ({
  event,
  year,
  handelSaveModal,
  setOpenUpgradeModal,
  countries,
}) => {
  const [tab, setTab] = useState(0);

  useEffect(() => {
    if (event.life_event_images.length > 0) {
      setTab(0);
    } else if (event.life_event_videos.length > 0) {
      setTab(1);
    } else {
      setTab(0);
    }
  }, [event]);
  return (
    <React.Fragment>
      {tab === 0 && (
        <EventImages
          photos={event.life_event_images}
          event={event}
          year={year}
          handelSaveModal={handelSaveModal}
          setOpenUpgradeModal={setOpenUpgradeModal}
        />
      )}
      {tab === 1 && (
        <EventVideos
          videos={event.life_event_videos}
          event={event}
          year={year}
        />
      )}
      {event.life_event_images.length > 0 &&
        event.life_event_videos.length > 0 && (
          <Tabs tab={tab} setTab={setTab} />
        )}
      <LifeEventDetails
        event={event}
        countries={countries}
        setOpenUpgradeModal={setOpenUpgradeModal}
      />
    </React.Fragment>
  );
};

export default Event;
