import React from 'react';
import styles from './MemoriesHeader.module.css';
import Avatar from '@mui/material/Avatar';
import { makeStyles } from '@mui/styles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FamilySilhouette from '../../../../img/FamilySilhouette.svg';
import Everyone from '../../../../img/Everyone.svg';
import OnlyMe from '../../../../img/OnlyMe.svg';
import noGender from '../../../../img/SexType.svg';

const useStyles = makeStyles(( ) => ({
  large: {
    width: '55px',
    height: '55px',
  },
}));

const MemoriesHeader = ({ user, setPrivacy, privacy }) => {
  const classes = useStyles();
  const handleChange = (event) => {
    setPrivacy(event.target.value);
  };

  return (
    <div className={styles['memories-header']}>
      <div className={styles['memories-header_left']}>
        <Avatar src={user.user.avatar} className={classes.large} />
        <span>{user.user.full_name}</span>
      </div>

      <div className={styles['memories-header_right']}>
        <FormControl variant='filled' className={styles['form-control']}>
          <Select
            className={styles['memories-header_right-select']}
            id='cemetery-select'
            value={privacy}
            onChange={(event) => handleChange(event)}
          >
            <MenuItem value='public'>
              <img
                loading='lazy'
                src={Everyone}
                alt='Family Silhouette'
                className={styles['memories-header_right-icon']}
              />
              Alle
            </MenuItem>
            <MenuItem value='relatives'>
              <img
                loading='lazy'
                src={FamilySilhouette}
                alt='Family Silhouette'
                className={styles['memories-header_right-icon']}
              />
              Familien
            </MenuItem>
            <MenuItem value='private'>
              <img
                loading='lazy'
                src={OnlyMe}
                alt='Family Silhouette'
                className={styles['memories-header_right-icon']}
                style={{ marginRight: '8px', marginLeft: '3px' }}
              />
              Privat
            </MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default MemoriesHeader;
