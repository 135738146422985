import React from 'react';
import styles from './BillingInformation.module.css';
import { FormattedMessage } from 'react-intl';

const BillingInformation = ({ billingInformation }) => {
  return (
    <div className={styles['billing-information']}>
      <div className={styles['billing-heading']}>
        <FormattedMessage id='settings_billingInfo' />
      </div>
      <div className={styles['detail']}>
        <div className={styles['title']}>
          <FormattedMessage id='settings_name' />:
        </div>
        <div className={styles['value']}>{billingInformation.name}</div>
      </div>
      <div className={styles['detail']}>
        <div className={styles['title']}>
          <FormattedMessage id='settings_address' />:
        </div>
        <div className={styles['value']}>{billingInformation.address}</div>
      </div>
      <div className={styles['detail']}>
        <div className={styles['title']}>
          <FormattedMessage id='settings_billingCountry' />:
        </div>
        <div className={styles['value']}>{billingInformation.country}</div>
      </div>
      <div className={styles['detail']}>
        <div className={styles['title']}>
          <FormattedMessage id='settings_billingEmail' />:
        </div>
        <div className={styles['value']}>{billingInformation.email}</div>
      </div>
      <div className={styles['detail']}>
        <div className={styles['title']}>
          <FormattedMessage id='settings_postCode' />:
        </div>
        <div className={styles['value']}>{billingInformation.postal_code}</div>
      </div>
      <div className={styles['detail']}>
        <div className={styles['title']}>
          <FormattedMessage id='settings_telephoneNumber' />:
        </div>
        <div className={styles['value']}>{billingInformation.phone_number}</div>
      </div>
    </div>
  );
};

export default BillingInformation;
