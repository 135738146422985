import React from 'react';
import PrimaryButton from '../../../../../../components/PrimaryButton';
import { FormattedMessage } from 'react-intl';
import styles from './Footer.module.css';
import SelectMedia from '../SelectedMedia/SelectMedia';
import ImageGallery from '../../../../../../img/ImageGallery.svg';
import YoutubeIcon from '../../../../../../img/youtube.svg';
import AudioIcon from '../../../../../../img/AudioIcon.svg';
import SendIcon from '@mui/icons-material/Send';

const Footer = ({
  image,
  audio,
  video,
  text,
  submitQuestion,
  selectTheAudio,
  selectTheVideo,
  selectTheImage,
}) => {
  return (
    <div className={styles['footer-wrapper']}>
      <div className={styles['input-group']}>
        {/* {!(image || audio || video) && (
          <React.Fragment>
            <SelectMedia
              onChange={selectTheImage}
              icon={ImageGallery}
              fileType={'image/*'}
            />
            <SelectMedia
              onChange={selectTheVideo}
              icon={YoutubeIcon}
              fileType={'video/*'}
            />
            <SelectMedia
              onChange={selectTheAudio}
              icon={AudioIcon}
              fileType={'audio/*'}
            />
          </React.Fragment>
        )} */}
      </div>
      <PrimaryButton
        onClick={submitQuestion}
        disabled={!text.length > 0}
        style={{ width: '100%' }}
        children={<FormattedMessage id='create_spark' />}
      />
    </div>
  );
};

export default Footer;
