import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';

const Contacts = () => {
  const location = useLocation();

  const items = [
    {
      title: <FormattedMessage id='contact_us' />,
      url: '/send-message',
    },
    {
      title: <FormattedMessage id='business_inquiries' />,
      url: '#',
    },
    {
      title: <FormattedMessage id='feedback' />,
      url: '#',
    },
  ];
  return (
    <div className='item-container'>
      <div className='title'>
        <FormattedMessage id='contact' />
      </div>
      <div className='link-container'>
        {items.map((item, index) => (
          <Link
            className={location.pathname === item.url ? 'active-link' : 'link'}
            key={index}
            to={item.url}
          >
            {item.title}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Contacts;
