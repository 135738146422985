import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import styles from './VideoPost.module.css';
import ReactPlayer from 'react-player';
import NextPhotoPointer from '../../../../img/OrangePointerNext.svg';
import PrevPhotoPointer from '../../../../img/OrangePointerPrev.svg';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const useStyle = makeStyles(() => ({
  rootDotsButton: {
    marginLeft: 'auto',
    minWidth: '40px',
  },
  rootFinalButtons: {
    width: '50%',
    borderRadius: '10px',
    height: '47px',
  },
  rootDialog: {
    maxWidth: 'none !important',
    borderRadius: '10px !important',
  },
  rootScroolBody: {
    padding: '20px 0px !important',
  },
}));

const VideoPost = (props) => {
  const classes = useStyle();
  const [openModal, setOpenModal] = useState(false);
  const grave = useSelector((state) => state.grave.grave);
  // const [currentIndex, setCurrentIndex] = useState(0);
  const [amountOfVideos, setAmountOfVideos] = useState(props.videos.length);
  const navigate = useNavigate();
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const [playing, setPlaying] = useState(false);

  const prevPhoto = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (props.currentVideo === 0) {
      props.setCurrentVideo(amountOfVideos - 1);
    } else {
      props.setCurrentVideo(props.currentVideo - 1);
    }
  };
  const nextPhoto = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (props.currentVideo === amountOfVideos - 1) {
      props.setCurrentVideo(0);
      return 0;
    } else {
      props.setCurrentVideo(props.currentVideo + 1);
    }
  };

  return (
    <div>
      <div className='music-post_content'>
        <ReactPlayer
          url={
            props.videos.length > 0 && props.videos[props.currentVideo].file_url
          }
          playing={playing}
          onPlay={() => setPlaying(true)}
          onPause={() => {
            setPlaying(false);
            navigate(
              `/deceased/${grave.slug}/memory/${props.post.id}?video=${
                props.videos[props.currentVideo].id
              }`
            );
          }}
          className={styles['video-player']}
          controls={true}
          playIcon={true}
        />

        {props.videos.length > 1 && (
          <React.Fragment>
            <button
              className={styles['buttons-prev']}
              onClick={(event) => prevPhoto(event)}
            >
              <img
                loading='lazy'
                src={PrevPhotoPointer}
                alt='PrevPhotoPointer'
              />
            </button>
            <button
              className={styles['buttons-next']}
              onClick={(event) => nextPhoto(event)}
            >
              <img
                loading='lazy'
                src={NextPhotoPointer}
                alt='PrevPhotoPointer'
              />
            </button>
          </React.Fragment>
        )}
      </div>
      {/* <Dialog
        open={openModal}
        onClose={handleCloseModal}
        className='cropper-modal_modal'
        scroll='body'
        classes={{
          paper: classes.rootDialog,
          scrollBody: classes.rootScroolBody,
        }}
      >
        <VideoPostModal post={props.post} closeModal={handleCloseModal} />
      </Dialog> */}
      {/* {props.videos.length > 0 &&
        props.videos.map((video, index) => {
          <div className='music-post_content' key={index}>
            <ReactPlayer url={video.description} width='100%' />
          </div>;
        })} */}
    </div>
  );
};

export default VideoPost;
