import React from 'react';
import styles from './AboutItem.module.css';
import ExpandIcon from './ExpandIcon';

const Header = (props) => {
  return (
    <div
      className={styles['about-item-header']}
      onClick={() => props.setIsExpanded(!props.isExpanded)}
    >
      <span>{props.title}</span>
      {props.hideEdit ? (
        <ExpandIcon isExpanded={props.isExpanded} />
      ) : (
        props.children
      )}
    </div>
  );
};

export default Header;
