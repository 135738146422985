import React from 'react';
import styles from './ReactionPopover.module.css';
import ReactionIcon from '../../Components/ReactionIcon';

const ReactionPopover = ({ onReactionClick, reactionList }) => {
  return (
    <div className={styles['container']} onClick={(e) => e.stopPropagation()}>
      {Object.entries(reactionList).map(([key, reaction]) => (
        <ReactionIcon
          key={key}
          reactionType={reaction.type}
          title={reaction.title}
          handelOnClick={onReactionClick}
          icon={reaction.icon}
        />
      ))}
    </div>
  );
};

export default ReactionPopover;
