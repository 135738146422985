import React, { useState, useEffect, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import axios from 'axios';
import isEmpty from 'lodash.isempty';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ActiveGiftMembership from './ActiveGiftMembership';
import { fetch_request, request } from '../../../service/request';
import {
  CONSTANTS,
  PaymentType,
  ProductKeys,
  SubscriptionStatuses,
} from '../../../service/constants';
import PopupModal from '../../Payment/atoms/Modal/Modal';
import CancelSubscriptionModal from './CancelSubscriptionModal/CancelSubscriptionModal';
import BillingInformation from './BillingInformation/BillingInformation';
import PaidSubscription from './PaidSubscription/PaidSubscription';
import FreeSubscription from './FreeSubscription/FreeSubscription';
import ProcessModal from '../../Payment/atoms/ProcessModal';
import ErrorModal from '../../Payment/atoms/ErrorModal/ErrorModal';
import showUserNotification from '../../../components/UserNotification/showUserNotification';
import Loader from '../../../components/Loader/Loader';
import ChangePlanOptions from './ChangePlanOptions/ChangePlanOptions';
import CancelSubscription from './CancelSubscription/CancelSubscription';
import CustomModal from '../../../components/CustomModal';
import RenewAgreement from './RenewAgreement/RenewAgreement';
import { planKey } from '../../../service/PlanKey';
import styles from './Subscriptions.module.css';
import SubscriptionAlert from './SubscriptionAlert/SubscriptionAlert';
import SubscriptionChanges from './SubscriptionAlert/SubscriptionChanges';
import { formatDate } from '../../../service/Utils';
import AddPaymentMethod from './AddPaymentMethod/AddPaymentMethod';
import { getListOfCountries } from '../../../redux/actions';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import SubscriptionInviteModal from '../../../components/SubscriptionInviteModal/SubscriptionInviteModal';

const appearance = {
  theme: 'night',
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY, appearance);

const Subscriptions = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const agreementId = localStorage.getItem('agreementId');
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state.user?.user) || {};
  const subscription = user.subscription || {};
  const [openModal, setOpenModal] = useState(false);
  const [processMessageKey, setProcessMessageKey] =
    useState('payment_progress');
  const [loading, setLoading] = useState(true);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [loadingGiftMembers, setLoadingGiftMembers] = useState(true);
  const [currentSubscription, setCurrentSubscription] = useState({});
  const [giftedSubscriptionMembers, setGiftedSubscriptionMembers] = useState(
    []
  );
  const [paymentMethod, setPaymentMethod] = useState({});
  const [billingInformation, setBillingInformation] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [agreementHash, setAgreementHash] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [messageKey, setMessageKey] = useState('');
  const [openOptionsModal, setOpenOptionsModal] = useState(false);
  const [actionKey, setActionKey] = useState('');
  const [openAgreementModal, setOpenAgreementModal] = useState(false);
  const [subscriptionChanges, setSubscriptionChanges] = useState([]);
  const [openAddPaymentModal, setOpenAddPaymentModal] = useState(false);
  const [countries, setCountries] = useState([]);
  const [openInvitationModal, setOpenInvitationModal] = useState(false);
  const [discountsList, setDiscountsList] = useState([]);
  const agreementApproved = new URLSearchParams(location.search).get(
    'agreement-approved'
  );
  const paymentMethodApproved = new URLSearchParams(location.search).get(
    'new-payment-method'
  );
  const userSlug =
    localStorage.getItem('user-slug') || sessionStorage.getItem('user-slug');
  const redirectGraveSlug = localStorage.getItem('slug_for_redirect');

  const updateSubscription = (id) => {
    request(`/vipps/subscriptions/${id}/initiate_payment`, {}, 'post')
      .then((res) => {
        localStorage.removeItem('agreementId');
        setCurrentSubscription(res.data.subscription);
        setPaymentMethod(res.data.payment_method);
        setBillingInformation(res.data.billing_information);
        setGiftedSubscriptionMembers(res.data.gifted_subscriptions);
        fetchAgreements();
        setLoadingGiftMembers(false);
        setLoading(false);
        if (redirectGraveSlug) {
          navigate(`/deceased/${redirectGraveSlug}`);
        }
      })
      .catch((error) => {
        console.log(error);
        localStorage.removeItem('agreementId');
        fetchSubscriptionData();
      });
  };

  const fetchAgreement = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        Accept: '*/*',
      },
      redirect: 'follow',
    };
    axios
      .get(`${CONSTANTS.baseUrl}/api/vipps/agreements/${agreementId}`, config)
      .then((res) => {
        if (res.data.agreement_details.status === 'ACTIVE') {
          updateSubscription(res.data.subscription.id);
        } else {
          fetchSubscriptionData();
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    const show = location.state?.show;
    const key = location.state?.key;
    if (show && !isEmpty(key)) {
      setMessageKey(key);
      setModalOpen(true);
      navigate.replace({ state: undefined, key: '' });
    }
  }, [location, navigate]);

  const fetchSubscriptionData = () => {
    fetchCurrentSubscription();
    fetchGiftedSubscriptionMembers();
    fetchAgreements();
  };

  const fetchCurrentSubscription = useCallback(() => {
    if (userSlug) {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          Accept: '*/*',
        },
        redirect: 'follow',
      };
      axios
        .get(
          `${CONSTANTS.baseUrl}/api/users/${userSlug}/subscriptions/current_subscription`,
          config
        )
        .then((res) => {
          if (res.data) {
            setCurrentSubscription(res.data.subscription);
            setPaymentMethod(res.data.payment_method);
            setBillingInformation(res.data.billing_information);
            setSubscriptionChanges(res.data.subscription_changes);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [userSlug]);

  const fetchAgreements = useCallback(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        Accept: '*/*',
      },
      redirect: 'follow',
    };
    axios
      .get(`${CONSTANTS.baseUrl}/api/vipps/agreements`, config)
      .then((res) => {
        const hash = {};
        if (Array.isArray(res.data) && res.data.length > 0) {
          res.data.forEach((agreement) => {
            hash[agreement.agreement_id] = agreement;
          });
        }
        setAgreementHash(hash);
      })
      .catch((err) => console.log(err));
  }, [userSlug]);

  const fetchGiftedSubscriptionMembers = useCallback(() => {
    if (userSlug) {
      setLoadingGiftMembers(true);
      const requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      fetch_request(
        `/api/users/${userSlug}/subscriptions/gifted_subscriptions`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          setGiftedSubscriptionMembers(data.gifted_subscriptions);
          setLoadingGiftMembers(false);
        })
        .catch(() => {
          setLoadingGiftMembers(false);
        });
    }
  }, [userSlug]);

  const UpdateAgreement = async (id) => {
    setLoading(true);
    request(`/vipps/agreements/${id}/sync_status`, null, 'put')
      .then((res) => {
        localStorage.removeItem('agreementId');
        fetchSubscriptionData();
      })
      .catch((err) => {
        localStorage.removeItem('agreementId');
        fetchSubscriptionData();
      });
  };

  // -------Fetch Countries-------//

  useEffect(() => {
    fetch_request('/api/countries')
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries);
        dispatch(getListOfCountries(data));
      })
      .catch((err) => console.log(err));
  }, [dispatch]);

  const fetchSubscriptionDiscount = () => {
    axios
      .get(`${CONSTANTS.baseUrl}/api/subscription_discounts`)
      .then((res) => {
        setDiscountsList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    fetchSubscriptionDiscount();
  }, []);

  useEffect(() => {
    if (parseInt(agreementApproved) === 1 && agreementId) {
      fetchAgreement();
    } else if (parseInt(paymentMethodApproved) === 1 && agreementId) {
      UpdateAgreement(agreementId);
    } else {
      fetchSubscriptionData();
    }
  }, [
    agreementApproved,
    paymentMethodApproved,
    userSlug,
    fetchCurrentSubscription,
    fetchGiftedSubscriptionMembers,
  ]);

  const handleShowModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleCreatePaymentMethod = async (phoneNumber) => {
    handleShowModal();
    const requestPayload = {
      payment_method: {
        phone_number: phoneNumber,
        subscription_id: currentSubscription.id,
      },
    };

    try {
      const response = await request(
        `/vipps/payment_methods`,
        requestPayload,
        'post'
      );

      if (response.status === 201) {
        if (response.data.payment_method) {
          setOpenAddPaymentModal(false);
          setOpenAgreementModal(false);
          handleCloseModal();
          fetchSubscriptionData();
        }
        if (response.data.agreement) {
          const agreement = response.data.agreement;
          localStorage.setItem('agreementId', agreement.agreementId);
          setOpenAddPaymentModal(false);
          setOpenAgreementModal(false);
          handleVippsAgreement(agreement);
        }
      } else {
        const data = response.json();
        const error = data.error || 'something_wrong';
        setErrorMessage(error);
        handleCloseModal();
        setOpenErrorModal(true);
      }
    } catch (error) {
      setErrorMessage(error.message);
      handleCloseModal();
      setOpenErrorModal(true);
    }
  };

  const handleVippsAgreement = (agreement) => {
    if (agreement.vippsConfirmationUrl) {
      window.location.href = agreement.vippsConfirmationUrl;
      handleCloseModal();
    } else {
      handleCloseModal();
      setErrorMessage(intl.formatMessage({ id: 'something_wrong' }));
      setOpenErrorModal(true);
    }
  };

  const isCardValid = (card) => {
    const { exp_month, exp_year, stripe_id } = card;
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();

    if ((exp_year <= currentYear && exp_month < currentMonth) || !stripe_id) {
      return null;
    }

    return 'active';
  };

  const getAgreementStatus = () => {
    if (!isEmpty(paymentMethod) && !isEmpty(agreementHash)) {
      return agreementHash[paymentMethod.agreement_id]
        ? agreementHash[paymentMethod.agreement_id].status
        : null;
    } else {
      return null;
    }
  };

  const subscriptionMethod = () => {
    if (
      !isEmpty(paymentMethod) &&
      paymentMethod.payment_type === PaymentType.vipps &&
      !paymentMethod?.destroyed_at
    ) {
      return getAgreementStatus();
    } else if (
      !isEmpty(paymentMethod) &&
      paymentMethod.payment_type === PaymentType.card &&
      !paymentMethod?.destroyed_at
    ) {
      return isCardValid(paymentMethod);
    } else {
      return null;
    }
  };

  const SubscriptionAlerts = {
    paymentDue: {
      title: <FormattedMessage id='payment_due_title' />,
      key: 'paymentDue',
      message: <FormattedMessage id='payment_due_message' />,
    },
    active: {
      title: <FormattedMessage id='active_title' />,
      key: 'active',
      message: <FormattedMessage id='active_message' />,
    },
    pending: {
      title: <FormattedMessage id='pending_title' />,
      key: 'pending',
      message: <FormattedMessage id='pending_message' />,
      action: <FormattedMessage id='complete_setup' />,
      handelAction: () => setOpenAddPaymentModal(true),
    },
    stopped: {
      title: <FormattedMessage id='stopped_title' />,
      key: 'stopped',
      message: <FormattedMessage id='stopped_message' />,
      action: <FormattedMessage id='complete_setup' />,
      handelAction: () => setOpenAddPaymentModal(true),
    },
    expired: {
      title: <FormattedMessage id='expired_title' />,
      key: 'expired',
      message: <FormattedMessage id='expired_message' />,
      action: <FormattedMessage id='complete_setup' />,
      handelAction: () => setOpenAddPaymentModal(true),
    },
    cancelled: {
      title: <FormattedMessage id='cancelled_title' />,
      key: 'cancelled',
      message: (
        <FormattedMessage
          id='cancelled_message'
          values={{
            period_end_date: formatDate(currentSubscription.period_end_date),
          }}
        />
      ),
      action: <FormattedMessage id='Reactivate' />,
      handelAction: () => setOpenCancelModal(true),
    },
    activeStopped: {
      title: <FormattedMessage id='activeStopped_title' />,
      key: 'activeStopped',
      message: <FormattedMessage id='activeStopped_message' />,
      action: <FormattedMessage id='renew_Agreement' />,
      handelAction: () => setOpenAddPaymentModal(true),
    },
    activeExpired: {
      title: <FormattedMessage id='activeExpired_title' />,
      key: 'activeExpired',
      message: <FormattedMessage id='activeExpired_message' />,
      action: <FormattedMessage id='renew_Agreement' />,
      handelAction: () => setOpenAddPaymentModal(true),
    },
    notValid: {
      title: <FormattedMessage id='notValid_title' />,
      key: 'notValid',
      message: <FormattedMessage id='notValid_message' />,
      action: <FormattedMessage id='add_card' />,
      handelAction: () => setOpenAddPaymentModal(true),
    },
  };

  const subscriptionInvitationAlert = {
    title: <FormattedMessage id='invited_title' />,
    message: (
      <FormattedMessage
        id='subscription_invitation_message'
        values={{
          subscription: planKey(user.subscription_invitation?.subscription_key),
          name: user.subscription_invitation?.invited_by,
        }}
      />
    ),
    action: <FormattedMessage id='open' />,
    handelAction: () => setOpenInvitationModal(true),
  };

  const getAlert = () => {
    const status = subscriptionMethod();

    const isNotFreePlan = currentSubscription.plan_key !== ProductKeys.free;
    const isPendingApproval =
      currentSubscription.status === SubscriptionStatuses.PENDING_APPROVAL;
    const isCancelled =
      currentSubscription.status === SubscriptionStatuses.CANCELLED;
    const isActive = currentSubscription.status === SubscriptionStatuses.ACTIVE;
    const isPaymentDue =
      currentSubscription.status === SubscriptionStatuses.PAYMENT_DUE;
    const isExpired =
      currentSubscription.status === SubscriptionStatuses.EXPIRED;

    switch (status) {
      case 'active':
        if (isCancelled) {
          return SubscriptionAlerts.cancelled;
        }
        if (isPendingApproval) {
          return SubscriptionAlerts.active;
        }
        if (isPaymentDue) {
          return SubscriptionAlerts.paymentDue;
        }

        if (isExpired) {
          return SubscriptionAlerts.expired;
        }
        return null;

      case 'expired':
        if (isActive || isCancelled) {
          return SubscriptionAlerts.activeExpired;
        }
        return SubscriptionAlerts.expired;

      case 'stopped':
        if ((isActive || isCancelled) && isNotFreePlan) {
          return SubscriptionAlerts.activeStopped;
        }
        if ((isPendingApproval || isPaymentDue) && isNotFreePlan) {
          return SubscriptionAlerts.stopped;
        }
        return null;

      case 'pending':
        if (isPendingApproval) {
          return SubscriptionAlerts.pending;
        }
        return SubscriptionAlerts.pending;

      case null:
        return SubscriptionAlerts.notValid;

      default:
        return SubscriptionAlerts.stopped;
    }
  };

  const updateSubscriptionStatus = () => {
    if (currentSubscription.status === SubscriptionStatuses.CANCELLED) {
      reactivateSubscription();
    } else {
      cancelSubscription();
    }
  };

  const cancelSubscription = async () => {
    setOpenCancelModal(false);
    setProcessMessageKey('canceling_waite_message');
    handleShowModal();
    try {
      const response = await fetch(
        `${CONSTANTS.baseUrl}/api/vipps/subscriptions/${currentSubscription.id}/cancel`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        handleCloseModal();
        showUserNotification(
          intl.formatMessage({ id: 'something_wrong' }),
          'warning'
        );
        return;
      }
      fetchSubscriptionData();
      handleCloseModal();
      showUserNotification(
        intl.formatMessage({ id: 'success_message' }),
        'success'
      );
    } catch (error) {
      console.log(error);
      handleCloseModal();
    }
  };

  const reactivateSubscription = async () => {
    setOpenCancelModal(false);
    try {
      const response = await fetch(
        `${CONSTANTS.baseUrl}/api/vipps/subscriptions/${currentSubscription.id}/reactivate`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        showUserNotification(
          intl.formatMessage({ id: 'something_wrong' }),
          'warning'
        );
        return;
      }
      setCurrentSubscription({
        ...currentSubscription,
        status: 'active',
      });
      showUserNotification(
        intl.formatMessage({ id: 'reactivate_success_message' }),
        'success'
      );
    } catch (error) {
      console.log(error);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const getLength = () => {
    return (
      <>
        - <FormattedMessage id={currentSubscription.period} />
      </>
    );
  };

  const isPeriodSet = () => {
    return currentSubscription.period ? (
      getLength()
    ) : (
      <>
        - <FormattedMessage id='month' />
      </>
    );
  };

  const showCancelButton = () => {
    const status = subscriptionMethod();
    return (
      currentSubscription.status !== SubscriptionStatuses.CANCELLED &&
      currentSubscription.plan_key !== ProductKeys.free &&
      !isEmpty(paymentMethod) &&
      !paymentMethod.destroyed_at &&
      status &&
      status !== 'expired' &&
      status !== 'stopped' &&
      status !== 'pending'
    );
  };

  return (
    <div>
      <div className={styles['subscription-heading']}>
        <FormattedMessage id='settings_subscriptionHeading' />
      </div>
      {loading && (
        <div className={styles['loading-container']}>
          <Loader />
        </div>
      )}
      {!loading && !isEmpty(currentSubscription) ? (
        <>
          {!isEmpty(user) && user?.subscription_invitation && (
            <SubscriptionAlert alert={subscriptionInvitationAlert} />
          )}
          {!isEmpty(getAlert()) &&
            currentSubscription.plan_key !== ProductKeys.free && (
              <SubscriptionAlert alert={getAlert()} />
            )}
          {subscriptionChanges.length > 0 && (
            <SubscriptionChanges
              changes={subscriptionChanges}
              currentSubscription={currentSubscription}
              setSubscriptionChanges={setSubscriptionChanges}
            />
          )}
          <div className={styles['subscription-card']}>
            <div className={styles['plan-name-heading']}>
              <div className={styles['plans-heading']}>
                <FormattedMessage id={planKey(currentSubscription.plan_key)} />{' '}
                <FormattedMessage id='membership' /> - NOK{' '}
                {currentSubscription.subscription_price} ,{isPeriodSet()}
              </div>
            </div>
            {currentSubscription.plan_key === ProductKeys.free ? (
              <FreeSubscription
                currentSubscription={currentSubscription}
                setOpenOptionsModal={setOpenOptionsModal}
                setActionKey={setActionKey}
              />
            ) : (
              <>
                <PaidSubscription
                  currentSubscription={currentSubscription}
                  action={getAlert()}
                  setOpenOptionsModal={setOpenOptionsModal}
                  setActionKey={setActionKey}
                  paymentMethod={paymentMethod}
                  subscriptionChanges={subscriptionChanges}
                  subscriptionMethod={subscriptionMethod}
                  setOpenAddPaymentModal={setOpenAddPaymentModal}
                  discountsList={discountsList}
                />
                {billingInformation && (
                  <BillingInformation billingInformation={billingInformation} />
                )}
              </>
            )}
          </div>
          {showCancelButton() && (
            <CancelSubscription
              subscription={currentSubscription}
              setOpenCancelModal={setOpenCancelModal}
            />
          )}
        </>
      ) : !loading ? (
        <div className={styles['empty-gift-memberships']}>
          <FormattedMessage id='settings_noSubscription' />{' '}
          <Link className={styles['plan-link']} to='/pricing'>
            <FormattedMessage id='settings_clickHere' />
          </Link>
        </div>
      ) : null}
      <div className={styles['active-memberships-header']}>
        <FormattedMessage id='settings_memberHeading' />{' '}
        <span>
          <FormattedMessage id='settings_receivedMembership' />
        </span>
      </div>
      {giftedSubscriptionMembers && giftedSubscriptionMembers.length > 0 ? (
        <>
          {loadingGiftMembers && isEmpty(giftedSubscriptionMembers) && (
            <div className={styles['loading']}>
              <Loader />
            </div>
          )}
          {!loadingGiftMembers && giftedSubscriptionMembers.length > 0 && (
            <div className={styles['membership-cards']}>
              {giftedSubscriptionMembers.map((membership) => (
                <ActiveGiftMembership
                  key={membership.id}
                  membership={membership}
                />
              ))}
            </div>
          )}
        </>
      ) : !loadingGiftMembers ? (
        <div className={styles['empty-gift-memberships']}>
          <FormattedMessage id='settings_emptyMembership' />
        </div>
      ) : null}
      {!loading && modalOpen && !isEmpty(currentSubscription) && (
        <PopupModal
          open={modalOpen}
          handleClose={closeModal}
          plan={currentSubscription.plan_key}
          messageKey={messageKey}
        />
      )}
      {openCancelModal && (
        <CancelSubscriptionModal
          status={currentSubscription.status}
          open={openCancelModal}
          planeName={currentSubscription.plan_key}
          handleClose={() => setOpenCancelModal(false)}
          cancelSubscription={updateSubscriptionStatus}
        />
      )}
      {openModal && (
        <ProcessModal open={openModal} messageKey={processMessageKey} />
      )}
      {openErrorModal && (
        <ErrorModal
          message={errorMessage}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
        />
      )}
      {openOptionsModal && actionKey.trim() !== '' && (
        <ChangePlanOptions
          openOptionsModal={openOptionsModal}
          setOpenOptionsModal={setOpenOptionsModal}
          actionKey={actionKey}
          currentSubscription={currentSubscription}
          fetchSubscriptionData={fetchSubscriptionData}
          paymentMethod={paymentMethod}
          openAddPaymentModal={() => setOpenAddPaymentModal(true)}
        />
      )}
      {openAgreementModal && (
        <CustomModal
          isOpen={openAgreementModal}
          closeModal={() => setOpenAgreementModal(false)}
          title={<FormattedMessage id='renew_Agreement' />}
          children={
            <RenewAgreement
              handleCreatePaymentMethod={handleCreatePaymentMethod}
              closeModal={() => setOpenAgreementModal(false)}
            />
          }
          style={{ width: '400px' }}
        />
      )}
      {openAddPaymentModal && (
        <Elements stripe={stripePromise}>
          <AddPaymentMethod
            open={openAddPaymentModal}
            close={() => setOpenAddPaymentModal(false)}
            user={user}
            countries={countries}
            fetchSubscriptionData={fetchSubscriptionData}
            currentSubscription={currentSubscription}
            handleCreatePaymentMethod={handleCreatePaymentMethod}
            paymentMethod={paymentMethod}
            agreementHash={agreementHash}
          />
        </Elements>
      )}
      {openInvitationModal && (
        <SubscriptionInviteModal
          onOpen={openInvitationModal}
          currentUser={user}
          onClose={() => setOpenInvitationModal(false)}
        />
      )}
    </div>
  );
};

export default Subscriptions;
