import React from 'react';
import './Header.css';
import SearchBar from './SearchBar';

const Header = (props) => {
  return (
    <div className='municipality-header'>
      <div className='municipality-header_left'>
        <div className='municipality-header_title'>
          <span>{props.title}</span>
        </div>
        <div className='municipality-header_info'>{props.description}</div>
      </div>
      <div className='municipality-header_right'>
        <SearchBar
          setSearchInputValue={props.setSearchInputValue}
          title={props.placeholder}
        />
      </div>
    </div>
  );
};

export default Header;
