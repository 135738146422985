import { gql } from '@apollo/client';

export const removeSparkCache = (cache, { data: { destroySpark } }) => {
  if (destroySpark?.id) {
    cache.modify({
      fields: {
        sparks(existingSparks = { edges: [], pageInfo: {} }) {
          const updatedEdges = existingSparks.edges.filter((edge) => {
            const nodeData = cache.readFragment({
              id: edge.node.__ref,
              fragment: gql`
                fragment Sparks on Spark {
                  id
                }
              `,
            });
            return nodeData?.id !== destroySpark.id;
          });

          return {
            ...existingSparks,
            edges: updatedEdges,
          };
        },
      },
    });
  }
};
