import React, { useEffect, useState } from 'react';
import Permissions from './Permissions/Permissions';
import AddIcon from '@mui/icons-material/Add';
import styles from './LifeEventHeader.module.css';
import EventModal from '../AddEventModal/AddEventModal';
import { useDispatch, useSelector } from 'react-redux';
import { addLifeEventPhotos } from '../../../../../redux/actions';
import UpgradePlanModal from '../../../../../components/Modals/UpgradePlanModal';
import { useNavigate } from 'react-router-dom';
import ConnectRelationsModal from '../../ConnectRelationsModal/ConnectRelationsModal';
import InfoModal from './InfoModal/InfoModal';
import OpenClaimModal from './OpenClaimModal/OpenClaimModal';

const LifeEventHeader = ({ handelSaveModal, currentSubscription }) => {
  const [openModal, setOpenModal] = useState(false);
  const [openUpgradeModal, setOpenUpgradeModal] = useState(false);
  const grave = useSelector((state) => state.grave.grave);
  const isAuth = useSelector((state) => state.auth.isAuthorized);
  const user = useSelector((state) => state.user.user);
  const [openRelationDialog, setOpenRelationDialog] = useState(false);
  const [isClaiming, setIsClaiming] = useState(false);
  const [openConfirmationDialog, setOpenConfimationDialog] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [message, setMessage] = useState('');
  const [openClaimGraveModal, setOpenClaimGraveModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const is_relative = grave?.is_relative;

  const handleOpenClaimDialog = () => {
    setIsClaiming(true);
    setOpenRelationDialog(true);
  };

  const handleCloseConfirmationDialog = () => {
    setOpenConfimationDialog(false);
  };

  const handleCloseRelationDialog = () => {
    setOpenRelationDialog(false);
  };

  const handleGraveUpdate = () => {
    setOpenConfimationDialog(false);
  };

  const handleOpenRelationDialog = () => {
    setIsClaiming(false);
    setOpenRelationDialog(true);
  };

  const addEvent = () => {
    if (!isAuth) {
      navigate('/auth/sign-in');
    } else if (!user.is_subscribed) {
      setOpenUpgradeModal(true);
    } else {
      setOpenModal(true);
    }
  };

  const onConfirm = () => {
    if (!user.is_subscribed) {
      setOpenClaimGraveModal(false);
      setOpenUpgradeModal(true);
    } else {
      setOpenClaimGraveModal(false);
      setOpenModal(true);
    }
  };

  return (
    <React.Fragment>
      {openUpgradeModal && (
        <UpgradePlanModal
          isOpen={openUpgradeModal}
          onClose={() => setOpenUpgradeModal(false)}
          body='life_archive_body.'
          title='upgrade_modal_heading'
        />
      )}
      {openInfoModal && (
        <InfoModal
          open={openInfoModal}
          handleClose={() => setOpenInfoModal(false)}
          message={message}
        />
      )}
      {openRelationDialog && (
        <ConnectRelationsModal
          grave={grave}
          canClaim={grave.can_claim}
          isClaiming={isClaiming}
          openRelationDialog={openRelationDialog}
          openConfirmationDialog={openConfirmationDialog}
          handleCloseRelationDialog={handleCloseRelationDialog}
          setOpenConfimationDialog={setOpenConfimationDialog}
          handleCloseConfirmationDialog={handleCloseConfirmationDialog}
          handleGraveUpdate={handleGraveUpdate}
        />
      )}
      <div className={styles['header-container']}>
        <div className={styles['wrapper']}>
          <button className={styles['add-button']} onClick={addEvent}>
            <AddIcon style={{ color: '#fff' }} /> Legg til hendelse
          </button>
        </div>
      </div>
      {openModal && (
        <EventModal
          title='Livsarkivet'
          onOpen={openModal}
          onClose={() => {
            setOpenModal(false);
            dispatch(addLifeEventPhotos([]));
          }}
          event={null}
          grave={grave}
          handelSaveModal={handelSaveModal}
        />
      )}
      {openClaimGraveModal && (
        <OpenClaimModal
          onOpen={openClaimGraveModal}
          onClose={() => setOpenClaimGraveModal(false)}
          onConfirm={onConfirm}
          grave={grave}
        />
      )}
    </React.Fragment>
  );
};

export default LifeEventHeader;
