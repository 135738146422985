import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function DatePickerValue({ date, disable, setDatePickerValue }) {
  const customDatePicker = {
    '& .MuiInputBase-root': {
      border: 'none !important',
      background: '#F2F5F8',
      padding: '8px 28px',
      borderRadius: '8px',
      cursor: 'pointer',
      fontSize: '18px',
    },
  };
  const [value, setValue] = useState(dayjs('YYYY'));
  useEffect(() => {
    if (date) {
      setValue(dayjs(date));
    }
  }, [date]);

  const handleDateChange = (newValue) => {
    setValue(newValue);
    if (newValue) {
      setDatePickerValue(newValue);
    } else {
      setDatePickerValue('');
    }
  };
  const clearDate = (e) => {
    e.preventDefault();
    setValue('');
    setDatePickerValue('');
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        disabled={disable}
        views={['year']}
        value={value}
        onChange={handleDateChange}
        slotProps={{
          textField: {
            variant: 'standard',
            fullWidth: true,
            sx: customDatePicker,
          },
        }}
        disableFuture
      />
    </LocalizationProvider>
  );
}
