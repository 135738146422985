import React, { useState } from 'react';
import './MusicPost.css';
import TrianglePLay from '../../../../img/TrianglePlay.svg';
import MusicPlayer from '../CommentTextArea/atoms/MusicPlayer';

const MusicPost = ({ music, post }) => {
  const [chosenSong, setChosenSong] = useState();

  return (
    <div>
      <div className='music-post_content'>
        <div className='music-post_content-songs'>
          {music?.map((song, i) => {
            return <MusicPlayer key={i} song={song} />;
          })}
        </div>
        {chosenSong && (
          <div className='music-post_content-player'>
            <div className='music-post_content-player_play'>
              <img loading='lazy' src={TrianglePLay} alt='play' />
            </div>
            <div className='music-post_content-player_author'>
              <span className='add-by-link_header-music_text'>
                <span>{chosenSong.title} -</span> {chosenSong.author}
              </span>
              <div />
            </div>
            <div className='music-post_content-player_duration'>
              <span>{chosenSong.duration}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MusicPost;
