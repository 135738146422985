import React from 'react';
import UserElement from './UserElement';
import { Avatar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import styles from './GraveUserForm.module.css';
import { useNavigate } from 'react-router-dom';

const useStyle = makeStyles(() => ({
  large: {
    width: '80px !important',
    height: '80px !important',
    cursor: 'pointer',
  },
}));

const UserInfo = ({ editRelation }) => {
  const classes = useStyle();
  const navigate = useNavigate();
  const isOwner = editRelation.grave_owner
    ? 'graveier'
    : editRelation.admin.key === 'administrator'
    ? 'Admin'
    : '';

  const navigateTo = () => {
    navigate(`/member/${editRelation.user?.slug}`);
  };

  return (
    <div className={styles['user-info-container']}>
      <Avatar
        src={editRelation.user?.user_avatar}
        className={classes.large}
        onClick={navigateTo}
      />
      <div className={styles['user-info']}>
        <UserElement
          value={editRelation.user?.full_name}
          navigateTo={navigateTo}
        />
        <UserElement
          value={editRelation.relation?.name}
          style={{
            color: '#8d9093',
            fontSize: '14px',
            fontWeight: '400',
          }}
        />
        <UserElement
          value={isOwner}
          style={{
            color: '#404d56',
            fontSize: '14px',
            fontWeight: '400',
          }}
        />
      </div>
    </div>
  );
};

export default UserInfo;
