import React, { useState } from 'react';
import SelectPaymentMethod from '../../../Payment/atoms/SelectedPaymentMethod/SelectPaymentMethod';
import RenewAgreement from '../RenewAgreement/RenewAgreement';
import PaymentMethodForm from './PaymentMethodForm';
import { PaymentType, CONSTANTS } from '../../../../service/constants';
import ErrorModal from '../../../Payment/atoms/ErrorModal/ErrorModal';
import ProcessModal from '../../../Payment/atoms/ProcessModal';
import showUserNotification from '../../../../components/UserNotification/showUserNotification';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash.isempty';

const SelectedMethod = ({
  selectedMethod,
  setSelectedMethod,
  handleCreatePaymentMethod,
  close,
  user,
  fetchSubscriptionData,
  currentSubscription,
  cardPaymentMethods,
  vippsPaymentMethods,
  countries,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  currentPaymentMethod,
  agreementHash,
}) => {
  const intl = useIntl();
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openLoader, setOpenLoader] = useState(false);

  const updateSubscription = async () => {
    if (
      !isEmpty(selectedPaymentMethod) &&
      selectedPaymentMethod.id === currentPaymentMethod.id
    ) {
      showUserNotification(
        intl.formatMessage({ id: 'warning_payment_method' }),
        'warning'
      );
      return;
    }

    setOpenLoader(true);

    let requestPayload = {
      subscription: {
        payment_method_id: selectedPaymentMethod.id,
      },
    };
    try {
      const response = await fetch(
        `${CONSTANTS.baseUrl}/api/subscriptions/${currentSubscription.id}.json`,
        {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestPayload),
        }
      );
      if (!response.ok) {
        const data = await response.json();
        showUserNotification(data.error, 'error');
        setOpenLoader(false);
        setErrorMessage(data.error);
        setOpenErrorModal(true);
        return;
      }
      const data = await response.json();
      if (data) {
        showUserNotification(
          intl.formatMessage({
            id: 'update_payment_method_success',
          }),
          'success'
        );
        setOpenLoader(false);
        fetchSubscriptionData();
        close();
      }
    } catch (error) {
      setOpenLoader(false);
      setErrorMessage(error.response.data.error || error.message, 'error');
      setOpenErrorModal(true);
    }
  };

  return (
    <div>
      <SelectPaymentMethod
        selectedPaymentMethod={selectedMethod}
        handelSelectPayment={setSelectedMethod}
      />
      {selectedMethod === PaymentType.card ? (
        <PaymentMethodForm
          countries={countries}
          cardPaymentMethods={cardPaymentMethods}
          close={close}
          user={user}
          currentSubscription={currentSubscription}
          fetchSubscriptionData={fetchSubscriptionData}
          setSelectedPaymentMethod={setSelectedPaymentMethod}
          selectedPaymentMethod={selectedPaymentMethod}
          updateSubscription={updateSubscription}
          selectedMethod={selectedMethod}
        />
      ) : (
        <RenewAgreement
          vippsPaymentMethods={vippsPaymentMethods}
          handleCreatePaymentMethod={handleCreatePaymentMethod}
          closeModal={close}
          setSelectedPaymentMethod={setSelectedPaymentMethod}
          selectedPaymentMethod={selectedPaymentMethod}
          currentPaymentMethod={currentPaymentMethod}
          updateSubscription={updateSubscription}
          selectedMethod={selectedMethod}
          agreementHash={agreementHash}
        />
      )}
      {openLoader && (
        <ProcessModal
          open={openLoader}
          messageKey='update_payment_method_waiting'
        />
      )}
      {openErrorModal && (
        <ErrorModal
          message={errorMessage}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
        />
      )}
    </div>
  );
};

export default SelectedMethod;
