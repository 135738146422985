import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './SparksSubMenuDesktop.module.css';
import Sparks from './Sparks/CreateSparks/Sparks';
import GravesUserJoined from '../UserGraves/GravesUserJoined.jsx';
import UserConnections from '../Relations/UserConnections.jsx';

const SparksSubMenuDesktop = ({ user }) => {
  const [selectedMenuKey, setSelectedMenuKey] = useState(1);
  const menuItems = {
    1: {
      titleKey: 'sparks',
    },
    2: {
      titleKey: 'graves',
    },
    3: {
      titleKey: 'relatives',
    },
  };

  return (
    <div className={styles['menu-section']}>
      <div className={styles['container']}>
        {Object.entries(menuItems).map(([key, item]) => {
          const newKey = Number(key);
          return (
            <div
              key={key}
              className={
                selectedMenuKey === newKey
                  ? `${styles['menu-item']} ${styles['active']}`
                  : styles['menu-item']
              }
              onClick={() => setSelectedMenuKey(newKey)}
            >
              <div className={styles['item']}>
                <FormattedMessage id={item.titleKey} />
              </div>
            </div>
          );
        })}
      </div>
      {selectedMenuKey === 1 && (
        <Sparks
          user={user}
          selectedMenuKey={selectedMenuKey}
          setSelectedMenuKey={setSelectedMenuKey}
        />
      )}
      {selectedMenuKey === 2 && (
        <GravesUserJoined
          user={user}
          selectedMenuKey={selectedMenuKey}
          setSelectedMenuKey={setSelectedMenuKey}
        />
      )}
      {selectedMenuKey === 3 && (
        <UserConnections
          user={user}
          selectedMenuKey={selectedMenuKey}
          setSelectedMenuKey={setSelectedMenuKey}
        />
      )}
    </div>
  );
};

export default SparksSubMenuDesktop;
