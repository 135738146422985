import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PrimaryButton from '../../../../components/PrimaryButton/index';
import styles from './UserAccount.module.css';
import CustomModal from '../../../../components/CustomModal';
import { request } from '../../../../service/request';
import Content from './Content';
import { useDispatch, useSelector } from 'react-redux';
import { setInformationAboutUser } from '../../../../redux/actions';

const UserAccount = () => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const user = useSelector((state) => state.user.user);
  const userSlug =
    localStorage.getItem('user-slug') || sessionStorage.getItem('user-slug');

  const closeConfirmModal = () => {
    setOpenModal(false);
  };

  const openConfirmModal = () => {
    setOpenModal(true);
  };

  const isRequestedToDelete = () => {
    return user.status === 'requested_to_delete';
  };

  const handelAccountDeletion = () => {
    const payload = {
      user: {
        status: isRequestedToDelete() ? 0 : 4,
      },
    };
    request(`/users/${userSlug}`, payload, 'put')
      .then((response) => {
        dispatch(setInformationAboutUser(response.data));
        closeConfirmModal();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className={styles['container']}>
        <div className={styles['heading']}>
          <FormattedMessage id='account_heading' />
        </div>
        <div className={styles['details']}>
          <div className={styles['title']}>
            <FormattedMessage
              id={
                isRequestedToDelete()
                  ? 'delete_account_pending'
                  : 'delete_account'
              }
            />
          </div>
          <div className={styles['message']}>
            <FormattedMessage
              id={
                isRequestedToDelete()
                  ? 'deleted_message_pending'
                  : 'deleted_message'
              }
            />
          </div>
          <div className={styles['btn-container']}>
            <PrimaryButton
              onClick={openConfirmModal}
              children={
                <FormattedMessage
                  id={isRequestedToDelete() ? 'settings_cancel' : 'delete'}
                />
              }
              style={{
                padding: '8px 42px',
                background: isRequestedToDelete() ? '#404D56' : '#f23131',
              }}
            />
          </div>
        </div>
      </div>
      {openModal && (
        <CustomModal
          children={
            <Content
              closeConfirmModal={closeConfirmModal}
              handelAccountDeletion={handelAccountDeletion}
              isRequestedToDelete={isRequestedToDelete}
            />
          }
          isOpen={openModal}
          closeModal={closeConfirmModal}
          title={<FormattedMessage id='delete_account' />}
        />
      )}
    </>
  );
};

export default UserAccount;
