import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './AccountConfirmation.module.css';
import { useNavigate } from 'react-router-dom';

const Messages = ({ headingKey, descriptionKey }) => {
  const navigate = useNavigate();

  const navigateToPage = () => {
    const userSlug = localStorage.getItem('user-slug');
    let url = '/';
    if (userSlug) {
      url = `/member/${userSlug}`;
    }
    navigate(url);
  };

  return (
    <React.Fragment>
      <div className={styles['heading']}>
        <FormattedMessage id={headingKey} />
      </div>
      <div className={styles['description']}>
        <FormattedMessage id={descriptionKey} />{' '}
        <span className={styles['login']} onClick={() => navigateToPage()}>
          <FormattedMessage id='go_to_home_page' />
        </span>
      </div>
    </React.Fragment>
  );
};

export default Messages;
