import React from 'react';
import './HomepageInvestigateFirst.css';
import InvestigateImage from '../../../../img/InvestigateImage.jpg';
import InvestigateMobile from '../../../../img/InvestigateMobile.jpg';

const HomepageInvestigateFirst = (props) => {
    return(
        <div className='homepage-investigate_first'>
            <div className="homepage-investigate_first-content">
                <img loading='lazy' src={InvestigateImage} alt='InvestigateImage' className='homepage-investigate_first-content_image'/>
                <img loading='lazy' src={InvestigateMobile} alt='InvestigateMobile' className='homepage-investigate_first-content_image-mobile'/>
                <div className="homepage-investigate_first-content_text">
                    <span className='homepage-investigate_first-content_text-header'>Vi er motsatsen til <span>sosiale medier</span> som minnesider, facebook og X.</span>
                    <span className='homepage-investigate_first-content_text-subheader'>Nettgrav, en del av The End Of Time Corporation, har som mål å styrke familier og individer innenfra og ut. Vår misjon som er livet, underbygger TEOTCs visjon om å bringe universet nærmere deg.</span>
                    <span className='homepage-investigate_first-content_text-subheader'></span>
                </div>
            </div>
        </div>
    )
}

export default HomepageInvestigateFirst;