import React from 'react';
import './PlansHeading.css';
import { FormattedMessage } from 'react-intl';

const PlansHeading = () => {
  return (
    <div className='plans-section'>
      <div className='subscription-plans_card-content_header'>
        <span>
          <FormattedMessage id='plans_page_heading' />
        </span>
      </div>
      <div className='subscription-plans_card-content_header-desc'>
        <span>
          <FormattedMessage id='plans_page_subheading' />
        </span>
      </div>
    </div>
  );
};

export default PlansHeading;
