import React, { useState } from 'react';
import styles from './VirtueModal.module.css';
import X from '../../../../img/X.svg';
import FamilySilhouette from '../../../../img/FamilySilhouette.svg';
import OnlyMe from '../../../../img/OnlyMe.svg';
import Everyone from '../../../../img/Everyone.svg';
import { Button, Select, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { request } from '../../../../service/request';
import showUserNotification from '../../../../components/UserNotification/showUserNotification';
import CustomModal from '../../../../components/CustomModal';
import Loader from '../../../../components/Loader/Loader';

const useStyle = makeStyles(() => ({
  rootSuggestion: {
    position: 'relative',
    '& button': {
      borderRadius: '10px !important',
      backgroundColor: '#fff !important',
      border: 'none !important',
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.161) !important',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px !important',
      lineHeight: '19px !important',
      color: '#36434B !important',
      textTransform: 'none !important',
      minWidth: '119px !important',
      height: '39px !important',
      marginRight: '20px !important',
    },
    '&:last-child': {
      marginRight: '0px',
    },
  },
  rootSuggestionClicked: {
    position: 'relative',
    '& button': {
      borderRadius: '10px !important',
      backgroundColor: '#fff !important',
      border: '1px solid #FEA65A !important',
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.161) !important',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px !important',
      lineHeight: '19px !important',
      color: '#FEA65A !important',
      textTransform: 'none !important',
      minWidth: '119px !important',
      height: '39px !important',
      marginRight: '20px !important',
    },
    '&:last-child': {
      marginRight: '0px',
    },
  },
  rootSuggestionNew: {
    borderRadius: '10px !important',
    backgroundColor: '#f5f5f5 !important',
    border: 'none !important',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.161) !important',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px !important',
    lineHeight: '19px!important',
    color: '#36434B !important',
    textTransform: 'none !important',
    minWidth: '119px !important',
    height: '39px !important',
    marginRight: '20px !important',

    '&:last-child': {
      marginRight: '0px',
    },
  },
  rootAddNewSuggestion: {
    border: 'none !important',
  },
  rootAddButton: {
    width: '180px !important',
    height: '55px !important',
    backgroundColor: '#FEA65A !important',
    borderRadius: '10px !important',
    color: '#fff !important',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px !important',
    lineHeight: '19px !important',
    textTransform: 'none !important',
    '&:hover': {
      backgroundColor: '#FEA65A !important',
    },
  },
  rootSaveNewSuggestion: {
    width: '99px !important',
    height: '41px !important',
    backgroundColor: '#FEA65A !important',
    borderRadius: '10px !important',
    color: '#fff !important',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px !important',
    lineHeight: '18px !important',
    textTransform: 'none !important',
    boxShadow: 'none !important',
    position: 'absolute !important',
    '&:hover': {
      backgroundColor: '#FEA65A !important',
    },
  },
}));

const VirtueModal = (props) => {
  const classes = useStyle();
  const [mainListOfSuggestions, setMainListOfSuggestions] = useState(
    props.suggestions?.length > 0 ? props.suggestions : []
  );
  const [newSuggestions, setNewSuggestions] = useState([]);
  const [chosenSuggestion, setChosenSuggestion] = useState(null);
  const [story, setStory] = useState('');
  const graveName = useSelector((state) => state.grave.grave.name);
  const graveId = useSelector((state) => state.grave.grave.id);

  const [privacy, setPrivacy] = useState('relatives');
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(true);

  // get new suggestions
  React.useEffect(() => {
    const refreshNewSuggestions = async () => {
      setLoading(true);
      try {
        const response = await request('/personalities');
        const suggestionsId = props.suggestions.map(
          (suggest) => suggest.personality_id
        );
        const newArrayOfNewSuggestions = response.data.personalities
          .map((suggest) => {
            if (!suggestionsId.includes(suggest.id)) {
              return suggest;
            } else return null;
          })
          .filter((suggest) => suggest);
        setNewSuggestions(newArrayOfNewSuggestions);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    refreshNewSuggestions();
  }, []);

  const choseOrDeleteSuggestion = (suggestion) => {
    setChosenSuggestion(suggestion);
    setStory(suggestion.text);
  };

  const AddSuggestionToMainList = (suggestion) => {
    setMainListOfSuggestions((prev) => [
      ...prev,
      { ...suggestion, isNew: true },
    ]);
    const newArray = newSuggestions.filter(
      (suggest) => suggest.id !== suggestion.id
    );
    setChosenSuggestion(suggestion);
    setNewSuggestions(newArray);
  };

  const addSuggestionToDeceasedPage = async () => {
    chosenSuggestion === null &&
      showUserNotification('Velg ett personlighetstrekk', 'warning');
    story === '' &&
      showUserNotification(
        'Husk å legge til en kort historie som viser til hvorfor',
        'warning'
      );
    try {
      if (chosenSuggestion && story !== '') {
        const formdata = new FormData();
        formdata.append('grave_personality[grave_id]', graveId);
        formdata.append('grave_personality[comment]', story);
        formdata.append(
          'grave_personality[personality_id]',
          chosenSuggestion.personality_id || chosenSuggestion.id
        );
        formdata.append(
          'grave_personality[privacy_attributes][setting]',
          privacy
        );
        await request('/grave_personalities', formdata, 'post');
        await props.refreshVirtueInformation();
        props.closeModal();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteSelectedSugg = () => {
    const recovered = mainListOfSuggestions[mainListOfSuggestions.length - 1];
    setNewSuggestions((prev) => [...prev, { ...recovered, isNew: false }]);
    setMainListOfSuggestions(mainListOfSuggestions.slice(0, -1));
    setDisabled(false);
  };

  const deleteDuplicate = (array, key) => {
    return JSON.parse(
      JSON.stringify([...new Map(array.map((x) => [key(x), x])).values()])
    );
  };

  return (
    <CustomModal
      title={'Verdiarkivet'}
      isOpen={props.openVirtueModal}
      closeModal={props.closeModal}
      children={
        loading ? (
          <div className={styles['loading']}>
            <Loader />
          </div>
        ) : (
          <div className={styles['virtue-modal']}>
            <div className={styles['virtue-modal_content']}>
              {mainListOfSuggestions.length > 0 && (
                <React.Fragment>
                  <div className={styles['virtue-modal_content-subheader']}>
                    <span>Verdiarkivet</span>
                  </div>
                  <div
                    className={styles['virtue-modal_content-suggestions-list']}
                  >
                    {deleteDuplicate(
                      mainListOfSuggestions,
                      (it) => it.personality
                    ).map((suggestion) => (
                      <div
                        key={suggestion.id}
                        className={
                          chosenSuggestion
                            ? chosenSuggestion.id === suggestion.id
                              ? classes.rootSuggestionClicked
                              : classes.rootSuggestion
                            : classes.rootSuggestion
                        }
                      >
                        {suggestion.isNew && (
                          <img
                            src={X}
                            alt='close icon'
                            onClick={handleDeleteSelectedSugg}
                          />
                        )}
                        {/* {suggestion.isNew && setDisabled(prev => !prev)} */}
                        <Button
                          key={suggestion.id}
                          variant='outlined'
                          onClick={() => choseOrDeleteSuggestion(suggestion)}
                        >
                          {suggestion.personality
                            ? suggestion.personality
                            : suggestion.title}
                        </Button>
                      </div>
                    ))}
                  </div>
                </React.Fragment>
              )}
              <div className={styles['virtue-modal_content-text']}>
                <span>Velg en verdi og legg til historie</span>
              </div>
              <div
                className={styles['virtue-modal_content-add-suggestions-list']}
              >
                {newSuggestions.map((suggestion) => (
                  <Button
                    disabled={disabled}
                    key={suggestion.id}
                    variant='outlined'
                    className={classes.rootSuggestionNew}
                    onClick={() => {
                      props.suggestions?.length + 1 >
                        mainListOfSuggestions.length &&
                        AddSuggestionToMainList(suggestion);
                      setDisabled(true);
                    }}
                  >
                    {suggestion.title}
                  </Button>
                ))}
              </div>

              {chosenSuggestion && (
                <div
                  className={
                    styles['virtue-modal_content-suggestion-description']
                  }
                >
                  <span>
                    Du husker {graveName} som{' '}
                    <span>
                      en{' '}
                      {chosenSuggestion.personality
                        ? chosenSuggestion.personality.toLowerCase()
                        : chosenSuggestion.title.toLowerCase()}
                    </span>{' '}
                    person. Skriv en liten historie som viser til hvorfor.
                  </span>
                </div>
              )}
              <div className={styles['virtue-modal_content-add-story']}>
                <textarea
                  placeholder='Skriv en kort historie her'
                  rows={5}
                  value={story}
                  onChange={(e) => setStory(e.target.value)}
                />
              </div>
              <Select
                value={privacy}
                className={styles['select-roles-filter_form_control']}
                onChange={(e) => setPrivacy(e.target.value)}
                style={{ margin: '0 auto 24px 0' }}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                  },
                  transformOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                  },
                }}
              >
                <MenuItem value=' ' disabled={true}>
                  {' '}
                  Del med:
                </MenuItem>
                <MenuItem value='public'>
                  <img
                    loading='lazy'
                    src={Everyone}
                    alt='Family Silhouette'
                    className={styles['memories-header_right-icon']}
                  />
                  Alle
                </MenuItem>
                <MenuItem value='relatives'>
                  <img
                    loading='lazy'
                    src={FamilySilhouette}
                    alt='Family Silhouette'
                    className={styles['memories-header_right-icon']}
                  />
                  Familien
                </MenuItem>
                <MenuItem value='private'>
                  <img
                    loading='lazy'
                    src={OnlyMe}
                    alt='Family Silhouette'
                    className={styles['memories-header_right-icon']}
                    style={{ marginLeft: '3px' }}
                  />
                  Privat
                </MenuItem>
              </Select>
              <div className={styles['virtue-modal_content-add-button']}>
                <Button
                  variant='contained'
                  className={classes.rootAddButton}
                  onClick={addSuggestionToDeceasedPage}
                >
                  Del
                </Button>
              </div>
            </div>
          </div>
        )
      }
    />
  );
};

export default VirtueModal;
