import React from 'react';
import styles from './Anniversary.module.css';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useIntl } from 'react-intl';

dayjs.extend(utc);
dayjs.extend(timezone);

const AnniversaryTitle = ({ anniversary, isPublic }) => {
  const intl = useIntl();
  const formatDateTime = (dateString) => {
    const date = dayjs(dateString).tz(dayjs.tz.guess());
    const formattedMonth = intl.formatMessage({ id: `month_${date.month()}` });
    const formattedWeekday = intl.formatMessage({
      id: `weekday_${date.day()}`,
    });

    return `${formattedWeekday}, ${formattedMonth} ${date.format('DD, YYYY')}`;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const formattedWeekday = intl.formatMessage({
      id: `weekday_${date.getDay()}`,
    });
    const formattedMonth = intl.formatMessage({
      id: `month_${date.getMonth()}`,
    });

    const day = date.getDate();
    const year = date.getFullYear();

    return `${formattedWeekday}, ${formattedMonth} ${day}, ${year}`;
  };

  return (
    <div className={styles['title-container']}>
      <div className={styles['title']}>{anniversary.name}:</div>
      <div className={styles['date']}>
        {isPublic
          ? formatDate(anniversary.date)
          : formatDateTime(anniversary.datetime)}
      </div>
    </div>
  );
};

export default AnniversaryTitle;
