import React from 'react';
import styles from './SelectDocument.module.css';
import { FormattedMessage } from 'react-intl';

const SelectDocument = ({ handelSelectedDocument, selectedDocument }) => {
  const certificateTypeMapper = {
    death_certificate: <FormattedMessage id='death_certificate' />,
    birth_certificate: <FormattedMessage id='birth_certificate' />,
    invoice: <FormattedMessage id='invoice' />,
    power_of_attorney: <FormattedMessage id='power_of_attorney' />,
    driving_license: <FormattedMessage id='driving_license' />,
  };

  return (
    <div className={styles['container']}>
      <div className={styles['heading']}>
        <FormattedMessage id='upload_document' />
      </div>
      <div className={styles['sub-heading']}>
        <FormattedMessage id='upload_document_description' />
      </div>
      <div className={styles['document-container']}>
        {Object.keys(certificateTypeMapper).map((key, index) => {
          return (
            <div
              key={index}
              className={
                selectedDocument === key
                  ? styles['select-option-selected']
                  : styles['select-option']
              }
              onClick={() => {
                if (selectedDocument === key) {
                  handelSelectedDocument(null);
                } else {
                  handelSelectedDocument(key);
                }
              }}
            >
              <input type='checkbox' checked={selectedDocument === key} />
              <label>{certificateTypeMapper[key]}</label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SelectDocument;
