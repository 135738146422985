import React, { useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import Play from '../../../../../../img/TrianglePlay.svg';
import Pause from '../../../../../../img/pause.png';
import { Button } from '@mui/material';
import Next from '../../../../../../img/next.svg';
import Prev from '../../../../../../img/prev.svg';
import './Audio.css';

const SelectedAudio = ({ audio, setAudio }) => {
  const player = useRef();
  const [playing, setPlaying] = useState(false);

  const play = () => {
    player.current.audio.current.play();
    setPlaying(true);
  };

  const pause = () => {
    player.current.audio.current.pause();
    setPlaying(false);
    // console.log(player)
  };

  const nextSec = () => {
    player.current.audio.current.currentTime += 5;
  };

  const prevSec = () => {
    if (player.current.audio.current.currentTime > 5) {
      player.current.audio.current.currentTime -= 5;
    }
  };
  return (
    <div className='music-player'>
      <Button className='player_button' onClick={playing ? pause : play}>
        <img
          loading='lazy'
          src={playing ? Pause : Play}
          alt=''
          className='player_button-image'
        />
      </Button>
      <Button onClick={prevSec} className='player_button-secs'>
        <img loading='lazy' src={Prev} alt='' />
      </Button>
      <Button onClick={nextSec} className='player_button-secs'>
        <img loading='lazy' src={Next} alt='' />
      </Button>
      <AudioPlayer
        ref={player}
        src={audio.url}
        layout='horizontal-reverse'
        className='music-player'
        customAdditionalControls={[]}
      />
      <div className='remove-audio'>
        <CloseIcon
          style={{ fontSize: '16px', color: '#404D56' }}
          onClick={() => setAudio(null)}
        />
      </div>
    </div>
  );
};

export default SelectedAudio;
