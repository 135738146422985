import React from 'react';
import styles from './DeleteInvitationModal.module.css';
import DeleteSubscriptionMembers from './DeleteSubscriptionMembers';
import isEmpty from 'lodash.isempty';
import { FormattedMessage } from 'react-intl';

const DeleteInvitationsModal = ({
  onClose,
  currentSubscriptionData,
  updateSubscription,
}) => {
  const filterMembers = () => {
    const members = currentSubscriptionData.subscription_members;
    return members.filter(
      (member) => member.user_id !== currentSubscriptionData.user_id
    );
  };

  return (
    <React.Fragment>
      <DeleteSubscriptionMembers
        members={filterMembers()}
        invitations={currentSubscriptionData.subscription_invitations}
        currentSubscriptionData={currentSubscriptionData}
        updateSubscription={updateSubscription}
      />
      {isEmpty(filterMembers()) &&
        isEmpty(currentSubscriptionData.subscription_invitations) && (
          <div className={styles['empty-message']}>
            <FormattedMessage id='close_message' />
          </div>
        )}
      <div className={styles['btn-container']}>
        <button onClick={() => onClose()}>
          <FormattedMessage id='close' />
        </button>
      </div>
    </React.Fragment>
  );
};

export default DeleteInvitationsModal;
