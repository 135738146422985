import React, { useEffect } from 'react';
import isEmpty from 'lodash.isempty';
import { ApolloProvider } from '@apollo/client';
import { IntlProvider } from 'react-intl';
import actionCable from 'actioncable';
import subscribe from './actionCable/subscribe';
import { useSelector, useDispatch } from 'react-redux';
import { setCableConsumer, setTranslation } from './redux/actions';
import { CONSTANTS } from './service/constants';
import './global.css';
import './App.css';
import AppRouter from './pages/Router';
import Cookies from './components/Cookies';
import messages from './translations';
import { LanguageKeys } from './service/constants';
import client from './apolloClient';

function App() {
  const translationKey = useSelector((state) => state.auth.translationKey);
  const user = useSelector((state) => state.user?.user);
  const isAuth = useSelector((state) => state.auth.isAuthorized);
  const consumerCable = useSelector((state) => state.user.actionCableConsumer);

  const dispatch = useDispatch();
  const script = document.createElement('script');
  script.src = '//js.hs-scripts.com/25892365.js';
  script.async = true;
  document.body.appendChild(script);

  const token = localStorage.getItem('token');
  const url = `${CONSTANTS.actionCable}?token=${token}`;

  useEffect(() => {
    if (user?.preferred_language?.key) {
      const { key } = user.preferred_language;
      const language =
        key === LanguageKeys.English
          ? LanguageKeys.English
          : LanguageKeys.Norwegian;
      dispatch(setTranslation(language));
    } else {
      dispatch(setTranslation(LanguageKeys.Norwegian));
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (isEmpty(consumerCable)) {
      dispatch(setCableConsumer(actionCable.createConsumer(url)));
    }
    subscribe(user);
  }, [user, dispatch]);

  useEffect(() => {
    if (!isAuth) {
      if (consumerCable.subscriptions) {
        consumerCable.subscriptions.subscriptions = [];
      }

      dispatch(setCableConsumer({}));
    }
  }, [isAuth, dispatch]);

  return (
    <IntlProvider locale={translationKey} messages={messages[translationKey]}>
      <ApolloProvider client={client}>
        <div className='App'>
          <AppRouter />
          <Cookies />
        </div>
      </ApolloProvider>
    </IntlProvider>
  );
}

export default App;
