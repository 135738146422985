import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setSubscriptionTotalPrice,
  setSubscriptionCount,
  setSubscriptionDiscount,
  setSubscriptionPeriod,
} from '../../../../redux/actions';
import { ProductKeys } from '../../../../service/constants';
import PlanLength from './PlanLength';
import PlansList from './PlansList';
import MembersCounter from './MembersCounter';
import isEmpty from 'lodash.isempty';
import { getSubscriptionDiscount } from '../../../../service/Utils';
import PlanDetailsRow from './PlanDetailsRow';
import { planKey } from '../../../../service/PlanKey';
import DiscountInfo from '../../../../components/DiscountsInfo/DiscountsInfo';

const PlanPaymentDetails = ({
  chosenPlan,
  plans,
  handleProductChosen,
  subscriptionPeriods,
  isCurrentPlan,
  discountsList,
}) => {
  const dispatch = useDispatch();
  const subscriptionCount = useSelector(
    (state) => state.subscriptionDetails.subscriptionTotalCount
  );
  const selectedPeriod = useSelector(
    (state) => state.paymentMethods.subscriptionPeriod
  );
  const subscriptionDiscount = useSelector(
    (state) => state.paymentMethods.subscriptionDiscount
  );
  const amount = useSelector(
    (state) => state.subscriptionDetails.subscriptionTotalPrice
  );
  const [tax, setTax] = useState(0.0);

  const calculateTotal = useCallback(() => {
    if (chosenPlan && subscriptionCount > 0) {
      let total = chosenPlan.price * subscriptionCount + tax;
      dispatch(setSubscriptionTotalPrice(total));
    } else {
      let total = chosenPlan.price + tax;
      dispatch(setSubscriptionTotalPrice(total));
    }
  }, [amount, subscriptionCount, chosenPlan, dispatch]);

  useEffect(() => {
    calculateTotal();
  }, [calculateTotal, subscriptionCount, amount, chosenPlan]);

  const addMembers = () => {
    if (subscriptionCount > 0) {
      const members = subscriptionCount + 1;
      updateSubscriptionMembers(members);
      const discount = getSubscriptionDiscount(
        discountsList,
        subscriptionCount + 1
      );
      dispatch(setSubscriptionDiscount(discount));
    }
  };

  const removeMembers = () => {
    if (subscriptionCount > 1) {
      const members = subscriptionCount - 1;
      updateSubscriptionMembers(members);
      const discount = getSubscriptionDiscount(
        discountsList,
        subscriptionCount - 1
      );
      dispatch(setSubscriptionDiscount(discount));
    }
  };

  const updateSubscriptionMembers = (member) => {
    if (member > 0 && !isEmpty(chosenPlan)) {
      dispatch(setSubscriptionCount(member));
      const total = chosenPlan.price * member + tax;
      dispatch(setSubscriptionTotalPrice(total));
    } else {
      const total = chosenPlan.price + tax;
      dispatch(setSubscriptionTotalPrice(total));
      dispatch(setSubscriptionCount(1));
    }
  };

  const getSubtotal = () => {
    return amount * selectedPeriod.length;
  };

  const getDiscountPrice = () => {
    return getSubtotal() * (subscriptionDiscount.discount_percentage / 100);
  };

  const getTotalAmount = () => {
    return getSubtotal() - getDiscountPrice();
  };

  const getPlanPrice = () => {
    return chosenPlan && chosenPlan.price ? chosenPlan.price.toFixed(2) : 0.0;
  };

  const onSelectPeriod = (value) => {
    dispatch(setSubscriptionPeriod(value));
  };

  return (
    <div>
      <PlansList
        plans={plans}
        handleProductChosen={handleProductChosen}
        subscriptionCount={subscriptionCount}
        tax={tax}
      />
      <PlanDetailsRow
        messageKey={planKey(chosenPlan.key)}
        value={`NOK ${getPlanPrice()}`}
      />
      {chosenPlan.key !== ProductKeys.free && (
        <MembersCounter
          addMembers={addMembers}
          removeMembers={removeMembers}
          count={subscriptionCount}
          messageKey={'added_members'}
        />
      )}
      {subscriptionPeriods.length > 0 && (
        <PlanLength
          subscriptionPeriods={subscriptionPeriods}
          onSelect={onSelectPeriod}
          selectedPeriod={selectedPeriod}
        />
      )}
      <PlanDetailsRow
        messageKey='sub_total'
        value={`NOK ${getSubtotal().toFixed(2)}`}
      />
      <PlanDetailsRow
        style={{
          color: '#ea9449',
        }}
        messageKey='discount'
        value={
          <DiscountInfo
            discountsList={discountsList}
            discount={getDiscountPrice()}
          />
        }
      />
      <PlanDetailsRow messageKey='taxes' value={`NOK ${tax.toFixed(2)}`} />
      <PlanDetailsRow
        messageKey='total'
        value={`NOK ${getTotalAmount().toFixed(2)}`}
      />
    </div>
  );
};

export default PlanPaymentDetails;
