import React, { useState } from 'react';
import { useReactionsQuery } from '../../../../../../../graphql/generated/sparkHooks.ts';
import { Skeleton } from '@mui/material';
import ReactionDetails from './ReactionDetails.jsx';
import styles from './Reactions.module.css';
import SparkModal from '../../Components/SparkModal.jsx';
import ReactionsTab from './ReactionsTab.jsx';
import Drawer from '../../Components/Drawer.jsx';

const Reactions = ({
  open,
  close,
  spark,
  reactionList,
  isMobile,
  handelOpen,
}) => {
  const [reactions, setReactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredReactions, setFilteredReactions] = useState([]);
  const [tabType, setTabType] = useState('All');

  useReactionsQuery({
    variables: {
      reactableId: spark.id,
      reactableType: 'Spark',
    },
    onCompleted: (data) => {
      if (data?.reactions) {
        setReactions(data.reactions);
        setFilteredReactions(data.reactions);
        setLoading(false);
      }
    },
  });

  const filterReactions = (type) => {
    setTabType(type);
    if (type === 'All') {
      setFilteredReactions(reactions);
    } else {
      const filteredReactions = reactions.filter(
        (reaction) => reaction.reactionType === type
      );
      setFilteredReactions(filteredReactions);
    }
  };

  return isMobile ? (
    <Drawer
      isOpen={open}
      children={
        <React.Fragment>
          {filteredReactions && filteredReactions.length > 0 && (
            <ReactionDetails
              reactions={filteredReactions}
              reactionList={reactionList}
            />
          )}
          {loading &&
            Array.from({ length: 4 }).map((_, index) => (
              <div key={index} className={styles['loading']}>
                <Skeleton
                  animation='wave'
                  variant='circular'
                  width={45}
                  height={40}
                />
                <Skeleton
                  variant='text'
                  sx={{ fontSize: '16px', width: '100%' }}
                />
              </div>
            ))}
        </React.Fragment>
      }
      closeDrawer={close}
      openDrawer={handelOpen}
      title={
        <ReactionsTab
          spark={spark}
          reactionList={reactionList}
          filterReactions={filterReactions}
          tabType={tabType}
        />
      }
    />
  ) : (
    <SparkModal
      isOpen={open}
      children={
        <React.Fragment>
          {filteredReactions && filteredReactions.length > 0 && (
            <ReactionDetails
              reactions={filteredReactions}
              reactionList={reactionList}
            />
          )}
          {loading &&
            Array.from({ length: 4 }).map((_, index) => (
              <div key={index} className={styles['loading']}>
                <Skeleton
                  animation='wave'
                  variant='circular'
                  width={45}
                  height={40}
                />
                <Skeleton
                  variant='text'
                  sx={{ fontSize: '16px', width: '100%' }}
                />
              </div>
            ))}
        </React.Fragment>
      }
      closeModal={close}
      title={
        <ReactionsTab
          spark={spark}
          reactionList={reactionList}
          filterReactions={filterReactions}
          tabType={tabType}
        />
      }
    />
  );
};

export default Reactions;
