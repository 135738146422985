import React from 'react';
import styles from './Plans.module.css';
import { FormattedMessage } from 'react-intl';
import { ProductKeys } from '../../../../service/constants';

const Price = ({ plan }) => {
  return (
    <div className={styles['plan-amount']}>
      <span
        className={
          plan.key === ProductKeys.free
            ? styles['plan-amount-currency']
            : styles['plan-amount-currency-family']
        }
      >
        NOK {plan.price}
      </span>
      {plan.key === ProductKeys.free ? (
        <span className={styles['plan-amount-price']}>
          /<FormattedMessage id='per_month' />
        </span>
      ) : (
        <span className={styles['plan-amount-price']}>
          {' '}
          <FormattedMessage id='per_user' />/
          <FormattedMessage id={plan.validity === 365 ? 'year' : 'month'} />
        </span>
      )}
    </div>
  );
};

export default Price;
