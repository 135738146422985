import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { Button } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { FormattedMessage } from 'react-intl';

const DateTimePickerInput = ({
  date,
  setDatePickerValue,
  disableFuture,
  disablePast,
}) => {
  const customDatePicker = {
    '& .MuiInputBase-root': {
      border: '1px solid transparent !important',
      background: '#f5f5f5',
      padding: '11px 16px',
      borderRadius: '4px',
      cursor: 'pointer',
      marginBottom: '12px',
      '&:hover': {
        borderColor: '#36434b !important',
      },
    },
  };

  const [value, setValue] = useState(dayjs('YYYY-DD-MM HH:mm'));
  useEffect(() => {
    if (date) {
      setValue(dayjs(date));
    }
  }, [date]);

  const handleDateChange = (newValue) => {
    if (newValue) {
      setValue(newValue);
      setDatePickerValue(newValue);
    } else {
      setDatePickerValue(null);
    }
  };

  const clearDate = (e) => {
    e.preventDefault();
    setValue(dayjs('YYYY-DD-MM HH:mm'));
    setDatePickerValue(null);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        value={value}
        onChange={handleDateChange}
        slotProps={{
          textField: {
            variant: 'standard',
            fullWidth: true,
            sx: customDatePicker,
          },
        }}
        ampm={false}
        disableFuture={disableFuture}
        disablePast={disablePast}
      />
      {date && (
        <Button onClick={clearDate} color='#737a7f' type='text'>
          <FormattedMessage id='reset_date' />
        </Button>
      )}
    </LocalizationProvider>
  );
};

export default DateTimePickerInput;
