import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './MusicComment.module.css';
import MusicPlayer from './atoms/MusicPlayer';
import X from '../../../../img/X.svg';
import { addCommentOnToggle } from '../../../../redux/actions';

const MusicComment = (props) => {
  const dispatch = useDispatch();
  const commentOnToggle = useSelector((state) => state.grave.commentOnToggle);

  const removeMusic = (id) => {
    if (commentOnToggle.music.length > 0) {
      const newArray = commentOnToggle.music.filter((music) => music.id !== id);
      dispatch(addCommentOnToggle({ ...commentOnToggle, music: newArray }));
    }
  };

  return (
    <div className={styles['music-comment']}>
      <div className={styles['music-comment_content']}>
        {commentOnToggle.music.map((song) => {
          if (song.file) {
            return (
              <div className={styles['music']}>
                <MusicPlayer song={song} />
                {!props.isUploading && (
                  <div
                    className={styles['remove']}
                    onClick={() => removeMusic(song.id)}
                  >
                    <img src={X} alt='close' />
                  </div>
                )}
              </div>
            );
          } else return null;
        })}
      </div>
    </div>
  );
};

export default MusicComment;
