import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import DeceasedTopHeader from '../../containers/NewDeceased/DeceasedTopHeader';
import PersonalityWrapper from '../../containers/NewDeceased/atoms/PersonalityWrapper/PersonalityWrapper';
import DeceasedMenu from '../../containers/NewDeceased/atoms/DeceasedMenu/DeceasedMenu';
import DeceasedImageContainer from '../../containers/NewDeceased/DeceasedImageContainer';
import BackButton from '../../components/BackButton/BackButton';
import ClaimMessage from '../../containers/NewDeceased/atoms/ClaimMessageContainer/ClaimMessage';
import ClaimQueueMessage from '../../containers/NewDeceased/atoms/ClaimMessageContainer/ClaimQueueMessage';
import styles from './NewDeceased.module.css';

const AcceptedUsers = ({ grave, user, isAuth, isClaimed, isAcceptedUser }) => {
  const tabRef = useRef();
  const tabValue = useSelector(
    (state) => state.photoReducer.currentDeceasedTabIndex
  );

  const inClaimRequestQueue = () => {
    return isAuth && !grave.can_claim && grave.current_user_in_claim_queue;
  };

  const canRequestClaim = () => {
    return isAuth && grave.can_claim && !grave.current_user_in_claim_queue;
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (tabRef.current) {
        const extraHeight = 50;
        const rect = tabRef.current.getBoundingClientRect();
        window.scrollTo({
          top: window.scrollY + rect.top + extraHeight,
          behavior: 'smooth',
        });
      }
    }, 0.1);
    return () => clearTimeout(timeoutId);
  }, [tabValue]);

  return (
    <div className={styles['deceased-page']}>
      {/* <BackButton fallBackUrl={'/graves'} /> */}
      {canRequestClaim() && (
        <ClaimMessage grave={grave} isAcceptedUser={isAcceptedUser} />
      )}
      {inClaimRequestQueue() && <ClaimQueueMessage grave={grave} />}
      <DeceasedTopHeader
        isAuth={isAuth}
        user={user}
        grave={grave}
        isClaimed={isClaimed}
        isAcceptedUser={isAcceptedUser}
      />
      <DeceasedImageContainer isAuth={isAuth} grave={grave} />
      {grave.can_add_virtue && (
        <div className={styles['deceased-virtue']} ref={tabRef}>
          <PersonalityWrapper grave={grave} isAuth={isAuth} user={user} />
        </div>
      )}
      <div className={styles['menu-tabs-deceased']}>
        <DeceasedMenu
          isAuth={isAuth}
          grave={grave}
          user={user}
          isClaimed={isClaimed}
        />
      </div>
      <Outlet />
    </div>
  );
};

export default AcceptedUsers;
