import React, { useState, useEffect } from 'react';
import TotalReactions from '../../Reactions/TotalReactions/TotalReactions';
import TotalComments from '../../Comments/TotalComments/TotalComments';
import styles from './SparkFooter.module.css';
import FooterButtons from './FooterButtons';
import CommentsDrawer from '../../Comments/CommentsDrawer/CommentsDrawer';
import {
  useCreateCommentMutation,
  useDestroyCommentMutation,
  useUpdateCommentMutation,
} from '../../../../../../../graphql/generated/sparkHooks.ts';
import CommentsModal from '../../Comments/CommentsModal/CommentsModal.jsx';
import Comment from '../../Comments/Comment/Comment.jsx';
import { addCommentCache } from '../../../../../../../graphql/CacheUpdates/CommentsCache/addCommentCache.js';
import { removeCommentCache } from '../../../../../../../graphql/CacheUpdates/CommentsCache/removeCommentCache.js';
import CommentInput from '../../Comments/CommentInput/CommentInput.jsx';

const SparkFooter = ({
  spark,
  handelReaction,
  reactionList,
  handelRemoveReaction,
  handelUpdateReaction,
  sparkCategories,
  user,
  renderSparkModal,
}) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [createComment] = useCreateCommentMutation();
  const [deleteComment] = useDestroyCommentMutation();
  const [updateComment] = useUpdateCommentMutation();

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < 540);
    };

    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);
    return () => window.removeEventListener('resize', checkIsMobile);
  }, []);

  const handelCreateComment = async (description, sparkId, parentId) => {
    const variables = {
      description,
      commentableId: sparkId,
      commentableType: 'Spark',
    };
    if (parentId) {
      variables['parentId'] = parentId;
    }
    try {
      await createComment({
        variables: { ...variables },
        update: addCommentCache(openDrawer, parentId),
      });
      if (!parentId) localStorage.setItem('commented', true);
    } catch (error) {
      console.log(error);
    }
  };

  const handelDeleteComment = async (comment) => {
    try {
      if (comment?.id) {
        await deleteComment({
          variables: {
            id: comment.id,
          },
          update: removeCommentCache(spark.id, comment?.parentId),
        });
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handelEditComment = async (description, comment) => {
    try {
      if (comment.id && description) {
        await updateComment({
          variables: {
            id: comment.id,
            description,
          },
        });
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handelOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handelCloseDrawer = () => {
    setOpenDrawer(false);
  };

  return (
    <React.Fragment>
      <div className={styles['container']}>
        <TotalReactions
          spark={spark}
          reactionList={reactionList}
          isMobile={isMobile}
        />
        <TotalComments
          renderSparkModal={renderSparkModal}
          totalComments={spark.commentsCount}
          handelOpenDrawer={handelOpenDrawer}
        />
      </div>
      <div
        className={
          renderSparkModal || (spark?.comments && spark?.comments.length > 0)
            ? styles['footer-container']
            : ''
        }
      >
        <FooterButtons
          renderSparkModal={renderSparkModal}
          handelReaction={handelReaction}
          reactionList={reactionList}
          handelOpenDrawer={handelOpenDrawer}
          spark={spark}
          handelRemoveReaction={handelRemoveReaction}
          handelUpdateReaction={handelUpdateReaction}
        />
      </div>
      {openDrawer && isMobile ? (
        <CommentsDrawer
          openDrawer={openDrawer}
          spark={spark}
          handelCloseDrawer={handelCloseDrawer}
          handelOpenDrawer={handelOpenDrawer}
          handelCreateComment={handelCreateComment}
          handelEditComment={handelEditComment}
          handelDeleteComment={handelDeleteComment}
        />
      ) : (
        openDrawer && (
          <CommentsModal
            open={openDrawer}
            user={user}
            sparkCategories={sparkCategories}
            spark={spark}
            handelCloseModal={handelCloseDrawer}
            handelCreateComment={handelCreateComment}
            handelEditComment={handelEditComment}
            handelDeleteComment={handelDeleteComment}
          />
        )
      )}
      {spark?.comments && !renderSparkModal && spark.comments.length > 0 && (
        <div>
          {spark.comments.map((comment) => (
            <Comment
              spark={spark}
              comment={comment}
              handelEditComment={handelEditComment}
              handelDeleteComment={handelDeleteComment}
              handelCreateComment={handelCreateComment}
            />
          ))}
          <CommentInput
            handelCreateComment={handelCreateComment}
            spark={spark}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default SparkFooter;
