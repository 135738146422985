import React from 'react';
import { FormattedMessage } from 'react-intl';

const GetDiscountTitles = ({ discountsList }) => {
  return (
    <div>
      <div>
        <FormattedMessage id='discount' />
      </div>
      <div>
        {discountsList.map((discount, index) => (
          <div key={index}>
            <FormattedMessage
              id={
                discount.user_count === 1
                  ? 'for_member'
                  : discount.user_count >= 6
                  ? 'for_6_members'
                  : 'for_members'
              }
              values={{ user: discount.user_count }}
            />
            : {discount.discount_percentage}%
          </div>
        ))}
      </div>
    </div>
  );
};

export default GetDiscountTitles;
