import { ApolloClient, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { CONSTANTS } from './service/constants';
import cache from './graphql/GraphqlPolicies/MemoryCache.ts';

const graphQLink = new HttpLink({
  uri: `${CONSTANTS.baseUrl}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token') || '';

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(graphQLink),
  cache: cache,
});

export default client;
