import React from 'react';
import Workspace from '../WorkPlaces/Workspace';
import X from '../../../../img/X.svg';
import { Modal } from '@mui/material';
import { makeStyles } from '@mui/styles';
import styles from './WorkPLaceModal.module.css';

const WorkPlacesModal = ({ onOpen, onClose, grave }) => {
  const useStyles = makeStyles(( ) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalContent: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      width: '900px',
      height: 'calc(100% - 32px)',
      overflowY: 'auto',
      backgroundColor: '#fff',
      padding: '32px',
      borderRadius: 0,
      outline: 'none',
      '@media (max-width: 800px)': {
        width: '100%',
        height: 'calc(100% - 32px)',
        overflow: 'auto',
      },
    },
    modalBackground: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  }));
  const classes = useStyles();

  return (
    <Modal
      open={onOpen}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{
        className: classes.modalBackground,
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.modalContent}>
        <div>
          <div className={styles['workspace-content_header']}>
            <span>Arbeidsplasser</span>
            <img loading='lazy' src={X} alt='x' onClick={onClose} />
          </div>
          <div className={styles['workspace-content_subheader']}>
            <span>Steder den gravlagte arbeidet</span>
          </div>
        </div>
        <Workspace
          closeModal={onClose}
          graveInformation={grave}
          workspaces={{
            workspaces: grave.grave_workplaces,
          }}
        />
      </div>
    </Modal>
  );
};

export default WorkPlacesModal;
