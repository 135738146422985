import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Upload from '../../../../../img/UploadImageIcon.svg';
import showUserNotification from '../../../../../components/UserNotification/showUserNotification';
import { addCommentOnToggle } from '../../../../../redux/actions';
import styles from './UploadImage.module.css';
import UpgradePlanModal from '../../../../../components/Modals/UpgradePlanModal';
import isEmpty from 'lodash.isempty';

const UploadImage = ({ handleShowImages }) => {
  const inputFile = useRef();
  const dispatch = useDispatch();
  const [openUpgradePlanModal, setOpenUpgradePlanModal] = useState(false);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const canAddPhotos = useSelector(
    (state) => state.user.activeSubscription?.can_add_timeline_photos
  );
  const currentComment = useSelector((state) => state.grave.commentOnToggle);
  const bigAmountOfPhotos = () =>
    showUserNotification('Velg ferre en 10 bilder og prøv igjen', 'warning');

  const biggerImage = () =>
    showUserNotification(
      'Last opp et bilde som ikke er større enn 50MB.',
      'warning'
    );

  const choseCoverPhotoFromList = (photos) => {
    if (!isEmpty(currentComment.photos)) {
      if (currentComment.photos.length + photos.length <= 10) {
        dispatch(
          addCommentOnToggle({
            ...currentComment,
            photos: [...currentComment.photos, ...photos],
          })
        );
      } else {
        bigAmountOfPhotos();
      }
    } else {
      dispatch(
        addCommentOnToggle({
          ...currentComment,
          photos: [...photos],
        })
      );
    }
  };

  const selectTheImages = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const imagesArray = Array.from(e.target.files);
      const selectedImages = [];

      const processImage = (file, result) => {
        if (file.size / (1024 * 1024) <= 50) {
          selectedImages.push({
            image: result,
            id: Date.now().toString(),
            file: file,
            title: '',
          });
        } else {
          biggerImage();
        }
        if (selectedImages.length === imagesArray.length) {
          choseCoverPhotoFromList(selectedImages);
        }
      };

      imagesArray.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (event) => processImage(file, event.target.result);
        reader.readAsDataURL(file);
      });
    }
  };

  useEffect(() => {
    if (!isEmpty(currentComment.photos)) {
      handleShowImages();
    }
  }, [currentComment.photos]);

  const clickOnHideInput = () => {
    if (canAddPhotos) {
      inputFile.current.click();
    } else {
      setOpenUpgradePlanModal(true);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDraggingOver(false);
    const droppedFiles = e.dataTransfer.files;
    selectTheImages({ target: { files: droppedFiles } });
  };

  return (
    <>
      <UpgradePlanModal
        isOpen={openUpgradePlanModal}
        onClose={() => setOpenUpgradePlanModal(false)}
        body='add_more_images_body'
        title='upgrade_modal_heading'
      />
      <div
        className={
          isDraggingOver
            ? styles['drop-image']
            : styles['cropper-modal_content-input-image']
        }
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onDragLeave={() => setIsDraggingOver(false)}
      >
        <div className={styles['cropper-modal_content-input-image_image']}>
          <input
            multiple
            ref={inputFile}
            type='file'
            accept='image/jpeg, image/jpg, image/png'
            className={styles['cropper-modal_content-input-image_image-input']}
            onChange={selectTheImages}
          />
          <div className={styles['upload-image']}>
            <div
              className={styles['upload-image-input']}
              onClick={clickOnHideInput}
            >
              <img loading='lazy' src={Upload} alt='UploadImage' />
              <span className={styles['upload-image-text']}>
                Dra bilder hit eller bla gjennom filer
              </span>
            </div>
            <div className={styles['image-text-message']}>
              Bildet bør ikke være større enn 50MB
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadImage;
