import React from 'react';
import './Paginator.css';
import PrimaryButton from '../../../../../components/PrimaryButton';
import { FormattedMessage } from 'react-intl';

const Paginator = (props) => {
  const handlePrevClick = () => {
    props.setCurrentPage(props.currentPage - 1);
  };

  const handleNextClick = () => {
    props.setCurrentPage(props.currentPage + 1);
  };

  return (
    <div className='paginator'>
      <PrimaryButton
        onClick={handlePrevClick}
        children={<FormattedMessage id='auth_back' />}
        disabled={props.currentPage === 1}
      />
      <PrimaryButton
        onClick={handleNextClick}
        children={<FormattedMessage id='auth_next' />}
        disabled={props.currentPage >= props.totalPages}
      />
    </div>
  );
};

export default Paginator;
