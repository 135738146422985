import React from 'react';
import styles from './VideoList.module.css';
import { useDispatch } from 'react-redux';
import { setEventVideoIndex } from '../../../../../redux/actions';
import ReactPlayer from 'react-player';

const VideoList = ({ video, videoIndex, currentEventVideoIndex }) => {
  const dispatch = useDispatch();

  return (
    <video
      className={
        currentEventVideoIndex === videoIndex
          ? styles['selected-video']
          : styles['video']
      }
      onClick={() => {
        dispatch(setEventVideoIndex(videoIndex));
      }}
    >
      <source src={video.file_url}></source>
    </video>
  );
};

export default VideoList;
