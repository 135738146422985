import React, { useState, useEffect } from 'react';
import Header from './Header';
import styles from './GraveWall.module.css';
import { Modal, Dialog } from '@mui/material';
import ExpandIcon from '../About/components/ExpandIcon';
import Loader from '../../../../components/Loader/Loader';
import VideoMobile from '../ChatModals/VideoModal/VideoMobile';
import MusicModal from '../ChatModals/MusicModal/MusicModal';
import MusicMobile from '../ChatModals/MusicModal/MusicMobile';
import Memories from '../Memories/Memories';
import GraveWallImageModal from '../GraveWallImagesModal/GraveWallImageModal';
import GraveWallVideoModal from '../GraveWallVideoModal/GraveWallVideoModal';

const MemoryWall = ({ hideMenu, loading, grave, isAuth, user }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [openMusicModal, setOpenMusicModal] = useState(false);
  const [openMusicMobileModal, setOpenMusicMobileModal] = useState(false);
  const [openVideoMobileModal, setOpenVideoMobileModal] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);

  const handleOpenVideoMobileModal = () => setOpenVideoMobileModal(true);
  const handleCloseVideoMobileModal = () => setOpenVideoMobileModal(false);
  const handleOpenMusicMobileModal = () => setOpenMusicMobileModal(true);
  const handleCloseMusicMobileModal = () => setOpenMusicMobileModal(false);
  const handleOpenMusicModal = () => setOpenMusicModal(true);
  const handleCloseMusicModal = () => setOpenMusicModal(false);
  const handleOpenVideoModal = () => setOpenVideoModal(true);
  const handleCloseVideoModal = () => setOpenVideoModal(false);
  const handleOpenImageModal = () => setOpenImageModal(true);
  const handleCloseImageModal = () => setOpenImageModal(false);

  const handleResize = () => {
    if (window.innerWidth >= 1020) {
      setIsExpanded(true);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isExpanded]);

  const showExpandIcon = () => {
    return !hideMenu ? <ExpandIcon isExpanded={isExpanded} /> : null;
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <Header
        title='Minnetavle'
        isExpanded={isExpanded}
        children={showExpandIcon()}
        setIsExpanded={setIsExpanded}
      />
      {isExpanded && (
        <Memories
          isAuth={isAuth}
          user={user}
          grave_name={grave?.name}
          openVideoModal={handleOpenVideoModal}
          openMusicModal={handleOpenMusicModal}
          openMusicModalMobile={handleOpenMusicMobileModal}
          openVideoModalMobile={handleOpenVideoMobileModal}
          openImageModal={handleOpenImageModal}
        />
      )}
      {openVideoModal && (
        <GraveWallVideoModal
          onOpen={openVideoModal}
          onClose={handleCloseVideoModal}
          title='Legg til video'
        />
      )}

      {openImageModal && (
        <GraveWallImageModal
          onOpen={openImageModal}
          onClose={handleCloseImageModal}
          title='Legg til bilde'
        />
      )}
      <Modal
        open={openMusicModal}
        className={styles['edit-virtue-modal_modal']}
        onClose={handleCloseMusicModal}
      >
        <MusicModal closeModal={handleCloseMusicModal} />
      </Modal>
      <Dialog
        open={openMusicMobileModal}
        className={styles['edit-virtue-modal_modal']}
        onClose={handleCloseMusicMobileModal}
      >
        <MusicMobile closeModal={handleCloseMusicMobileModal} />
      </Dialog>
      <Dialog
        open={openVideoMobileModal}
        className={styles['edit-virtue-modal_modal']}
        onClose={handleCloseVideoMobileModal}
      >
        <VideoMobile closeModal={handleCloseVideoMobileModal} />
      </Dialog>
    </div>
  );
};

export default MemoryWall;
