import React from 'react';
import './Card.css';

const Card = (props) => {
    return(
        <div className='scientists-card' style={{minHeight: props.height}}>
            <div className="scientists-card_header">
                <img loading='lazy' src={props.image} alt='card img'/>
                <span>{props.header}</span>
            </div>
            <span>{props.title}</span>
        </div>
    )
}

export default Card;