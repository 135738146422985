import React, { useEffect, useState } from 'react';
import styles from './EmbeddedVideo.module.css';
import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '../../../../../img/DeleteIcon.svg';
import { addCommentOnToggle } from '../../../../../redux/actions';

const EmbeddedVideo = ({ embeddedVideo }) => {
  const [title, setTitle] = useState('');
  const dispatch = useDispatch();
  const currentComment = useSelector((state) => state.grave.commentOnToggle);

  useEffect(() => {
    if (embeddedVideo.title) {
      setTitle(embeddedVideo.title);
    }
  }, [embeddedVideo]);

  const handleRemoveVideo = () => {
    dispatch(
      addCommentOnToggle({
        ...currentComment,
        embeddedVideo: null,
      })
    );
  };

  const handelOnTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleAddTitle = () => {
    dispatch(
      addCommentOnToggle({
        ...currentComment,
        embeddedVideo: {
          ...embeddedVideo,
          title: title,
        },
      })
    );
  };

  const handleKeyDown = (e) => {
    const keyCode = e.keyCode || e.which;
    if (keyCode === 13) {
      e.preventDefault();
      handleAddTitle();
    }
  };

  return (
    <div>
      <div className={styles['input-element']}>
        <div className={styles['title']}>
          Videotittel <span>{`(valgfritt)`}</span>
        </div>
        <div className={styles['input-wrapper']}>
          <input
            type='text'
            value={title}
            placeholder='Bildetittel'
            onChange={handelOnTitleChange}
            onBlur={handleAddTitle}
            onKeyDown={(e) => handleKeyDown(e)}
          />
        </div>
      </div>
      <div className={styles['video-player']}>
        <ReactPlayer
          url={embeddedVideo.video}
          height='483px'
          width='100%'
          light={true}
          controls={true}
        />
        <button onClick={() => handleRemoveVideo()}>
          <img src={DeleteIcon} alt='Delete' />
        </button>
      </div>
    </div>
  );
};

export default EmbeddedVideo;
