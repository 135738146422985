import React, { useState } from 'react';
import EditButton from '../../../../components/EditButton/EditButton';
import DeleteIcon from '../../../../img/DeleteRedIcon.svg';
import EditIcon from '../../../../img/EditSmallIcon.svg';
import styles from './GraveUserForm.module.css';
import MoreHorizontal from '../../../../img/MoreHorizontal.svg';
import { Popover } from '@mui/material';
import WarningModal from './WarningModal';

const PopoverModal = ({
  handelRelationId,
  setOpenDeleteModal,
  editRelation,
  relations,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const open = Boolean(anchorEl);
  const graveUser = relations.length > 1;

  const handleClick = (value) => {
    setAnchorEl(value);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <span
        className={styles['more-icon-container']}
        onClick={(e) => {
          handleClick(e.currentTarget);
        }}
      >
        <img
          className={styles['more-icon']}
          src={MoreHorizontal}
          alt='moreIcon'
        />
      </span>
      <Popover
        id='long-menu'
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: '100px',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={styles['user-action']}>
          <EditButton
            title={'settings_edit'}
            onClick={() => {
              handelRelationId(editRelation.id);
              handleClose();
            }}
            icon={EditIcon}
          />
          <EditButton
            title={'delete'}
            onClick={() => {
              if (graveUser) {
                setOpenDeleteModal(true);
              } else {
                setOpenWarningModal(true);
              }
              handleClose();
            }}
            icon={DeleteIcon}
          />
        </div>
      </Popover>
      {openWarningModal && (
        <WarningModal
          open={openWarningModal}
          close={() => setOpenWarningModal(false)}
        />
      )}
    </div>
  );
};

export default PopoverModal;
