import React from 'react';
import './HomepageScientists.css';
import HomepageScientistsFirst from './Scientists/HomepageScientistsFirst';
import HomepageScientistsSecond from './Scientists/HomepageScientistsSecond';
import HomepageScientistsThird from './Scientists/HomepageScientistsThird';
import HomepageScientistsFourth from './Scientists/HomepageScientistsFourth';
import HomepageScientistsFifth from './Scientists/HomepageScientistsFifth';

const HomepageScientists = (props) => {
    return(
        <div className='homepage-scientists'>
            <HomepageScientistsFirst />
            <HomepageScientistsSecond />
            <HomepageScientistsThird />
            <HomepageScientistsFourth />
            <HomepageScientistsFifth />
        </div>
    )
}

export default HomepageScientists;