import React from 'react';
import styles from './VerificationCodeInput.module.css';

const VerificationCodeInput = ({ values, setValues }) => {
  const handleChange = (e, index) => {
    const { value } = e.target;
    if (/^[0-9]$/.test(value) || value === '') {
      const newValues = [...values];
      newValues[index] = value;
      setValues(newValues);
      if (value && index < 4) {
        setTimeout(() => {
          document.getElementById(`input-${index + 1}`).focus();
        }, 10);
      }

      if (!value && index > 0) {
        setTimeout(() => {
          document.getElementById(`input-${index - 1}`).focus();
        }, 10);
      }
    }
  };

  return (
    <div className={styles['verification-code-input']}>
      {values.map((value, index) => (
        <input
          key={index}
          id={`input-${index}`}
          type='text'
          inputMode='numeric'
          maxLength='1'
          value={value}
          onChange={(e) => handleChange(e, index)}
          onFocus={(e) => e.target.select()}
          className={styles['digit-input']}
        />
      ))}
    </div>
  );
};

export default VerificationCodeInput;
