import React, { useEffect, useState } from 'react';
import styles from './PaymentMethods.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { request } from '../../../service/request';
import { setPaymentMethods } from '../../../redux/actions';
import BillingMethod from './BillingMethod';
import Loader from '../../../components/Loader/Loader';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

const PaymentMethods = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.user?.user) || {};
  const subscription = user.subscription || {};
  const amount = subscription.amount && `${subscription.amount} kroner`;
  const userSlug =
    localStorage.getItem('user-slug') || sessionStorage.getItem('user-slug');
  const paymentMethods = useSelector(
    (state) => state.paymentMethods.paymentMethods || null
  );

  useEffect(() => {
    fetchPaymentMethods();
  }, [userSlug]);

  const fetchPaymentMethods = async () => {
    setLoading(true);
    if (!userSlug) return;
    try {
      await request(`/users/${userSlug}/payment_methods`).then((data) => {
        dispatch(setPaymentMethods(data.data));
        setLoading(false);
      });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <div className={styles['membership-page']}>
      <div className={styles['membership-page_content-header']}>
        <span className={styles['membership-page_content-header_header']}>
          <FormattedMessage id='settings_paymentMethods' />
        </span>
        <div className={styles['membership-page_content-header_subheader']}>
          <FormattedMessage id='settings_mangeMethods' />
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <React.Fragment>
          {paymentMethods &&
            Array.isArray(paymentMethods) &&
            paymentMethods.length == 0 && (
              <div
                className={styles['membership-page_content-header-no-result']}
              >
                <FormattedMessage id='settings_emptyMethods' />
              </div>
            )}
          {paymentMethods &&
            paymentMethods.map((method) => (
              <BillingMethod
                paymentMethod={method}
                amount={amount}
                key={method.id}
              />
            ))}
          <div className={styles['agreement']}>
            <FormattedMessage id='settings_manageVipps' />{' '}
            <Link to='/vipps-agreements'>
              <FormattedMessage id='settings_goToAgreements' />
            </Link>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default PaymentMethods;
