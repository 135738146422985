import React, { useState, useEffect } from 'react';
import styles from './DeceasedImageContainer.module.css';
import { useSelector, useDispatch } from 'react-redux';
import Upload from '../../img/Upload.svg';
import {
  addPhotoForCropping,
  setCurrentGravePhotoIndex,
} from '../../redux/actions';
import AddNewImage from './atoms/AddNewImage/AddNewImage';
import ImageSlider from './atoms/ImagesSlider/ImageSlider';
import ImagesList from './atoms/ImagesList/ImagesList';
import { Dialog } from '@mui/material';
import { makeStyles } from '@mui/styles';
import GravePictureGallery from './atoms/GravePictureGallery/GravePictureGallery';
import { CONSTANTS } from '../../service/constants';
import Backdrop from '@mui/material/Backdrop';
import UploadImageModal from './atoms/UploadImageModal/UploadImageModal';
import ShowAllMediaImages from './atoms/ShowAllMediaImages/ShowAllMediaImages';

const useStyle = makeStyles(() => ({
  rootDialog: {
    maxWidth: 'none !important',
    borderRadius: '10px !important',
    '@media (max-width: 800px)': {
      margin: '0px',
      width: '100%',
    },
  },
  rootDialogProfilePhoto: {
    margin: '0px',
    width: '100%',
    maxWidth: '1140px !important',
    borderRadius: '10px !important',
    '@media (max-width: 800px)': {
      width: '100%',
    },
  },
  rootScrollBody: {
    padding: '20px 0px !important',
    width: '100%',
  },
}));

const DeceasedImageContainer = (props) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [category, setCategory] = useState('display');
  const { isAuth, grave } = props;
  const user = useSelector((state) => state.user) || {};
  const [openAllMediaImages, setOpenAllMediaImages] = useState(false);
  const photoForCropping = useSelector(
    (state) => state.photoReducer.photoForCropping
  );
  const backgroundImageList = useSelector(
    (state) => state.photoReducer.listOfPhotos
  );
  const [openCropperModal, setOpenCropperModal] = useState(false);
  const [firstModalOpen, setFirstModalOpen] = useState(true);
  const [croppedImage, setCroppedImage] = useState();
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [timelinePhotoLoading, setTimelinePhotoLoading] = useState(false);
  const [pictureGallery, setPictureGallery] = useState(false);
  const [loading, setLoading] = useState(true);
  const can_add_photos = grave.can_add_photos;
  const isClaimed = grave.grave_status === CONSTANTS.grave.claimed;
  const displayImage = grave.display_picture;
  const currentPhotoIndex = useSelector(
    (state) => state.photoReducer.currentGravePhoto
  );

  useEffect(() => {
    if (!pictureGallery) {
      if (backgroundImageList.length > 0) {
        if (grave.cover_image_id && !backgroundImage) {
          const coverImage = backgroundImageList.findIndex(
            (image) => image.id === grave.cover_image_id
          );
          dispatch(setCurrentGravePhotoIndex(coverImage));
          setBackgroundImage(backgroundImageList[coverImage]);
        } else {
          dispatch(setCurrentGravePhotoIndex(0));
          setBackgroundImage(backgroundImageList[0]);
        }
      } else {
        setBackgroundImage(null);
      }
    }
  }, [backgroundImageList]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 750) {
        setOpenAllMediaImages(false);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [openAllMediaImages]);

  const handleCloseCropperModal = () => {
    dispatch(addPhotoForCropping({}));
    setOpenCropperModal(false);
    setFirstModalOpen(true);
  };

  const handleOpenCropperModal = () => {
    setCategory('display');
    setOpenCropperModal(true);
  };

  const handleOpenPictureGalleryModal = () => {
    setPictureGallery(true);
  };

  const handleClosePictureGalleryModal = () => {
    setPictureGallery(false);
  };

  const handleOpenSecondModal = (croppedImage) => {
    setFirstModalOpen(false);
    setCroppedImage(croppedImage);
  };

  const handleOpenFirstModal = () => {
    setFirstModalOpen(true);
  };

  const handleTimelinePhotoLoading = (isLoading) => {
    setTimelinePhotoLoading(isLoading);
  };

  return (
    <div className={styles['image-container']}>
      <Dialog
        className={styles['grave-picture-gallery-modal']}
        open={pictureGallery}
        onClose={handleClosePictureGalleryModal}
        closeAfterTransition
        scroll='body'
        classes={{
          paper: classes.rootDialogProfilePhoto,
          scrollBody: classes.rootScrollBody,
        }}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <GravePictureGallery
          closeModal={handleClosePictureGalleryModal}
          backgroundImage={backgroundImage}
        />
      </Dialog>
      {user.user.is_subscribed && isAuth ? (
        <>
          {!backgroundImage ? (
            <>
              {isAuth && can_add_photos ? (
                <div className={styles['empty-container']}>
                  <div className={styles['upload-image']}>
                    <div className={styles['image-text-message']}>
                      Start Minnealbum
                    </div>
                    <div
                      className={styles['upload-image-input']}
                      onClick={handleOpenCropperModal}
                    >
                      <img loading='lazy' src={Upload} alt='UploadImage' />
                      <span className={styles['upload-image-text']}>
                        Trykk for å legge til Minnebilder
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={styles['empty-container']}>
                  <div className={styles['right']}>
                    {!isAuth
                      ? `Velkommen til ${grave.name} sin grav.`
                      : `Velkommen ${user.user?.full_name}. Graven til ${grave.name} blir sterkere med din relasjon. Takk for at du er her.`}
                  </div>
                </div>
              )}
            </>
          ) : (
            backgroundImage && (
              <div className={styles['images-wrapper']}>
                <ImageSlider
                  handleOpenPictureGalleryModal={handleOpenPictureGalleryModal}
                  backgroundImage={backgroundImage}
                  canAddPhotos={can_add_photos}
                  isAuth={isAuth}
                />
                <div className={styles['images-list-section']}>
                  <div className={styles['images-list-container']}>
                    {isAuth && user.user.is_subscribed && (
                      <AddNewImage
                        title='Legg til bilde'
                        handleOpenCropperModal={handleOpenCropperModal}
                      />
                    )}
                    {backgroundImageList.length > 0 && (
                      <div className={styles['images-list']}>
                        {backgroundImageList.map((photo, index) => (
                          <ImagesList
                            photo={photo}
                            key={index}
                            photoIndex={index}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles['add-media-container']}>
                  {isAuth && user.user.is_subscribed && (
                    <AddNewImage
                      title='Legg til media'
                      handleOpenCropperModal={handleOpenCropperModal}
                    />
                  )}
                  <button
                    className={styles['all-media-btn']}
                    onClick={() => setOpenAllMediaImages(true)}
                  >
                    Se alle medier
                  </button>
                </div>
              </div>
            )
          )}
        </>
      ) : (
        <div className={styles['empty-container']}>
          <div className={styles['right']}>
            {!isAuth
              ? `Velkommen til ${grave.name} sin grav.`
              : `Velkommen ${user.user?.full_name} til ${grave.name} sin grav. Bilder av gravlagte vil vises her når de legges til.`}
          </div>
        </div>
      )}
      {openCropperModal && (
        <UploadImageModal
          onOpen={openCropperModal}
          onClose={() => setOpenCropperModal(false)}
          category={category}
          title='Legg til Minnebilde'
        />
      )}
      {openAllMediaImages && (
        <ShowAllMediaImages
          onOpen={openAllMediaImages}
          onClose={() => setOpenAllMediaImages(false)}
        />
      )}
    </div>
  );
};

export default DeceasedImageContainer;
