import React from 'react';
import isEmpty from 'lodash.isempty';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import styles from './LoginForm.module.css';
import { planKey } from '../../../service/PlanKey';

const Invitation = () => {
  const subscriptionInvitationToken = useSelector(
    (state) => state.invitationToken.subscriptionInvitationData
  );
  const tokeErrorMessage = useSelector(
    (state) => state.invitationToken.tokeErrorMessage
  );

  const graveInvitation = useSelector(
    (state) => state.grave.graveInvitationToken
  );
  return (
    <div>
      {!isEmpty(subscriptionInvitationToken) ? (
        <div className={styles['invitation-message']}>
          <FormattedMessage
            id='subscription_invitation_message'
            values={{
              subscription: planKey(
                subscriptionInvitationToken.subscription_key
              ),
              name: subscriptionInvitationToken.invited_by,
            }}
          />
        </div>
      ) : null}
      {!isEmpty(tokeErrorMessage) ? (
        <div className={styles['invitation-error-message']}>
          {tokeErrorMessage}
        </div>
      ) : null}

      {!isEmpty(graveInvitation) ? (
        <div className={styles['invitation-message']}>
          <FormattedMessage id='grave_invitation_first_text' />{' '}
          <FormattedMessage id='of' />{' '}
          {graveInvitation.inviting_user?.full_name}.
        </div>
      ) : null}
    </div>
  );
};

export default Invitation;
