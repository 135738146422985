import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NextPhotoPointer from '../../../../../img/OrangePointerNext.svg';
import PrevPhotoPointer from '../../../../../img/OrangePointerPrev.svg';
import EmptyDot from '../../../../../img/EmptyDot.svg';
import styles from './EventVideos.module.css';
import OrangeDot from '../../../../../img/FilledDot.svg';
import ManAvatar from '../../../../../img/BoyAvatar.svg';
import EventImageModal from '../AddEventImageModal/AddEventImageModal';
import {
  setSelectedEvent,
  setSelectedEventYear,
  setOpenSelectedEvent,
  addLifeEventPhotos,
  addLifeEventVideos,
} from '../../../../../redux/actions';
import ReactPlayer from 'react-player';

const EventVideos = ({ videos, event, year }) => {
  const dispatch = useDispatch();
  const [videoIndex, setVideoIndex] = useState(0);
  const [touchStartX, setTouchStartX] = useState(null);
  const user = useSelector((state) => state.user.user);

  const prevPhoto = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (videoIndex === 0) {
      setVideoIndex(videos.length - 1);
    } else {
      setVideoIndex(videoIndex - 1);
    }
  };

  const nextPhoto = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (videoIndex === videos.length - 1) {
      setVideoIndex(0);
      return 0;
    } else {
      setVideoIndex(videoIndex + 1);
    }
  };

  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchEnd = (e) => {
    const touchEndX = e.changedTouches[0].clientX;
    const touchDistance = touchEndX - touchStartX;

    const swipeThreshold = 50;

    if (Math.abs(touchDistance) > swipeThreshold) {
      if (touchDistance > 0) {
        prevPhoto();
      } else {
        nextPhoto();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchend', handleTouchEnd);

    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, [videoIndex]);

  const handelChangeImage = (event, index) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setVideoIndex(index);
  };

  const handelStopPagination = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const handelClick = (e) => {
    e.preventDefault();
    dispatch(setSelectedEvent(event));
    dispatch(setSelectedEventYear(year));
    dispatch(setOpenSelectedEvent(true));
  };

  return (
    <React.Fragment>
      <div
        className={styles['grave-picture-gallery_photos-photo']}
        onClick={(e) => handelClick(e)}
      >
        {videos.length > 0 && (
          <ReactPlayer
            url={
              videoIndex === 0
                ? videos[0].file_url
                : videos[videoIndex].file_url
            }
            className={styles['video-player']}
          />
        )}
        {videos.length > 1 && (
          <React.Fragment>
            <button
              className={`${styles['buttons-prev']} ${styles['show-buttons']}`}
              onClick={(event) => prevPhoto(event)}
            >
              <img
                loading='lazy'
                src={PrevPhotoPointer}
                alt='PrevPhotoPointer'
              />
            </button>
            <button
              className={`${styles['buttons-next']} ${styles['show-buttons']}`}
              onClick={(event) => nextPhoto(event)}
            >
              <img
                loading='lazy'
                src={NextPhotoPointer}
                alt='PrevPhotoPointer'
              />
            </button>
          </React.Fragment>
        )}
        {videos.length > 1 && (
          <div
            className={styles['dots-container']}
            onClick={(event) => handelStopPagination(event)}
          >
            {videos.map((video, index) => (
              <img
                style={{
                  height: '10px',
                  width: '10px',
                }}
                onClick={(event) => handelChangeImage(event, index)}
                key={index}
                loading='lazy'
                src={videoIndex === index ? OrangeDot : EmptyDot}
                alt='dot'
              />
            ))}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default EventVideos;
