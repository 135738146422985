import React from 'react';
import './Stepper.css';

const Stepper = (props) => {
  return (
    <div className='stepper-section'>
      <div className='stepper-section-test'>
        <div
          className={
            props.step === 0
              ? 'stepper-section-button active'
              : 'stepper-section-button'
          }
        >
          01
        </div>
      </div>

      <div
        className={
          props.step === 1
            ? 'stepper-section-dashed active'
            : 'stepper-section-dashed'
        }
      />

      <div className='stepper-section-test'>
        <div
          className={
            props.step === 1
              ? 'stepper-section-button active'
              : 'stepper-section-button'
          }
        >
          02
        </div>
      </div>

      <div
        className={
          props.step === 2
            ? 'stepper-section-dashed active'
            : 'stepper-section-dashed'
        }
      />

      <div className='stepper-section-test'>
        <div
          className={
            props.step === 2
              ? 'stepper-section-button active'
              : 'stepper-section-button'
          }
        >
          03
        </div>
      </div>
    </div>
  );
};

export default Stepper;
