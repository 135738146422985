import React from 'react';
import styles from './Input.module.css';
import Warning from '../../img/Warning.svg';
import { FormattedMessage } from 'react-intl';

const WarningMessage = ({ messageKey, value = null }) => {
  return (
    <div className={styles['warning-container']}>
      <img src={Warning} alt='warning' />
      <div className={styles['warning']}>
        <FormattedMessage id={messageKey} values={value} />
      </div>
    </div>
  );
};

export default WarningMessage;
