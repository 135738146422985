import React from 'react';
import styles from './YearButton.module.css';
import ExpandMore from '@mui/icons-material/ExpandMore';

const YearButton = ({ year }) => {
  return (
    <div className={styles['year-btn']}>
      <button>{year}</button>
      <ExpandMore />
    </div>
  );
};

export default YearButton;
