import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styles from './NewDeceased.module.css';
import {
  addComment,
  addProfilePhotoList,
  goToChosenGrave,
  setGraveUsersList,
  saveListOfPhotosFromCropper,
  spinnerStart,
  spinnerFinish,
  setSubscription,
  addCommentOnToggle,
  setCurrentGravePhotoIndex,
  setInformationAboutUser,
} from '../../redux/actions';
import { request } from '../../service/request';
import { CONSTANTS } from '../../service/constants';
import Loader from '../../components/Loader/Loader';
import AuthModal from '../../components/Modals/AuthModal';
import { useNavigate } from 'react-router-dom';
import AcceptedUsers from './AcceptedUsers';
import ConnectRequest from '../ConnectRequest/ConnectRequest';

const NewDeceasedPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.auth.isAuthorized);
  const isLoading = useSelector((state) => state.spinnerLoad.isLoading);
  const [user, setUser] = useState({});
  const grave = useSelector((state) => state.grave.grave);
  const userSlug =
    localStorage.getItem('user-slug') || sessionStorage.getItem('user-slug');
  const isClaimed = grave.grave_status === CONSTANTS.grave.claimed;

  let { slug } = useParams();

  const fetchGrave = async () => {
    try {
      if (slug) {
        dispatch(spinnerStart());
        dispatch(goToChosenGrave({}));
        dispatch(setCurrentGravePhotoIndex(0));
        dispatch(addProfilePhotoList([]));
        dispatch(saveListOfPhotosFromCropper([]));
        const response = await request(`/graves/${slug}`, null, 'get');
        dispatch(goToChosenGrave(response.data));
        dispatch(addCommentOnToggle({}));
        localStorage.removeItem('slug_for_redirect');
        if (
          response.data.grave_images &&
          response.data.grave_images.length > 0
        ) {
          const profilePhotos = response.data.grave_images
            .filter((image) => image.category === 'display')
            .map((el) => ({
              ...el,
              image_comments: el.image_comments?.map((com) => ({
                ...com,
                description: com.comment,
              })),
            }));
          if (response.data.memories && response.data.memories.length > 0) {
            dispatch(addComment(response.data.memories));
          }
          dispatch(addProfilePhotoList(profilePhotos));
          dispatch(saveListOfPhotosFromCropper(response.data.grave_images));
        }
        if (response.data.grave_users && response.data.grave_users.length > 0) {
          dispatch(setGraveUsersList(response.data.grave_users));
        } else {
          dispatch(setGraveUsersList([]));
        }
        setTimeout(() => {
          dispatch(spinnerFinish());
        }, 550);
      }
    } catch (err) {
      console.log(err);
      err.message === 'Request failed with status code 500' &&
        console.log('document.location.reload()');
    }
  };

  const fetchUserData = () => {
    if (userSlug) {
      try {
        request(`/users/${userSlug}`).then((res) => {
          dispatch(setInformationAboutUser(res.data));
          dispatch(setSubscription(res.data.user.subscription));
          setUser(res.data.user);
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const fetchAllData = async () => {
    dispatch(spinnerStart());
    try {
      await Promise.all([fetchUserData(), fetchGrave()]);
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        dispatch(spinnerFinish());
      }, 550);
    } finally {
      setTimeout(() => {
        dispatch(spinnerFinish());
      }, 550);
    }
  };

  useEffect(() => {
    if (isAuth) {
      fetchAllData();
    }
  }, [slug, isAuth]);

  if (!isAuth) {
    return <AuthModal isOpen={true} handleClose={() => navigate('/')} />;
  }

  if (isLoading)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 500,
        }}
      >
        <Loader />
      </div>
    );

  const isAcceptedUser = () => {
    return (
      user &&
      user.is_subscribed &&
      grave &&
      grave.current_user_relation &&
      (grave.current_user_relation.status === 'accepted' ||
        grave.current_user_relation.status === 'accepted_without_review')
    );
  };

  return (
    <div className={styles['deceased-page-container']}>
      {isAcceptedUser() ? (
        <AcceptedUsers
          grave={grave}
          user={user}
          isClaimed={isClaimed}
          isAuth={isAuth}
          isAcceptedUser={isAcceptedUser}
        />
      ) : (
        <ConnectRequest
          user={user}
          grave={grave}
          isAuth={isAuth}
          isClaimed={isClaimed}
          isAcceptedUser={isAcceptedUser}
        />
      )}
    </div>
  );
};

export default NewDeceasedPage;
