import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ClipBoardIcon from '../../../../../img/ClipBoardIcon.svg';
import styles from './CopyInvitedLink.module.css';

const CopyInvitationLInk = ({ link, handelSetCopied }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    handelSetCopied(true);
  };

  return (
    <div className={styles['container']}>
      <div className={styles['link']}>{link}</div>
      <CopyToClipboard text={link} onCopy={handleCopy}>
        <img src={ClipBoardIcon} alt='copy' style={{ cursor: 'pointer', width: 24 }} />
      </CopyToClipboard>
    </div>
  );
};

export default CopyInvitationLInk;
