import React, { useState } from 'react';
import { Modal } from '@mui/material';
import { makeStyles } from '@mui/styles';
import styles from './GraveWallVideoModal.module.css';
import VideoTabs from './VideoTabs/VideoTabs';
import X from '../../../../img/X.svg';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from 'lodash.isempty';
import CloseImageModal from '../CloseImageModal/CloseImageModal';
import { addCommentOnToggle } from '../../../../redux/actions';

const useStyles = makeStyles(( ) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '900px',
    height: 'calc(100% - 32px)',
    overflowY: 'auto',
    backgroundColor: '#fff',
    padding: '32px',
    borderRadius: 0,
    outline: 'none',
    '@media (max-width: 800px)': {
      width: '100%',
      height: 'calc(100% - 32px)',
      overflow: 'auto',
    },
  },
  emptyModalContent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '900px',
    height: 'auto',
    overflowY: 'auto',
    backgroundColor: '#fff',
    padding: '32px',
    borderRadius: 10,
    outline: 'none',
    '@media (max-width: 800px)': {
      width: '100%',
      height: 'auto',
      overflow: 'auto',
    },
  },
  modalBackground: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}));

const GraveWallVideoModal = ({ onOpen, onClose, title }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentComment = useSelector((state) => state.grave.commentOnToggle);
  const [openWarningModal, setOpenWarningModal] = useState(false);

  const handelOpenWarning = () => {
    if (
      !isEmpty(currentComment.video) ||
      !isEmpty(currentComment.embeddedVideo)
    ) {
      setOpenWarningModal(true);
    } else {
      onClose();
    }
  };

  const handleCloseModal = () => {
    setOpenWarningModal(false);
    onClose();
    dispatch(
      addCommentOnToggle({
        ...currentComment,
        embeddedVideo: null,
        video: null,
      })
    );
  };

  return (
    <React.Fragment>
      <Modal
        open={onOpen}
        className={classes.modal}
        BackdropProps={{
          className: classes.modalBackground,
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <div className={classes.modalContent}>
          <div className={styles['upload-image-header']}>
            <span>{title}</span>
            <img loading='lazy' src={X} alt='x' onClick={handelOpenWarning} />
          </div>
          <div className={styles['upload-image-subheader']}>
            <span>
              Bygg enkelt inn lenker til YouTube-video eller last opp fra
              enheten
            </span>
          </div>
          <VideoTabs onClose={onClose} openWarningModal={handelOpenWarning} />
        </div>
      </Modal>
      {openWarningModal && (
        <CloseImageModal
          body='Er du sikker på å fjerne alle videoene du velger eller laster opp'
          title='Fjern valgte videoer'
          isUploading={false}
          onOpen={openWarningModal}
          onClose={() => setOpenWarningModal(false)}
          onConfirm={handleCloseModal}
        />
      )}
    </React.Fragment>
  );
};

export default GraveWallVideoModal;
