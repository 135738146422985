import { gql } from '@apollo/client';

export const addSparkCache = (cache, { data: { createSpark } }) => {
  if (createSpark && createSpark.spark) {
    const newSparkRef = cache.writeFragment({
      data: createSpark.spark,
      fragment: gql`
        fragment NewSpark on Spark {
          id
          description
          visibility
          location
          createdAt
          updatedAt

          user {
            id
            fullName
            slug
            displayPicture
            dateOfBirth
          }

          category {
            id
            name
          }
          reactionsCount
          totalReactionsCount
          reaction {
            id
            reactionType
          }
          lastCommentUpdatedAt
          commentsCount
          comments {
            id
            description
            repliesCount
            user {
              id
              fullName
              slug
              displayPicture
            }
          }
        }
      `,
    });

    cache.modify({
      fields: {
        sparks(existingSparks = { edges: [], pageInfo: {} }) {
          return {
            ...existingSparks,
            edges: [
              { __typename: 'SparkEdge', node: newSparkRef },
              ...existingSparks.edges,
            ],
          };
        },
      },
    });
  }
};
