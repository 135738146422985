import React from 'react';
import './AboutMenuItem.css';

const AboutMenuItem = (props) => {
  const key = Number(props.indexKey);
  return (
    <div
      className={props.aboutMenuKey === key ? 'menu-item-active' : 'menu-item'}
      onClick={() => {
        props.handelAboutMenuKey(key);
      }}
    >
      <span className={props.aboutMenuKey === key ? 'active-item' : 'item'}>
        {props.menuItem.title}
      </span>
    </div>
  );
};

export default AboutMenuItem;
