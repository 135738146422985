import React, { useState, useEffect } from 'react';
import styles from './UploadVideoForm.module.css';
import UploadVideo from '../UploadVideo/UploadVideo';
import isEmpty from 'lodash.isempty';
import { useDispatch, useSelector } from 'react-redux';
import SelectedVideo from '../SelectedVideo/SelectedVideo';
import { addCommentOnToggle } from '../../../../../redux/actions';

const UploadVideoForm = ({ onClose, openWarningModal }) => {
  const currentComment = useSelector((state) => state.grave.commentOnToggle);
  const dispatch = useDispatch();

  return (
    <div>
      {!isEmpty(currentComment.video) && (
        <SelectedVideo video={currentComment.video} />
      )}
      <UploadVideo />
      <div className={styles['grave-btn-container']}>
        <div className={styles['grave-information_content-verify']}>
          <button
            type='button'
            className={styles['rootDiscard']}
            onClick={() => openWarningModal()}
          >
            Avbryt
          </button>
          <button
            disabled={!currentComment.video}
            className={
              currentComment.video
                ? styles['rootVerify']
                : styles['disabledButton']
            }
            type='submit'
            onClick={() => onClose()}
          >
            Lagre
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadVideoForm;
