import React from 'react';
import styles from './InfoModal.module.css';
import { Modal } from '@mui/material';
import { makeStyles } from '@mui/styles';
import X from '../../../../../../img/X.svg';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    position: 'relative',
    padding: '42px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: 'maxContent',
    backgroundColor: 'white',
    borderRadius: '10px',
    outline: 'none',
    maxWidth: '300px',
  },
  modalBackground: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}));

const InfoModal = ({ open, handleClose, message }) => {
  const classes = useStyles();
  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modal}
      BackdropProps={{
        className: classes.modalBackground,
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.modalContent}>
        <img
          style={{ cursor: 'pointer' }}
          loading='lazy'
          src={X}
          alt='x'
          onClick={handleClose}
          className={styles['image']}
        />
        <div className={styles['message']}>{message}</div>
      </div>
    </Modal>
  );
};

export default InfoModal;
