import React, { useState } from 'react';
import DeleteIcon from '../../../../../../img/DeleteRedIcon.svg';
import EditIcon from '../../../../../../img/EditSmallIcon.svg';
import EditButton from '../../../../../../components/EditButton/EditButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu } from '@mui/material';

const ActionButton = ({ onClickEdit, onClickDelete, spark }) => {
  const [anchorElement, setAnchorElement] = useState(null);
  const open = Boolean(anchorElement);
  const id = open ? 'simple-popover' : undefined;

  const handleOpenMenu = (event) => {
    setAnchorElement(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElement(null);
  };

  return (
    <div>
      <MoreVertIcon
        onClick={handleOpenMenu}
        style={{
          color: '#080809',
          fontSize: '24px',
          cursor: 'pointer',
        }}
      />
      <Menu
        id='simple-menu'
        elevation={1}
        anchorEl={anchorElement}
        keepMounted
        open={open}
        onClose={handleCloseMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <EditButton
          title='settings_edit'
          onClick={() => {
            handleCloseMenu();
            onClickEdit(spark);
          }}
          icon={EditIcon}
        />
        <EditButton
          title='delete'
          onClick={() => {
            handleCloseMenu();
            onClickDelete(spark.id);
          }}
          icon={DeleteIcon}
        />
      </Menu>
    </div>
  );
};

export default ActionButton;
