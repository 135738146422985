import React from 'react';
import IframeComponent from '../../../components/Iframe/IframeComponent';

const FeaturesAvailableSustainable = () => {
  return (
    <IframeComponent
      title='Features available sustainable'
      source='https://www.wix.nettgrav.no/om-tilgjengelig-grav'
    />
  );
};

export default FeaturesAvailableSustainable;
