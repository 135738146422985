import React, { useEffect, useState } from 'react';
import MasterCard from '../../../../img/MasterCard.svg';
import VisaCard from '../../../../img/Visa.svg';
import VippsLogo from '../../../../img/VippsLogo.svg';
import styles from './PaymentMethods.module.css';
import { FormattedMessage } from 'react-intl';
import { PaymentType } from '../../../../service/constants';
import WarningMessage from '../../../../components/Input/WarningMessage';

const PaymentMethods = ({
  method,
  showPayment,
  handelSelectPaymentMethod,
  getAgreementStatus,
}) => {
  const [cardType, setCardType] = useState(null);
  useEffect(() => {
    if (method.card_type === 'visa') {
      setCardType(VisaCard);
    } else if (method.card_type === 'mastercard') {
      setCardType(MasterCard);
    } else if (method.payment_type === PaymentType.vipps) {
      setCardType(VippsLogo);
    }
  }, [method]);

  return (
    <div
      className={styles['user-payment-card']}
      onClick={() => {
        handelSelectPaymentMethod(method);
      }}
    >
      <div className={styles['payment-wrapper']}>
        <div className={styles['select']}>
          <input type='checkbox' checked={showPayment} readOnly />
          {method.payment_type === PaymentType.card && (
            <label>
              {method.name} <FormattedMessage id='ending_with' />{' '}
              {method.card_number}
            </label>
          )}
          {method.payment_type === PaymentType.vipps && (
            <label>
              {method.name} +47{method.phone_number}
            </label>
          )}
        </div>
        <div className={styles['images']}>
          {cardType && <img src={cardType} alt='VisaCard' />}
        </div>
      </div>
      {method.payment_type === PaymentType.vipps &&
        (getAgreementStatus(method) === 'pending' ||
          getAgreementStatus(method) === 'expired' ||
          getAgreementStatus(method) === 'stopped') && (
          <div>
            <WarningMessage messageKey='complete_pending_payment' />
          </div>
        )}
    </div>
  );
};

export default PaymentMethods;
