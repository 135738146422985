import React from 'react'
import './LearnMoreFirst.css';
import Pointer from '../../../../img/DoublePointer.svg';
import LearnMoreDeceased from '../../../../img/LearnMoreDeceased.jpg';
import Upper from '../../../../img/LearnMoreUpper.svg';
import Bottom from '../../../../img/LearnMoreBottom.svg';

import { Button } from '@mui/material';

const LearnMoreFirst = (props) => {
    return(
        <div className='learn-more_first'>
            <div className="learn-more_first-content">
                <div className="learn-more_first-content_main">
                    <div className="learn-more_first-content_main-header">
                        <span className='learn-more_first-content_main-header_title'>Som graveier kan du ta styring <span>på graven</span></span>
                        <span className='learn-more_first-content_main-header_title mobile'>Som graveier kan du ta styring <span>på graven</span></span>
                        <span className='learn-more_first-content_main-header_subtitle'>Siden gravens forlengelse på nett er mer personlig, bestemmer graveier hvem som skal ha tilgang til graven.</span>
                        <Button className='learn-more_first-content_main-header_button'><a href = "#scroll-point" style={{color: "white", textDecoration: "none"}}>Les mer <img loading='lazy' src={Pointer} alt='Pointer' className='learn-more_first-content_main-header_button-image'/></a></Button>
                    </div>
                    <div className="learn-more_first-content_main-images">
                        <img loading='lazy' src={LearnMoreDeceased} alt='LearnMoreDeceased' className='learn-more_first-content_main-images_deceased'/>
                        <img loading='lazy' src={Upper} alt='Upper' className='learn-more_first-content_main-images_upper'/>
                        <img loading='lazy' src={Bottom} alt='Bottom' className='learn-more_first-content_main-images_bottom'/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LearnMoreFirst;