import React from 'react';
import { Avatar } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  large: {
    height: '100px !important',
    width: '100px !important',
    borderRadius: '10px !important',
    marginBottom: '12px',
  },
}));

const UserAvatar = ({ src }) => {
  const classes = useStyles();

  return <Avatar src={src} className={classes.large} />;
};

export default UserAvatar;
