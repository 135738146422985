import React from 'react';
import IframeComponent from '../../../components/Iframe/IframeComponent';

const FeaturesCommemorative = () => {
  return (
    <IframeComponent
      title='Features commemorative'
      source='https://www.wix.nettgrav.no/features-minnetavlen'
    />
  );
};

export default FeaturesCommemorative;
