import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './SideContainer.module.css';

const SideContainer = () => {
  return (
    <div className={styles['container']}>
      <div className={styles['side-container']}>
        <div className={styles['heading']}>
          <FormattedMessage id='did_you_know' />
        </div>
        <div className={styles['description']}>
          <FormattedMessage id='registration_description_one' />
        </div>
        <div className={styles['description']}>
          <FormattedMessage id='registration_description_two' />
        </div>
        <div className={styles['description']}>
          <FormattedMessage id='registration_description_three' />
        </div>
      </div>
    </div>
  );
};

export default SideContainer;
