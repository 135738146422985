import React from 'react';
import styles from './CommentFooter.module.css';
import { FormattedMessage } from 'react-intl';
import TotalReactions from '../../Reactions/TotalReactions/TotalReactions';
import { timeSinceCreated } from '../../../../../../../service/Utils';

const CommentFooter = ({ comment, onOpenReplyInput }) => {
  return (
    <div>
      <div className={styles['container']}>
        <div className={styles['created-at']}>
          {timeSinceCreated(comment.createdAt)}
        </div>
        {/* <div className={styles['reaction']}>
          <FormattedMessage id='inspired' />
        </div> */}
        {/* <div className={styles['reply']} onClick={onOpenReplyInput}>
          <FormattedMessage id='reply' />
        </div> */}
      </div>
      {/* <div>
        <TotalReactions />
      </div> */}
    </div>
  );
};

export default CommentFooter;
