import React from 'react';
import styles from './Reactions.module.css';
import SmallReactionIcon from '../../Components/SmallReactionIcon';
import { formatTotalCount } from '../../../../../../../service/Utils';
import { FormattedMessage } from 'react-intl';

const ReactionsTab = ({ spark, reactionList, filterReactions, tabType }) => {
  return (
    <div className={styles['btn-container']}>
      {spark?.reactionsCount &&
        Object.entries(spark.reactionsCount).length > 1 && (
          <button
            className={`${styles['btn']} ${
              tabType === 'All' ? styles['active'] : ''
            }`}
            onClick={() => filterReactions('All')}
          >
            <span>
              <FormattedMessage id='all' />
            </span>
            <span>{formatTotalCount(spark.totalReactionsCount)}</span>
          </button>
        )}
      {spark?.reactionsCount &&
        Object.entries(spark.reactionsCount).map(([key, value]) => {
          const reaction = reactionList[key];
          const reactionsCountEntries =
            Object.entries(spark.reactionsCount).length === 1;

          return (
            reaction && (
              <button
                className={`${styles['btn']} ${
                  tabType === key || reactionsCountEntries
                    ? styles['active']
                    : ''
                }`}
                onClick={() => filterReactions(reaction.type)}
              >
                <SmallReactionIcon
                  key={key}
                  reactionType={reaction.type}
                  title={
                    <div>
                      {formatTotalCount(value)} {reaction.title}
                    </div>
                  }
                  icon={reaction.icon}
                />
                {formatTotalCount(value)}
              </button>
            )
          );
        })}
    </div>
  );
};

export default ReactionsTab;
