import React from 'react';
import IconButton from './IconButton';
import Popover from '@mui/material/Popover';
import BellIcon from '../../../img/BellIcon.svg';
import noGender from '../../../img/Logo.svg';
import { Avatar } from '@mui/material';
import defaultAvatar from '../../../img/LoGo_main_notification.svg';
import RequestActionLabels from './RequestActionLabels';
import RequestActionButtons from './RequestActionButtons';
import './Notifications.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  setUserNotificationReadRedux,
  setUserReadNotifications,
  setUserUnReadNotifications,
} from '../../../redux/actions';
import { fetch_request } from '../../../service/request';
import { FormattedMessage } from 'react-intl';

const Notifications = (props) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [activeTab, setActiveTab] = React.useState('New');
  const newNotifications = useSelector(
    (state) => state.user?.unreadNotifications
  );
  const readNotifications = useSelector(
    (state) => state.user?.readNotifications
  );
  const [flag, setFlag] = React.useState(0);

  const showAlertDot = newNotifications.some((item) => item.read === false);
  const alertDotCount = newNotifications.filter(
    (item) => item.read === false
  ).length;

  // React.useEffect(() => {
  //   currentPage !== 1 &&
  //     fetch_request(`/api/notifications?page=${currentPage}`, {
  //       method: "GET",
  //       redirect: "follow",
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("token")}`,
  //       },
  //     })
  //       .then((response) => response.json())
  //       .then((data) => data.notifications)
  //       .then((notificationsBE) => {
  //         dispatch(
  //           setUserNotificationsRedux([...notifications, ...notificationsBE])
  //         );
  //       })
  //       .catch((err) => console.log(err));
  // }, [currentPage]);

  //----------------------------------------------------------------------------------------
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  React.useEffect(() => {
    fetchReadNotifications();
    fetchUnReadNotifications();
  }, []);

  const fetchReadNotifications = () => {
    fetch_request(`/api/notifications?read=true`, {
      method: 'GET',
      redirect: 'follow',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch(setUserReadNotifications(data.notifications));
      })
      .catch((err) => console.log(err));
  };

  const fetchUnReadNotifications = () => {
    fetch_request(`/api/notifications?read=false`, {
      method: 'GET',
      redirect: 'follow',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch(setUserUnReadNotifications(data.notifications));
      })
      .catch((err) => console.log(err));
  };

  const handleClose = async () => {
    await newNotifications.forEach((item) => {
      makeNotificationRead(item);
    });
    dispatch(setUserNotificationReadRedux());
    setAnchorEl(null);
  };

  const makeNotificationRead = (item) => {
    let formData = new FormData();
    formData.append('read', true);

    const requestOptions = {
      method: 'PUT',
      redirect: 'follow',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        Accept: '*/*',
      },
      body: formData,
    };

    fetch_request(`/api/notifications/${item.id}`, requestOptions)
      .then((res) => {
        if (res.status === 200) {
          // dispatch(setUserNotificationReadRedux(item));
        }
      })
      .catch((err) => console.log(err));
  };
  const getNotificationsSource = () => {
    return activeTab === 'New' ? newNotifications : readNotifications;
  };

  const formattedDateTime = (string) => {
    if (!string) return;

    var m = new Date(string);
    return m.toLocaleString('uk-UK', {
      dateStyle: 'short',
      timeStyle: 'short',
    });
  };

  return (
    <div className='notifications'>
      <div className='icon-relative'>
        <IconButton icon={BellIcon} handleClick={handleClick} />
        {showAlertDot && <div className='yellow-dot'>{alertDotCount}</div>}
      </div>
      {props.open && (
        <Popover
          className='hiddenScroll'
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div className='headline tabs'>
            <span
              className={`${activeTab === 'New' ? 'active' : ''}`}
              onClick={() => setActiveTab('New')}
            >
              <FormattedMessage id='new' />
            </span>
            <span
              className={`${activeTab === 'Read' ? 'active' : ''}`}
              onClick={() => setActiveTab('Read')}
            >
              <FormattedMessage id='read' />
            </span>
          </div>
          <div className='notifications-bar'>
            {
              <div>
                {getNotificationsSource() &&
                getNotificationsSource().length !== 0 ? (
                  getNotificationsSource().map((item, index) => (
                    <div
                      className={`item false`}
                      key={index}
                      // onMouseEnter={() => {

                      //   //-------------------------Pagination implementing----------------------------------------
                      //   if (
                      //     notifications.length < totalCount &&
                      //     index > readNotifications.length - 6
                      //   )
                      //     setCurrentPage((prevPage) => prevPage + 1);
                      // }}
                    >
                      <div className='notification-card'>
                        {item?.category === 'acknowledge_accept' ||
                        item?.category === 'acknowledge_accept' ? (
                          <Avatar src={noGender} />
                        ) : item.category ===
                            'acknowledge_grave_admin_rejection' ||
                          item.category === 'memorial_day_notification' ||
                          item.category === 'memorial_day_prior_notification' ||
                          item.category ===
                            'acknowledge_grave_admin_approval' ? (
                          <div className='ava-frame-for-url'>
                            <Avatar src={defaultAvatar} />
                          </div>
                        ) : (
                          <div className='ava-frame-for-url'>
                            <Avatar src={item?.extra_info?.user_avatar} />
                          </div>
                        )}
                        <div className='text'>{item.title}</div>
                      </div>
                      {item.show_button && (
                        <RequestActionButtons
                          notification={item}
                          setFlag={setFlag}
                        />
                      )}
                      {(item.requestStatus || item.status) && (
                        <RequestActionLabels
                          requestStatus={item.requestStatus || item.status}
                        />
                      )}
                      <div className='notification-date-time'>
                        {formattedDateTime(item.created_at)}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className={'noItems-notification'}>
                    <FormattedMessage id='empty_notification_message' />
                  </div>
                )}
              </div>
            }
          </div>
        </Popover>
      )}
    </div>
  );
};

export default Notifications;
