import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import styles from './Input.module.css';
import { makeStyles } from '@mui/styles';
import { useIntl } from 'react-intl';

const useStyles = makeStyles({
  filledInput: {
    padding: '16px !important',
  },
});

const SelectInput = ({ item }) => {
  const classes = useStyles();
  const intl = useIntl();

  const renderOptionText = (option) => {
    return option.dialect || option.name || option.reason;
  };

  return (
    <TextField
      sx={{
        '& .MuiSelect-select span::before': {
          content: "'Select'",
        },
      }}
      id='filled-basic'
      select
      variant='filled'
      value={item.value}
      className={`${styles['auth-button']} ${
        item.error ? styles['error'] : ''
      }`}
      InputProps={{
        classes: { input: classes.filledInput },
      }}
      onChange={(e) => {
        const value = e.target.value;
        item.action(value);
      }}
      SelectProps={{
        MenuProps: {
          PaperProps: {
            style: {
              maxHeight: 200,
              overflowY: 'auto !important',
            },
          },
        },
      }}
    >
      {item.list.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          {renderOptionText(option)}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default SelectInput;
