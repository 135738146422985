import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import SettingsPageHeader from './atoms/SettingsPageHeader';
import styles from './SettingsPage.module.css';
import { fetch_request } from '../../service/request';
import { setInformationAboutUser } from '../../redux/actions';
import { CONSTANTS } from '../../service/constants';
import axios from 'axios';
import spinner from '../../service/Loading_animation.gif';
import isEmpty from 'lodash.isempty';

const SettingsPage = () => {
  const dispatch = useDispatch();
  const [currentSubscription, setCurrentSubscription] = useState({});
  const [loading, setLoading] = useState(true);
  const pendingRelation = useSelector((state) => state.grave.pendingRelation);

  const isAuth = useSelector((state) => state.auth.isAuthorized);
  const userSlug =
    localStorage.getItem('user-slug') || sessionStorage.getItem('user-slug');

  useEffect(() => {
    if (userSlug) {
      const requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      setLoading(true);
      fetch_request(`/api/users/${userSlug}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          dispatch(setInformationAboutUser(data));
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [userSlug]);

  useEffect(() => {
    if (!isAuth) return;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        Accept: '*/*',
      },
      redirect: 'follow',
    };
    axios
      .get(`${CONSTANTS.baseUrl}/api/users/${userSlug}/subscription`, config)
      .then((res) => {
        if (res.data?.product) {
          setCurrentSubscription(res.data.product);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [userSlug]);

  return (
    <>
      {loading && isEmpty(currentSubscription) ? (
        <div className={styles['loadingSpinner']}>
          <img loading='lazy' src={spinner} alt='elastic' />
        </div>
      ) : (
        <div className={styles['settings-page']}>
          <div className={styles['settings-page-wrapper']}>
            <div className={styles['settings-page_content']}>
              <SettingsPageHeader currentSubscription={currentSubscription} />
              <div className={styles['content_page_border']}>
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SettingsPage;
