import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './Plans.module.css';
import featureCheck from '../../../../img/featureCheck.svg';

const Feature = ({ plan }) => {
  return (
    <ul className={styles['features-list']}>
      {Array.isArray(plan.features) &&
        plan.features.map((feature) => {
          return (
            <li className={styles['feature-list-item']} key={feature.id}>
              <img src={featureCheck} alt='check' />
              <span>
                <FormattedMessage id={feature.translation_key} />
              </span>
            </li>
          );
        })}
    </ul>
  );
};

export default Feature;
