import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Upload from '../../../../../img/UploadImageIcon.svg';
import showUserNotification from '../../../../../components/UserNotification/showUserNotification';
import { addLifeEventVideos } from '../../../../../redux/actions';
import styles from './UploadVideo.module.css';
import UpgradePlanModal from '../../../../../components/Modals/UpgradePlanModal';
import isEmpty from 'lodash.isempty';

const UploadVideo = () => {
  const inputFile = useRef();
  const dispatch = useDispatch();
  const [openUpgradePlanModal, setOpenUpgradePlanModal] = useState(false);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const canAddPhotos = useSelector(
    (state) => state.user.activeSubscription?.can_add_timeline_photos
  );
  const lifeEventVideos = useSelector((state) => state.grave.lifeEventVideos);

  const biggerVideos = () =>
    showUserNotification(
      'Last opp en video som ikke er større enn 1 GB.',
      'warning'
    );

  const videosList = (videos) => {
    if (lifeEventVideos.length > 0) {
      const newPhotos = [...lifeEventVideos, ...videos];
      dispatch(addLifeEventVideos(newPhotos));
    } else {
      dispatch(addLifeEventVideos(videos));
    }
  };

  const selectTheVideos = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const videosArray = Array.from(e.target.files);
      const selectedVideos = [];

      const generateUniqueId = () => {
        return Date.now().toString() + Math.floor(Math.random() * 1000);
      };

      const processVideo = (file, result) => {
        if (file.size / (1024 * 1024) <= 1000) {
          const newFile = URL.createObjectURL(file);
          selectedVideos.push({
            file_url: newFile,
            id: generateUniqueId(),
            file: file,
          });
        } else {
          biggerVideos();
        }
        if (selectedVideos.length === videosArray.length) {
          videosList(selectedVideos);
        }
      };

      videosArray.forEach((file) => {
        const reader = new FileReader();
        reader.onloadend = (event) => processVideo(file, event.target.result);
        reader.readAsDataURL(file);
      });
    }
  };

  // const handleVideoChange = (event) => {
  //   const file = event.target.files[0];
  //   const maxSize = 1024 * 1024 * 1024;
  //   const newFile = URL.createObjectURL(event.target.files[0]);

  //   if (file && file.size <= maxSize) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       if (!isEmpty(currentComment.video)) {
  //         dispatch(
  //           addLifeEventPhotos({
  //             ...currentComment,
  //             video: {
  //               ...currentComment.video,
  //               video: newFile,
  //               file: file,
  //             },
  //           })
  //         );
  //       } else {
  //         dispatch(
  //           addLifeEventPhotos({
  //             ...currentComment,
  //             video: {
  //               id: Date.now().toString(),
  //               video: newFile,
  //               title: '',
  //               type: 'local',
  //               file: file,
  //             },
  //           })
  //         );
  //       }
  //     };
  //     reader.readAsDataURL(file);
  //   } else {
  //     biggerVideos();
  //   }
  // };

  const clickOnHideInput = () => {
    if (canAddPhotos) {
      inputFile.current.click();
    } else {
      setOpenUpgradePlanModal(true);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDraggingOver(false);
    const droppedFiles = e.dataTransfer.files;
    selectTheVideos({ target: { files: droppedFiles } });
  };

  return (
    <>
      <UpgradePlanModal
        isOpen={openUpgradePlanModal}
        onClose={() => setOpenUpgradePlanModal(false)}
        body='add_more_images_body'
        title='upgrade_modal_heading'
      />
      <div
        className={
          isDraggingOver
            ? styles['drop-image']
            : styles['cropper-modal_content-input-image']
        }
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onDragLeave={() => setIsDraggingOver(false)}
      >
        <div className={styles['cropper-modal_content-input-image_image']}>
          <input
            ref={inputFile}
            multiple
            type='file'
            accept=',video/mp4,video/x-m4v,video/*'
            className={styles['cropper-modal_content-input-image_image-input']}
            onChange={selectTheVideos}
          />
          <div className={styles['upload-image']}>
            <div
              className={styles['upload-image-input']}
              onClick={clickOnHideInput}
            >
              <img loading='lazy' src={Upload} alt='UploadImage' />
              <span className={styles['upload-image-text']}>
                Dra videoer hit eller bla gjennom filer
              </span>
            </div>
            <div className={styles['image-text-message']}>
              Video bør ikke være mer enn 1 GB
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadVideo;
