import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Upload from '../../../../../img/UploadImageIcon.svg';
import showUserNotification from '../../../../../components/UserNotification/showUserNotification';
import { addCommentOnToggle } from '../../../../../redux/actions';
import styles from './UploadVideo.module.css';
import UpgradePlanModal from '../../../../../components/Modals/UpgradePlanModal';
import isEmpty from 'lodash.isempty';

const UploadVideo = () => {
  const inputFile = useRef();
  const dispatch = useDispatch();
  const [openUpgradePlanModal, setOpenUpgradePlanModal] = useState(false);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const canAddPhotos = useSelector(
    (state) => state.user.activeSubscription?.can_add_timeline_photos
  );
  const currentComment = useSelector((state) => state.grave.commentOnToggle);

  const biggerImage = () =>
    showUserNotification(
      'Last opp en video som ikke er større enn 1 GB.',
      'warning'
    );

  const handleVideoChange = (event) => {
    const file = event.target.files[0];
    const maxSize = 1024 * 1024 * 1024;
    const newFile = URL.createObjectURL(event.target.files[0]);

    if (file && file.size <= maxSize) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (!isEmpty(currentComment.video)) {
          dispatch(
            addCommentOnToggle({
              ...currentComment,
              video: {
                ...currentComment.video,
                video: newFile,
                file: file,
              },
            })
          );
        } else {
          dispatch(
            addCommentOnToggle({
              ...currentComment,
              video: {
                id: Date.now().toString(),
                video: newFile,
                title: '',
                type: 'local',
                file: file,
              },
            })
          );
        }
      };
      reader.readAsDataURL(file);
    } else {
      biggerImage();
    }
  };

  const clickOnHideInput = () => {
    if (canAddPhotos) {
      inputFile.current.click();
    } else {
      setOpenUpgradePlanModal(true);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDraggingOver(false);
    const droppedFiles = e.dataTransfer.files;
    handleVideoChange({ target: { files: droppedFiles } });
  };

  return (
    <>
      <UpgradePlanModal
        isOpen={openUpgradePlanModal}
        onClose={() => setOpenUpgradePlanModal(false)}
        body='add_more_images_body'
        title='upgrade_modal_heading'
      />
      <div
        className={
          isDraggingOver
            ? styles['drop-image']
            : styles['cropper-modal_content-input-image']
        }
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onDragLeave={() => setIsDraggingOver(false)}
      >
        <div className={styles['cropper-modal_content-input-image_image']}>
          <input
            ref={inputFile}
            type='file'
            accept=',video/mp4,video/x-m4v,video/*'
            className={styles['cropper-modal_content-input-image_image-input']}
            onChange={handleVideoChange}
          />
          <div className={styles['upload-image']}>
            <div
              className={styles['upload-image-input']}
              onClick={clickOnHideInput}
            >
              <img loading='lazy' src={Upload} alt='UploadImage' />
              <span className={styles['upload-image-text']}>
                Dra videoer hit eller bla gjennom filer
              </span>
            </div>
            <div className={styles['image-text-message']}>
              Video bør ikke være mer enn 1 GB
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadVideo;
