import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';

const Features = () => {
  const location = useLocation();
  const items = [
    {
      title: <FormattedMessage id='available_sustainable' />,
      url: '/features-tilgjengelig-bærekraftig',
    },
    {
      title: <FormattedMessage id='maintenance_free_grave' />,
      url: '/features-vedlikeholdsfri-grav',
    },
    {
      title: <FormattedMessage id='memory_album' />,
      url: '/features-minnealbum',
    },
    {
      title: <FormattedMessage id='value_archive' />,
      url: '/features-verdiarkiv',
    },
    {
      title: <FormattedMessage id='memorial_wall' />,
      url: '/features-minnetavlen',
    },
    {
      title: <FormattedMessage id='life_archive' />,
      url: '#',
    },
    {
      title: <FormattedMessage id='anniversary_calendar' />,
      url: '#',
    },
    {
      title: <FormattedMessage id='Family_values' />,
      url: '#',
    },
  ];
  return (
    <div className='item-container'>
      <div className='title'>
        <FormattedMessage id='features' />
      </div>
      <div className='link-container'>
        {items.map((item, index) => (
          <Link
            className={location.pathname === item.url ? 'active-link' : 'link'}
            key={index}
            to={item.url}
          >
            {item.title}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Features;
