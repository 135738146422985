import React, { useState, useEffect } from 'react';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import styles from './Anniversary.module.css';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';

const Timer = ({ anniversary }) => {
  const calculateTimeLeft = () => {
    // Parse the datetime string into a Day.js object
    const targetDate = dayjs(anniversary.datetime);

    const now = dayjs();
    const difference = targetDate.diff(now);
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const timeDisplay =
    timeLeft.days > 0 ? (
      `${timeLeft.days}d ${timeLeft.hours}h ${timeLeft.minutes}m ${timeLeft.seconds}s`
    ) : timeLeft.hours > 0 ? (
      `${timeLeft.hours}h ${timeLeft.minutes}m ${timeLeft.seconds}s`
    ) : timeLeft.minutes > 0 ? (
      `${timeLeft.minutes}m ${timeLeft.seconds}s`
    ) : timeLeft.seconds > 0 ? (
      `${timeLeft.seconds}s`
    ) : (
      <FormattedMessage id='anniversary_time_end' />
    );

  return (
    <div className={styles['timer-container']}>
      <AccessAlarmIcon style={{ color: '#404D56' }} />
      <div className={styles['remaining-timer']}>{timeDisplay}</div>
    </div>
  );
};

export default Timer;
