import React from 'react';
import './Notifications.css';
import { useDispatch } from 'react-redux';
import { request, fetch_request } from '../../../service/request';
import showUserNotification from '../../UserNotification/showUserNotification';
import {
  setUserNotificationRequestStatusRedux,
  setUserNotificationButton,
} from '../../../redux/actions';

const RequestActionButtons = ({ notification, setFlag }) => {
  const dispatch = useDispatch();

  const setRelations = (status, item, onSuccess) => {
    let formdata = new FormData();
    formdata.append('grave_user_id', item.extra_info.grave_user_id);
    formdata.append('status', status);

    const requestOptions = {
      method: 'POST',
      redirect: 'follow',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        Accept: '*/*',
      },
      body: formdata,
    };

    fetch_request('/api/relation_approvals', requestOptions)
      .then((res) => {
        let formdata = new FormData();
        formdata.append('status', status);
        formdata.append('show_button', false);
        formdata.append('read', true);
        fetch_request(`/api/notifications/${item.id}`, {
          ...requestOptions,
          method: 'put',
          body: formdata,
        })
          .then((e) => {
            if (status === 'accepted') {
              showUserNotification(`Relasjonen er godkjent`, 'success');
            } else {
              showUserNotification(`Relasjonen er avvist`, 'success');
            }
            onSuccess && onSuccess();
          })
          .catch((err) => {
            // showUserNotification(err.data.message, 'error');
            console.log(err);
          });
      })
      .catch((err) => console.log(err));
  };

  const setUserConnection = (status, notification, onSuccess) => {
    let formdata = new FormData();
    formdata.append('id', notification.extra_info.id);
    formdata.append('status', status);

    request(`/user_connections/${notification.extra_info.id}`, formdata, 'put')
      .then((res) => {
        let formdata = new FormData();
        formdata.append('status', status);
        formdata.append('show_button', false);
        formdata.append('read', true);
        request(`/notifications/${notification.id}`, formdata, 'put').then(
          (e) => {
            if (status === 'accepted') {
              showUserNotification(`Relasjonen er godkjent`, 'success');
            } else {
              showUserNotification(`Relasjonen er avvist`, 'success');
            }
            onSuccess && onSuccess();
          }
        );
      })
      .catch((err) => {
        // showUserNotification(err.data.message, 'error');
        console.log(err);
      });
  };

  const setLinkGrave = (status, notification, onSuccess) => {
    let formdata = new FormData();
    formdata.append('link_grave_id', notification.extra_info.id);
    formdata.append('status', status);

    request(`/link_grave_approvals`, formdata, 'post')
      .then((res) => {
        let formdata = new FormData();
        formdata.append('status', status);
        formdata.append('show_button', false);
        formdata.append('read', true);
        request(`/notifications/${notification.id}`, formdata, 'put').then(
          () => {
            onSuccess && onSuccess();
          }
        );
      })
      .catch((err) => {
        // showUserNotification(err.data.message, 'error');
        console.log(err);
      });
  };

  const setGraveOwner = (status, notification, onSuccess) => {
    let formdata = new FormData();
    formdata.append('grave_user_id', notification.extra_info.grave_user_id);
    formdata.append('status', status);

    request(`/grave_approvals`, formdata, 'post')
      .then((res) => {
        let formdata = new FormData();
        formdata.append('status', status);
        formdata.append('show_button', false);
        formdata.append('read', true);
        request(`/notifications/${notification.id}`, formdata, 'put').then(
          () => {
            onSuccess && onSuccess();
          }
        );
      })
      .catch((err) => {
        // showUserNotification(err.data.message, 'error');
        console.log(err);
      });
  };

  const handleDeclineActionButtonClick = () => {
    switch (notification.category) {
      case 'link_grave_approval':
        setLinkGrave('rejected', notification, () => {
          dispatch(
            setUserNotificationRequestStatusRedux(notification.id, 'rejected')
          );
          setFlag((prev) => prev + 1);
        });
        break;
      case 'user_connection_approval':
        setUserConnection('rejected', notification, () => {
          dispatch(
            setUserNotificationRequestStatusRedux(notification.id, 'rejected')
          );
          setFlag((prev) => prev + 1);
        });
        break;
      case 'grave_admin_approval':
        setRelations('rejected', notification, () => {
          dispatch(
            setUserNotificationRequestStatusRedux(notification.id, 'rejected')
          );
          setFlag((prev) => prev + 1);
        });
        break;
      case 'nettgrav_admin_approval_for_grave':
        setRelations('rejected', notification, () => {
          dispatch(
            setUserNotificationRequestStatusRedux(notification.id, 'rejected')
          );
          setFlag((prev) => prev + 1);
        });
        break;
      case 'nettgrav_admin_approval_for_grave_ownership':
        setGraveOwner('rejected', notification, () => {
          dispatch(
            setUserNotificationRequestStatusRedux(notification.id, 'rejected')
          );
          setFlag((prev) => prev + 1);
        });
        break;
      default:
        return;
    }
    dispatch(
      setUserNotificationButton({
        ...notification,
        show_button: false,
        status: 'rejected',
      })
    );
  };

  const handleAcceptActionButtonClick = () => {
    switch (notification.category) {
      case 'link_grave_approval':
        setLinkGrave('accepted', notification, () => {
          dispatch(
            setUserNotificationRequestStatusRedux(notification.id, 'accepted')
          );
          setFlag((prev) => prev + 1);
        });
        break;
      case 'user_connection_approval':
        setUserConnection('accepted', notification, () => {
          dispatch(
            setUserNotificationRequestStatusRedux(notification.id, 'accepted')
          );
          setFlag((prev) => prev + 1);
        });
        break;
      case 'grave_admin_approval':
        setRelations('accepted', notification, () => {
          dispatch(
            setUserNotificationRequestStatusRedux(notification.id, 'accepted')
          );
          setFlag((prev) => prev + 1);
        });
        break;
      case 'nettgrav_admin_approval_for_grave':
        setRelations('accepted', notification, () => {
          dispatch(
            setUserNotificationRequestStatusRedux(notification.id, 'accepted')
          );
          setFlag((prev) => prev + 1);
        });
        break;
      case 'nettgrav_admin_approval_for_grave_ownership':
        setGraveOwner('accepted', notification, () => {
          dispatch(
            setUserNotificationRequestStatusRedux(notification.id, 'accepted')
          );
          setFlag((prev) => prev + 1);
        });
        break;
      default:
        return;
    }
    dispatch(
      setUserNotificationButton({
        ...notification,
        show_button: false,
        status: 'accepted',
      })
    );
  };

  return (
    <>
      <div className='buttons'>
        <button className='white' onClick={handleDeclineActionButtonClick}>
          Avslå
        </button>
        <button className='yellow' onClick={handleAcceptActionButtonClick}>
          Godkjenn
        </button>
      </div>
    </>
  );
};

export default RequestActionButtons;
