import React, { useState, useEffect } from 'react';
import styles from './EventDetails.module.css';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';

const EventDetails = ({ event }) => {
  const countries = useSelector((state) => state.auth.countries);
  const [country, setCountry] = useState({});

  useEffect(() => {
    const country = countries.find(
      (country) => country.id.toString() === event.location
    );
    if (!isEmpty(country)) setCountry(country);
  }, [countries]);

  const formatDateString = (dateString) => {
    const options = { month: 'short', day: '2-digit' };
    const formattedDate = new Date(dateString).toLocaleDateString(
      'en-US',
      options
    );

    return formattedDate;
  };

  return (
    <div className={styles['details-container']}>
      <div className={styles['date-container']}>
        <div className={styles['date']}>
          {event.event_date ? formatDateString(event.event_date) : ''}
        </div>
        <div className={styles['location']}>
          {country.name ? country.name : ''}
        </div>
      </div>
      <div className={styles['title']}>{event.event_title}</div>
      <div className={styles['description']}>{event.description}</div>
    </div>
  );
};

export default EventDetails;
