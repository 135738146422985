import React, { useState } from 'react';
import styles from './Reply.module.css';
import Dots from '../../../../../img/DotsMenu.svg';
import { Avatar, Button, Fade, Menu, MenuItem } from '@mui/material';
import { useSelector } from 'react-redux';
import MenuItemBlock from '../../../../../containers/NewDeceased/atoms/MemoriesPostDetails/icon/MenuItemBlock';
import MenuItemBlockLast from '../../../../../containers/NewDeceased/atoms/MemoriesPostDetails/icon/MenuItemBlockLast';
import EditPen from '../../../../../img/EditPen.svg';
import MenuBin from '../../../../../img/MenuBin.svg';

function Reply({ reply, onEdit, onDelete }) {
  const replyAuthor = useSelector((state) =>
    state.grave.grave.grave_users.find((u) => u.user?.id === reply.user_id)
  );
  const userId = useSelector((state) => state.user.user.id);
  const [menuAnchorElem, setMenuAnchorElem] = useState(null);
  const [edit, setEdit] = useState(false);
  const [editedReplyText, setEditedReplyText] = useState(reply.description);
  const [replyText, setReplyText] = useState(reply.description);
  const toggleOpenEdit = () => setEdit(!edit);
  const handleOpenMenu = (e) => setMenuAnchorElem(e.currentTarget);
  const handleCloseMenu = () => setMenuAnchorElem(null);

  const editReply = (e) => {
    try {
      e.preventDefault();
      setReplyText(editedReplyText);
      onEdit(reply, editedReplyText);
      toggleOpenEdit();
      handleCloseMenu();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteReply = () => {
    try {
      onDelete(reply);
      handleCloseMenu();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className={styles['comment-reply']}>
      {replyAuthor && replyAuthor?.user?.user_avatar ? (
        <Avatar src={replyAuthor?.user.user_avatar} />
      ) : (
        <Avatar>{replyAuthor?.user?.full_name?.charAt(0)}</Avatar>
      )}
      <div className={styles['comment-reply_content']}>
        <div className={styles['comment-reply_content_header']}>
          <span className={styles['comment-reply_content_header_user']}>
            {replyAuthor && replyAuthor?.user.full_name
              ? replyAuthor?.user.full_name
              : ''}
          </span>
          {(userId === reply.user_id ||
            reply.can_destroy ||
            reply.can_edit) && (
            <Button
              className={styles['comment-reply_content_header_dots']}
              onClick={handleOpenMenu}
            >
              <img loading='lazy' src={Dots} alt='' />
            </Button>
          )}
        </div>
        <div className={styles['comment-reply_content_reply-text']}>
          {!edit && <span>{replyText}</span>}
          {edit && (
            <form
              onSubmit={editReply}
              className={styles['music-post_content-title_form']}
            >
              <textarea
                value={editedReplyText}
                placeholder='Skriv her'
                onChange={(e) => setEditedReplyText(e.target.value)}
                className={styles['music-post_content-title_textarea_editing']}
              />
              <div className={styles['grave-btn-container']}>
                <div className={styles['grave-information_content-verify']}>
                  <button
                    type='button'
                    className={styles['rootDiscard']}
                    onClick={() => {
                      toggleOpenEdit();
                    }}
                  >
                    Avbryt
                  </button>
                  <button className={styles['rootVerify']} type='submit'>
                    Lagre
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
      <Menu
        id='fade-menu'
        anchorEl={menuAnchorElem}
        keepMounted
        open={Boolean(menuAnchorElem)}
        onClose={handleCloseMenu}
        TransitionComponent={Fade}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {reply.can_edit && (
          <MenuItem
            onClick={() => {
              toggleOpenEdit();
              handleCloseMenu();
            }}
          >
            <MenuItemBlock
              image={EditPen}
              title='Rediger'
              description='Legg til, endre eller fjern tekst'
            />
          </MenuItem>
        )}
        {reply.can_destroy && (
          <MenuItem onClick={deleteReply}>
            <MenuItemBlockLast
              image={MenuBin}
              title='Slett'
              description='Legg til, endre eller fjern tekst'
            />
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}

export default Reply;
