import './SearchBar.css';
import React, { useState } from 'react';

const SearchBar = (props) => {
  const [searchVal, setSearchVal] = useState('');
  const handleSearchValueChange = (value) => {
    setSearchVal(value);
    props.setSearchInputValue(value);
  };

  return (
    <div className='searchbar-input'>
      <input
        type='text'
        value={searchVal}
        onChange={(e) => handleSearchValueChange(e.target.value)}
        placeholder={props.title}
      />
    </div>
  );
};

export default SearchBar;
