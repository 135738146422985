import React from 'react';
import CustomModal from '../../../../components/CustomModal';
import { FormattedMessage } from 'react-intl';
import { ChangePlanKey } from '../../../../service/constants';
import BillingCycle from '../BillingCycle/BillingCycle';
import ManageMembers from '../ManageMembers/ManageMembers';
import Plans from '../Plans/Plans';

const ChangePlanOptions = ({
  setOpenOptionsModal,
  openOptionsModal,
  actionKey,
  currentSubscription,
  fetchSubscriptionData,
  paymentMethod,
  openAddPaymentModal,
}) => {
  const getTitle = () => {
    return actionKey === ChangePlanKey.change ? (
      <>
        <FormattedMessage id={actionKey} />{' '}
        <FormattedMessage id='billing_cycle' />
      </>
    ) : (
      <FormattedMessage id={actionKey} />
    );
  };

  const getActionComponent = () => {
    return (
      <>
        {actionKey === ChangePlanKey.change ? (
          <BillingCycle
            currentSubscription={currentSubscription}
            fetchSubscriptionData={fetchSubscriptionData}
            setOpenOptionsModal={setOpenOptionsModal}
            paymentMethod={paymentMethod}
          />
        ) : actionKey === ChangePlanKey.change_members ? (
          <ManageMembers
            currentSubscription={currentSubscription}
            setOpenOptionsModal={setOpenOptionsModal}
            fetchSubscriptionData={fetchSubscriptionData}
          />
        ) : (
          <Plans
            currentSubscription={currentSubscription}
            openAddPaymentModal={openAddPaymentModal}
            paymentMethod={paymentMethod}
            setOpenOptionsModal={setOpenOptionsModal}
            fetchSubscriptionData={fetchSubscriptionData}
          />
        )}
      </>
    );
  };

  return (
    <CustomModal
      isOpen={openOptionsModal}
      closeModal={setOpenOptionsModal}
      title={getTitle()}
      children={getActionComponent()}
    />
  );
};

export default ChangePlanOptions;
