import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import { Button } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormattedMessage } from 'react-intl';
import WarningMessage from '../Input/WarningMessage';

const DATE_FORMAT = 'DD/MM/YYYY';

const DatePickerInput = ({
  date,
  setDatePickerValue,
  disableFuture,
  disablePast,
  item,
}) => {
  dayjs.locale('en-gb');

  const customDatePicker = {
    '& .MuiInputBase-root': {
      border: `1px solid ${item?.error ? '#c65c00' : 'transparent'} !important`,
      background: '#f5f5f5',
      padding: '11px 16px',
      borderRadius: '4px',
      cursor: 'pointer',
      marginBottom: '12px',
      '&:hover': {
        borderColor: item?.error ? '#c65c00 !important' : '#36434b !important',
      },
    },
  };

  const [value, setValue] = useState(null);

  useEffect(() => {
    if (date) {
      setValue(dayjs(date, DATE_FORMAT));
    }
  }, [date]);

  const handleDateChange = (newValue) => {
    if (newValue) {
      const isFutureDate = newValue.isAfter(dayjs());
      if (!isFutureDate) {
        const formattedDate = newValue.format(DATE_FORMAT);
        setValue(newValue);
        setDatePickerValue(formattedDate);
      } else {
        setValue(dayjs());
      }
    } else {
      setValue(null);
      setDatePickerValue(null);
    }
  };

  const clearDate = (e) => {
    e.preventDefault();
    setValue(null);
    setDatePickerValue(null);
  };

  return (
    <React.Fragment>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
        <DatePicker
          value={value}
          onChange={handleDateChange}
          inputFormat={DATE_FORMAT}
          views={['year', 'month', 'day']}
          slotProps={{
            textField: {
              variant: 'standard',
              fullWidth: true,
              sx: customDatePicker,
            },
          }}
          disableFuture={disableFuture}
          disablePast={disablePast}
        />
        {date && (
          <Button onClick={clearDate} color='#737a7f' type='text'>
            <FormattedMessage id='reset_date' />
          </Button>
        )}
      </LocalizationProvider>
      {item?.error && <WarningMessage messageKey={item.errorKey} />}
    </React.Fragment>
  );
};

export default DatePickerInput;
