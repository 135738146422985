import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import styles from './SelectedMedia.module.css';

const SelectedVideo = ({ video, setVideo }) => {
  return (
    <div className={styles['image-container']}>
      <video controls key={video.video} className={styles['selected-video']}>
        <source src={video.url} />
      </video>
      <div className={styles['remove-image']}>
        <CloseIcon
          style={{ fontSize: '16px', color: '#404D56' }}
          onClick={() => setVideo(null)}
        />
      </div>
    </div>
  );
};

export default SelectedVideo;
