import React from 'react';
import styles from './Plans.module.css';
import { planKey } from '../../../../service/PlanKey';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { FormattedMessage } from 'react-intl';

const Heading = ({ plan, isCurrentPlan }) => {
  return (
    <div className={styles['plan-wrapper']}>
      <span className={styles['product-name']}>
        <FormattedMessage id={planKey(plan.key)} />
      </span>
      {isCurrentPlan && (
        <CheckCircleIcon fontSize='large' style={{ color: '#fea65a' }} />
      )}
    </div>
  );
};

export default Heading;
