import React from 'react';
import styles from './BillingCycle.module.css';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { setSubscriptionPeriod } from '../../../../redux/actions';

const BillingButton = ({ cycle, selectedPeriod }) => {
  const dispatch = useDispatch();
  const isSelected = selectedPeriod.key === cycle.key;
  return (
    <button
      onClick={() => dispatch(setSubscriptionPeriod(cycle))}
      className={
        isSelected
          ? `${styles['period-btn']} ${styles['active-btn']}`
          : styles['period-btn']
      }
    >
      <FormattedMessage id={cycle.key} />
    </button>
  );
};

export default BillingButton;
