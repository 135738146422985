import React from 'react';
import './Question.css';

const Question = (props) => {
  return (
    <div className='question-section'>
      <span className='question-section-title'>
        {props.title}
      </span>

      <span className='question-section-content'>
        {props.content}
      </span>
    </div>
  )
};


export default Question;