import { gql } from '@apollo/client';

export const updatedReactionCache =
  (sparkId) =>
  (cache, { data: { updateReaction } }) => {
    const updatedReaction = updateReaction?.reaction;
    if (updatedReaction) {
      cache.modify({
        fields: {
          sparks(existingSparks = { edges: [], pageInfo: {} }) {
            const updatedEdges = existingSparks.edges.map((edge) => {
              const nodeData = cache.readFragment({
                id: edge.node.__ref,
                fragment: gql`
                  fragment SparkDetails on Spark {
                    id
                    reactionsCount
                    reaction {
                      reactionType
                      id
                    }
                  }
                `,
              });

              if (nodeData?.id === sparkId) {
                const previousReactionType = nodeData.reaction?.reactionType;

                let updatedReactionsCount = {
                  ...nodeData.reactionsCount,
                };
                if (
                  previousReactionType &&
                  updatedReactionsCount[previousReactionType]
                ) {
                  updatedReactionsCount[previousReactionType] -= 1;
                  if (updatedReactionsCount[previousReactionType] <= 0) {
                    delete updatedReactionsCount[previousReactionType];
                  }
                }

                if (updatedReaction.reactionType) {
                  updatedReactionsCount[updatedReaction.reactionType] =
                    (updatedReactionsCount[updatedReaction.reactionType] || 0) +
                    1;
                }
                cache.writeFragment({
                  id: edge.node.__ref,
                  fragment: gql`
                    fragment UpdateSpark on Spark {
                      id
                      reactionsCount
                      reaction {
                        reactionType
                        id
                      }
                    }
                  `,
                  data: {
                    id: nodeData.id,
                    reactionsCount: updatedReactionsCount,
                    reaction: {
                      reactionType: updatedReaction.reactionType,
                      id: updatedReaction.id,
                    },
                  },
                });

                return edge;
              }

              return edge;
            });

            return {
              ...existingSparks,
              edges: updatedEdges,
            };
          },
        },
      });
    }
  };
