import React from 'react';
import './About.css';
import IframeComponent from '../../../components/Iframe/IframeComponent';

const About = () => {
  return (
    <IframeComponent
      title='About the company'
      source='https://www.wix.nettgrav.no/about-the-company'
    />
  );
};

export default About;
