import React, { useState, useEffect } from 'react';
import styles from './BasicInformation.module.css';
import ActionButton from './components/ActionButton';
import itemStyles from '../About/components/AboutItem.module.css';
import Header from './components/Header';
import EditSmallIcon from '../../../../img/EditSmallIcon.svg';

const BasicInformation = ({ item, onEdit, title, emptyMessage, canEdit }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [hideEdit, setHideEdit] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1020) {
        setIsExpanded(true);
        setHideEdit(false);
      } else {
        setHideEdit(true);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isExpanded, hideEdit]);

  return (
    <div div className={itemStyles['container-wrapper']}>
      <Header
        title={title}
        hideEdit={hideEdit}
        setIsExpanded={setIsExpanded}
        isExpanded={isExpanded}
        children={
          canEdit && (
            <ActionButton
              onEdit={onEdit}
              title='Redigere'
              icon={EditSmallIcon}
            />
          )
        }
      />
      {isExpanded && (
        <React.Fragment>
          {hideEdit && canEdit && (
            <div className={itemStyles['edit-btn']}>
              <ActionButton
                onEdit={onEdit}
                title='Redigere'
                icon={EditSmallIcon}
              />
            </div>
          )}
          {item.length > 0 ? (
            <div className={styles['about-item-content_row-container']}>
              {item.map((item, index) => {
                return (
                  <div className={styles['about-item-content_row']} key={index}>
                    {item.icon && (
                      <div className={styles['icon']}>
                        <img src={item.icon} alt='' />
                      </div>
                    )}
                    <div className={styles['about-item-value-container']}>
                      <span className={styles['about-item-key']}>
                        {item.key}:{' '}
                      </span>
                      <span className={styles['about-item-value']}>
                        {item.value}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className={styles['empty-grave-info']}>
              <span className={styles['empty-message']}>{emptyMessage}</span>
              {canEdit && (
                <button className={styles['add-btn']} onClick={onEdit}>
                  Add {title}
                </button>
              )}
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default BasicInformation;
