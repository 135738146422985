import React from 'react';
import AboutMenuItem from './components/AboutMenuItem';
import './AboutMenuBar.css';
import { AboutMenuItems } from '../../../../service/constants';

const AboutMenuBar = (props) => {
  return (
    <div className='about-menu-container'>
      {Object.entries(AboutMenuItems).map(([key, value]) => (
        <AboutMenuItem
          menuItem={value}
          key={key}
          indexKey={key}
          handelAboutMenuKey={props.handelAboutMenuKey}
          aboutMenuKey={props.aboutMenuKey}
        />
      ))}
    </div>
  );
};

export default AboutMenuBar;
