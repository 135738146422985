import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BackIcon from '../../img/BackIcon.svg';
import styles from './BackPageButton.module.css';
import { FormattedMessage } from 'react-intl';

const BackPageButton = ({ backLink, customStyles }) => {
  const navigate = useNavigate();
  return (
    <div style={customStyles} className={styles['header']}>
      <div
        className={styles['header-container']}
        onClick={() => navigate(backLink)}
      >
        <img src={BackIcon} alt='Back' />
        <FormattedMessage id='auth_back' />
      </div>
    </div>
  );
};

export default BackPageButton;
