import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import styles from './PlanPaymentDetails.module.css';
import { FormattedMessage } from 'react-intl';

const MembersCounter = ({ removeMembers, addMembers, count, messageKey }) => {
  return (
    <div className={styles['selected-plan-price']}>
      <div className={styles['selected-plan-name']}>
        <FormattedMessage id={messageKey} />
      </div>
      <div className={styles['selected-plan-add-more']}>
        <span className={styles['add-more-member']} onClick={removeMembers}>
          <RemoveIcon style={{ color: '#404d56', fontSize: '16px' }} />
        </span>
        <span className={styles['selected-price']}>{count}</span>
        <span className={styles['add-more-member']} onClick={addMembers}>
          <AddIcon style={{ color: '#404d56', fontSize: '16px' }} />
        </span>
      </div>
    </div>
  );
};

export default MembersCounter;
