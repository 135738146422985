import React from 'react';
import { makeStyles } from '@mui/styles';
import Warning from '../../img/orange_warning.svg';
import { Link, useLocation } from 'react-router-dom';
import './UpgradePlanModal.css';
import { useDispatch } from 'react-redux';
import { setReturnPage } from '../../redux/actions';
import CustomModal from '../CustomModal';
import { FormattedMessage } from 'react-intl';

const useStyle = makeStyles(() => ({
  rootDialog: {
    borderRadius: '10px !important',
    '@media (max-width: 800px)': {
      width: '100%',
    },
  },
}));

const UpgradePlanModal = (props) => {
  const { isOpen, title, onClose, body } = props;
  const classes = useStyle();
  const dispatch = useDispatch();
  const location = useLocation();
  return (
    <CustomModal
      title={
        <div className='upgrade-plan-prompt_body_subheading'>
          <img src={Warning} alt='warning' style={{ marginRight: '10px' }} />
          <FormattedMessage id={title} />
        </div>
      }
      isOpen={isOpen}
      closeModal={onClose}
      children={
        <div className='upgrade-plan-prompt_body_content'>
          <span>
            <FormattedMessage id={body} /> <FormattedMessage id='go_to' />{' '}
            <Link
              to='/pricing/payment?payment_method_type=vipps'
              onClick={() => {
                dispatch(setReturnPage(`${location.pathname}`));
                onClose();
              }}
            >
              <FormattedMessage id='our_plans' />
            </Link>{' '}
            <FormattedMessage id='choose_the_subscription' />
          </span>
        </div>
      }
      style={{
        maxWidth: '500px',
      }}
    />
  );
};

export default UpgradePlanModal;
