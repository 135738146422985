import React from 'react';

class NotFoundPage extends React.Component {
  render() {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 300,
          width: '100%',
          background: 'white',
        }}
      >
        <h1 style={{ color: '#999999' }}>This page does not exist.</h1>
      </div>
    );
  }
}

export default NotFoundPage;
