import React from 'react';
import Physical from '../../../img/PhysicalGrave.svg';

const GraveCategory = ({ handlePopoverOpen, handlePopoverClose }) => {
  return (
    <div
      className='grave-images_circle'
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      <img loading='lazy' src={Physical} alt='Physical' />
    </div>
  );
};

export default GraveCategory;
