import React from 'react';
import location from '../../../img/Location.svg';

const GraveLocation = ({ grave }) => {
  return (
    <div className='grave-location'>
      <span className='grave-date second'>
        <img loading='lazy' src={location} alt='location' />{' '}
        {grave.municipality.name}
      </span>
      <span> - </span>
      <span className='grave-id'>{grave.graveyard.name}</span>
    </div>
  );
};

export default GraveLocation;
