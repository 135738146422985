import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NextPhotoPointer from '../../../../../img/OrangePointerNext.svg';
import PrevPhotoPointer from '../../../../../img/OrangePointerPrev.svg';
import EmptyDot from '../../../../../img/EmptyDot.svg';
import styles from './SelectedImages.module.css';
import OrangeDot from '../../../../../img/FilledDot.svg';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import {
  setEventPhotoIndex,
  setEditEventPhotoIndex,
  addLifeEventPhotos,
} from '../../../../../redux/actions';
import CloseIcon from '@mui/icons-material/Close';
import CropIcon from '@mui/icons-material/Crop';
import isEmpty from 'lodash.isempty';
import showUserNotification from '../../../../../components/UserNotification/showUserNotification';

const SelectedImages = (props) => {
  const dispatch = useDispatch();
  const inputFile = useRef();
  const photos = useSelector((state) => state.grave.lifeEventPhotos);
  const currentPhoto = useSelector(
    (state) => state.photoReducer.currentEventPhotoIndex
  );

  const [touchStartX, setTouchStartX] = useState(null);

  const prevPhoto = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (currentPhoto === 0) {
      dispatch(setEventPhotoIndex(photos.length - 1));
    } else {
      dispatch(setEventPhotoIndex(currentPhoto - 1));
    }
  };

  const nextPhoto = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (currentPhoto === photos.length - 1) {
      dispatch(setEventPhotoIndex(0));
      return 0;
    } else {
      dispatch(setEventPhotoIndex(currentPhoto + 1));
    }
  };

  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchEnd = (e) => {
    const touchEndX = e.changedTouches[0].clientX;
    const touchDistance = touchEndX - touchStartX;

    const swipeThreshold = 50;

    if (Math.abs(touchDistance) > swipeThreshold) {
      if (touchDistance > 0) {
        prevPhoto();
      } else {
        nextPhoto();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchend', handleTouchEnd);

    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, [currentPhoto]);

  const handelChangeImage = (event, index) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    dispatch(setEventPhotoIndex(index));
  };

  const handelStopPagination = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const handelRemoveImage = () => {
    if (photos[currentPhoto]) {
      const id = photos[currentPhoto].id;
      const newArray = photos.filter((photo) => photo.id !== id);
      if (currentPhoto > 0) {
        dispatch(setEventPhotoIndex(currentPhoto - 1));
      }
      dispatch(addLifeEventPhotos(newArray));
    }
  };

  const clickOnHideInput = () => {
    if (inputFile.current) {
      inputFile.current.click();
    }
  };

  const biggerImage = () =>
    showUserNotification(
      'Last opp et bilde som ikke er større enn 50MB.',
      'warning'
    );

  const choseCoverPhotoFromList = (newPhotos) => {
    if (!isEmpty(newPhotos)) {
      const newImages = [...newPhotos, ...photos];
      dispatch(addLifeEventPhotos(newImages));
    } else {
      dispatch(addLifeEventPhotos(newPhotos));
    }
  };

  const handelCoverImage = (id) => {
    const updateImages = photos.map((image) => {
      if (image.id === id) {
        if (image.category === 'timeline') {
          return { ...image, category: 'display' };
        } else {
          return { ...image, category: 'timeline' };
        }
      }
      return { ...image, category: 'timeline' };
    });
    dispatch(addLifeEventPhotos([...updateImages]));
  };

  return (
    <div className={styles['grave-picture-gallery_photos-photo']}>
      {photos.length > 0 && (
        <img
          className={styles['gallery_photos-photo']}
          loading='lazy'
          src={
            currentPhoto === 0
              ? photos[0].file_url
              : photos[currentPhoto].file_url
          }
          alt='Display-image'
        />
      )}
      {photos.length > 1 && (
        <React.Fragment>
          <button
            className={`${styles['buttons-prev']} ${styles['show-buttons']}`}
            onClick={(event) => prevPhoto(event)}
          >
            <img loading='lazy' src={PrevPhotoPointer} alt='PrevPhotoPointer' />
          </button>
          <button
            className={`${styles['buttons-next']} ${styles['show-buttons']}`}
            onClick={(event) => nextPhoto(event)}
          >
            <img loading='lazy' src={NextPhotoPointer} alt='PrevPhotoPointer' />
          </button>
        </React.Fragment>
      )}
      <div className={styles['image-actions']}>
        <React.Fragment>
          <button
            onClick={() => {
              props.handelOpenCropper();
              dispatch(setEditEventPhotoIndex(currentPhoto));
            }}
          >
            <CropIcon style={{ fontSize: '14px', color: '#404D56' }} />
          </button>
          <button onClick={() => handelRemoveImage()}>
            <CloseIcon style={{ fontSize: '16px', color: '#404D56' }} />
          </button>
        </React.Fragment>
      </div>
      {photos.length > 1 && (
        <div
          className={styles['dots-container']}
          onClick={(event) => handelStopPagination(event)}
        >
          {photos.map((photo, index) => (
            <img
              style={{
                height: '10px',
                width: '10px',
              }}
              onClick={(event) => handelChangeImage(event, index)}
              key={index}
              loading='lazy'
              src={currentPhoto === index ? OrangeDot : EmptyDot}
              alt='dot'
            />
          ))}
        </div>
      )}
      <div
        className={styles['set-cover-image']}
        onClick={() => {
          if (photos[currentPhoto].id) {
            handelCoverImage(photos[currentPhoto].id);
          }
        }}
      >
        <input
          type='checkbox'
          checked={photos[currentPhoto].category === 'display'}
        />
        <label>Sett som visningsbilde</label>
      </div>
    </div>
  );
};

export default SelectedImages;
