import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './SetSelfRelation.module.css';
import PrimaryButton from '../../../components/PrimaryButton';

const style = {
  background: '#fff',
  border: '1px solid #404D56',
  color: '#404D56',
  padding: '7px 16px',
};

const SubscribedGraveInvite = ({
  grave,
  graveInvitation,
  handelGraveInvitation,
}) => {
  return (
    <React.Fragment>
      <div className={styles['heading']}>
        <FormattedMessage id='you_are_invited' />
      </div>
      <div className={styles['sub-heading']}>
        <FormattedMessage
          id='subscribed_grave_invitation'
          values={{
            graveName: grave?.name,
            name: graveInvitation.inviting_user?.full_name,
            relation: (
              <FormattedMessage
                id={
                  graveInvitation.relation?.key
                    ? graveInvitation.relation?.key
                    : graveInvitation.relation?.name
                }
              />
            ),
          }}
        />
      </div>
      <div className={styles['sub-heading']}>
        <FormattedMessage id='click_below_to_accept' />
      </div>
      <div className={styles['btn-container']}>
        <PrimaryButton
          children={<FormattedMessage id='decline' />}
          onClick={() => handelGraveInvitation('decline')}
          style={style}
        />
        <PrimaryButton
          onClick={() => handelGraveInvitation('accept')}
          children={<FormattedMessage id='accept_invitation' />}
        />
      </div>
    </React.Fragment>
  );
};

export default SubscribedGraveInvite;
