import React, { useState, useEffect } from 'react';
import styles from './AboutBox.module.css';
import { useSelector } from 'react-redux';
import AboutMenuBar from './AboutMenuBar';
import AboutBox from './AboutBox';

const AboutGraveInfo = () => {
  const grave = useSelector((state) => state.grave.grave);
  const user = useSelector((state) => state.user) || {};
  const [hideMenu, setHideMenu] = useState(false);
  const [aboutMenuKey, setAboutMenuKey] = useState(1);
  const [graveUser, setGraveUser] = useState(null);

  const handelAboutMenuKey = (key) => {
    setAboutMenuKey(key);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1020) {
        setHideMenu(true);
      } else {
        setHideMenu(false);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [hideMenu, aboutMenuKey]);

  useEffect(() => {
    if (grave.grave_users.length > 0) {
      const graveUser = grave.grave_users
        ? grave.grave_users.find((u) => u.user?.id === user.user.id)
        : null;
      if (graveUser) setGraveUser(graveUser);
    }
  }, [grave]);

  return (
    <div className={styles['about-container']}>
      {hideMenu && (
        <AboutMenuBar
          handelAboutMenuKey={handelAboutMenuKey}
          aboutMenuKey={aboutMenuKey}
        />
      )}
      <AboutBox
        grave={grave}
        hideMenu={hideMenu}
        aboutMenuKey={aboutMenuKey}
        graveUser={graveUser}
      />
    </div>
  );
};

export default AboutGraveInfo;
