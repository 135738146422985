import React, { useEffect } from 'react';
import styles from './SparksList.module.css';
import { Avatar, Tooltip } from '@mui/material';
import { timeSinceCreated } from '../../../../../../service/Utils';
import { useDestroySparkMutation } from '../../../../../../graphql/generated/sparkHooks.ts';
import showUserNotification from '../../../../../../components/UserNotification/showUserNotification';
import ActionButton from '../Components/ActionButton.jsx';
import Categories from '../Components/Categories.jsx';
import Visibility from '../Components/Visibility.jsx';
import { privacyList } from '../CreateSparks/CreateSparks.jsx';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { removeSparkCache } from '../../../../../../graphql/CacheUpdates/SparksCache/removeSparkCache.js';

const Header = ({
  spark,
  onClickEdit,
  editSpark,
  sparkCategories,
  setSparkCategory,
  privacy,
  setPrivacy,
  sparkCategory,
  user,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const activeUser = user?.id?.toString() === spark?.user.id;
  const [destroySpark] = useDestroySparkMutation();

  const findPrivacy = async () => {
    if (spark?.visibility) {
      const findPrivacy = await privacyList.find(
        (privacy) => privacy.key === spark.visibility
      );
      if (findPrivacy) {
        setPrivacy(findPrivacy);
      }
    }
  };

  useEffect(() => {
    findPrivacy();
  }, [spark]);

  const handleDestroySpark = async (id) => {
    try {
      if (!id) return;
      await destroySpark({
        variables: {
          id,
        },
        update: removeSparkCache,
      });
      showUserNotification(
        intl.formatMessage({ id: 'spark_delete' }),
        'success'
      );
    } catch (err) {
      showUserNotification('Error destroying spark', 'error');
      console.error('Error destroying spark:', err);
    }
  };

  const isUserCurrentUser = () => {
    return (
      user.slug === localStorage.getItem('user-slug') ||
      user.slug === sessionStorage.getItem('user-slug')
    );
  };

  return (
    <div className={styles['header']}>
      <div className={styles['user-info']}>
        <div onClick={() => navigate(`/member/${spark?.user.slug}`)}>
          <Avatar
            src={spark?.user.displayPicture}
            className={styles['avatar']}
          />
        </div>
        <div className={styles['user-header']}>
          {spark?.user.fullName && (
            <div
              className={styles['user-name']}
              onClick={() => navigate(`/member/${spark?.user.slug}`)}
            >
              {spark?.user.fullName}
            </div>
          )}

          {spark?.createdAt && (
            <div
              className={
                !editSpark
                  ? styles['user-name-container']
                  : styles['user-name-container-edit']
              }
            >
              <div className={styles['create-at']}>
                {timeSinceCreated(spark?.createdAt)}
              </div>
              {spark?.visibility && !editSpark && (
                <div className={styles['dot']} />
              )}
              {editSpark ? (
                <Visibility setPrivacy={setPrivacy} privacy={privacy} />
              ) : (
                spark?.visibility &&
                privacy && (
                  <Tooltip title={<FormattedMessage id={privacy.key} />}>
                    {privacy.icon}
                  </Tooltip>
                )
              )}
            </div>
          )}
        </div>
      </div>
      <div className={styles['action-container']}>
        {editSpark ? (
          <Categories
            sparkCategories={sparkCategories}
            setSparkCategory={setSparkCategory}
            sparkCategory={sparkCategory}
          />
        ) : (
          spark?.category.name && (
            <div className={styles['category']}>
              <div className={styles['circle']} />
              <FormattedMessage id={spark.category.name} />
            </div>
          )
        )}

        {activeUser && isUserCurrentUser() && (
          <ActionButton
            onClickEdit={onClickEdit}
            onClickDelete={handleDestroySpark}
            spark={spark}
          />
        )}
      </div>
    </div>
  );
};

export default Header;
