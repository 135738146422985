import React from 'react';
import { Avatar } from '@mui/material';
import styles from './User.module.css';
import Visibility from '../Components/Visibility';

const User = ({ user, privacy, setPrivacy }) => {
  return (
    <div className={styles['container']}>
      <div className={styles['user-info']}>
        <Avatar src={user?.avatar} />
        <div>{user.full_name}</div>
      </div>
      <Visibility setPrivacy={setPrivacy} privacy={privacy} />
    </div>
  );
};

export default User;
