import React from 'react';
import './SubscriptionPlanChosen.css';
import PlansHeading from './PlansHeading';
import AdditionalBenefits from './AdditionalBenefits';
import FrequentlyAskedQuestions from './FrequentlyAskedQuestions';
import { FormattedMessage } from 'react-intl';
import Plans from '../../NewSettings/atoms/Plans/Plans';

const faqs = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }];

const SubscriptionPlanChosen = ({ currentSubscription }) => {
  return (
    <div className='subscription-chosen_content-main'>
      <PlansHeading />
      <div className='subscription-chosen-cards'>
        <Plans currentSubscription={currentSubscription} />
      </div>
      <div className='additional-benefit'>
        <AdditionalBenefits />
      </div>
      <div className='faq'>
        <div className='faq-heading'>
          <FormattedMessage id='question_answer' />
        </div>
        {faqs.map((question) => {
          return (
            <FrequentlyAskedQuestions question={question} key={question.id} />
          );
        })}
      </div>
    </div>
  );
};

export default SubscriptionPlanChosen;
