import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { TextField } from '@mui/material';
import Input from '../../../../components/Input/Input';
import './SetRelationOrClaimDialog.css';
import SelectDocument from '../GraveClaimPage/SelectDocument/SelectDocument';
import UploadDocumentFile from '../GraveClaimPage/UploadDocumentFile/UploadDocumentFile';
import TermsAndCondition from '../GraveClaimPage/TermsAndCondition/TermsAndCondition';

const RelationTabs = ({ label, onClick, active }) => {
  const buttonClassName = active ? 'active-button' : 'inactive-button';
  return (
    <button className={buttonClassName} onClick={onClick}>
      <FormattedMessage id={label} />
    </button>
  );
};

const SetRelationOrClaimDialog = ({
  handleTabClick,
  activeTab,
  formInputs,
  grave,
  handleFriendNoteChange,
  friendNote,
  graveInvitation,
  graveUser,
  handelSelectedDocument,
  selectedDocument,
  handelSetDocumentFile,
  selectedFile,
  user,
  setIsChecked,
  isChecked,
  isClaimed,
  wantToClaim,
  setWantToClaim,
}) => {
  const intl = useIntl();

  return (
    <React.Fragment>
      <div className='set-relation-and-claim-modal_display_image_wrapper'>
        <div>
          {!(graveUser || graveInvitation) && (
            <React.Fragment>
              <div className='choose-relation-text'>
                <FormattedMessage id='choose_relation' />
              </div>
              <div className='tab-container'>
                <RelationTabs
                  label='relative'
                  onClick={() => handleTabClick('relative')}
                  active={activeTab === 'relative'}
                />
                <RelationTabs
                  label='friend'
                  onClick={() => handleTabClick('friend')}
                  active={activeTab === 'friend'}
                />
                <RelationTabs
                  label='auth_other'
                  onClick={() => handleTabClick('other')}
                  active={activeTab === 'other'}
                />
              </div>
            </React.Fragment>
          )}
          <div className='tab-content'>
            {activeTab === 'relative' && (
              <div className='tab-content_body'>
                {formInputs.map((item, index) => (
                  <Input key={index} item={item} />
                ))}
              </div>
            )}
            {activeTab === 'friend' && (
              <div className='text-field-relation'>
                <TextField
                  placeholder={intl.formatMessage(
                    {
                      id: 'set_relation_placeholder',
                    },
                    { grave: grave.name }
                  )}
                  variant='outlined'
                  multiline
                  minRows={4}
                  fullWidth
                  value={friendNote}
                  onChange={handleFriendNoteChange}
                />
              </div>
            )}
            {!isClaimed && (
              <div className='accept-container'>
                <input
                  type='checkbox'
                  onChange={() => setWantToClaim(!wantToClaim)}
                  checked={wantToClaim}
                />
                <div className='label'>
                  <FormattedMessage id='claim_grave_labe' />
                </div>
              </div>
            )}
            {wantToClaim && !isClaimed && (
              <React.Fragment>
                <SelectDocument
                  handelSelectedDocument={handelSelectedDocument}
                  selectedDocument={selectedDocument}
                />
                {selectedDocument && (
                  <React.Fragment>
                    <UploadDocumentFile
                      handelSetDocumentFile={handelSetDocumentFile}
                      selectedFile={selectedFile}
                    />
                    <TermsAndCondition
                      user={user}
                      isCheckedTerms={(value) => setIsChecked(value)}
                      isChecked={isChecked}
                    />
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
            {activeTab === 'other' && (
              <div className='message-text'>
                <FormattedMessage id='set_relation_other' />
              </div>
            )}
          </div>
        </div>
      </div>
      <p className='message-text'>
        <FormattedMessage id='set_relation_note' />
      </p>
    </React.Fragment>
  );
};

export default SetRelationOrClaimDialog;
