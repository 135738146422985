import React from 'react';
import './HomepageScientistsFourth.css';
import Group from '../../../../img/ScientistsGroupFourth.jpg';

const HomepageScientistsFourth = () => {
    return(
        <div className='homepage-scientists_fourth'
        >
            <div className="homepage-scientists_fourth-content">
                <img loading='lazy' src={Group} alt='Group' className='homepage-scientists_fourth-content_group'/>
                <div className='homepage-scientists_fourth-content_empty'/>
                <div className="homepage-scientists_fourth-content_main">
                    <span className='homepage-scientists_fourth-content_main-text'>Tenk om vi sammen kan gi vitenpersoner muligheten til å gå på jobb for og søke ny kunnskap finansiert av oss og gravene?</span>
                    <div className="homepage-scientists_fourth-content_main-boxes">
                        <div className="homepage-scientists_fourth-content_main-big-box"/>
                        <div className="homepage-scientists_fourth-content_main-small-box"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomepageScientistsFourth;