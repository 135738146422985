import React from 'react';
import { Modal } from '@mui/material';
import { makeStyles } from '@mui/styles';
import styles from './CancelSubscriptionModal.module.css';
import X from '../../../../img/X.svg';
import { FormattedMessage } from 'react-intl';
import { SubscriptionStatuses } from '../../../../service/constants';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    position: 'relative',
    padding: '32px',
    display: 'flex',
    flexDirection: 'column',
    height: 'maxContent',
    width: 700,
    backgroundColor: '#fff',
    borderRadius: '10px',
    outline: 'none',
  },
  modalBackground: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}));

const CancelSubscriptionModal = ({
  open,
  handleClose,
  cancelSubscription,
  planeName,
  status,
}) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modal}
      BackdropProps={{
        className: classes.modalBackground,
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.modalContent}>
        <div className={styles['heading-container']}>
          <div className={styles['heading']}>
            <FormattedMessage
              id={
                status === SubscriptionStatuses.CANCELLED
                  ? 'Reactivate'
                  : 'settings_cancelSubscription'
              }
            />
          </div>
          <img
            style={{ cursor: 'pointer', height: '25px', width: '25px' }}
            loading='lazy'
            src={X}
            alt='x'
            onClick={handleClose}
          />
        </div>
        <div className={styles['message']}>
          <FormattedMessage
            id={
              status === SubscriptionStatuses.CANCELLED
                ? 'reactivateSubscription_message'
                : 'cancelSubscription_message'
            }
            values={{ name: planeName }}
          />
        </div>
        <div className={styles['footer-container']}>
          <div className={styles['footer']}>
            <button
              className={styles['rootDiscard']}
              onClick={() => handleClose()}
            >
              <FormattedMessage id='not_now' />
            </button>
            <button
              className={` ${styles['rootVerify']} ${
                status === SubscriptionStatuses.CANCELLED
                  ? styles['activate']
                  : ''
              }`}
              onClick={() => cancelSubscription()}
            >
              <FormattedMessage
                id={
                  status === SubscriptionStatuses.CANCELLED
                    ? 'reactivate'
                    : 'settings_cancel_mySubscription'
                }
              />
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CancelSubscriptionModal;
