import React, { useState } from 'react';
import {
  setGravePassionRedux,
  setScreenRedux,
} from '../../../../redux/actions';
import { Button, MenuItem, Select } from '@mui/material';
import { makeStyles } from '@mui/styles';
import FamilySilhouette from '../../../../img/FamilySilhouette.svg';
import OnlyMe from '../../../../img/OnlyMe.svg';
import Everyone from '../../../../img/Everyone.svg';
import { useDispatch } from 'react-redux';
import { request } from '../../../../service/request';
import styles from './GravePassionForm.module.css';
import SavedPopupModal from '../SavedPopupModal/SavedPopupModal';
import showUserNotification from '../../../../components/UserNotification/showUserNotification';
import PrimaryButton from '../../../../components/PrimaryButton/index';

const useStyle = makeStyles(() => ({
  rootRelations: {
    backgroundColor: '#F5F5F5',
    borderRadius: '10px',
    width: '160px',
    height: '37px',
  },
  rootSave: {
    width: '180px',
    height: '55px',
    backgroundColor: '#404D56',
    borderRadius: '8px',
    color: '#fff',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '18px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#404D56',
    },
  },
}));

const GravePassionForm = ({ grave, closeModal }) => {
  const dispatch = useDispatch();
  const classes = useStyle();
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [comment, setComment] = useState(grave?.passion?.comment);
  const [privacy, setPrivacy] = useState('relatives');

  const onSave = () => {
    let formdata = new FormData();
    formdata.append('grave_passion[grave_id]', `${grave?.id}`);
    formdata.append('grave_passion[comment]', comment);
    formdata.append('grave_passion[privacy_attributes][setting]', privacy);
    if (grave?.passion) {
      request(`/grave_passions/${grave.passion?.id}`, formdata, 'put')
        .then(dispatch(setGravePassionRedux({ comment })))
        .then(setOpenSaveModal(true))
        .catch((e) => {
          showUserNotification('noe gikk galt, prøv igjen', 'warning');
        });
    } else {
      request('/grave_passions', formdata, 'post')
        .then((res) =>
          dispatch(
            setGravePassionRedux({
              id: res.data.grave_passion_id,
              grave_id: grave?.id,
              comment,
            })
          )
        )
        .then(setOpenSaveModal(true))
        .catch((e) => {
          showUserNotification('noe gikk galt, prøv igjen', 'warning');
        });
    }
  };

  const closeSaveModal = () => {
    setOpenSaveModal(false);
    closeModal();
  };

  return (
    <>
      <div>
        <div className={styles['passion_content-textarea']}>
          <textarea
            value={comment ? comment : ''}
            onChange={(e) => setComment(e.target.value)}
            placeholder='Fortell hvordan den gravlagte brukte sine evener og lidenskap for å påvirke verden positivt. Del de store og små handlingene som bidro til å skape en bedre fremtid for andre'
          />
        </div>
        <div className={styles['passion_content-buttons']}>
          <div className={styles['passion_content-buttons_privacy']}>
            <span>Del med:</span>
            <Select
              value={privacy}
              onChange={(e) => setPrivacy(e.target.value)}
              className={styles['profile-photo_body-content_chosen-menu']}
              classes={{
                icon: classes.rootIconMenu,
                iconOpen: classes.rootIconMenuOpen,
              }}
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'center',
                },
                transformOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
                },
              }}
            >
              <MenuItem value='default' disabled={true}>
                Del med:
              </MenuItem>
              <MenuItem value='public'>
                <img
                  loading='lazy'
                  src={Everyone}
                  alt='Family Silhouette'
                  className={styles['memories-header_right-icon']}
                />
                Alle
              </MenuItem>
              <MenuItem value='relatives'>
                <img
                  loading='lazy'
                  src={FamilySilhouette}
                  alt='Family Silhouette'
                  className={styles['memories-header_right-icon']}
                />
                Familie
              </MenuItem>
              <MenuItem value='private'>
                <img
                  loading='lazy'
                  src={OnlyMe}
                  alt='Family Silhouette'
                  className={styles['memories-header_right-icon']}
                  style={{ marginLeft: '3px', marginRight: '7px' }}
                />
                Privat
              </MenuItem>
            </Select>
          </div>
          <PrimaryButton className={classes.rootSave} onClick={onSave}>
            Lagre
          </PrimaryButton>
        </div>
      </div>
      {openSaveModal && (
        <SavedPopupModal
          open={openSaveModal}
          titleKey='passion'
          handleClose={closeSaveModal}
        />
      )}
    </>
  );
};

export default GravePassionForm;
