import React, { useEffect, useState } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import styles from './NewCard/NewCard.module.css';
import { useSelector } from 'react-redux';

const CustomPhoneNumber = ({
  isValid,
  setIsValid,
  onNumberChange,
  userNumber,
}) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryName, setCountryName] = useState('NO');
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    if (userNumber) {
      setPhoneNumber(userNumber);
      setIsValid(null);
      return;
    } else {
      setPhoneNumber('');
    }
  }, [user]);

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
    const valid =
      typeof value === 'string' && isPossiblePhoneNumber(value, countryName);
    if (valid && value.trim() !== '') {
      setIsValid(null);
      onNumberChange(value);
    } else {
      onNumberChange(value);
      setIsValid('Invalid number');
    }
  };

  const handleCountryChange = (newCountryCode) => {
    setCountryName(newCountryCode);
    setPhoneNumber('');
  };

  return (
    <PhoneInput
      international
      defaultCountry={countryName}
      country={countryName}
      countryCallingCodeEditable={false}
      withCountryCallingCode
      value={phoneNumber}
      onChange={handlePhoneNumberChange}
      onCountryChange={handleCountryChange}
      className={
        isValid
          ? styles['custom-phone-input-error']
          : styles['custom-phone-input']
      }
    />
  );
};

export default CustomPhoneNumber;
