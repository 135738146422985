import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import VippsPhoneNumber from '../../../Payment/atoms/Vipps/VippsPhoneNumber';
import PrimaryButton from '../../../../components/PrimaryButton/index';
import styles from './RenewAgreement.module.css';
import PaymentMethods from '../../../Payment/atoms/PaymentMethods/PaymentMethods';
import AddNewCard from '../../../Payment/atoms/AddNewCard/AddNewCard';
import isEmpty from 'lodash.isempty';
import { PaymentType } from '../../../../service/constants';
import showUserNotification from '../../../../components/UserNotification/showUserNotification';

const RenewAgreement = ({
  handleCreatePaymentMethod,
  closeModal,
  vippsPaymentMethods,
  setSelectedPaymentMethod,
  selectedPaymentMethod,
  updateSubscription,
  selectedMethod,
  agreementHash,
}) => {
  const intl = useIntl();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState(false);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    if (vippsPaymentMethods) {
      setShowForm(vippsPaymentMethods.length === 0);
    } else {
      setShowForm(true);
    }
  }, [vippsPaymentMethods]);

  const onClick = (number) => {
    if (number.trim().length === 8) {
      handleCreatePaymentMethod(number);
    } else {
      showUserNotification(
        intl.formatMessage({ id: 'filled_out_info' }),
        'error'
      );
      setError(true);
    }
  };

  const onChangeNumber = (value) => {
    setPhoneNumber(value);
    if (value.trim().length === 8 || value.trim() === '') {
      setError(false);
    }
  };

  const handelSelectPaymentMethod = (value) => {
    setSelectedPaymentMethod(value);
    setShowForm(false);
  };

  const getAgreementStatus = (paymentMethod) => {
    if (
      !isEmpty(paymentMethod) &&
      !isEmpty(agreementHash) &&
      paymentMethod.payment_type === PaymentType.vipps
    ) {
      return agreementHash[paymentMethod.agreement_id]
        ? agreementHash[paymentMethod.agreement_id].status
        : null;
    } else {
      return null;
    }
  };

  const handelOnSave = () => {
    if (isEmpty(selectedPaymentMethod) && !showForm) {
      return showUserNotification(
        intl.formatMessage({ id: 'warning_payment_method' }),
        'warning'
      );
    }
    if (getAgreementStatus(selectedPaymentMethod) === 'active') {
      updateSubscription();
    } else if (
      selectedPaymentMethod &&
      selectedPaymentMethod.payment_type === PaymentType.vipps
    ) {
      onClick(selectedPaymentMethod.phone_number);
    } else if (showForm) {
      onClick(phoneNumber);
    }
  };

  return (
    <div>
      {vippsPaymentMethods &&
        vippsPaymentMethods.length > 0 &&
        vippsPaymentMethods.map((paymentMethod) => (
          <PaymentMethods
            key={paymentMethod.id}
            method={paymentMethod}
            handelSelectPaymentMethod={handelSelectPaymentMethod}
            showPayment={selectedPaymentMethod.id === paymentMethod.id}
            getAgreementStatus={getAgreementStatus}
          />
        ))}
      {!showForm && vippsPaymentMethods && vippsPaymentMethods.length > 0 && (
        <AddNewCard
          handleShowCard={setShowForm}
          handleShowPayment={setSelectedPaymentMethod}
          showCard={showForm}
        />
      )}
      {showForm && (
        <React.Fragment>
          <VippsPhoneNumber
            vippsPhoneNumber={phoneNumber}
            handelOnChange={onChangeNumber}
            error={error}
          />
        </React.Fragment>
      )}
      <div className={styles['next-button']}>
        <PrimaryButton
          onClick={closeModal}
          children={<FormattedMessage id='settings_cancel' />}
          style={{
            background: '#fff',
            border: '1px solid #404D56',
            color: '#404D56',
            padding: '7px 16px',
          }}
        />
        <PrimaryButton
          onClick={handelOnSave}
          children={<FormattedMessage id='auth_save' />}
        />
      </div>
    </div>
  );
};

export default RenewAgreement;
