import React from 'react';
import BirthDate from '../../../img/BirthDate.svg';
import calendar from '../../../img/Calendar.svg';

const GraveDate = ({ grave }) => {
  const convertDate = (data) => {
    if (data)
      return `${data.slice(-2)}.${data.slice(-5, -3)}.${data.slice(-10, -6)}`;
    else return 'no info';
  };
  return (
    <div className='grave-data_date'>
      <span>
        <img loading='lazy' src={BirthDate} alt='birth' />{' '}
        {convertDate(grave.date_of_birth)}
      </span>
      <span>
        <img loading='lazy' src={calendar} alt='calendar' />{' '}
        {convertDate(grave.date_of_death)}
      </span>
    </div>
  );
};

export default GraveDate;
