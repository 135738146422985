import React, { useState } from 'react';
import styles from './UploadImagesForm.module.css';
import UploadImage from '../UploadImage/UploadImage';
import SelectedImages from '../SelectedImage/SelectedImages';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';

const UploadImagesForm = ({ onClose, openWarningModal }) => {
  const [showImages, setShowImages] = useState(false);
  const currentComment = useSelector((state) => state.grave.commentOnToggle);

  return (
    <div>
      {showImages &&
        !isEmpty(currentComment.photos) &&
        currentComment.photos.map((photo, index) => {
          return <SelectedImages photo={photo} key={index} />;
        })}
      <UploadImage handleShowImages={() => setShowImages(true)} />
      <div className={styles['grave-btn-container']}>
        <div className={styles['grave-information_content-verify']}>
          <button
            type='button'
            className={styles['rootDiscard']}
            onClick={() => openWarningModal()}
          >
            Avbryt
          </button>
          <button
            disabled={
              !(currentComment.photos && currentComment.photos.length > 0)
            }
            className={
              currentComment.photos && currentComment.photos.length > 0
                ? styles['rootVerify']
                : styles['disabledButton']
            }
            type='submit'
            onClick={() => onClose()}
          >
            Lagre
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadImagesForm;
