import React from 'react';
import styles from './CreateMembership.module.css';
import { FormControlLabel, Checkbox } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const TermsAndCondition = ({
  handleCheckBoxChange,
  checked,
  messageKey,
  actionKey,
  style,
}) => {
  const navigate = useNavigate();
  return (
    <div className={styles['check-container']} style={style}>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={handleCheckBoxChange}
            inputProps={{ 'aria-label': 'checkbox' }}
            className={styles['checkbox']}
            required
          />
        }
      />
      <span className={styles['terms_conditions']}>
        <FormattedMessage id={messageKey} />{' '}
        {actionKey && (
          <span
            onClick={() => navigate(`/terms_conditions`)}
            className={styles['link']}
          >
            <FormattedMessage id={actionKey} />
          </span>
        )}
      </span>
    </div>
  );
};

export default TermsAndCondition;
