import "./cookies.css";
import React, { useState, useEffect } from "react";

function Cookies() {
    const [cookies, setCookies] = useState(localStorage.getItem("netgrav_cookies"));

    const enableCookies = () => {
        setCookies(true);
        localStorage.setItem("netgrav_cookies", true);
    }

    useEffect(() => {
        const storedUserCookiesInfo = localStorage.getItem('netgrav_cookies')

        if(storedUserCookiesInfo) {
            setCookies(true);
        }
    }, [])

    return !cookies ? (
        <div className="cookies">
            <p className="cookies_text">Vi bruker informasjonskapsler med mål om å forbedre portalen. Google analytics og Hotjar er målingsverktøyene vi i dag benytter.</p>
            <div className="cookies_controls">
                <p className="cookies_controls_link" onClick={enableCookies}>Lukk</p>
                <button
                    className="cookies_controls_button"
                    onClick={enableCookies}>Ok</button>
            </div>
        </div>
    ) : null;
}

export default Cookies
