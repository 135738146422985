import React, { useRef, useEffect, useState } from 'react';
import './LearnMoreSecond.css';
import HeaderImage from '../../../../img/LearnMoreSecondHeader.svg';
import LearnMoreFirstVideo from '../../../../img/LearnMoreFirst.mp4';
import Number from '../../../../img/LearnMoreOne.svg';
import { isVideoScrolledIntoView } from '../../../../service/Utils';

const LearnMoreSecond = (props) => {
    const vidRef = useRef(null);
    const handlePlayVideo = () => {
      vidRef.current.play();
    }
    const [scrollY, setScrollY] = useState(0);

    const updateYOffSet = () => {
      setScrollY(window.pageYOffset)
    }

    useEffect(() => {
      function watchScroll() {
        window.addEventListener("scroll", updateYOffSet);
      }

      watchScroll()
      return () => window.removeEventListener("scroll", updateYOffSet);
    });

    useEffect(() => {
      let elem = document.querySelector('.learn-more_second-content_main-image')
      if ( isVideoScrolledIntoView(elem)) {
        vidRef.current.play()
      } else {
        vidRef.current.pause()
      }
    }, [scrollY])

    return(
        <div className='learn-more_second'>
            <div className="learn-more_second-content">
                <div className="learn-more_second-content_header">
                    <span id ='scroll-point' className='learn-more_second-content_header-main'>Hvordan ta styring på en <span>Nettgrav</span>?<span></span> </span>
                </div>
                <div className="learn-more_second-content_main">
                    <div className="learn-more_second-content_main-text">
                        <div className="learn-more_second-content_main-text_header">
                            <img loading='lazy' src={HeaderImage} alt='HeaderImage' className='learn-more_second-content_main-text_header-image'/>
                            <span className='learn-more_second-content_main-text_header-title'>Opplaste dokumentasjon for graveierskap</span>
                            <img loading='lazy' src={Number} alt='Number' className='learn-more_second-content_main-text_header-number'/>
                        </div>
                        <div className="learn-more_second-content_main-text_title">
                            <span>Når du har mottatt eierskapet til gravlagte, har du full kontroll og muligheten til å redigere eller legge til informasjon som du måtte ønske. Som graveier aksepterer du alle nye forespørsler om graven.</span>
                        </div>
                    </div>
                    <div className="learn-more_second-content_main-empty">
                        <img loading='lazy' src={Number} alt='Number'/>
                    </div>
                    <div className="learn-more_second-content_main-image">
                        <video ref={vidRef} onFocus={handlePlayVideo} loop muted="muted">
                          <source src={LearnMoreFirstVideo} type="video/mp4" />
                        </video>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LearnMoreSecond;