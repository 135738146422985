import Header from '../Component/Header';
import Content from '../Component/Content';
import Paginator from '../Component/Paginator';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { fetch_request } from '../../../../../service/request';
import AboutCemetery from '../../../../../img/AboutCemetery.svg';
import Loader from '../../../../../components/Loader/Loader';
import { FormattedMessage, useIntl } from 'react-intl';

const Index = (props) => {
  const intl = useIntl();
  const [searchInputValue, setSearchInputValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const municipalitySlug = props.municipalitySlug;
  const [cemeteriesCount, setCemeteriesCount] = useState(0);
  const [cemeteriesList, setCemeteriesList] = useState([]);
  const [municipalityName, setMunicipalityName] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchInputValue]);

  useEffect(() => {
    if (municipalitySlug) {
      fetch_request(
        `/api/cemeteries_count?municipality_slug=${municipalitySlug}`
      )
        .then((response) => response.json())
        .then((data) => {
          setCemeteriesCount(data.count);
        })
        .catch((err) => console.log(err));
    }
  }, []);

  useEffect(() => {
    if (municipalitySlug) {
      fetch_request(`/api/municipalities/${municipalitySlug}`)
        .then((response) => response.json())
        .then((data) => {
          setMunicipalityName(data.name);
        })
        .catch((err) => console.log(err));
    }
  }, []);

  useEffect(() => {
    fetchCemeteries();
  }, [searchInputValue, currentPage]);

  const fetchCemeteries = () => {
    if (municipalitySlug) {
      setLoading(true);
      fetch_request(
        `/api/search_cemeteries?input=${searchInputValue}&page=${currentPage}&municipality_slug=${municipalitySlug}`
      )
        .then((response) => response.json())
        .then((data) => {
          setCemeteriesList(data.cemeteries);
          setTotalPages(data.total_pages);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  const Description = () => {
    return (
      <span className='search-graves_none-text'>
        <FormattedMessage id='it_is' />{' '}
        <span>
          <FormattedMessage
            id='find_grave_count'
            values={{ count: cemeteriesCount }}
          />
        </span>{' '}
        <FormattedMessage id='cemetery_description' />
      </span>
    );
  };

  return (
    <div className='municipality-index'>
      <Header
        title={<FormattedMessage id='find_cemetery' />}
        description={<Description />}
        setSearchInputValue={setSearchInputValue}
        placeholder={intl.formatMessage({ id: 'search_cemetery' })}
      />
      {cemeteriesCount !== 0 && (
        <Paginator
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          totalPages={totalPages}
        />
      )}
      {!loading ? (
        <Content
          searchInputValue={searchInputValue}
          list={cemeteriesList}
          url={`/finn-en-grav/${municipalitySlug}`}
          logo={AboutCemetery}
        />
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default Index;
