import React, { useEffect, useState, useRef } from 'react';
import styles from './SelectedVideo.module.css';
import { useSelector, useDispatch } from 'react-redux';
import DeleteIcon from '../../../../../img/DeleteIcon.svg';
import { addCommentOnToggle } from '../../../../../redux/actions';
import ReactPlayer from 'react-player';

const SelectedVideo = ({ video }) => {
  const [title, setTitle] = useState('');
  const dispatch = useDispatch();
  const currentComment = useSelector((state) => state.grave.commentOnToggle);

  useEffect(() => {
    if (video.title) {
      setTitle(video.title);
    }
  }, [video]);

  const handleRemoveVideo = () => {
    dispatch(
      addCommentOnToggle({
        ...currentComment,
        video: null,
      })
    );
  };

  const handelOnTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleAddTitle = () => {
    dispatch(
      addCommentOnToggle({
        ...currentComment,
        video: {
          ...video,
          title: title,
        },
      })
    );
  };

  const handleKeyDown = (e) => {
    const keyCode = e.keyCode || e.which;
    if (keyCode === 13) {
      e.preventDefault();
      handleAddTitle();
    }
  };

  return (
    <React.Fragment>
      <div className={styles['input-element']}>
        <div className={styles['title']}>
          Videotittel <span>{`(valgfritt)`}</span>
        </div>
        <div className={styles['input-wrapper']}>
          <input
            type='text'
            value={title}
            placeholder='Skriv inn videotittel'
            onChange={handelOnTitleChange}
            onBlur={handleAddTitle}
            onKeyDown={(e) => handleKeyDown(e)}
          />
        </div>
      </div>
      {video.video && (
        <div className={styles['video-container']}>
          <video controls key={video.video}>
            <source src={video.video} />
          </video>
          <button onClick={() => handleRemoveVideo()}>
            <img src={DeleteIcon} alt='Delete' />
          </button>
        </div>
      )}
    </React.Fragment>
  );
};

export default SelectedVideo;
