import React from 'react';
import styles from './AddNewImages.module.css';

const AddNewImage = (props) => {
  return (
    <div
      style={{
        position: 'sticky',
        left: '0',
        background: '#fff',
        borderRadius: '0px 10px 18px 0px',
        zIndex: 1,
      }}
    >
      <div
        className={styles['add-new-image']}
        onClick={() => props.handleOpenModal()}
      >
        <span>{props.title}</span>
      </div>
    </div>
  );
};

export default AddNewImage;
