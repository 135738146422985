import React from 'react';
import './Terms_Conditions.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { setAuthFormRedux, setStepRegistration } from '../../../redux/actions';

const TermsConditions = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const setAuthFormData = (data) => dispatch(setAuthFormRedux(data));
  const isAuth = useSelector((state) => state.auth.isAuthorized);
  const userSlug = localStorage.getItem('user-slug');

  const OnAccept = () => {
    setAuthFormData({ checkbox: true });
    dispatch(setStepRegistration(0));
    if (location.key) {
      navigate(-1);
    } else if (isAuth && userSlug) {
      navigate(`/member/${userSlug}`);
    } else {
      navigate('/');
    }
  };

  const OnReject = () => {
    setAuthFormData({ checkbox: false });
    dispatch(setStepRegistration(0));
    if (location.key) {
      navigate(-1);
    } else if (isAuth && userSlug) {
      navigate(`/member/${userSlug}`);
    } else {
      navigate('/');
    }
  };

  return (
    <div className='terms_conditions'>
      <header className='header_terms_condiyions'>Vilkår & betingelser</header>
      <header className='header_terms_condiyions subheader_terms_condiyions'>
        Generelle brukervilkår og personvern for nettgrav.no
      </header>
      <div>
        <span className='listHeader_terms_condiyions'>1. Generelt</span>
        <label>
          Nettgrav.no er en sosial portal for ivaretakelse av det indre livet
          vårt. Portalen starter arbeidet med fornyelse av den fysiske graven
          gjennom et skybasert gravsted som tilrettelegger for at pårørende og
          andre skal få mulighet til å møte behovene døden etterlater seg på
          Nettgrav med mere.I tillegg til muligheten for å opprette og besøke en
          Nettgrav, tilrettelegges det for at medlemmer kan lagre bilder i
          Minnealbum, minner på Minnetavlen, verdier i Verdiarkivet og
          livshendeler i Livsarkivet, og andre tjenester kommer til knyttet til
          ivaretakelse av seg selv, gravlagte og andre relasjoner. For enkelte
          deler av Produktet gjelder i tillegg særskilte vilkår som fremgår av
          Nettstedet med hensyn til blant annet priser, betalingsvilkår og
          betalingsmetoder, avtaleperiode og annet som Nettgrav AS tilbyr. Ved
          avvik mellom slike særskilte vilkår og disse generelle brukervilkårene
          vil de særskilte vilkårene gjelde foran de generelle brukervilkårene.
        </label>
        <span className='listHeader_terms_condiyions'>
          2. REGISTRERING, BRUKERNAVN OG PASSORD
        </span>
        <label>
          Fremgangsmåten for å få tilgang til Produktet varierer etter som du er
          fester for den fysiske graven eller besøkende. Registrering av
          brukerkonto kan enten skje på Nettstedet eller via andre tjenester og
          sosiale medier i den grad Nettgrav tilbyr det. Dersom du registrerer
          deg via andre tjenester eller sosiale medier gir du samtykke til at
          Nettgrav kan innhente brukerinformasjon for å sette opp brukerkontoen.
          Du er selv ansvarlig for at alle opplysninger som oppgis ved
          registrering er korrekte. Ditt passord er personlig og kan ikke brukes
          av andre. Dersom du oppdager uautorisert bruk av din konto må du
          umiddelbart ta kontakt med Nettgrav AS og endre ditt passord. For å
          ivareta sikkerheten ved Produktet har Nettgrav AS rett til å kreve at
          du når som helst endrer ditt passord.
        </label>
        <span className='listHeader_terms_condiyions'>
          3. BRUK AV TJENESTENE M.M
        </span>
        <label>
          Du er selv ansvarlig for din bruk av Produktet, og skal kun benyttes
          til det formål det er ment for. Du forplikter deg til å holde Nettgrav
          AS skadesløs for følger av lovovertredelser og for ethvert krav som
          tredjemann måtte rette mot Nettgrav AS som følge av din bruk av
          Produktet og Tjenestene som tilbys.
        </label>
        <span className='listHeader_terms_condiyions'>
          4. PRISER OG BETALING
        </span>
        <label>Priser for Produktet fremgår av Nettstedet.</label>
        <span className='listHeader_terms_condiyions'>
          5. ANSVARSFRASKRIVELSE
        </span>
        <label>
          Med mindre annet klart fremgår er Nettgrav AS ikke ansvarlig for
          informasjon eller innhold plassert på eller sendt via våre Tjenester.
          Nettgrav AS har ikke kontroll over andre nettsider som det lenkes til,
          og har heller ikke ansvar for innholdet som finnes på slike nettsider.
          Innenfor det som er tillatt etter gjeldende rett fraskriver Nettgrav
          AS seg ethvert ansvar for skade eller tap som følge av dine handlinger
          eller unnlatelser i forbindelse med Tjenestene.
        </label>
        <span className='listHeader_terms_condiyions'>6. ENDRINGER</span>
        <label>
          Nettgrav AS har rett til å endre innholdet i og omfanget av Produktets
          Tjenester. Nettgrav AS har rett til å endre priser og andre vilkår,
          herunder vilkårene for Tjenestene. Når endringene er av en viss
          betydning og hvis til ugunst for deg, skal Nettgrav AS varsle deg via
          brev, SMS eller e-post på den adresse du har registrert, og senest 30
          dager før endringen trer i kraft. Om du ikke ønsker å godta slike nye
          vilkår kan du som alltid si opp ditt medlemskap når du selv ønsker
          det.
        </label>
        <span className='listHeader_terms_condiyions'>
          7. VARIGHET OG OPPSIGELSE
        </span>
        <label>
          Du kan når som helst slette din brukerkonto og eventuelle abonnement.
          Send e-post til kontakt@nettgrav.no hvis du har spørsmål eller trenger
          hjelp.
        </label>
        <span className='listHeader_terms_condiyions'>8. REKLAMASJON</span>
        <label>
          For å benytte deg av reklamasjon må du meddele Nettgrav AS via e-post,
          telefon, brev eller gjennom Nettstedet innen 5 dager fra
          leveringstidspunktet. Blomster og planter regnes som ferskvare og er
          unnlatt angrefristloven.
        </label>
        <span className='listHeader_terms_condiyions'>9. PERSONVERN </span>
        <div className='order'>
          <span className='nested_terms_condiyions'>9.1 Generelt</span>
          Ditt personvern er viktig, og i tråd med lover og regler vil vi derfor
          informere deg om følgende: <br />
          Nettgrav AS, Damstredet 3, 0177 Oslo (org.nr. 912 857 336) er
          behandlingsansvarlig for personopplysninger som behandles i
          forbindelse med levering av Produkt og Tjenester. Nettgrav AS
          behandler personopplysninger i henhold den norske
          personopplysningsloven.
          <br />
          Ved å registrere en brukerkonto samtykker du til at Nettgrav AS
          behandler personopplysninger i henhold til disse brukervilkårene og
          personopplysningspolicyen
          <span className='nested_terms_condiyions'>9.2 Formål</span>
          Nettgrav AS behandler personopplysninger for å kunne tilby Tjenestene,
          for å analysere hvordan mennesker benytter Produktet og Tjenestene,
          administrasjon av kundeforhold, for å kommunisere med kontoinnehavere,
          og for å kunne oppfylle våre forpliktelser i henhold til lov.
          <span className='nested_terms_condiyions'>
            9.3 Hvilke personopplysninger behandles?
          </span>
          Personopplysningene som vi behandler er den informasjonen som oppgis
          av mennesker i forbindelse med registrering av konto, samt informasjon
          som ellers oppgis av mennesker ved bruk av Tjenestene. Dette omfatter
          opplysninger som navn, adresse, telefonnummer, e-postadresse og
          betalingsopplysninger. I tillegg behandles relevante opplysninger om
          avdøde og minner knyttet til graven.
          <span className='nested_terms_condiyions'>
            9.4 Utlevering av opplysninger
          </span>
          Nettgrav AS kan utlevere personopplysninger til foretak som utfører
          tjenester for Nettgrav AS, slik at disse foretakene skal kunne utføre
          de tjenester som Nettgrav AS etterspør. Dette gjelder blant annet
          levering av infrastruktur og IT-tjenester og levering av
          kundetjenester. Vi vil ikke utlevere personopplysninger til andre
          tredjeparter eller benytte dine data for trening av KI.
          <span className='nested_terms_condiyions'>
            9.5 Retting, sletting og innsyn
          </span>
          Du har rett til å få innsyn i hva slags personopplysninger Nettgrav AS
          behandler om deg i tråd med personopplysningslovens bestemmelser.
          Videre har du rett til å kreve retting av personopplysninger som er
          uriktige, ufullstendige eller som det ikke er anledning til å
          behandle. Hvor det foreligger grunnlag for det, kan du også kreve at
          opplysninger slettes.
          <span className='nested_terms_condiyions'>
            9.6 Bruk av informasjonskapsler
          </span>
          Vi bruker informasjonskapsler (såkalte cookies) på Nettsiden, som er
          små tekstfiler som lagres på din datamaskin. Informasjonskapslene gjør
          at dine valg og ditt brukermønster kan bli lagret mens du er inne på
          Nettsiden. Vi bruker disse opplysningene til å tilpasse Nettsiden, og
          for å gjøre Tjenestene så brukervennlige som mulig. I tillegg måler vi
          trafikken på Nettsiden.
          <br />
          <br />
          De fleste moderne nettlesere aksepterer informasjonskapsler
          automatisk, men du kan selv stenge av for informasjonskapsler dersom
          du ønsker det. Vi bruker ikke cookies til å personalisere opplevelsen.
          Alle opplever Produktet likt.
          <br />
          <br />
          For å analysere informasjon bruker vi verktøyet Google Analytics.
          Dette verktøyet registrerer din IPadresse ved hjelp av
          informasjonskapsler, og gir oss informasjon om dine bevegelser på
          nett. Statistikken vi mottar gir oss blant annet svar på hvor mange
          som besøker ulike sider, hvor lenge brukeren er på hver side, hvilke
          nettsteder våre brukere kommer fra, og informasjon om netthandel.
          Denne informasjonen lagres på Googles servere i USA, og mottatte
          opplysninger er underlagt Googles personvernpolicy.
          <br />
          <br />
          Vi bruker Hotjar for å bedre forstå våre brukeres behov og for å
          optimalisere denne tjenesten og opplevelsen. Hotjar er en
          teknologitjeneste som hjelper oss med å forstå våre brukeres
          opplevelse bedre (f.eks. hvor mye tid de bruker på hvilke sider,
          hvilke lenker de velger å klikke på, hva brukerne liker og ikke liker
          osv.), og dette gjør oss i stand til å bygge og opprettholde vår
          tjeneste med tilbakemeldinger fra brukerne. Hotjar bruker
          informasjonskapsler og andre teknologier for å samle inn data om våre
          brukeres atferd og deres enheter. Dette inkluderer enhetens IP-adresse
          (behandlet under økten din og lagret i en avidentifisert form),
          skjermstørrelse, enhetstype (unike enhetsidentifikatorer),
          nettleserinformasjon, geografisk plassering (kun land), og foretrukket
          språk som brukes for å vise vårt nettsted. Hotjar lagrer denne
          informasjonen på våre vegne i en pseudonymisert brukerprofil. Hotjar
          har kontraktsfestet forbud mot å selge noen av dataene samlet inn på
          våre vegne.
        </div>
        <span className='listHeader_terms_condiyions'>10. Tvister </span>
        Disse brukervilkår skal reguleres og tolkes i samsvar med gjeldende
        norsk rett. Tvister skal være underlagt norske domstolers jurisdiksjon.
        Oslo tingrett avtales som verneting.
        <span className='listHeader_terms_condiyions'>
          11. Kontaktinformasjon
        </span>
        Ved spørsmål knyttet til Tjenestene og Produktet kan du ta kontakt med
        Nettgrav AS på kontakt@nettgrav.no, +4745007009, eller per post:
        Damstredet 3, 0151, OSLO.
      </div>
      <div className='button_container'>
        <button className='button_terms_condiyions' onClick={OnAccept}>
          Aksepter
        </button>
        <button className='button_terms_condiyions' onClick={OnReject}>
          Avvis
        </button>
      </div>
    </div>
  );
};

export default TermsConditions;
