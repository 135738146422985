import './UserProfileHeader.css';
import { Dialog } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Cropper from 'react-cropper';
import BlackCameraIcon from '../../../img/black_camera.png';
import React, { useState, useRef, useEffect } from 'react';
import loadingSpinner from '../../../service/Loading_animation.gif';
import { makeFileFromURL } from '../../../service/makeFileFromBlob';
import { request } from '../../../service/request';
import { useDispatch } from 'react-redux';
import { setInformationAboutUser } from '../../../redux/actions';
import showUserNotification from '../../../components/UserNotification/showUserNotification';
import noGender from '../../../img/SexTypeGrey.svg';
import { fetch_request } from '../../../service/request';
import SetUserConnectionModal from './SetUserConnectionModal';
import DefaultBackground from '../../../img/DefaultMoon.png';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import UploadPhoto from '../../../img/UploadPhoto1.svg';
import DeletePhoto from '../../../img/BinIcon.svg';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';
import BoyAvatar from '../../../img/BoyAvatar.svg';
import GirlAvatar from '../../../img/GirlsAvatar.svg';

const useStyles = makeStyles(( ) => ({
  typography: {
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  root: {
    '& .MuiDialog-paperWidthSm': {
      width: '900px !important',
    },
  },
}));

const UserProfileHeader = (props) => {
  const classes = useStyles();
  const user = props.user;
  const dateObject = user.date_of_birth && new Date(user.date_of_birth);
  const dateOfBirth = dateObject
    ? `${dateObject.toLocaleString('en-US', {
        month: 'long',
      })} ${dateObject.toLocaleString('en-US', {
        day: '2-digit',
      })}, ${dateObject.getFullYear()}`
    : 'Fødselsdato';
  const [openCropper, setOpenCropper] = useState(false);
  const [newImage, setNewImage] = useState(null);
  const [newCoverPhoto, setNewCoverPhoto] = useState(null);
  const [photoType, setPhotoType] = useState('');
  const [tempImage, setTempImage] = useState('');
  const [tempCoverPhoto, setTempCoverPhoto] = useState(null);
  const [crop, setCrop] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isCoverPhotoLoading, setIsCoverPhotoLoading] = useState(false);
  const isAuth = useSelector((state) => state.auth.isAuthorized);

  const handleCloseCropper = () => setOpenCropper(false);
  const inputFileProfilePhoto = useRef(null);
  const inputFileCoverPhoto = useRef(null);
  const [relationList, setRelationList] = useState([]);
  const [openSetUserConnectionModal, setOpenUserConnectionModal] =
    useState(false);
  const handleCloseUserConnectionModal = () =>
    setOpenUserConnectionModal(false);
  const canSendRequest = user.can_send_request;
  const isPendingApproval = user.is_pending_approval;
  const [requestAlreadyInQueue, setRequestAlreadyInQueue] = useState(false);
  const handleRequestAlreadyInQueue = () => {
    setRequestAlreadyInQueue(true);
  };

  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    fetch_request('/api/relations', requestOptions)
      .then((response) => response.json())
      .then((relations) => setRelationList(relations))
      .catch((err) => console.log(err));
    setRequestAlreadyInQueue(false);
    setTempCoverPhoto(null);
    setTempImage('');
  }, [user]);

  const selectTheImage = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.readAsDataURL(file);
      reader.addEventListener('load', async () => {
        if (file.size / (1024 * 1024) <= 20) {
          if (photoType === 'coverPhoto') {
            setNewCoverPhoto({ file, url: reader.result });
            setOpenCropper(true);
            setAnchorEl(false);
          } else {
            setNewImage({ file, url: reader.result });
            setOpenCropper(true);
            setAnchorEl(false);
          }
        } else {
          showUserNotification(
            'Velg en fil som ikke er større enn 20MB',
            'warning'
          );
        }
      });
    }
  };

  const saveCroppedImage = async () => {
    setLoading(true);
    if (crop && newImage) {
      const croppedImage = await makeFileFromURL(
        crop.getCroppedCanvas().toDataURL(),
        newImage.file?.name
      );
      try {
        const formdata = new FormData();
        formdata.append('user[avatar]', croppedImage, newImage.file?.name);
        const headers = {
          'Content-Type': 'multipart/form-data',
        };
        const response = await request(
          `/users/${user.slug}`,
          formdata,
          'put',
          headers
        );
        if (response.status >= 200 && response.status < 300) {
          try {
            const newUserInfo = await request(`/users/${user.slug}`);
            dispatch(setInformationAboutUser(newUserInfo.data));
            setTempImage(newUserInfo.data.user.avatar);
            showUserNotification('Profilbildet er oppdatert', 'success');
            handleCloseCropper();
            setAnchorEl(false);
          } catch (err) {
            console.log(err);
          }
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        showUserNotification(err.message, 'warning');
      }
    }
  };

  const saveCoverPhoto = async () => {
    setIsCoverPhotoLoading(true);
    if (crop && newCoverPhoto) {
      const croppedImage = await makeFileFromURL(
        crop.getCroppedCanvas().toDataURL(),
        newCoverPhoto.file?.name
      );
      const headers = {
        'Content-Type': 'multipart/form-data',
      };

      try {
        const formdata = new FormData();
        formdata.append(
          'user[cover_photo]',
          croppedImage,
          newCoverPhoto.file?.name
        );
        setAnchorEl(false);
        const response = await request(
          `/users/${user.slug}`,
          formdata,
          'put',
          headers
        );
        if (response.status >= 200 && response.status < 300) {
          try {
            const newUserInfo = await request(`/users/${user.slug}`);
            dispatch(setInformationAboutUser(newUserInfo.data));
            setTempCoverPhoto(newUserInfo.data.user.cover_photo);
            showUserNotification(
              'Ditt oversiktsbildet er oppdatert',
              'success'
            );
            setAnchorEl(false);
            handleCloseCropper();
          } catch (err) {
            console.log(err);
          }
        }
        setIsCoverPhotoLoading(false);
      } catch (err) {
        setIsCoverPhotoLoading(false);
        showUserNotification(err.message, 'warning');
      }
    }
  };

  const handleProfilePhotoSelection = () => {
    inputFileProfilePhoto.current.click();
    setPhotoType('profilePhoto');
  };

  const handleCoverPhotoSelection = () => {
    inputFileCoverPhoto.current.click();
    setPhotoType('coverPhoto');
  };

  const handleCoverPhotoDeletion = async () => {
    try {
      const formdata = new FormData();
      formdata.append('user[cover_photo]', null);
      setAnchorEl(false);
      const response = await request(`/users/${user.slug}`, formdata, 'put');
      if (response.status >= 200 && response.status < 300) {
        try {
          const newUserInfo = await request(`/users/${user.slug}`);
          dispatch(setInformationAboutUser(newUserInfo.data));
          setTempCoverPhoto(null);
          showUserNotification('Ditt oversiktsbildet er fjernet', 'success');
          handleCloseCropper();
        } catch (err) {
          console.log(err);
        }
      }
    } catch (err) {
      showUserNotification(err.message, 'warning');
    }
  };

  const isUserCurrentUser = () => {
    return (
      user.slug === localStorage.getItem('user-slug') ||
      user.slug === sessionStorage.getItem('user-slug')
    );
  };

  const handleNewConnection = () => {
    setOpenUserConnectionModal(true);
  };

  return (
    <>
      <Dialog open={openCropper}>
        <div className='cropper-wrapper'>
          <Cropper
            style={{
              height: '450px',
              width: '100%',
              minHeight: 300,
              minWidth: 300,
            }}
            src={
              photoType === 'coverPhoto' ? newCoverPhoto?.url : newImage?.url
            }
            initialAspectRatio={1}
            viewMode={1}
            guides={true}
            zoomOnWheel={false}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive={true}
            autoCropArea={1}
            aspectRatio={photoType === 'coverPhoto' ? 16 / 9 : 235 / 260}
            checkOrientation={false}
            onInitialized={(instance) => {
              setCrop(instance);
            }}
          />
          {(isLoading || isCoverPhotoLoading) && (
            <div className='image-loader'>Sender....</div>
          )}
        </div>
        <div className='settings-page_header_cropper-buttons'>
          <button
            className='settings-page_header_cropper-buttons_button cancel-b'
            onClick={handleCloseCropper}
          >
            Avbryt
          </button>
          {!isLoading && photoType === 'profilePhoto' && (
            <button
              className='settings-page_header_cropper-buttons_button save-b'
              onClick={saveCroppedImage}
            >
              Lagre
            </button>
          )}
          {!isCoverPhotoLoading && photoType === 'coverPhoto' && (
            <button
              className='settings-page_header_cropper-buttons_button save-b'
              onClick={saveCoverPhoto}
            >
              Lagre
            </button>
          )}
        </div>
      </Dialog>
      <div
        className='member-background-image-container'
        style={{
          backgroundImage: tempCoverPhoto
            ? `url(${tempCoverPhoto})`
            : props.user.cover_photo === undefined || null
            ? `url(${loadingSpinner})`
            : props.user.cover_photo
            ? `url(${props.user.cover_photo})`
            : `url(${DefaultBackground})`,
        }}
      >
        <div
          className='member-header-display-image-container'
          onClick={handleProfilePhotoSelection}
        >
          <img
            loading='lazy'
            src={
              tempImage
                ? tempImage
                : isEmpty(props.user?.avatar)
                ? props.user?.gender === 'mann'
                  ? BoyAvatar
                  : GirlAvatar
                : props.user.avatar
                ? props.user.avatar
                : noGender
            }
            alt='plceholder'
            className={
              tempImage
                ? 'user-avatar'
                : isEmpty(props.user?.avatar)
                ? 'user-gender-avatar-header'
                : props.user.avatar
                ? 'user-avatar'
                : 'no-gender'
            }
            key={props.user.avatar}
          />
          {isUserCurrentUser() && (
            <div
              className='member-photo-selector'
              onClick={handleProfilePhotoSelection}
            >
              <input
                style={{ display: 'none' }}
                ref={inputFileProfilePhoto}
                type='file'
                accept='image/jpeg, image/jpg, image/png'
                className='test'
                onChange={selectTheImage}
              />
              <img
                src={BlackCameraIcon}
                alt='black-camera'
                style={{
                  height: '23px',
                  width: '30px',
                }}
              />
            </div>
          )}
        </div>
      </div>
      <div className='member-header-info_container'>
        <div className='member-header-info'>
          <div className='user-information'>
            <span
              className={user.full_name ? 'user-name' : 'animated-background'}
            >
              {user.full_name || ''}
            </span>
            <span className='user-desc'>
              {user.occupation ? user.occupation : 'Yrke'}
            </span>
            <span className='user-date-of-birth'>{dateOfBirth}</span>
          </div>
          <div className='actions-buttons-container'>
            {!isUserCurrentUser() &&
              !requestAlreadyInQueue &&
              !isPendingApproval &&
              canSendRequest &&
              isAuth && (
                <button
                  className='make-connection_button'
                  onClick={handleNewConnection}
                >
                  Sett relasjon
                </button>
              )}
            {(requestAlreadyInQueue || isPendingApproval) && (
              <span className='user-confirmation-span'>
                Forespørsel er sendt
              </span>
            )}

            {isUserCurrentUser() && (
              <>
                <button
                  className='edit-coverphoto_button'
                  onClick={handleClick}
                >
                  Oversiktsbildet
                </button>

                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Typography
                    className={classes.typography}
                    variant='subtitle1'
                  >
                    <div
                      className='member-cover-photo-selector'
                      onClick={handleCoverPhotoSelection}
                    >
                      <input
                        style={{ display: 'none' }}
                        ref={inputFileCoverPhoto}
                        type='file'
                        accept='image/jpeg, image/jpg, image/png'
                        className='test'
                        onChange={selectTheImage}
                      />
                      <img
                        src={UploadPhoto}
                        className='upload-coverphoto-icon'
                        alt=''
                      />
                      Last opp nytt oversiktsbildet
                    </div>
                  </Typography>
                  <Typography
                    className={classes.typography}
                    variant='subtitle1'
                  >
                    <div
                      className='member-cover-photo-selector'
                      onClick={handleCoverPhotoDeletion}
                    >
                      <img
                        src={DeletePhoto}
                        className='delete-coverphoto-icon'
                        alt=''
                      />
                      Fjern oversiktsbildet
                    </div>
                  </Typography>
                </Popover>
              </>
            )}
          </div>
        </div>
      </div>

      <Dialog
        open={openSetUserConnectionModal}
        onClose={handleCloseUserConnectionModal}
        maxWidth={'90%'}
        //className="user-connection-dialog"
      >
        <SetUserConnectionModal
          slug={user.slug}
          relationsList={relationList}
          closeModal={handleCloseUserConnectionModal}
          setRequestAlreadyInQueue={handleRequestAlreadyInQueue}
          user={user}
        />
      </Dialog>
    </>
  );
};

export default UserProfileHeader;
