import React, { useState, useEffect } from 'react';
import styles from './GraveClaimPage.module.css';
import BackIcon from '../../../../img/BackIcon.svg';
import BackButton from '../../../../components/BackButton/BackButton';
import SetRelation from './SetRelation/SetRelation';
import { request } from '../../../../service/request';
import { useDispatch, useSelector } from 'react-redux';
import SelectDocument from './SelectDocument/SelectDocument';
import UploadDocumentFile from './UploadDocumentFile/UploadDocumentFile';
import TermsAndCondition from './TermsAndCondition/TermsAndCondition';
import { updateCurrentUserInClaimQueue } from '../../../../redux/actions';
import showUserNotification from '../../../../components/UserNotification/showUserNotification';
import CircularProgress from '@mui/material/CircularProgress';
import DocumentSubmission from './DocumentSubmission/DocumentSubmission';
import { useParams } from 'react-router-dom';
import spinner from '../../../../service/Loading_animation.gif';
import PrimaryButton from '../../../../components/PrimaryButton';
import isEmpty from 'lodash.isempty';
import Loader from '../../../../components/Loader/Loader';

const GraveClaimPage = () => {
  const dispatch = useDispatch();
  const [directionsList, setDirectionsList] = useState([]);
  const [relationList, setRelationList] = useState([]);
  const [grave, setGrave] = useState({});
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [selectedFile, setSelectedFile] = useState({});
  const [chosenRelation, setChosenRelation] = useState({});
  const [reciprocalRelation, setReciprocalRelation] = useState({});
  const [user, setUser] = useState();
  const [isChecked, setIsChecked] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isUploaded, setIsUploaded] = useState(false);
  const userSlug =
    localStorage.getItem('user-slug') || sessionStorage.getItem('user-slug');
  let { slug } = useParams();

  const handleClaimGrave = async () => {
    if (
      chosenRelation.id &&
      reciprocalRelation.id &&
      selectedFile &&
      isChecked
    ) {
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      try {
        if (selectedFile) {
          setUploading(true);
          const formData = new FormData();
          formData.append('death_certificate[grave_id]', grave.id);
          formData.append('death_certificate[image]', selectedFile.file);
          formData.append(
            'death_certificate[certificate_type]',
            selectedDocument
          );
          formData.append('relation_id', chosenRelation.id);
          formData.append('reciprocal_relation_id', reciprocalRelation.id);
          const response = await request(
            `/grave_claims`,
            formData,
            'post',
            headers
          );
          if (response.data) {
            setIsUploaded(true);
            setUploading(false);
          }
          // dispatch(updateCurrentUserInClaimQueue());
        }
      } catch (err) {
        showUserNotification('noe gikk galt, prøv igjen', 'error');
        console.log(err);
        setUploading(false);
      }
    } else {
      showUserNotification('Alle felt må fylles ut', 'warning');
    }
  };

  useEffect(() => {
    request('/relation_directions')
      .then((res) => {
        setDirectionsList(res.data?.list || []);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    request('/relations')
      .then((res) => setRelationList(res.data))
      .catch((err) => console.log(err));
  }, []);

  const fetchUser = async () => {
    if (userSlug)
      try {
        const res = await request(`/users/${userSlug}`);
        setUser(res.data.user);
      } catch (err) {
        console.log(err);
      }
  };

  useEffect(() => {
    fetchUser();
  }, [userSlug]);

  const fetchGrave = async () => {
    if (slug) {
      try {
        const res = await request(`/graves/${slug}`, null, 'get');
        setGrave(res.data);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    fetchGrave();
  }, [slug]);

  useEffect(() => {
    if (grave?.grave_users && grave.grave_users.length > 0 && !isEmpty(user)) {
      const graveUser = grave.grave_users.find((u) => u.user?.id === user.id);
      if (graveUser?.relation) {
        setChosenRelation(graveUser.relation);
      }
      if (graveUser?.reciprocal_relation) {
        setReciprocalRelation(graveUser.reciprocal_relation);
      }
    }
  }, [grave, relationList, user]);

  const handelSelectedDocument = (value) => {
    setSelectedDocument(value);
  };

  const handelSetDocumentFile = (file) => {
    setSelectedFile(file);
  };

  const handelReciprocalRelation = (value) => {
    setReciprocalRelation(value);
  };

  const handelRelation = (value) => {
    setChosenRelation(value);
  };

  return (
    <React.Fragment>
      {loading || uploading || isEmpty(user) || isEmpty(grave) ? (
        <Loader />
      ) : (
        <div className={styles['claim-page']}>
          <BackButton fallBackUrl={'/graves'} />
          {!isUploaded && !grave.current_user_in_claim_queue ? (
            <div className={styles['container']}>
              <div className={styles['wrapper']}>
                <div className={styles['heading']}>
                  Ta styring på {grave.name} sin grav
                </div>
                <div className={styles['sub-heading']}>
                  Ta styringen og gjør krav på eierskapet som vil tillate deg å
                  ivareta og berike graven.
                </div>
                <SetRelation
                  directionsList={directionsList}
                  relationList={relationList}
                  handelReciprocalRelation={handelReciprocalRelation}
                  handelRelation={handelRelation}
                  relation={chosenRelation}
                  reciprocalRelation={reciprocalRelation}
                />
                <SelectDocument
                  handelSelectedDocument={handelSelectedDocument}
                  selectedDocument={selectedDocument}
                />
                {selectedDocument && (
                  <React.Fragment>
                    <UploadDocumentFile
                      handelSetDocumentFile={handelSetDocumentFile}
                      selectedFile={selectedFile}
                    />
                    <TermsAndCondition
                      user={user}
                      isCheckedTerms={(value) => setIsChecked(value)}
                      isChecked={isChecked}
                    />
                  </React.Fragment>
                )}
                <PrimaryButton
                  children='SEND INN DOKUMENT'
                  onClick={handleClaimGrave}
                />
              </div>
            </div>
          ) : (
            <DocumentSubmission grave={grave} user={user} />
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default GraveClaimPage;
