import React from 'react';
import './MenuItemBlock.css';

const MenuItemBlockLast = ({image, title, description}) => {
    return(
        <div className='menu-item-block last'>
            <div className="menu-item-block_content">
                <div className="menu-item-block_content-row last">
                    <img loading='lazy' src={image} alt=''/>
                    <span>{title}</span>
                </div>
            </div>
        </div>
    )
}

export default MenuItemBlockLast;