import React from 'react';
import styles from './TitleRow.module.css';
import SubscriptionButton from '../SubscriptionButton';
import isEmpty from 'lodash.isempty';

const TitleRow = ({
  title,
  value,
  setOpenOptionsModal,
  action,
  messageKey,
  setActionKey,
}) => {
  return (
    <div className={styles['container']}>
      <div className={styles['detail']}>
        <div className={styles['title']}>{title}: </div>
        <div className={styles['value']}>{value}</div>
      </div>
      <div className={styles['action-button']}>
        {action && (
          <SubscriptionButton
            action={action}
            messageKey={messageKey}
            setOpenOptionsModal={setOpenOptionsModal}
            setActionKey={setActionKey}
          />
        )}
      </div>
    </div>
  );
};

export default TitleRow;
