import { applyMiddleware, legacy_createStore as createStore } from 'redux';
import { thunk } from 'redux-thunk';
import localStorageMiddleware from './localStorageMiddleware';
import rootReducer from './rootReducer';

// convert object to string and store in localStorage
export const saveToLocalStorage = async (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('persistentState', serializedState);
  } catch (e) {
    console.warn(e);
  }
};

// load string from localStorage and convert into an Object
// invalid output must be undefined
export const loadFromLocalStorage = async () => {
  try {
    const serializedState = localStorage.getItem('persistentState');
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    console.warn(e);
    return undefined;
  }
};

// create our store from our rootReducers and use loadFromLocalStorage
// to overwrite any values that we already have saved
const store = createStore(
  rootReducer,
  loadFromLocalStorage(),
  applyMiddleware(thunk, localStorageMiddleware)
);

// listen for store changes and use saveToLocalStorage to
// save them to localStorage
store.subscribe(() => saveToLocalStorage(store.getState()));

export default store;
