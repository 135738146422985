import React from 'react';
import './HomepageFindGraveSecond.css';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
const HomepageFindGraveSecond = () => {
  let { municipality, cemetery } = useParams();

  if (municipality || cemetery) {
    return null;
  }

  return (
    <div className='homepage-findgrave_second'>
      <div className='homepage-findgrave_second-content'>
        <div className='homepage-findgrave_second-content_header'>
          <span className='homepage-findgrave_second-content_header-main'>
            <FormattedMessage id='visit_grave' />{' '}
            <span>
              <FormattedMessage id='wish' />
            </span>
          </span>
          <span className='homepage-findgrave_second-content_header-submain'>
            <FormattedMessage id='second_section_description' />
          </span>
        </div>
        <div className='homepage-findgrave_second-content_main'>
          <div className='homepage-findgrave_second-content_main-row'>
            <div className='homepage-findgrave_second-content_main-row_block'>
              <div className='homepage-findgrave_second-content_main-row_block-header'>
                <div className='homepage-findgrave_second-content_main-row_block-header_orange' />
                <span>
                  <FormattedMessage id='memorial_plaque' />
                </span>
              </div>
              <span>
                <FormattedMessage id='memorial_plaque_description' />
              </span>
            </div>
            <div
              className='homepage-findgrave_second-content_main-row_block'
              style={{ maxWidth: '339px' }}
            >
              <div className='homepage-findgrave_second-content_main-row_block-header'>
                <div className='homepage-findgrave_second-content_main-row_block-header_grey' />
                <span>
                  <FormattedMessage id='archive' />
                </span>
              </div>
              <span>
                <FormattedMessage id='archive_description' />
              </span>
            </div>
            <div
              className='homepage-findgrave_second-content_main-row_block'
              style={{ maxWidth: '257px' }}
            >
              <div className='homepage-findgrave_second-content_main-row_block-header'>
                <div className='homepage-findgrave_second-content_main-row_block-header_orange' />
                <span>
                  <FormattedMessage id='memory_album' />
                </span>
              </div>
              <span>
                <FormattedMessage id='memory_album_description' />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomepageFindGraveSecond;
