const ENG = {
  auth_mobileNumber: 'Mobile Number',
  auth_password: 'Password',
  auth_passwordPlaceholder: 'Enter the password',
  auth_rememberMe: 'Keep me logged in on my trusted devices',
  auth_login: 'Log in',
  auth_get_newsletr: `I would like to receive information directly from Nettgrav
  regarding the service, our purposes and overall general information`,
  auth_notRegistered: `Don’t have an account? `,
  auth_alreadyRegistered: 'Already have an account? ',
  auth_login_here: 'Log in here',
  auth_register: 'Register',
  auth_forgotPassword: 'Forgot password?',
  auth_welcome: 'Welcome',
  auth_toNettgrav: 'to nettgrav.no',
  auth_enterPhoneNumber: 'Enter your phone number',
  auth_man: 'Man',
  auth_woman: 'Women',
  auth_other: 'Other',
  auth_acceptConditions: 'I accept to receive SMS from Nettgrav and I accept',
  auth_termsAndCondition: 'terms and conditions',
  auth_next: 'Next',
  auth_receivedSMS: 'You will now receive a message',
  auth_otpCode: 'Enter the OTP code',
  auth_received: 'Have you not received a one-time code?',
  auth_newOtp: 'You can send a new one-time code in 00:',
  auth_sendNew: 'Send new',
  auth_confirm: 'Confirm',
  auth_passwordSentTo: 'The password was sent to:',
  auth_yourName: 'Your name',
  auth_enterFullName: 'Enter full name',
  auth_enterPasswordAgain: 'Enter password again',
  auth_passwordMessage:
    'The password must consist of at least 7 characters and contain at least 3 of the 4 categories:',
  auth_capitalLetter: '⚙︎ Capital letter',
  auth_smallLetters: '⚙︎ Small letters',
  auth_digits: '⚙︎ Digits',
  auth_characters: '⚙︎ Non-alphanumeric characters',
  auth_signupInvitation: 'You were invited to the conscious subscription from',
  auth_resetPassword: 'Reset password',
  auth_resetPasswordSMS: 'A one-time code is sent to your mobile phone',
  auth_back: 'Back',
  auth_send: 'Send',
  auth_restOtp: 'Enter the one-time code sent to {code}{phoneNumber}',
  auth_code: 'Enter the code',
  auth_confirmPassword: 'Confirm new password',
  auth_newPassword: 'New password',
  auth_notSame: 'The passwords are not the same',
  auth_save: 'Save',
  settings_profile: 'Profile',
  settings_personalInformation: 'Personal information',
  settings_payments: 'Payments',
  settings_paymentMethod: 'Payment Method',
  settings_emptyPayments: 'There are currently no payments available',
  settings_subscription: 'Subscription',
  settings_members: 'Members',
  settings_giftInvitations: 'Gift invitations',
  settings_notifications: 'Notifications',
  settings_account_information: 'Account Management',
  settings_contact: 'Contact Nettgrav',
  settings_password: 'Password',
  settings_edit: 'Edit',
  settings_fullName: 'Full name',
  settings_gender: 'Gender',
  settings_occupation: 'Occupation',
  settings_birthday: 'Day of birth',
  settings_email: 'E-mail address',
  settings_telephoneNumber: 'Phone number',
  settings_address: 'Address',
  settings_language: 'Language',
  settings_currency: 'Currency',
  settings_timeZone: 'Time zone',
  settings_invalidEmail: 'Invalid email address',
  settings_cancel: 'Cancel',
  settings_currentPassword: 'Current password',
  settings_newPassword: 'Enter new password',
  settings_retype: 'Retype the password',
  confirm_password: 'Confirm password',
  settings_billingMethod: 'Payment method',
  settings_cancelSubscription: 'Cancel subscription',
  settings_cancel_mySubscription: 'Yes, cancel my subscription',
  settings_month: 'month',
  cancelSubscription_message: `You are about to cancel your subscriptions to the Nettgrav {name} plan. Note that by canceling your subscription, you will not have access to all the features associated with this plan. You will only be able to interact with Nettgrav with the free plan function.`,
  reactivateSubscription_message:
    'You are about to reactivate your subscription to the Nettgrav {name} plan. This will restore full access to all the features associated with this plan.',
  not_now: 'not now',
  reactivate: 'Yes, reactivate my subscription',
  settings_nextDue: 'Next payment due',
  settings_paymentMethods: 'Payment Methods',
  settings_nextBilling: 'Next automatic invoicing is',
  settings_mangeMethods: 'View and manage your payment method',
  settings_emptyMethods: 'There are no payment methods added yet',
  settings_vippsHeading: `Vipp's payment and agreements will be here soon`,
  settings_manageVipps: 'To view and manage your agreements',
  settings_cardEnd: 'ending in',
  settings_remove: 'Remove',
  settings_stop: 'stope',
  settings_vippsAgreement: 'Vipps agreement',
  view_manage_agreements: 'View and manage your agreement',
  empty_agreements: 'No Vipps agreements were found',
  settings_amount: '{amount}',
  settings_subscriptionHeading: 'Subscription management',
  settings_subscriptionStatus: 'pending',
  settings_freeSubscriptionHeading: 'Get started for free.',
  months: 'months',
  year: 'year',
  month: 'month',
  years: 'years',
  saved: 'Saved',
  saved_message: '{name} has been saved successfully',
  settings_upgrade: 'Upgrade plan',
  settings_subDescription: 'For a meaningful life',
  settings_for: 'for',
  settings_paymentInfo: 'Payment information',
  settings_cardNumber: 'Card number',
  settings_billingInfo: 'Billing information',
  settings_name: 'Name',
  settings_billingEmail: 'Email',
  settings_billingCountry: 'Country',
  settings_postCode: 'Postal code',
  settings_changePlan: 'Change plan',
  change_plan: 'change plan',
  change: 'change',
  change_members: 'change members',
  settings_noSubscription:
    'You do not have an active subscription. Go to the plan page to choose a new one plan.',
  settings_clickHere: 'click here',
  settings_memberHeading: 'Membership you participate in',
  settings_receivedMembership: '(Received membership)',
  settings_emptyMembership: 'You currently have no invitations',
  settings_subscribedPlan:
    'Your subscription has been successfully activated. You can view or manage your ',
  settings_updatePlan:
    'Your subscription has been successfully updated. You can view or manage your ',
  settings_downgradePlan:
    'Your subscription has been successfully downgraded. You can view or manage your ',
  settings_addedMember:
    'New members have been successfully added to your current plan. You can view or manage your ',
  payment_progress: 'Payment in progress. please wait',
  something_wrong: 'Something went wrong. please try again later',
  add_validNumber: 'Please enter a valid phone number',
  select_planMessage:
    'To continue, select a plan that best suits your needs. Our plans are designed to offer flexibility and great features. Make sure you choose the one that matches your requirements.',
  settings_memberPage: 'member page',
  settings_your: 'your.',
  settings_enable: 'plan enabled',
  free: 'Free',
  conscious: 'Conscious',
  consciousPlus: 'Conscious plus',
  settings_membershipOn: '- membership on',
  settings_modalSubheading: 'Greatest of all is love',
  settings_modalLink: 'Thank you for being here',
  settings_memberSubheading: 'Your Family plan.',
  settings_emptyMembers: 'You have no subscription members',
  settings_memberName: `{name}'s Family`,
  settings_memberLength: '{length} members',
  settings_administrator: 'Administrator',
  settings_showMembers: 'See members',
  settings_familyMembers: 'Family members',
  settings_you: 'You',
  settings_connect: 'connect',
  settings_setRelation: 'Set relationship',
  settings_selectedRelation: 'Select relationship',
  settings_giftHeading: `Be a good shepherd and show the way to those who come after you. Here you give
  membership, to those you think need it.`,
  settings_giftFreeSubscription: `You are now on the Free plan. Update your subscription for unlimited access to all Nettgrav's services and to invite more people
  members:`,
  settings_seeSubscriptions: 'See the subscriptions',
  settings_youHave: 'You have {count} ',
  settings_inviteAgain: 'invitations left.',
  settings_addMore: 'to add more memberships to your plan',
  settings_addMember: 'Add member',
  settings_invitedMember: 'Invited members',
  settings_emptyGiftMembers: 'No unanswered invitations',
  settings_inviteTo: 'Click here to invite to your family subscription',
  settings_pendingSubscription: 'Your subscription is currently {status}.',
  settings_notificationsSubheading: 'Set your notification preferences.',
  settings_preferenceText:
    'Receive notifications from Nettgrav and our partners via:',
  settings_genealText: 'Receive news and updates from nettgrav.no via:',
  settings_textMessage: 'Text messages',
  settings_general: 'General updates',
  delete: 'Delete',
  settings_goToAgreements: 'Click here',
  settings_shareLink: 'Copy and share your Nettgrav.no membership invitation',
  grave_invitation_shareLink: 'Copy and share your grave invitation link',
  settings_copyToClipboard: 'link copied to clipboard',
  discard_subheading: `Are you sure you want to discard the changes? This action cannot be undone.`,
  no: 'No',
  yes: 'Yes',
  invitation: 'Invitation',
  information: 'Information',
  interests: 'Interests',
  note: 'Note',
  relative: 'Relative',
  uploadingImages: 'uploading images',
  lifeEvent: 'Life event',
  Workplace: 'Workplace',
  delete_modalHeading: 'Confirm that you want to remove',
  picture: 'picture',
  paymentMethod: 'Payment method',
  vippsAgreement: 'Vipps agreement',
  settings_complete_subscription: 'Complete Subscription',
  pending_message:
    'Your subscription is incomplete. Complete your subscription to continue accessing conscious features.',
  pending_title: 'Your payment agreement is pending',
  stopped_title: 'Your payment agreement was stopped',
  stopped_message:
    'Your subscription is incomplete because the payment agreement was cancelled. Complete your subscription to continue accessing conscious features.',
  expired_title: 'Your payment agreement has expired',
  expired_message:
    'Your subscription is incomplete because the payment agreement has expired. Complete your subscription to continue accessing conscious features.',
  complete_setup: 'complete setup',
  cancelled_title: 'Your subscription is cancelled',
  cancelled_message:
    'Your subscription has been cancelled. You may reactivate your subscription before {period_end_date} to continue access conscious features. After this date, you subscription will be downgraded to free plan.',
  activeStopped_title: 'Your payment agreement has ended',
  activeStopped_message: `Your subscription’s payment method has expired. Please renew payment method to continue access conscious features. If the problem persists, the subscription will be downgraded to the free plan after the current billing cycle.`,
  renew_Agreement: 'Renew payment agreement',
  add_card: 'Add payment method',
  Reactivate: 'Reactivate subscription',
  active_title: 'Your subscription will be activated soon',
  active_message: `Your subscription is being processed and will be activated in a few minutes. Thank you for your patience and access to conscious services will be unlocked shortly`,
  activeExpired_title: 'Your payment agreement has expired',
  activeExpired_message: `Your subscription’s payment method has expired. Please renew or establish a new payment agreement to continue access conscious features.`,
  notValid_title: 'Payment method required',
  notValid_message: `It looks like your payment method is missing or no longer valid. Please update your payment details to continue uninterrupted service.`,
  consciousness: 'Leadership',
  about_us: 'The End Of Time Corporation',
  team: 'About End Of Time',
  careers: 'Work',
  features: 'Features',
  available_sustainable: 'Available and sustainable',
  maintenance_free_grave: 'Maintenance free',
  memory_album: 'Memory album',
  value_archive: 'Value archive',
  memorial_wall: 'Memory wall',
  life_archive: 'Life archive',
  anniversary_calendar: 'Anniversary calendar',
  Family_values: 'Family tree',
  resources: 'Resources',
  find_grave: 'Find a grave',
  blog: 'Blog',
  help_center: 'Help center',
  contact: 'Contact',
  contact_us: 'Send a message',
  business_inquiries: 'Business inquires',
  feedback: 'Wishes',
  basic_information: 'Basic information',
  interest: 'Interest',
  notes: 'Notes',
  passion: 'Passion',
  relation: 'Relationship',
  life_event: 'Life event',
  subscription_invitation: 'Subscription invitation',
  find_grave_norway: 'Find a grave in Norway',
  search_grave: 'Search by name',
  or: 'or',
  create_netgrave: 'create Nettgrav',
  netgrave: 'Nettgrav',
  visit_grave: 'Visit the grave when you yourself',
  wish: 'wish for it',
  second_section_description:
    'Nettgrav is a grave that strengthens the love of children, parents, grandchildren and others affected.',
  memorial_plaque: 'The memorial plaque',
  memorial_plaque_description:
    'Follow your gut feeling and share what reminds you of the buried, when you want to.',
  archive: 'The value archive',
  archive_description: `Choose values ​​and write how they shaped you. Leave buried
inspire and guide future generations.`,
  memory_album_description:
    'Save and share with Memory Album. Add photos that show the life and history of the person buried, and let the photos preserve the person buried.',
  find_municipality: 'Find a grave in these municipalities',
  search_municipality: 'Search for municipality',
  it_is: 'It is',
  find_grave_count: '{count}',
  municipality_description:
    'available municipalities. You can search for the municipality to find the grave more easily and look after the grave net.',
  no_results: 'No results found for',
  find_cemetery: 'Find a grave in these cemetery',
  search_cemetery: 'Search for cemetery',
  cemetery_description:
    'available cemetery. You can search for a cemetery to find the grave more easily and link your relationship to the buried.',
  grave_description:
    'available graves. You can visit the grave by searching for names and look after the buried online.',
  goto_grave: 'view grave',
  payment_due_message:
    'Your subscription is active. Your payment is being processed. Vipps will handle the charge.',
  payment_due_title: 'Your subscription is active',
  find_grave_image_title:
    'The grave gives a different perspective on our life. On your',
  find_grave_text:
    'do you have a separate wall where your contacts can send you questions.',
  life_page: 'Life page',
  the_life_page: 'The Life page',
  first_description:
    'On your life page, we cultivate the life that will live on. There will be major updates here in the future.',
  second_description:
    'All graves and relationships are linked to your life side.',
  update: 'Updates',
  third_description:
    'You will receive notifications of upcoming events anniversaries, new relationships related to graves you are connected to, or new memories added to the grave.',
  find_grave_last_text:
    'Having an accessible grave to visit is a principle we believe everyone has the right to. Proven offers a simple solution where you can have as many dig as needed in the same location, for a fixed price, wherever you live. This makes it easier to keep track of and control one financially, regardless of which municipality you live in, or where many graves you have in your life',
  plans_page_heading: 'Everything you need in one plan',
  plans_page_subheading: `Preserve and save memories. Read, respond, and see others' reflections. Be close when you want. All at the grave`,
  per_user: 'per member',
  current_members: 'Current members',
  added_members: 'Number of members to be added',
  removed_members: 'Number of members to be removed',
  view_current_members: 'View current members',
  total_members: 'Members after the change',
  plan_price: 'Plan price',
  increment_text:
    'Choose the number of memberships you want for you and your family',
  free_description:
    'Find and visit tombs with five quests that renew every day.',
  conscious_description: `Love is strengthened with the grave's Life Archive, Memorial Board, Value Archive and Memorial Album. Price: NOK 270 per year`,
  consciousPlus_description: `Access as Conscious, with extra emphasis on the ethics of the grave. Price: NOK 500 per year`,
  total_user_count:
    ' For {subscriptionTotalCount} family members, the total payment is:',
  per_month: 'per month',
  current_plan: 'Current plan',
  got_started: 'Got started',
  free_feature_heading: 'All features included in the free plan',
  conscious_feature_heading:
    'All features included in the free plan, plus additional ones',
  conscious_plus_feature_heading:
    'All features included in the conscious plan, plus additional ones',
  // Adjust the feature texts according to the requirements.//
  feature_memory_album: 'Memory album',
  feature_purposes: 'Purposes',
  feature_end_of_time: 'End Of Time Coin',
  feature_family_tree: 'Family tree',
  feature_testament: 'Testament',
  feature_groups: 'Groups (Communities)',
  feature_Shop: 'Shop',
  feature_Sparks: 'Sparks',
  feature_life_page: 'Life page',
  feature_search_grave: 'Search for grave',
  feature_create_grave: 'Create new online graves',
  question_answer: 'Questions and answers',
  feature_life_archive: 'Life archive',
  feature_anniversary_calendar: 'Anniversary calendar',
  feature_memory_wall: 'Memory wall',
  feature_value_archive: 'Value Archive',
  benefits_1_title: 'Closeness',
  benefits_1_description:
    'Experience, receive, and show closeness to your loved ones whenever you feel it, no matter where you are.',
  benefits_2_title: 'Lasting Grave Site',
  benefits_2_description: 'Nettgrav.no is here until the end of time.',
  benefits_3_title: 'Awareness',
  benefits_3_description:
    'See life from a new perspective with Nettgrav.no, and do more of the good.',
  benefits_4_title: 'Control over the Grave',
  benefits_4_description:
    'See, experience, and build the grave online. The grave owner always has full control and overview.',
  benefits_title: 'To be a little ahead',
  faqs_1_question: 'What payment methods do you accept?',
  faqs_1_answer: 'We accept credit/debit cards (Visa, Mastercard) and Vipps',
  faqs_2_question: 'Are there any additional fees I should be aware of?',
  faqs_2_answer:
    'There are no hidden fees. Here you can gather all graves and burials without extra fees',
  faqs_3_question: 'How do I update my payment details?',
  faqs_3_answer:
    'To update your payment details, please go to your account settings and navigate to the subscription tab',
  faqs_4_question: 'What happens after the payment is completed?',
  faqs_4_answer:
    'Once the payment is completed, you will receive a notification from Nettgrav, indicating that your membership is activated.',
  faqs_5_question: 'What is the difference between Free and Conscious?',
  faqs_5_answer:
    'Free subscription provides five searches and five memories. There is also a limitation on the size of media added to graves. With Conscious, you have unlimited access to all features and access to new features that are added.',
  faqs_6_question: 'Who is Conscious plus for?',
  faqs_6_answer:
    'Conscious plus has the same features and access as Conscious. The membership is for those who wish to support the ethics of the grave with the End Of Time Center',
  update_modal_change_plan: 'Change {name} plan',
  waite_for_validation:
    'Please wait while checking for subscription validation',
  add_members: 'Add members',
  remove_members: 'Remove members',
  allowed_members: 'Allowed number of members',
  how_many_add: 'How many members do you want to add?',
  how_many_remove: 'How many members do you want to remove?',
  not_remove_all: 'You cannot remove all members.',
  updated_price: 'Updated price after the changes',
  remaining_members: 'Total remaining members',
  total_price: 'Total price',
  extra_user:
    'For additional {subscriptionTotalCount} family members is an additional payment',
  process_payment: 'Proceed to payment',
  continue: 'Continue',
  subscription_members: 'Subscription members',
  remove_subscription_members: 'Remove subscription members by clicking remove',
  subscription_invitation_members: 'Invitations to subscriptions',
  remove_invitation_members:
    'Remove the subscription invitation by clicking remove',
  downgrade_plan: 'Downgrade plan',
  upgrade_plan: 'Upgrade plan',
  downgrade_message:
    'Disable all subscription members and invitations to continue downgrading your subscription',
  upgrade_message:
    'Remove all subscription members and invitations to upgrade plan',
  close_message: 'You can now close and continue further',
  close: 'Close',
  active_members: 'Active Members in Your Subscription',
  active_members_message:
    'To proceed, please deactivate one or more members from your subscription.',
  active_members_empty_message: 'No active members found in your subscription',
  active_invitations: 'Pending Invitations for Your Subscription',
  active_invitations_message:
    'Please revoke the member invitations to proceed.',
  active_invitations_empty_message:
    'No outstanding invitations linked to your subscription',
  check_out: 'Check out',
  select_payment_method: 'Select payment method',
  order_details: 'Order details',
  subscription_number: 'Subscription count',
  taxes: 'Taxes',
  total: 'Total amount',
  sub_total: 'Subtotal',
  discount_title: 'Choose length',
  subscription_length: 'Plan duration',
  discount: 'Discount',
  renewal: 'Automatic renewal',
  renewal_details:
    'You have selected {subscriptionCount} users, which results in a total payment of NOK {total} {periodName}. You can cancel your subscription at any time from the My Membership section of your profile.',
  payment_page_terms: 'By clicking "Subscribe" you agree',
  terms_conditions: 'Terms and conditions.',
  change_free_plan: 'Free plan is free of charge',
  free_plan_message:
    'With free you can explore, visit and create Nettgrav. Free is one fundamental value.',
  ending_with: 'ending with',
  add_payment_method: 'Add payment method',
  name_on_card: 'Name on card',
  expiry_date: 'Expiry date',
  secure_payment_message: 'Secure payment with SSL encryption',
  billing_address: 'Billing address',
  enter_address: 'Enter address',
  billing_info_message:
    'This information is used to issue the first invoice. You can update the details of future invoices from your account profile.',
  subscribe: 'Subscribe',
  select_language: 'Select language',
  payment_error: 'Payment error',
  subscription_waiting_message:
    'Payment is being processed. Please wait, your subscription is in progress',
  success_message: 'Subscription cancelled successfully',
  reactivate_success_message: 'The subscription has been reactivated',
  choose_plan: 'Choose a plan before subscribing',
  canceling_waite_message: 'Cancelling subscription in process, please wait...',
  subscription_overview: 'Subscription overview',
  users: 'Members',
  for_members: 'For {user} members',
  for_member: 'For {user} member',
  for_6_members: 'For {user} or more',
  billing_cycle: 'Billing cycle',
  amount: 'Amount',
  cancelSubscriptionInfo:
    'By canceling your {planName} subscription, you will lose access to all the premium features associated with it. Your current billing cycle will end on the subscription end date, and you will not be charged further. Please note that any data associated with premium features may become inaccessible. You can always resubscribe at any time.',
  confirm_billing_cycle: 'Save changes',
  different_cycle: 'Please select a different subscription cycle',
  update_billing: 'The billing cycle has been updated',
  waiting_update_billing_cycle: `Just a moment, we're updating your billing cycle...`,
  add_members_message: 'Members have been added',
  remove_members_message: 'Members have been removed',
  your_current_plan: 'Your current plan',
  upgrade_to: 'Upgrade to {planName}',
  downgrade_to: 'Downgrade to {planName}',
  members_removed: 'Subscription member has been removed',
  invitation_removed: 'Member of the subscription invitation has been removed',
  mann: 'man',
  kvinne: 'woman',
  annen: 'other',
  life_page_description:
    'Here you will find all the graves you are associated with and your own life page.',
  settings: 'Settings',
  settings_description:
    'Your account information such as password, membership and person.',
  logout: 'Log out',
  logout_description:
    'Thank you for being there and we look forward to seeing you again.',
  create_new_grave: 'Create new grave',
  create_grave_description:
    'Create a new grave entry for a deceased individual in records.',
  new: 'New',
  read: 'Read',
  empty_notification_message: 'New notifications are displayed here',
  public_anniversaries: 'Public Anniversaries',
  grave_anniversaries: 'Grave Anniversaries',
  public_anniversary: 'Public Anniversary',
  grave_anniversary: 'Grave Anniversary',
  anniversary: 'Anniversary',
  anniversary_delete: 'The anniversary has been deleted successfully.',
  anniversary_created: 'The anniversary has been created successfully.',
  anniversary_updated: 'The anniversary has been updated successfully.',
  empty_anniversaries: 'No anniversary has been created yet.',
  create: 'Create',
  add_dates: 'Add Dates',
  date: 'Date',
  time: 'Time',
  title: 'Title',
  reset_time: 'Reset Time',
  home: 'Home',
  about: 'About',
  howTo: 'How to',
  plans: 'Plans',
  find_a_grave: 'Find a Grave',
  create_a_grave: 'Create a Grave',
  take_control: 'Take Control of the Grave',
  pricing: 'Pricing',
  get_started: 'Get started',
  create_new_account: 'Create new account',
  did_you_know: 'Did you know that',
  registration_description_one:
    'Nettgrav is a portal for the whole life experience. It´s there through the challenges of loss, helping us live with integrity and purpose. The new grave is just the beginning,-filling the void left by a purely logical worldview, while nurturing spiritual well-being and lasting connections.',
  registration_description_two:
    'Whether it´s a standalone grave or an extension of a physical one, Nettgrav creates lasting value for families, friends and moons.',
  registration_description_three:
    'Join us in embracing life´s challenges, fostering a brighter future, and sharing your light with Sparks.',
  enter_your_password: 'Enter your password',
  confirm_your_password: 'Confirm your password',
  type_your_email: 'Type your email',
  type_email: 'Enter email',
  hi: 'Hi {name}',
  decline: 'Decline',
  read_only: '(read only)',
  accept: 'Accept',
  accepted_message: 'The invitation is accepted',
  invited_title: 'You are invited to a subscription',
  open: 'Open',
  subscription_invitation_message:
    'You are invited to {subscription} subscription from {name}',
  email_error: 'Please enter a valid email',
  password_length:
    'Password must be at least 8 characters long and include uppercase letters, lowercase letters, and numbers.',
  filled_out: 'All fields must be filled out',
  must_agree: 'You must agree to the terms and conditions',
  inactive: 'Inactive',
  delete_payment_method: 'Deleting payment method in process, wait...',
  payment_method_deleted: 'The payment method has been deleted',
  payment_method_inactive:
    'Your current payment method is either deleted or no longer active.',
  subscription_changes_title: 'You requested these subscription changes.',
  period:
    'You Requested to downgrade billing cycle from {month} {previous} to {newMonth} {new}',
  user_count: 'You Requested to downsize members from {previous} to {new}',
  product_id: 'You Requested to downgrade plan from {previous} to {new}',
  remove_subscription_change: 'The requested subscription change is reverted',
  remove_subscription_loading:
    'please waite request is in progress to delete changes......',
  revert: 'Revert',
  subscription_changes_subTitle:
    'These changes will be applied after your current billing cycle will end by {date}',
  add_new_paymentMethod: 'Add payment method',
  filled_out_info:
    'Make sure you have filled in all the fields in the form before continuing',
  continue_new_card: 'Select a payment method or add a new card to continue',
  create_payment_method_waiting: 'Creating payment method. Please wait...',
  login_description: 'Log in and experience memories left behind on the grave',
  login_with_email: 'Log in with email',
  login_with_phone: 'Log in with phone number',
  account_created: `Welcome. Your account has been created`,
  verify_account: 'Login failed due to unverified account',
  verification_message: `It looks like your email address hasn't been verified yet. Please check your mailbox for a verification email and follow the instructions to complete the process. If you can't find the email, check your spam or junk folder, or request a new verification email.`,
  resend_email: 'Resend verification email',
  sending_verification_email:
    'Please check your mailbox for a verification email',
  sending_verification_code:
    'Verification code has been sent to your phone number.',
  sending_verification_waiting:
    'A verification code has been sending to your phone number please wait...',
  email_verification:
    'We’ve sent a verification email. Please check your inbox and click the link to verify your email.',
  signup_message_heading: `Success! You're Almost There.`,
  signup_message_subheading: `Thanks for signing up, {name}!`,
  signup_first_message: `We're excited to have you with us. Before you can start exploring everything Nettgrav has to offer, we need to make sure it's really you. We've just sent an email to {email} with a link to verify your account.`,
  signup_second_message: `Next step: Check your inbox (and maybe your spam/junk folder just in case) and click the link in the email. Once you've confirmed your email, you'll be all set to log in and start using your account.`,
  signup_third_message: `Until then, your account is in a "waiting room" of sorts, so you won't be able to log in just yet. Don't worry it's just a quick click away!`,
  signup_forth_message: `If you don't see the email or need any help, feel free to reach out. We're here to help.`,
  signup_fifth_message: `We can't wait for you to get started!`,
  cheers: 'Cheers',
  the_team: 'The Nettgrav Team',
  waiting_for_account: `We're creating your account. Please wait a moment...`,
  account_verification_waiting:
    'Please wait, your account verification is in process...',
  account_verification_heading: 'Account Verified Successfully',
  account_verification_heading_error: 'Account Verification Failed',
  account_verification_description:
    'Your account has been successfully verified.',
  account_verification_description_error:
    'There was an issue verifying your account. Please try again or contact support for assistance',
  update_payment_method_waiting: 'Updating payment method. Please wait...',
  update_payment_method_success: 'Payment method successfully updated.',
  warning_payment_method: 'Select a new payment method',
  complete_pending_payment: `Please complete the payment process.`,
  plans_card_payment_method:
    'Your current payment method is Web Services this will be considered as the primary payment method for this transaction if you want to change the payment method change it before you want to make any changes in the subscription',
  forgot_password_heading: 'Forgot Your Password?',
  forgot_password_description: `Don't worry, it happens to the best of us. Just enter your email address below, and we'll send you a link to reset your password. Follow the instructions in the email to regain access to your account.`,
  forgot_password_button: 'Send Reset Link',
  forgot_password_message: `We've sent a password reset link to the email address you provided. Please check your inbox to reset your password. If you don't see it, be sure to check your junk or spam folder.`,
  forgot_password_title: 'Reset Link Sent',
  forgot_password_waiting:
    'Please wait while we send the password reset link. This may take a few moments.',
  set_new_password: 'Set a New Password',
  set_new_password_description:
    'Please enter a new password for your account. Make sure it’s strong and secure. After confirming, you’ll be able to log in with your new password.',
  set_new_password_error: 'Please Enter the password',
  set_new_password_success: 'Your password has been successfully updated',
  set_new_password_waiting:
    'Please hold on while we update your password. This will only take a moment.',
  action_required: 'Action Required: ',
  email_verification_alert: `Add and verify your email to
            continue logging in with email or phone. Accounts with no or unverified
            e-mail address will not be able to login`,
  goTo_settings_page: 'Go to setting page',
  go_to_home_page: 'Go to home page',
  verify_now: 'Verify Now',
  authCode_verified: 'Phone number verified successfully',
  personal_information_updated: 'Personal information updated',
  reset_date: 'Reset date',
  sending_verification_email_waiting:
    'A verification link has been sent to your email, please wait...',
  local_name: 'Local Name',
  create_anniversary_waiting: 'create anniversary in progress please wait...',
  anniversary_time_end: 'Event has finished',
  hello: 'Hello',
  coins: 'Coins',
  set_color: 'Whats your color today?',
  set_color_button: 'Set your color',
  sparks: 'Sparks',
  community: 'Community',
  purpose: 'Purpose',
  talents: 'Talents',
  profile_image_changed: 'Image updated successfully',
  waiting_profile_upload: 'profile image updating please wait...',
  graves: 'Graves',
  wisdom: 'Wisdom',
  sparks_description:
    'Send a awakening spark throughout the community and learn something new!',
  text_area_placeholder: 'How do you want to make the world a little better?',
  crop_image: 'Crop image',
  recurring_anniversary: 'Repeat Every Year',
  nick_name: 'Nick Name',
  birth_name: 'Birth name',
  date_of_birth: 'Date of birth',
  date_of_death: 'Date of death',
  time_of_birth: 'Time of birth',
  time_of_death: 'Time of death',
  place_of_birth: 'Place of birth',
  place_of_death: 'Place of death',
  cause_of_death: 'Cause of death',
  municipality: 'Municipality',
  country: 'Country',
  cemetery: 'Cemetery',
  grave_number: 'Grave number',
  grave_info_updating: 'updating grave information please wait...',
  deceased_name: 'Name of person',
  deceased_placeholder: 'Name of the deceased/buried person',
  deceased_gender: 'Gender of the deceased',
  i_am: 'I am',
  buried_is_mine: 'Buried is mine',
  select_relationship: 'Select relationship',
  upload_document: 'Upload document',
  upload_document_description:
    'Uploaded document validates ownership of the grave.',
  death_certificate: 'Death certificate',
  birth_certificate: 'Birth certificate',
  invoice: 'Invoice for grave maintenance/lease',
  power_of_attorney: 'Letter from attorney',
  driving_license: `Deceased's driving license`,
  proof_of_person: 'Upload the proof of the person the grave page is for.',
  proof_of_person_description:
    'The document can be in PDF, png, jpg format and less than 5 MB',
  replace_files: 'Replace files',
  browse_files: 'Browse files',
  drag_image: 'or drag and drop the file here',
  dear: 'Dear',
  new_grave_first_description:
    'Welcome to Nettgrav. Since our goal is to offer a meaningful and secure online grave site, it is important for us to ensure that the individuals managing these online graves are indeed the rightful owners or authorized representatives.',
  new_grave_second_description:
    'Please understand that when you create an online grave site, you are claiming your status as the grave owner or an authorized representative. By proceeding with the creation of a Nettgrav, you confirm that you have the authority to do so.',
  new_grave_third_description: `Please read and accept the following: 'I confirm that I am the rightful owner or an authorized representative of the deceased for whom I am creating an online grave. I understand that by proceeding with this action, I am obtaining digital ownership of the grave. I am aware that any misuse or misrepresentation may result in the removal of the deceased from Nettgrav.no.`,
  new_grave_fourth_description: `Click 'Accept' to confirm your understanding and proceed. Thank you for your cooperation in maintaining the integrity and respect for the deceased and all others who trust Nettgrav.no.`,
  new_grave_agree_terms: 'I agree to the terms above',
  enter_name_error: 'Please enter name of the person',
  select_gender_error: 'Please select gender of the deceased',
  select_relation_error: 'Please select your relation to the Buried',
  select_date_error: 'Please select a date',
  select_reason_error: 'Please select a reason of death',
  reason_of_death: 'Cause of death',
  other_reason: 'Enter another reason',
  other_reason_error: 'Please enter other reason of death',
  select_country: 'Select Country',
  select_municipality: 'Select Municipality',
  select_cemetery: 'Select Cemetery',
  select_country_error: 'Please select a country',
  select_municipality_error: 'Please select a municipality',
  select_cemetery_error: 'Please select a cemetery',
  create_the_grave: 'Create the grave',
  create_the_message: 'Creating a grave is in progress please wait....',
  death_date_error: 'Please select a valid date of death',
  birth_date_error: 'Please select a valid date of birth',
  month_0: 'January',
  month_1: 'February',
  month_2: 'March',
  month_3: 'April',
  month_4: 'May',
  month_5: 'June',
  month_6: 'July',
  month_7: 'August',
  month_8: 'September',
  month_9: 'October',
  month_10: 'November',
  month_11: 'December',
  weekday_0: 'Sunday',
  weekday_1: 'Monday',
  weekday_2: 'Tuesday',
  weekday_3: 'Wednesday',
  weekday_4: 'Thursday',
  weekday_5: 'Friday',
  weekday_6: 'Saturday',
  father: 'Father',
  mother: 'Mother',
  son: 'Son',
  husband: 'Husband',
  wife: 'Wife',
  brother: 'Brother',
  sister: 'Sister',
  grandfather: 'Grandfather',
  grandmother: 'Grandmother',
  grandson: 'Grandson',
  granddaughter: 'Granddaughter',
  uncle: 'Uncle',
  uncle_child: 'Uncle child',
  aunt: 'Aunt',
  aunt_child: 'Aunt child',
  nephew: 'Nephew',
  niece: 'Niece',
  stepbrother: 'Stepbrother',
  stepsister: 'Stepsister',
  stepfather: 'Stepfather',
  stepmother: 'Stepmother',
  stepson: 'Stepson',
  stepdaughter: 'Stepdaughter',
  father_in_law: 'Father in law',
  mother_in_law: 'Mother in law',
  brother_in_law: 'Brother in law',
  sister_in_law: 'Sister in law',
  son_in_law: 'Son in law',
  daughter_in_law: 'Daughter in law',
  boyfriend: 'Boyfriend',
  girlfriend: 'Girlfriend',
  adopt_son: 'Adopt son',
  adopt_daughter: 'Adopt daughter',
  friends: 'Friends',
  colleagues: 'Colleagues',
  friend: 'Friend',
  other: 'Other',
  daughter: 'Daughter',
  partner: 'Partner',
  colleague: 'Colleague',
  great_granddaughter: 'Great granddaughter',
  great_grandson: 'Great grandson',
  great_grandmother: 'Great grandmother',
  great_grandfather: 'Great grandfather',
  great_great_granddaughter: 'Great great granddaughter',
  great_great_grandson: 'Great great grandson',
  great_great_grandmother: 'Great great grandmother',
  great_great_grandfather: 'Great great grandfather',
  great_great_great_granddaughter: 'Great great great granddaughter',
  great_great_great_grandson: 'Great great great grandson',
  great_great_great_grandmother: 'Great great great grandmother',
  great_great_great_grandfather: 'Great great great grandfather',
  cohabitant: 'Cohabitant',
  contact_person: 'Your contact person',
  invited_person_name: `Invited person's name`,
  monthly: 'Monthly',
  semi_annual: 'Semi-Annual',
  annually: 'Annually',
  biennial: 'Biennial',
  auth_modal_description: `Create a free account or login now to find and visit the grave you're looking for and discover more features.`,
  auth_modal_heading: 'Register or Login',
  active: 'Active',
  requested_to_remove: 'Request to remove',
  manage_members: 'Manage Members',
  remove_members_warring:
    'You need to either remove a total of {remainingNumber} members from confirmed members or cancel their invitations, or a combination of both.',
  remove_members_warning:
    'Please remove members or decrease the number of members to continue.',
  subscription_base_price: 'Subscription base price',
  membership: 'membership',
  relation_to_grave: `Relation to the person you invite`,
  relation_to_person: `Invited person related to the buried`,
  administrator_access: 'Administrator access',
  select_role: 'Select role',
  administrator: 'Administrator',
  normal: 'Normal',
  select_role_error: 'Please select the user role',
  user_name: 'User name',
  remove_all_grave_user: 'Important Notice: Grave Management Update',
  remove_all_grave_user_message:
    'You cannot remove all members or administrators from the grave. If you wish to remove all members, you must either transfer ownership of the grave or delete it entirely. A grave must always be associated with a user or administrator for proper management.',
  send_it: 'Send it',
  public_view: 'Public view',
  constellation: 'Constellation',
  moons_only: 'Moons only',
  question: 'question',
  insight: 'insight',
  experience: 'experience',
  discovery: 'discovery',
  feeling: 'feeling',
  value: 'value',
  energy: 'energy',
  select_category: 'Select category',
  spark_delete: 'Spark deleted successfully',
  spark_create: 'Spark created successfully',
  spark_update: 'Spark update successfully',
  ENG: 'English',
  NOR: 'Norwegian',
  loading: 'Loading.....',
  in_your_mind: `What Spark do you have within {name}?`,
  create_spark: 'Create spark',
  empty_graves: 'No associated graves. Search by name to find the graves.',
  all: 'All',
  owner: 'Owner',
  affiliated: 'Affiliated',
  view_all: 'View All',
  close_relation: 'Your closest relationships come here',
  no_relation_set: 'No relations have been set',
  love_ones: '{name}´s close family and friends',
  set_relation: 'Set relation',
  relation_request_send: 'Your relation request has been successfully sent.',
  relation_connection:
    '{name} is my {reciprocalRelation}, and I am the {relation} of {name}.',
  request_send: 'Request has been sent',
  empty_sparks: 'No sparks available please create new one',
  relatives: 'Loved ones',
  account_heading: 'Deleting your Nettgrav account',
  delete_account: 'Delete account',
  delete_account_pending: 'Account Deletion in Progress',
  deleted_message_pending:
    'Your account deletion request has been submitted. All associated data will be permanently deleted from our servers. You can still cancel the deletion request before 23:50 today. If you wish to cancel the request or have any questions or concerns, please contact our customer support team.',
  deleted_message:
    'Permanently delete your account and all associated data from our servers. This action cannot be undone after the cancellation window closes. You can cancel the deletion request anytime before 23:50 on the same day the request is made. If you have any questions or concerns, please contact our customer support team.',
  confirm_delete_account: 'Are you sure you want to delete your account?',
  confirm_cancel_account:
    'Are you sure you want to cancel your account deletion request?',
  phone_number_error: 'Phone number/Password do not match',
  email_error_login: 'Email/Password do not match',
  set_relation_heading: 'Set Relation',
  set_relation_free_description: `By connecting with {graveName}´s grave, you ensure their grave is always accessible whenever you need. Setting this relation is a meaningful and thoughtful decision that includes a small fee to support our community services.`,
  set_relation_access: 'As a member, you gain access to:',
  do_you_know: 'Do you know {graveName}?',
  set_relation_description:
    'Submit request to join page as a relative, friend or a well-wisher',
  set_relation_info_message: `Once you've set your relationship, this grave will be linked to your life page. Your membership extends to all graves within our community, helping us maintain and enhance this special space for preserving memories.`,
  click_button_below: 'Click the button below to set your relation.',
  feature_title_1: 'Memory Album',
  feature_description_1: 'Upload and share cherished photos.',
  feature_title_2: 'Memory Wall',
  feature_description_2: 'View and contribute memories from others.',
  feature_title_3: 'Anniversary Calendar',
  feature_description_3: 'Receive reminders on special dates.',
  feature_title_4: 'Value Archive',
  feature_description_4: 'Preserve important values and lessons.',
  feature_title_5: 'Life Archive',
  feature_description_5: 'Document key life moments.',
  choose_relation: 'Choose relation',
  relation_label: 'Please select your relationship to {grave}',
  reciprocal_label: 'Please select {grave} relationship to you',
  set_relation_note:
    "Note: By setting a relation, you agree to follow and abide by the rules set by the grave's owner/admin.",
  set_relation_other:
    'For other acquaintances of deceased, who may not be known to the grave owner.',
  set_relation_placeholder:
    'Example, I was friends with {grave} at the university in Oslo...',
  confirm_relation_member: 'Confirm relation and become member',
  pay_and_reactivate: 'Pay to Reactivate Membership',
  confirm_membership: 'Confirm Membership',
  enlightened: 'Enlightened',
  inspired: 'Inspired',
  reflected: 'Reflected',
  comments: 'comments',
  comment: 'Comment',
  empty_comments: 'No Comments found',
  comment_placeholder: 'write comment...',
  reply: 'Reply',
  view_replies: 'View all {total} replies',
  create_grave_body: 'Create new grave is available with Conscious Membership.',
  upgrade_modal_heading: 'Update your membership here',
  go_to: 'Go to',
  our_plans: 'our plans',
  choose_the_subscription: 'and choose the subscription that suits you.',
  link_grave_body:
    'So nice that you want to link the buried together. To do that, you need Conscious membership.',
  add_more_images_body:
    'To add more photos to albums, you need to upgrade your subscription.',
  inviting_body: 'Inviting is available with Conscious Membership.',
  life_archive_body: 'The life archive is available with conscious membership.',
  upload_images_body:
    'To add Photos, an upgrade to your current subscription is necessary',
  add_value_body:
    'So nice that you want to add value. To add, you need conscious membership',
  search_limit_body:
    'Dive deeper into Nettgrav with our conscious Plan. Upgrade today for unlimited searches.',
  grave_invitation_first_text:
    'You have been invited to contribute to the grave',
  of: 'of',
  grave_invitation_second_text: `With the invitation, you can add 10 memories to the grave. To add more memories and collaborate, you must be part of one
conscious subscription or have an individually paid subscription.`,
  grave_email_info:
    'This email was used to invite you to contribute to the grave and cannot be changed. If there’s an issue, please reach out to the person who invited you for assistance.',
  subscription_email_info:
    'This email was used to subscribe you and cannot be changed. If there’s an issue, please contact the person who set up this subscription for assistance.',
  grave_invitation_message:
    '{name} has invited you to contribute to this grave. Accept the invitation or complete the payment to join.',
  accept_or_pay: 'Accept and process payment',
  paid_grave_user_message: `You’ve been invited by {userName} to join this grave with a paid subscription. Accept the invitation to join this exclusive community, or decline if it’s not the right time for you. Choose your next step below!`,
  unpaid_grave_user_message: `You’ve been invited by {userName} to join this grave. To be part of this exclusive community, please upgrade to a paid subscription. Accept the invitation to join, or decline if it’s not the right time for you. Choose your next step below!`,
  active_unpaid_grave_user_message: `You don't have an active subscription right now. Reactivate your subscription to continue contributing and stay an active member of the community. Click below to renew your membership and make an impact!`,
  invite: 'Invite',
  complete_relation_setup: 'Complete Your Payment To Confirm Your Relation',
  already_set_relation: `You've already set your relationship with the grave {graveName} as {relation}.`,
  already_accept_relation: `You've already accepted your relationship with the grave {graveName} as {relation}.`,
  finalize_membership: `To finalize this connection and unlock all membership benefits, please complete your payment. Once done, the grave will be linked to your life page, and you'll gain access to:`,
  click_below_to_complete:
    'Click the button below to complete your payment and confirm your relation.',
  complete_payment: 'Complete Payment',
  awaiting_owner_approval: `Awaiting Owner's Approval`,
  awaiting_approval_message:
    'The owner has been notified of your request for relation. Please wait for their approval or rejection. You’ll be updated as soon as they respond.',
  subscribed_grave_invitation: `You've been invited to connect with the grave {graveName} as {relation} by {name}.`,
  click_below_to_accept:
    'Click the button below to accept the invitation and confirm your relation.',
  accept_invitation: 'Accept Invitation',
  accept_invitation_payment: 'Accept and Complete Payment',
  complete_invitation: 'Complete Your Invitation',
  unsubscribed_grave_invitation: `To accept this invitation and finalize your connection, please complete the payment process. Once confirmed, you'll gain access to the following exclusive features:`,
  you_are_invited: `You've Been Invited!`,
  reclaim_user_access: 'Reclaim Your Access',
  previous_relation:
    'You were previously connected with the {graveName} as {relation}.',
  downgrade_subscription:
    'Since your membership has been downgraded to the free plan, access to this grave and its features has been restricted. However, you are still a recognized member of this grave.',
  regain_access:
    'To regain full access and reconnect with the grave, please upgrade your membership.',
  click_below_to_upgrade:
    'Click the button below to upgrade and restore your access.',
  upgrade_membership: 'Upgrade Membership',
  set_self_relation:
    'As a valued member, you can connect with {graveName}´s grave by selecting your relation.',
  set_relation_linked: 'Once connected, you have the opportunity to:',
  set_self_relation_1: 'Visit the grave when you need',
  set_self_relation_2: 'Preserve and share memories',
  set_self_relation_3: 'Keep the grave well kept',
  set_self_relation_message: `As a member, there is no additional cost to establish this connection.`,
  claim_grave_labe: 'I am the owner or caretaker of this grave',
  claim_grave_terms_1:
    'Welcome to Nettgrav. As we aim to provide a meaningful and respectful digital grave for our deceased loved ones, it is important to us to ensure that the individuals managing these graves are indeed the rightful owners or authorized representatives.',
  claim_grave_terms_2: `Please understand that when you create a digital grave site on our platform, you are asserting your status as a grave owner or authorized representative. By proceeding with the digital grave claim, you confirm
that you have the authority to do so.`,
  claim_grave_terms_3: `Please read and agree to the following: "I confirm that I am the rightful owner or an authorized representative of the grave that I
creates on Nettgrav. I understand that by proceeding with this action, I gain digital ownership of the grave. I am aware that any misuse or misrepresentation may lead to the removal of the digital grave from Nettgrav.`,
  claim_grave_terms_4: `Click "Accept" to confirm your understanding and continue. Thank you for the collaboration to maintain the integrity and respect of our platform.`,
  i_agreed_claim: 'I agree to the terms above',
  upload_death_document:
    'Upload the death certificate of the person the grave page is for.',
  upload_death_document_limit:
    'The document can be in PDF, png, jpg format and less than 5 MB',
  drag_files: 'or Drag and drop the file here',
  claim_grave_message:
    'Do you wish to manage the grave? Claim ownership by clicking here:',
  take_ownership: 'Claim ownership',
  claim_request_queue:
    'Your request to claim the grave to {graveName}, has been successfully submitted. It is now awaiting approval. You will be notified once your request has been reviewed.',
  register_ownership: 'Register ownership',
};
export default ENG;
