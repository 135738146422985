import React from 'react';
import styles from './TotalComments.module.css';
import { formatTotalCount } from '../../../../../../../service/Utils';
import { FormattedMessage } from 'react-intl';

const TotalComments = ({
  totalComments,
  handelOpenDrawer,
  renderSparkModal,
}) => {
  return (
    totalComments > 0 && (
      <div
        className={styles['comments']}
        onClick={() => {
          if (!renderSparkModal) handelOpenDrawer();
        }}
      >
        {formatTotalCount(totalComments)} <FormattedMessage id='comments' />
      </div>
    )
  );
};

export default TotalComments;
