import Header from './components/Header'
import Content from './components/Content'
import React, { useState } from 'react';

const GraveConnectionsModal = (props) => {
  const [step, setStep] = useState(1);
  return(
    <>
      <Header grave={props.grave} user={props.user} handleCloseGraveConnectionDialog={props.handleCloseGraveConnectionDialog} step={step}/>
      <Content grave={props.grave} user={props.user} setStep={setStep} step={step} handleCloseGraveConnectionDialog={props.handleCloseGraveConnectionDialog}/>
    </>
  )
}

export default GraveConnectionsModal;