import React from 'react';
import { Modal } from '@mui/material';
import { makeStyles } from '@mui/styles';
import X from '../img/X.svg';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContainer: {
    position: 'relative',
    backgroundColor: 'white',
    borderRadius: '6px',
    outline: 'none',
    maxWidth: '700px',
    maxHeight: 'calc(100vh - 100px)',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    '@media (max-width :540px)': {
      width: '100% !important',
      borderRadius: '0px',
      overflowY: 'auto',
      maxHeight: 'calc(100vh - 40px)',
    },
  },
  header: {
    padding: 20,
    borderBottom: '1px solid #ddd',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 16,
    position: 'sticky',
    top: 0,
    backgroundColor: 'white',
    borderRadius: '6px 6px 0px 0px',
    zIndex: 10,
  },
  heading: {
    fontSize: 24,
    fontWeight: 500,
  },
  content: {
    flex: 1,
    overflowY: 'auto',
    padding: 20,
  },
  footer: {
    padding: 20,
    borderTop: '1px solid #ddd',
  },
  closeButton: {
    cursor: 'pointer',
    alignSelf: 'start',
  },
}));

const CustomModal = ({
  isOpen,
  children,
  footer = null,
  closeModal,
  title,
  style,
}) => {
  const classes = useStyles();
  return (
    <Modal
      open={isOpen}
      className={classes.modal}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.modalContainer} style={style}>
        <div className={classes.header}>
          <div className={classes.heading}>{title}</div>
          <img
            src={X}
            onClick={() => closeModal(false)}
            alt='close'
            className={classes.closeButton}
          />
        </div>
        <div className={classes.content}>{children}</div>
        {footer && <div className={classes.footer}>{footer}</div>}
      </div>
    </Modal>
  );
};

export default CustomModal;
