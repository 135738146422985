import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './free.module.css';
import { FormattedMessage } from 'react-intl';
import TitleRow from '../TitleRow/TitleRow';
import { planKey } from '../../../../service/PlanKey';
import { ChangePlanKey } from '../../../../service/constants';

const FreeSubscription = ({
  currentSubscription,
  setOpenOptionsModal,
  setActionKey,
}) => {
  const navigate = useNavigate();

  const getLength = () => {
    return (
      <>
        <FormattedMessage id={currentSubscription.period} />
      </>
    );
  };

  const isPeriodSet = () => {
    return currentSubscription.period ? (
      getLength()
    ) : (
      <FormattedMessage id='month' />
    );
  };

  const getAction = () => {
    return {
      action: <FormattedMessage id='change' />,
      handelAction: () => navigate('/pricing'),
    };
  };

  return (
    <div className={styles['free-container']}>
      <TitleRow
        value={<FormattedMessage id={planKey(currentSubscription.plan_key)} />}
        title={<FormattedMessage id='settings_subscription' />}
        action={getAction()}
        setOpenOptionsModal={setOpenOptionsModal}
        messageKey={ChangePlanKey.change_plan}
        setActionKey={setActionKey}
      />
      <TitleRow
        value={currentSubscription.user_count}
        title={<FormattedMessage id='users' />}
        action={null}
        messageKey={ChangePlanKey.change_members}
        setActionKey={setActionKey}
      />
      <TitleRow
        value={isPeriodSet()}
        title={<FormattedMessage id='billing_cycle' />}
        action={null}
        messageKey={ChangePlanKey.change}
        setActionKey={setActionKey}
      />
      <div className={styles['detail']}>
        <div className={styles['title']}>
          <FormattedMessage id='amount' />:
        </div>
        <div className={styles['value']}>
          NOK {currentSubscription.subscription_price}
        </div>
      </div>
    </div>
  );
};

export default FreeSubscription;
