import React, { useState } from 'react';
import DeleteIcon from '../../../../../../../img/DeleteRedIcon.svg';
import EditIcon from '../../../../../../../img/EditSmallIcon.svg';
import EditButton from '../../../../../../../components/EditButton/EditButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Menu } from '@mui/material';

const ActionButton = ({ onClickEdit, onClickDelete, comment }) => {
  const [anchorElement, setAnchorElement] = useState(null);
  const open = Boolean(anchorElement);
  const id = open ? 'simple-popover' : undefined;

  const handleOpenMenu = (event) => {
    setAnchorElement(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElement(null);
  };

  return (
    <div>
      <MoreHorizIcon
        onClick={handleOpenMenu}
        style={{
          fontSize: '16px',
          cursor: 'pointer',
          color: '#080809',
        }}
      />
      <Menu
        id='simple-menu'
        elevation={1}
        anchorEl={anchorElement}
        keepMounted
        open={open}
        onClose={handleCloseMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <EditButton
          title='settings_edit'
          onClick={() => {
            handleCloseMenu();
            onClickEdit();
          }}
          icon={EditIcon}
        />
        <EditButton
          title='delete'
          onClick={() => {
            handleCloseMenu();
            onClickDelete(comment);
          }}
          icon={DeleteIcon}
        />
      </Menu>
    </div>
  );
};

export default ActionButton;
