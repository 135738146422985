import React from 'react';
import IframeComponent from "../../../components/Iframe/IframeComponent";

const Features = () => {
  return (
    <IframeComponent 
      title="Features" 
      source="https://wix.nettgrav.no/funksjoner"
    />
  );
};

export default Features;
