import React, { useState, useRef, useEffect } from 'react';
import Comment from './Comment/Comment';
import styles from './Comments.module.css';
import { useCommentsQuery } from '../../../../../../graphql/generated/sparkHooks.ts';
import { FormattedMessage } from 'react-intl';
import CommentsLoading from './CommentsLoading.jsx';
import useElementOnScreen from '../../../../../../hooks/useElementOnScreen.jsx';

const Comments = ({
  spark,
  handelEditComment,
  handelDeleteComment,
  handelCreateComment,
}) => {
  const [initialLoading, setInitialLoading] = useState(true);
  const commentRef = useRef(null);
  const loadingLength = spark?.commentsCount > 5 ? 5 : spark?.commentsCount;
  const isNewComment = localStorage.getItem('commented');
  const [loading, setLoading] = useState(false);
  const [containerRef, isVisible] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 1,
  });

  const { data, fetchMore } = useCommentsQuery({
    variables: { sparkId: spark?.id, first: 10 },
    fetchPolicy: 'cache-and-network',
    onCompleted: () => {
      setInitialLoading(false);
    },
    onError: () => {
      setInitialLoading(false);
    },
  });

  const loadMoreComments = async () => {
    if (data?.comments.pageInfo.hasNextPage && !loading) {
      setLoading(true);
      try {
        await fetchMore({
          variables: {
            after: data.comments.pageInfo.endCursor,
          },
        });
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (isNewComment && commentRef.current) {
      commentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      localStorage.removeItem('commented');
    }
  }, [isNewComment]);

  useEffect(() => {
    if (isVisible && data?.comments.pageInfo.hasNextPage) {
      loadMoreComments();
    }
  }, [isVisible]);

  return (
    <div className={styles['container']} ref={commentRef}>
      {initialLoading ? (
        <CommentsLoading length={loadingLength} />
      ) : data?.comments.edges?.length > 0 ? (
        data.comments.edges.map(({ node }) => (
          <Comment
            key={node.id}
            comment={node}
            handelEditComment={handelEditComment}
            handelDeleteComment={handelDeleteComment}
            spark={spark}
            handelCreateComment={handelCreateComment}
          />
        ))
      ) : (
        <div className={styles['empty-comments']}>
          <FormattedMessage id='empty_comments' />
        </div>
      )}
      {!initialLoading && <div ref={containerRef} />}
      {loading && !initialLoading && <CommentsLoading />}
    </div>
  );
};

export default Comments;
