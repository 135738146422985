import React from 'react';
import './HomepageFindGraveFourth.css';
import Image from '../../../../img/searchpage4.png';
import Arrow from '../../../../img/FindGraveArrow.svg';
import ImageMobile from '../../../../img/FindGraveFourthMobile.png';
import { FormattedMessage } from 'react-intl';

const HomepageFindGraveFourth = (props) => {
  return (
    <div className='homepage-findgrave_fourth'>
      <div className='homepage-findgrave_fourth-content'>
        <div className='homepage-findgrave_fourth-content_image'>
          <img
            loading='lazy'
            src={Image}
            alt='findgrave'
            className='homepage-findgrave_fourth-content_image-image'
          />
          <img
            loading='lazy'
            src={ImageMobile}
            alt='ImageMobile'
            className='homepage-findgrave_fourth-content_image-image_mobile'
          />
          <span className='homepage-findgrave_fourth-content_image_span'>
            <FormattedMessage id='find_grave_image_title' />{' '}
            <span>
              <FormattedMessage id='life_page' />
            </span>{' '}
            <FormattedMessage id='find_grave_text' />
          </span>
        </div>
        <div className='homepage-findgrave_fourth-content_empty' />
        <div className='homepage-findgrave_fourth-content_main'>
          <div className='homepage-findgrave_fourth-content_main-block'>
            <div className='homepage-findgrave_fourth-content_main-block_number'>
              <span>01</span>
              <img loading='lazy' src={Arrow} alt='arrow' />
            </div>
            <div className='homepage-findgrave_fourth-content_main-block_text'>
              <span className='homepage-findgrave_fourth-content_main-block_text-header'>
                <FormattedMessage id='the_life_page' />
              </span>
              <span className='homepage-findgrave_fourth-content_main-block_text-subheader'>
                <FormattedMessage id='first_description' />
              </span>
            </div>
          </div>
          <div className='homepage-findgrave_fourth-content_main-block'>
            <div className='homepage-findgrave_fourth-content_main-block_number'>
              <span>02</span>
              <img loading='lazy' src={Arrow} alt='arrow' />
            </div>
            <div className='homepage-findgrave_fourth-content_main-block_text'>
              <span className='homepage-findgrave_fourth-content_main-block_text-header'>
                <FormattedMessage id='the_life_page' />
              </span>
              <span className='homepage-findgrave_fourth-content_main-block_text-subheader'>
                <FormattedMessage id='second_description' />
              </span>
            </div>
          </div>
          <div className='homepage-findgrave_fourth-content_main-block'>
            <div className='homepage-findgrave_fourth-content_main-block_number'>
              <span>03</span>
              <img loading='lazy' src={Arrow} alt='arrow' />
            </div>
            <div className='homepage-findgrave_fourth-content_main-block_text'>
              <span className='homepage-findgrave_fourth-content_main-block_text-header'>
                <FormattedMessage id='update' />
              </span>
              <span className='homepage-findgrave_fourth-content_main-block_text-subheader'>
                <FormattedMessage id='third_description' />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomepageFindGraveFourth;
