import { COMMENTS_QUERY } from '../../Sparks/CommentQuery.ts';
import { COMMENT_REPLIES_QUERY } from '../../Sparks/CommentRepliesQuery.ts';

export const addCommentCache =
  (isCommentsOpen, parentId = null) =>
  (cache, { data: { createComment } }) => {
    if (createComment && createComment.comment) {
      const sparkId = createComment.comment?.commentableId;

      if (parentId) {
        const existingReplies = cache.readQuery({
          query: COMMENT_REPLIES_QUERY,
          variables: { commentId: parentId, first: 10 },
        });

        console.log('existingReplies', existingReplies);

        const updatedReplies = {
          ...existingReplies,
          commentReplies: {
            ...existingReplies.commentReplies,
            edges: [
              {
                __typename: 'CommentEdge',
                node: createComment.comment,
              },
              ...existingReplies.commentReplies.edges,
            ],
          },
        };

        console.log('updatedReplies', updatedReplies);

        cache.writeQuery({
          query: COMMENT_REPLIES_QUERY,
          variables: { commentId: parentId, first: 10 },
          data: updatedReplies,
        });

        cache.modify({
          id: `Comment:${parentId}`,
          fields: {
            repliesCount(existingCount = 0) {
              return existingCount + 1;
            },
          },
        });
      } else {
        const existingComments = cache.readQuery({
          query: COMMENTS_QUERY,
          variables: { sparkId: sparkId, first: 10 },
        });

        const updatedComments = {
          ...existingComments,
          comments: {
            ...existingComments.comments,
            edges: [
              {
                __typename: 'CommentEdge',
                node: createComment.comment,
              },
              ...existingComments.comments.edges,
            ],
          },
        };
        cache.writeQuery({
          query: COMMENTS_QUERY,
          variables: { sparkId: sparkId, first: 10 },
          data: updatedComments,
        });

        cache.modify({
          id: `Spark:${sparkId}`,
          fields: {
            commentsCount(existingCount = 0) {
              return existingCount + 1;
            },
          },
        });
      }

      if (!isCommentsOpen && !parentId) {
        cache.modify({
          id: `Spark:${sparkId}`,
          fields: {
            comments(existingComments = []) {
              const duplicateComments = [
                createComment?.comment,
                ...existingComments,
              ].filter(
                (comment, index, self) =>
                  self.findIndex((c) => c.__ref === comment.__ref) === index
              );

              return duplicateComments;
            },
          },
        });
      }
    }
  };
