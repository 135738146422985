import React from 'react';
import './HomepageInvestigateFifth.css';
import FirstImage from '../../../../img/InvestBLockFirst.png';
import SecondImage from '../../../../img/InvestBlockSecond.jpg';
import MobileFirstImage from '../../../../img/MobileFirstImage.png';
import MobileSecondImage from '../../../../img/SecondMobileImage.png';

const HomepageInvestigateFifth = (props) => {
    return(
        <div className='homepage-investigate_fifth'>
            <div className="homepage-investigate_fifth-content">
                <div className="homepage-investigate_fifth-content_block first">
                    <div className="homepage-investigate_fifth-content_block-grey"/>
                    <div className="homepage-investigate_fifth-content_block-image">
                        <img loading='lazy' src={FirstImage} alt='FirstImage'/>
                        <img loading='lazy' src={MobileFirstImage} alt='MobileFirstImage' className='homepage-investigate_fifth-content_block-image_mobile'/>
                    </div>
                    <div className="homepage-investigate_fifth-content_block-empty"/>
                    <div className="homepage-investigate_fifth-content_block-text">
                        <span className='homepage-investigate_fifth-content_block-text_header'>Vårt gull er vår forpliktelse <span>til tillit.</span><br/>Gjennom tillit kommer ekte innovasjon.</span>
                        <span className='homepage-investigate_fifth-content_block-text_subheader'>Vi mennesker er en enhet og vi må trygge våre "svakeste" ledd. Derfor begynner vi arbeide med den mest sårbare delen: Døden. Vi må møte usikkerheten og smerten døden etterlater oss med, for å utvikle oss. I dag er graver meningsløse fra det eksistensielle perspektivet. Vi endrer det faktum.</span>
                        <div className="homepage-investigate_fifth-content_block-text_grey"/>
                    </div>
                </div>
                <div className="homepage-investigate_fifth-content_block second">
                    <div className="homepage-investigate_fifth-content_block-orange"/>
                    <div className="homepage-investigate_fifth-content_block-image">
                        <img loading='lazy' src={SecondImage} alt='SecondImage' />
                        <img loading='lazy' src={MobileSecondImage} alt='MobileSecondImage' className='homepage-investigate_fifth-content_block-image_mobile'/>
                    </div>
                    <div className="homepage-investigate_fifth-content_block-empty"/>
                    <div className="homepage-investigate_fifth-content_block-text">
                        <div className="homepage-investigate_fifth-content_block-text_container">
                            <span className='homepage-investigate_fifth-content_block-text_header'>Å omfavne <span>virkeligheten</span></span>
                            <span className='homepage-investigate_fifth-content_block-text_subheader'>Vår arv vil leve videre, ettersom historiene våre blir en del av menneskehetens historie. Vi tar sikte på å dele kunnskap, skape meningsfulle produkter og fullt ut omfavne bevisstheten som døden gir</span>
                            <div className="homepage-investigate_fifth-content_block-text_orange"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomepageInvestigateFifth;