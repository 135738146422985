import React from 'react';
import HomepageGraveFirst from './Grave/HomepageGraveFirst';
import HomepageGraveFourth from './Grave/HomepageGraveFourth';
import HomepageGraveSecond from './Grave/HomepageGraveSecond';
//import HomepageGraveThird from './Grave/HomepageGraveThird';
import './HomepageGrave.css';

const HomepageGrave = (props) => {
    return(
        <div className='homepage-grave'>
            <HomepageGraveFirst/>
            <HomepageGraveSecond/>
            {/* <HomepageGraveThird/> */}
            <HomepageGraveFourth/>
        </div>
    )
}

export default HomepageGrave;