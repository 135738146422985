import React, { useState, useEffect } from 'react';
import styles from './EmbedVideoForm.module.css';
import { useSelector, useDispatch } from 'react-redux';
import ReactPlayer from 'react-player';
import { addCommentOnToggle } from '../../../../../redux/actions';
import isEmpty from 'lodash.isempty';
import DeleteIcon from '../../../../../img/DeleteIcon.svg';
import showUserNotification from '../../../../../components/UserNotification/showUserNotification';
import EmbeddedVideo from '../EmbeddedVideo/EmbeddedVideo';

const EmbedVideoForm = ({ onClose, openWarningModal }) => {
  const [link, setLink] = useState('');
  const dispatch = useDispatch();
  const currentComment = useSelector((state) => state.grave.commentOnToggle);

  const saveVideoComment = (link) => {
    // if (!isEmpty(currentComment.embeddedVideo)) {
    //   dispatch(
    //     addCommentOnToggle({
    //       ...currentComment,
    //       embeddedVideo: {
    //         ...currentComment.embeddedVideo,
    //         video: link,
    //       },
    //     })
    //   );
    // } else {
    dispatch(
      addCommentOnToggle({
        ...currentComment,
        embeddedVideo: {
          id: Date.now().toString(),
          title: '',
          video: link,
          type: 'embedded',
        },
      })
    );
    setLink('');
    // }
  };

  const handleOnLinkChange = (event) => {
    setLink(event.target.value);
  };

  const handleAddLink = (event) => {
    event.preventDefault();

    if (ReactPlayer.canPlay(link)) {
      saveVideoComment(link);
    } else {
      showUserNotification('PLease enter a valid URL', 'error');
    }
  };

  const handleKeyDown = (e) => {
    const keyCode = e.keyCode || e.which;
    if (keyCode === 13) {
      e.preventDefault();
      handleAddLink(e);
    }
  };

  return (
    <div>
      <div className={styles['input-element']}>
        <div className={styles['title']}>Lim inn linken her</div>
        <div className={styles['input-wrapper']}>
          <input
            type='text'
            value={link}
            placeholder='https://'
            onChange={handleOnLinkChange}
            onKeyDown={(e) => handleKeyDown(e)}
          />
          {!isEmpty(link) && (
            <div className={styles['action-button']}>
              <button onClick={handleAddLink}>Legg til</button>
              <button onClick={() => setLink('')}>Avbryt</button>
            </div>
          )}
        </div>
      </div>
      {!isEmpty(currentComment.embeddedVideo) &&
        !isEmpty(currentComment.embeddedVideo.video) && (
          <EmbeddedVideo embeddedVideo={currentComment.embeddedVideo} />
        )}
      <div className={styles['grave-btn-container']}>
        <div className={styles['grave-information_content-verify']}>
          <button
            type='button'
            className={styles['rootDiscard']}
            onClick={() => openWarningModal()}
          >
            Avbryt
          </button>
          <button
            disabled={!currentComment.embeddedVideo}
            className={
              currentComment.embeddedVideo
                ? styles['rootVerify']
                : styles['disabledButton']
            }
            type='submit'
            onClick={() => onClose()}
          >
            Lagre
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmbedVideoForm;
