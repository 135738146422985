import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';
import Input from '../../../components/Input/Input';
import { request } from '../../../service/request';
import PrimaryButton from '../../../components/PrimaryButton';
import { FormattedMessage } from 'react-intl';
import './EndStep.css';

const EndStep = ({ onBackClick, onDoneClick }) => {
  const countries = useSelector((state) => state.auth.countries);
  const [municipalityList, setMunicipalityList] = useState([]);
  const [cemeteryList, setCemeteryList] = useState([]);
  const [formData, setFormData] = useState({
    country: {},
    municipality: {},
    cemetery: {},
  });

  const [formErrors, setFormErrors] = useState({
    country: false,
    municipality: false,
    cemetery: false,
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  });

  useEffect(() => {
    request(`/municipalities?country_id=${formData.country.id}`)
      .then((res) => {
        setMunicipalityList(res.data.municipalities);
      })
      .catch((err) => console.log(err));
  }, [formData.country]);

  useEffect(() => {
    request(
      `/graveyards?graveyard[municipality_id][]=${formData.municipality.id}`
    )
      .then((res) => {
        setCemeteryList(res.data.graveyards);
      })
      .catch((err) => console.log(err));
  }, [formData.municipality]);

  const validateForm = () => {
    const { country, municipality, cemetery } = formData;
    const errors = {
      country: isEmpty(country),
      municipality: isEmpty(municipality),
      cemetery: isEmpty(cemetery),
    };
    setFormErrors(errors);
    return !Object.values(errors).some((error) => error);
  };

  const onDone = () => {
    if (validateForm()) {
      onDoneClick(formData);
    }
  };

  const handleOnSelectCountry = (event, value, reason) => {
    if (reason === 'clear') {
      setFormData((prev) => ({
        ...prev,
        country: {},
      }));
    } else if (reason === 'selectOption') {
      setFormData((prev) => ({
        ...prev,
        country: value,
      }));
      setFormErrors((prev) => ({
        ...prev,
        country: false,
      }));
    }
  };

  const handleOnSelectMunicipality = (event, value, reason) => {
    if (reason === 'clear') {
      setFormData((prev) => ({
        ...prev,
        municipality: {},
      }));
    } else if (reason === 'selectOption') {
      setFormData((prev) => ({
        ...prev,
        municipality: value,
      }));
      setFormErrors((prev) => ({
        ...prev,
        municipality: false,
      }));
    }
  };

  const handleOnSelectCemetery = (event, value, reason) => {
    if (reason === 'clear') {
      setFormData((prev) => ({
        ...prev,
        cemetery: {},
      }));
    } else if (reason === 'selectOption') {
      setFormData((prev) => ({
        ...prev,
        cemetery: value,
      }));
      setFormErrors((prev) => ({
        ...prev,
        cemetery: false,
      }));
    }
  };

  let formInputs = [
    {
      label: 'select_country',
      type: 'autocomplete',
      placeholder: 'select_country',
      value: formData.country,
      options: countries,
      autoComplete: 'off',
      error: formErrors.country,
      errorKey: 'select_country_error',
      disabled: false,
      action: handleOnSelectCountry,
    },
    {
      label: 'select_municipality',
      type: 'autocomplete',
      placeholder: 'select_municipality',
      value: formData.municipality,
      options: municipalityList,
      autoComplete: 'off',
      error: formErrors.municipality,
      errorKey: 'select_municipality_error',
      disabled: isEmpty(formData.country),
      action: handleOnSelectMunicipality,
    },
    {
      label: 'select_cemetery',
      type: 'autocomplete',
      placeholder: 'select_cemetery',
      value: formData.cemetery,
      options: cemeteryList,
      autoComplete: 'off',
      error: formErrors.cemetery,
      errorKey: 'select_cemetery_error',
      disabled: isEmpty(formData.municipality),
      action: handleOnSelectCemetery,
    },
  ];

  return (
    <div className='first-step'>
      {formInputs.map((item, index) => (
        <Input key={index} item={item} />
      ))}
      <div className='second-step-buttons'>
        <PrimaryButton
          children={<FormattedMessage id='auth_back' />}
          onClick={onBackClick}
          style={{
            background: '#fff',
            border: '1px solid #404D56',
            color: '#404D56',
            padding: '7px 24px',
          }}
        />
        <PrimaryButton
          children={<FormattedMessage id='create_the_grave' />}
          onClick={onDone}
          style={{
            padding: '8px 24px',
          }}
        />
      </div>
    </div>
  );
};

export default EndStep;
