import React, { useState } from 'react';
import styles from './AnniversaryCard.module.css';
import CardHeader from './CardHeader';
import Anniversary from './Anniversary';
import AnniversaryFormModal from '../AnniversaryForm/AnniversaryFormModal';
import { FormattedMessage, useIntl } from 'react-intl';
import { request } from '../../../../../service/request';
import showUserNotification from '../../../../../components/UserNotification/showUserNotification';
import ProcessModal from '../../../../Payment/atoms/ProcessModal';

const AnniversaryCard = ({
  titleKey,
  anniversaries,
  isPublic,
  grave,
  setAnniversaries,
  isGraveUser,
}) => {
  const intl = useIntl();
  const [openForm, setOpenForm] = useState(false);
  const [selectedAnniversary, setSelectedAnniversary] = useState({});
  const [openProcessModal, setOpenProcessModal] = useState(false);

  const handleCreateAnniversary = async (data) => {
    setOpenForm(false);
    setOpenProcessModal(true);
    console.log(data.dateTime);
    const payload = {
      grave_anniversary: {
        name: data.name,
        datetime: data.dateTime,
        grave_id: grave.id,
      },
    };
    request('/grave_anniversaries', payload, 'post')
      .then((response) => {
        console.log(response);
        setAnniversaries([response.data, ...anniversaries]);
        setOpenProcessModal(false);
        showUserNotification(
          intl.formatMessage({ id: 'anniversary_created' }),
          'success'
        );
      })
      .catch((error) => {
        console.log(error);
        showUserNotification(
          error.response.data.error || error.message,
          'error'
        );
        setOpenProcessModal(false);
      });
  };

  const handleUpdateAnniversary = async (data, id) => {
    setOpenForm(false);
    setOpenProcessModal(true);
    const payload = {
      grave_anniversary: {
        name: data.name,
        datetime: data.dateTime,
        grave_id: grave.id,
      },
    };
    request(`/grave_anniversaries/${id}`, payload, 'put')
      .then((response) => {
        const updatedAnniversaries = anniversaries.map((anniversary) =>
          anniversary.id === id ? response.data : anniversary
        );
        setAnniversaries(updatedAnniversaries);
        setOpenProcessModal(false);
        setSelectedAnniversary({});
        showUserNotification(
          intl.formatMessage({ id: 'anniversary_updated' }),
          'success'
        );
      })
      .catch((error) => {
        console.log(error);
        showUserNotification(
          error.response.data.error || error.message,
          'error'
        );
        setOpenProcessModal(false);
      });
  };

  const deleteAnniversary = (id) => {
    request(`/grave_anniversaries/${id}`, null, 'delete')
      .then((response) => {
        const updatedAnniversaries = anniversaries.filter(
          (anniversary) => anniversary.id !== id
        );
        setAnniversaries(updatedAnniversaries);
        showUserNotification(
          intl.formatMessage({ id: 'anniversary_delete' }),
          'success'
        );
      })
      .catch((error) => {
        console.log(error);
        showUserNotification(
          error.response.data.error || error.message,
          'error'
        );
      });
  };

  const getTitle = () => {
    return (
      <>
        <FormattedMessage id='create' />{' '}
        <FormattedMessage id='grave_anniversary' />
      </>
    );
  };

  return (
    <div className={styles['container']}>
      <CardHeader
        titleKey={titleKey}
        setOpenForm={setOpenForm}
        isPublic={isPublic}
        isGraveUser={isGraveUser}
      />
      {anniversaries && anniversaries.length > 0 ? (
        anniversaries.map((anniversary) => (
          <Anniversary
            key={anniversary.id}
            isPublic={isPublic}
            anniversary={anniversary}
            setOpenForm={setOpenForm}
            setSelectedAnniversary={setSelectedAnniversary}
            deleteAnniversary={deleteAnniversary}
          />
        ))
      ) : (
        <div className={styles['empty-message']}>
          <FormattedMessage id='empty_anniversaries' />
        </div>
      )}
      {openForm && (
        <AnniversaryFormModal
          title={getTitle()}
          isOpen={openForm}
          onClose={() => {
            setOpenForm(false);
            setSelectedAnniversary({});
          }}
          onCreate={handleCreateAnniversary}
          anniversary={selectedAnniversary}
          handleUpdateAnniversary={handleUpdateAnniversary}
        />
      )}
      {openProcessModal && (
        <ProcessModal
          open={openProcessModal}
          messageKey='create_anniversary_waiting'
        />
      )}
    </div>
  );
};

export default AnniversaryCard;
