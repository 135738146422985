import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import styles from './SelectedMedia.module.css';

const SelectedImage = ({ image, setImage }) => {
  return (
    <div
      className={styles['image-container']}
      style={{
        backgroundImage: `url(${image.url})`,
      }}
    >
      <img
        src={image.url}
        alt='selected-image'
        className={styles['selected-image']}
      />
      <div className={styles['remove-image']}>
        <CloseIcon
          style={{ fontSize: '16px', color: '#404D56' }}
          onClick={() => setImage(null)}
        />
      </div>
    </div>
  );
};

export default SelectedImage;
