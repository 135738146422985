import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import debounce from 'lodash.debounce';
import searchIcon from '../../img/SearchIcon.svg';
import styles from './SearchInput.module.css';

const SearchInput = ({ filterItems, title }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const inputRef = useRef(null);

  const onSearch = useCallback(
    (event) => {
      setSearchQuery(event.target.value);
      filterItems(event.target.value);
    },
    [searchQuery]
  );

  const debouncedChangeHandler = useMemo(
    () => debounce(onSearch, 300),
    [onSearch]
  );

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, [debouncedChangeHandler]);

  return (
    <div className={styles['search-input']}>
      <img src={searchIcon} alt='search' />
      <input
        ref={inputRef}
        type='text'
        className={styles['inputs']}
        onChange={debouncedChangeHandler}
        placeholder={`Søk etter ${title}`}
      />
    </div>
  );
};

export default SearchInput;
