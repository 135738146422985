import React from 'react';
import styles from './DeleteVirtue.module.css';
import DeleteImage from '../../../../img/DeleteImage.svg';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { request } from '../../../../service/request';

const useStyle = makeStyles(() => ({
  rootRemove: {
    width: '50% !important',
    border: '1px solid #eb5757 !important',
    backgroundColor: '#fff !important',
    borderRadius: '0px !important',
    borderBottomLeftRadius: '10px !important',
    height: '50px !important',
    color: '#eb5757 !important',
    fontFamily: 'Roboto !important',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px !important',
    lineHeight: '19px !important',
    textTransform: 'none !important',
    boxShadow: 'none !important',
    '&:hover': {
      boxShadow: 'none !important',
    },
    '@media (max-width: 750px)': {
      width: '170px !important',
      borderRadius: '10px !important',
    },
  },
  rootCancel: {
    width: '50% !important',
    border: 'none !important',
    backgroundColor: '#FEA65A !important',
    borderRadius: '0px !important',
    borderBottomRightRadius: '10px !important',
    height: '50px !important',
    color: '#fff !important',
    fontFamily: 'Roboto !important',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px !important',
    lineHeight: '19px !important',
    textTransform: 'none !important',
    boxShadow: 'none !important',
    '&:hover': {
      boxShadow: 'none !important',
      backgroundColor: '#FEA65A !important',
    },
    '@media (max-width: 750px)': {
      width: '170px !important',
      borderRadius: '10px !important',
    },
  },
}));

const DeleteVirtue = (props) => {
  const classes = useStyle();
  const deleteSuggestion = () => {
    const id = props.chosenSuggestion?.id;
    request(`/grave_personalities/${id}`, null, 'delete')
      .then(() => {
        props.delete(id);
        props.closeModal();
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className={styles['delete-virtue']}>
      <div className={styles['delete-virtue_content']}>
        <div className={styles['delete-virtue_content-header']}>
          <span>Slett fra verdiarkivet</span>
        </div>
        <div className={styles['delete-virtue_content-main']}>
          <img loading='lazy' src={DeleteImage} alt='delete' />
          <span>
            Fjern{' '}
            <span style={{ textTransform: 'capitalize', color: '#36434B' }}>
              {props.graveName}
            </span>{' '}
            verdi <span> {props.chosenSuggestion?.personality}</span> fra
            graven?
          </span>
        </div>
      </div>
      <div className={styles['delete-virtue_buttons']}>
        <Button
          variant='outlined'
          className={classes.rootRemove}
          onClick={deleteSuggestion}
        >
          Fjern
        </Button>
        <Button
          variant='contained'
          className={classes.rootCancel}
          onClick={props.closeModal}
        >
          Avbryt
        </Button>
      </div>
    </div>
  );
};

export default DeleteVirtue;
