import React, { useState, useEffect } from 'react';
import styles from './AboutUser.module.css';
import isEmpty from 'lodash.isempty';
import UploadUserAvatar from './UploadUserAvatar';
import PrimaryButton from '../../../components/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { fetch_request, request } from '../../../service/request';
import { useSelector } from 'react-redux';
import UserConnectionModal from './UserConnectionModal/UserConnectionModal';
import DoneIcon from '@mui/icons-material/Done';
import showUserNotification from '../../../components/UserNotification/showUserNotification';

const AboutUser = ({ user, setCurrentUser }) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const [relationList, setRelationList] = useState([]);
  const [directionsList, setDirectionsList] = useState([]);
  const [requestAlreadyInQueue, setRequestAlreadyInQueue] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const isAuth = useSelector((state) => state.auth.isAuthorized);
  const canSendRequest = user?.can_send_request;
  const isPendingApproval = user?.is_pending_approval;

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    fetch_request('/api/relations', requestOptions)
      .then((response) => response.json())
      .then((relations) => setRelationList(relations))
      .catch((err) => console.log(err));
    setRequestAlreadyInQueue(false);
  }, [user]);

  useEffect(() => {
    request('/relation_directions')
      .then((res) => {
        setDirectionsList(res.data?.list || []);
      })
      .catch((error) => console.log(error));
  }, []);

  const isUserCurrentUser = () => {
    return (
      user.slug === localStorage.getItem('user-slug') ||
      user.slug === sessionStorage.getItem('user-slug')
    );
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const userCanSetRelation = () => {
    return (
      !isUserCurrentUser() &&
      !requestAlreadyInQueue &&
      !isPendingApproval &&
      canSendRequest &&
      isAuth
    );
  };

  const handleRequestAlreadyInQueue = () => {
    setRequestAlreadyInQueue(true);
  };

  const dateOfBirth = () => {
    return !isEmpty(user) && user?.date_of_birth
      ? `${new Date(user.date_of_birth).getDate()} ${intl.formatMessage({
          id: `month_${new Date(user.date_of_birth).getMonth()}`,
        })}, ${new Date(user.date_of_birth).getFullYear()}`
      : '';
  };

  const relationInQueue = () => {
    return requestAlreadyInQueue || isPendingApproval;
  };

  const onConfirm = async (relation, reciprocalRelation) => {
    const payload = {
      slug: user.slug,
      relation_id: relation.id,
      reciprocal_relation_id: reciprocalRelation.id,
    };

    await request('/user_connections', payload, 'post')
      .then((res) => res.data)
      .then((data) => {
        handleRequestAlreadyInQueue();
        handleCloseModal();
        showUserNotification(
          intl.formatMessage({ id: 'relation_request_send' }),
          'success'
        );
      })
      .catch((error) => {
        showUserNotification(
          error.response.data.error || error.message,
          'error'
        );
        console.log(error);
      });
  };

  return (
    <div className={styles['container']}>
      <UploadUserAvatar user={user} setCurrentUser={setCurrentUser} />
      <div className={styles['sub-container']}>
        <div className={styles['user-info']}>
          <div className={styles['name']}>{user?.full_name}</div>
          <div className={styles['country']}>{user?.occupation}</div>
          <div className={styles['country']}>{dateOfBirth()}</div>
        </div>
        <div>
          {userCanSetRelation() && (
            <PrimaryButton
              onClick={handleOpenModal}
              children={<FormattedMessage id='set_relation' />}
            />
          )}
          {relationInQueue() && (
            <div className={styles['user-confirmation']}>
              <DoneIcon />
              <FormattedMessage id='request_send' />
            </div>
          )}
        </div>
        {openModal && (
          <UserConnectionModal
            openModal={handleOpenModal}
            closeModal={handleCloseModal}
            relationsList={relationList}
            user={user}
            directionsList={directionsList}
            onConfirm={onConfirm}
          />
        )}
      </div>
    </div>
  );
};

export default AboutUser;
