import React from 'react';
import { FormattedMessage } from 'react-intl';

const Heading = ({ grave }) => {
  return (
    <div>
      <FormattedMessage
        id={'set_relation_heading'}
        values={{ grave: grave.name }}
      />
    </div>
  );
};

export default Heading;
