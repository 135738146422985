import { sparksList, nextCursor, sparksComments } from '../actions';

const sparkState = {
  sparksList: [],
  sparksComments: {},
  nextCursor: null,
};

export const sparksReducer = (state = sparkState, action) => {
  switch (action.type) {
    case sparksList:
      return {
        ...state,
        sparksList: action.payload,
      };
    case nextCursor:
      return {
        ...state,
        nextCursor: action.payload,
      };
    case sparksComments:
      return {
        ...state,
        sparksComments: action.payload,
      };

    default:
      return state;
  }
};
