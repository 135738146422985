import React from 'react';
import IframeComponent from '../../../components/Iframe/IframeComponent';

const ConsciousPlus = () => {
  return (
    <IframeComponent
      title='Conscious plus'
      source='https://www.wix.nettgrav.no/consciousplus'
    />
  );
};

export default ConsciousPlus;
