import React, { useState } from 'react';
import './HeaderTabs.css';
import Earth from '../../../img/Earth_grey.svg';
import ActiveEarth from '../../../img/ActiveHome.svg';
import DisabledGrave from '../../../img/DisabledGrave.svg';
import ActiveGrave from '../../../img/ActiveGrave.svg';
import { Tab, Tabs } from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setScreenRedux } from '../../../redux/actions';

const AuthorizedHeader = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState();
  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(
      setScreenRedux(newValue === 0 ? 'search' : newValue === 1 ? 'graver' : '')
    );
  };
  return (
    <div className='authorized-header-full'>
      <Tabs
        value={value}
        onChange={handleChange}
        variant='scrollable'
        id='id223223'
        // indicatorColor="primary"
        textColor='primary'
        aria-label='scrollable force tabs example'
        // classes={{indicator: classes.indicator}}
      >
        <Tab
          icon={
            <img
              loading='lazy'
              src={value === 0 ? ActiveEarth : Earth}
              alt='Earth'
            />
          }
          {...a11yProps(0)}
          to='/'
          component={Link}
        />
        <Tab
          icon={
            <img
              loading='lazy'
              src={value === 1 ? ActiveGrave : DisabledGrave}
              alt='Grave'
            />
          }
          {...a11yProps(1)}
          to='/graves'
          component={Link}
        />
      </Tabs>
    </div>
  );
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

export default AuthorizedHeader;
