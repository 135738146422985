import React from 'react';
import styles from './GraveWall.module.css';

const Header = (props) => {
  return (
    <div
      className={styles['grave-wall-item-header']}
      onClick={() => {
        props.setIsExpanded(!props.isExpanded);
      }}
    >
      <div className={styles['grave-wall-heading']}>{props.title}</div>
      {props?.children && props.children}
    </div>
  );
};

export default Header;
