import React, { useRef, useState, useEffect} from 'react';
import './LearnMoreFourth.css';
import {Link} from "react-router-dom";

import MainVideo from '../../../../img/5th_Mob_whitebg.mp4';
import Relation from '../../../../img/LearnMoreFourthRelation.svg';
import Number from '../../../../img/LearnMoreThree.svg';

const LearnMoreFourth = (props) => {
    const vidRef = useRef(null);
    const handlePlayVideo = () => {
      vidRef.current.play();
    }
    const [scrollY, setScrollY] = useState(0);

    const updateYOffSet = () => {
      setScrollY(window.pageYOffset)
    }

    useEffect(() => {
      function watchScroll() {
        window.addEventListener("scroll", updateYOffSet);
      }

      watchScroll()
      return () => window.removeEventListener("scroll", updateYOffSet);
    });

    useEffect(() => {
      let elem = document.querySelector('.learn-more_fourth-content_images')
      if ( isVideoScrolledIntoView(elem)) {
        vidRef.current.play()
      } else {
        vidRef.current.pause()
      }
    }, [scrollY])

    const isVideoScrolledIntoView = (el) => {
      var rect = el.getBoundingClientRect();
      var elemTop = rect.top;

      var isVisible = (elemTop <= 500 && elemTop > 0)

      return isVisible;
    }

    return(
        <div className='learn-more_fourth'>
            <div className="learn-more_third-content fourth">
                <div className="learn-more_fourth-content_images">
                    <video ref={vidRef} onFocus={handlePlayVideo} loop muted="muted">
                      <source src={MainVideo} type="video/mp4" />
                    </video>
                </div>
                <div className="learn-more_third-content_empty fourth">
                    <img loading='lazy' src={Number} alt='Number'/>
                </div>
                <div className="learn-more_second-content_main-text third">
                    <div className="learn-more_second-content_main-text_header">
                        <img loading='lazy' src={Relation} alt='Certificate' className='learn-more_second-content_main-text_header-image'/>
                        <span className='learn-more_second-content_main-text_header-title'>Flere graver og gravlagte?</span>
                        <img loading='lazy' src={Number} alt='Number' className='learn-more_second-content_main-text_header-number'/>
                    </div>
                    <div className="learn-more_second-content_main-text_title">
                        <span>En Nettgrav er tilknyttet en person. For å koble gravlagte sammen, kan eiere og administratorer etablere relasjoner mellom flere graver. Dette vil koble gravlagte sammen, som en utvidet familiegrav.</span>
                    </div>
                </div>
            </div>
            <Link to='/ny-grav' style = {{textDecoration: "none"}}><div className = 'added'>Legg til gravlagte</div></Link>
        </div>
    )
}

export default LearnMoreFourth;