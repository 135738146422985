import React, { useEffect, useState } from 'react';
import EditPerson from '../../../img/EditSmallIcon.svg';
import styles from './SettingsPersonalInformation.module.css';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from 'lodash.isempty';
import { FormattedMessage, useIntl } from 'react-intl';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { request } from '../../../service/request';
import CustomModal from '../../../components/CustomModal';
import VerificationCodeInput from '../../../components/VerificationCodeInput/VerificationCodeInput';
import showUserNotification from '../../../components/UserNotification/showUserNotification';
import PrimaryButton from '../../../components/PrimaryButton';
import { setInformationAboutUser } from '../../../redux/actions';
import ProcessModal from '../../Payment/atoms/ProcessModal';
import SettingsPersonalInformationEdit from './SettingsPageInformationEdit';

const SettingsPersonalInformation = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const user = useSelector((state) => state.user.user || {});
  const [openVerifyModal, setOpenVerifyModal] = useState(false);
  const [verificationCode, setVerificationCode] = useState(Array(5).fill(''));
  const [openProcessModal, setOpenProcessModal] = useState(false);
  const [messageKey, setMessageKey] = useState('');
  const [edit, setEdit] = useState(false);

  const isCodeComplete = verificationCode.every((digit) => digit !== '');

  const VeriFicationContent = () => {
    return (
      <React.Fragment>
        <VerificationCodeInput
          values={verificationCode}
          setValues={setVerificationCode}
        />
        <div className={styles['btn-container']}>
          <PrimaryButton
            children={<FormattedMessage id='auth_save' />}
            onClick={verifyAuthCode}
            disabled={!isCodeComplete}
          />
        </div>
      </React.Fragment>
    );
  };

  const verifyEmail = () => {
    setMessageKey('sending_verification_email_waiting');
    setOpenProcessModal(true);
    const payload = {
      user: {
        user_id: user.id,
        email: user.email,
      },
    };
    request(
      `/account_confirmations/resend_account_confirmation_email`,
      payload,
      'post'
    )
      .then((response) => {
        setOpenProcessModal(false);
        setMessageKey('');
        showUserNotification(
          intl.formatMessage({ id: 'sending_verification_email' }),
          'success'
        );
      })
      .catch((err) => {
        setOpenProcessModal(false);
        setMessageKey('');
        showUserNotification(err.response.data.error || err.message, 'error');
      });
  };

  const verifyPhoneNumber = () => {
    setMessageKey('sending_verification_waiting');
    setOpenProcessModal(true);
    const payload = {
      generate_auth_token: {
        country_id: user.country_id,
        phone_number: user.phone_number,
      },
    };
    request(`/generate_auth_token`, payload, 'post')
      .then((response) => {
        showUserNotification(
          intl.formatMessage({ id: 'sending_verification_code' }),
          'success'
        );
        setOpenProcessModal(false);
        setMessageKey('');
        setOpenVerifyModal(true);
      })
      .catch((err) => {
        setOpenProcessModal(false);
        setMessageKey('');
        showUserNotification(err.response.data.error || err.message, 'error');
      });
  };

  const verifyAuthCode = () => {
    const code = verificationCode.join('');
    const payload = {
      generate_auth_token: {
        auth_code: code,
      },
    };
    request(`/generate_auth_token/verify_phone_number`, payload, 'post')
      .then((response) => {
        const updatedValue = {
          user: {
            ...user,
            is_phone_number_verified: true,
          },
        };
        dispatch(setInformationAboutUser(updatedValue));
        localStorage.setItem('isNumber-verified', true);
        setOpenVerifyModal(false);
        setVerificationCode(Array(5).fill(''));
        showUserNotification(
          intl.formatMessage({ id: 'authCode_verified' }),
          'success'
        );
      })
      .catch((err) => {
        setOpenVerifyModal(false);
        setVerificationCode(Array(5).fill(''));
        showUserNotification(err.response.data.error || err.message, 'error');
      });
  };

  return (
    <React.Fragment>
      {!edit ? (
        <div className={styles['settings-personal-information']}>
          <div
            className={styles['settings-personal-information_content-header']}
          >
            <span
              className={
                styles['settings-personal-information_content-header_title']
              }
            >
              <FormattedMessage id='settings_personalInformation' />
            </span>
            <div className={styles['action-btn']} onClick={() => setEdit(true)}>
              <img src={EditPerson} alt='Edit' />
              <button type='button'>
                <FormattedMessage id='settings_edit' />
              </button>
            </div>
          </div>
          <div className={styles['settings-user-information-container']}>
            <div className={styles['settings-user-information']}>
              <div className={styles['settings-labels']}>
                <FormattedMessage id='settings_fullName' />:
              </div>
              <div className={styles['user-info']}>
                {Array.isArray(user).length !== 0 ? user.full_name : ''}
              </div>
            </div>
            <div className={styles['settings-user-information']}>
              <div className={styles['settings-labels']}>
                <FormattedMessage id='settings_gender' />:
              </div>
              <div className={styles['user-info']}>
                {!isEmpty(user) ? <FormattedMessage id={user.gender} /> : ''}
              </div>
            </div>
            <div className={styles['settings-user-information']}>
              <div className={styles['settings-labels']}>
                <FormattedMessage id='settings_occupation' />:
              </div>
              <div className={styles['user-info']}>
                {user.occupation === 'null' ? '' : user.occupation}
              </div>
            </div>
            <div className={styles['settings-user-information']}>
              <div className={styles['settings-labels']}>
                <FormattedMessage id='settings_birthday' />:
              </div>
              <div className={styles['user-info']}>
                {!isEmpty(user) && user.date_of_birth
                  ? `${new Date(
                      user.date_of_birth
                    ).getDate()} ${intl.formatMessage({
                      id: `month_${new Date(user.date_of_birth).getMonth()}`,
                    })}, ${new Date(user.date_of_birth).getFullYear()}`
                  : ''}
              </div>
            </div>
            <div className={styles['settings-user-information']}>
              <div className={styles['settings-labels']}>
                <FormattedMessage id='settings_email' />:
              </div>
              <div className={styles['user-info']}>
                {!isEmpty(user) && user.email && (
                  <>
                    {user.email.toString()}
                    {user.is_email_verified ? (
                      <VerifiedUserIcon
                        style={{ color: '#04C600', fontSize: '16px' }}
                      />
                    ) : (
                      <span
                        className={styles['verification']}
                        onClick={() => verifyEmail()}
                      >
                        <FormattedMessage id='verify_now' />
                      </span>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className={styles['settings-user-information']}>
              <div className={styles['settings-labels']}>
                <FormattedMessage id='settings_telephoneNumber' />:
              </div>
              <div className={styles['user-info']}>
                {!isEmpty(user) && user.phone_number && (
                  <>
                    {user.phone_number}
                    {user.is_phone_number_verified ? (
                      <VerifiedUserIcon
                        style={{ color: '#04C600', fontSize: '16px' }}
                      />
                    ) : (
                      <span
                        className={styles['verification']}
                        onClick={() => verifyPhoneNumber()}
                      >
                        <FormattedMessage id='verify_now' />
                      </span>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className={styles['settings-user-information']}>
              <div className={styles['settings-labels']}>
                <FormattedMessage id='settings_address' />:
              </div>
              <div className={styles['user-info']}>
                {!isEmpty(user) ? user.address : ''}
              </div>
            </div>
            <div className={styles['settings-user-information']}>
              <div className={styles['settings-labels']}>
                <FormattedMessage id='settings_language' />:
              </div>
              <div className={styles['user-info']}>
                {!isEmpty(user.preferred_language)
                  ? user.preferred_language?.dialect
                  : ''}
              </div>
            </div>
            {/* <div className={styles['settings-user-information']}>
          <div className={styles['settings-labels']}>
            <FormattedMessage id='settings_currency' />:
          </div>
          <div className={styles['user-info']}>
            {!isEmpty(user.preferred_currency)
              ? user.preferred_currency?.notation
              : ''}
          </div>
        </div> */}
            {/* <div className={styles['settings-user-information']}>
          <div className={styles['settings-labels']}>
            <FormattedMessage id='settings_timeZone' />:
          </div>
          <div className={styles['user-info']}>
            {!isEmpty(user) ? user.time_zone : ''}
          </div>
        </div> */}
          </div>
          {openVerifyModal && (
            <CustomModal
              isOpen={openVerifyModal}
              closeModal={() => {
                setVerificationCode(Array(5).fill(''));
                setOpenVerifyModal(false);
              }}
              title={<FormattedMessage id='Enter Verification Code' />}
              children={<VeriFicationContent />}
            />
          )}
          {openProcessModal && (
            <ProcessModal open={openProcessModal} messageKey={messageKey} />
          )}
        </div>
      ) : (
        <SettingsPersonalInformationEdit setEdit={setEdit} />
      )}
    </React.Fragment>
  );
};

export default SettingsPersonalInformation;
