import React, { useRef, useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './RichInput.module.css';

const ContentEditable = ({
  onInput,
  value,
  editable = true,
  placeholderKey = '',
  style,
}) => {
  const intl = useIntl();
  const editableDivRef = useRef(null);
  const [showToolbar, setShowToolbar] = useState(false);
  const [selectionRange, setSelectionRange] = useState(null);

  useEffect(() => {
    if (editableDivRef.current && value) {
      editableDivRef.current.innerHTML = value;
    }
  }, []);

  useEffect(() => {
    if (editableDivRef.current && !value) {
      editableDivRef.current.innerHTML = '';
    }
  }, [value]);

  const handleInput = () => {
    const innerHTML = editableDivRef.current.innerHTML;
    onInput(innerHTML);
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedText = clipboardData.getData('Text');

    const urlPattern = /https?:\/\/[^\s/$.?#].[^\s]*/g;
    let formattedText = pastedText;

    if (urlPattern.test(pastedText)) {
      formattedText = pastedText.replace(urlPattern, (url) => {
        return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
      });
    }

    document.execCommand('insertHTML', false, formattedText);
    handleInput();
  };

  useEffect(() => {
    const editableDiv = editableDivRef.current;
    if (editableDiv) {
      editableDiv.addEventListener('input', handleInput);
      editableDiv.addEventListener('paste', handlePaste);
      editableDiv.addEventListener('mouseup', handleSelectText);
      editableDiv.addEventListener('keyup', handleSelectText);
    }

    return () => {
      if (editableDiv) {
        editableDiv.removeEventListener('input', handleInput);
        editableDiv.removeEventListener('paste', handlePaste);
        editableDiv.removeEventListener('mouseup', handleSelectText);
        editableDiv.removeEventListener('keyup', handleSelectText);
      }
    };
  }, [selectionRange]);

  const handleSelectText = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0 && !selection.isCollapsed) {
      setSelectionRange(selection.getRangeAt(0));
      setShowToolbar(true);
    } else {
      setShowToolbar(false);
    }
  };

  const applyFormatting = (tag) => {
    if (selectionRange) {
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(selectionRange);

      document.execCommand(tag, false, null);
      handleInput();
      setShowToolbar(false);
    }
  };

  return (
    <div className={styles['container']} style={style}>
      {editable && !value && (
        <div className={styles['placeholder']}>
          <FormattedMessage id={placeholderKey} />
        </div>
      )}
      <div
        ref={editableDivRef}
        className={styles['richInput']}
        contentEditable={editable}
        aria-placeholder={intl.formatMessage({ id: 'comment_placeholder' })}
        aria-label={intl.formatMessage({ id: 'comment_placeholder' })}
        spellCheck='false'
      />
    </div>
  );
};

export default ContentEditable;
