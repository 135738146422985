import React from 'react';
import styles from './Plans.module.css';
import { FormattedMessage } from 'react-intl';
import { planKey } from '../../../../service/PlanKey';

const Description = ({ plan }) => {
  return (
    <div className={styles['product_description']}>
      <FormattedMessage id={`${planKey(plan.key)}_description`} />
    </div>
  );
};

export default Description;
