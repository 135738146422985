import React, { useState, useEffect } from 'react';
import GroupButton from './GroupButton';
import Members from './Members';
import TotalRemove from './TotalRemove';
import TotalAdded from './TotalAdded';
import { CONSTANTS } from '../../../../service/constants';
import SubscriptionButton from './SubscriptionButton';
import ProcessModal from '../../../Payment/atoms/ProcessModal';
import ErrorModal from '../../../Payment/atoms/ErrorModal/ErrorModal';
import { FormattedMessage, useIntl } from 'react-intl';
import showUserNotification from '../../../../components/UserNotification/showUserNotification';
import styles from './ManageMembers.module.css';
import Loader from '../../../../components/Loader/Loader';
import { request } from '../../../../service/request';

const ManageMembers = ({
  currentSubscription,
  setOpenOptionsModal,
  fetchSubscriptionData,
}) => {
  const intl = useIntl();
  const [subscriptionTotalMembers, setSubscriptionTotalMembers] = useState(1);
  const [planAction, setPlanAction] = useState('add');
  const [subscriptionValidation, setSubscriptionValidation] = useState({});
  const [showMembers, setShowMembers] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [subscription, setSubscription] = useState({});
  const [totalRemainingMembers, setTotalRemainingMembers] = useState(0);

  const handleShowModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const fetchCurrentSubscription = async (id) => {
    try {
      const response = await fetch(
        `${CONSTANTS.baseUrl}/api/subscriptions/${id}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      if (!response.ok) {
        setLoading(false);
      }
      const data = await response.json();
      setSubscription(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (currentSubscription.id) {
      fetchCurrentSubscription(currentSubscription.id);
    }
  }, [currentSubscription.id]);

  const handelAction = (action) => {
    setPlanAction(action);
    setShowMembers(false);
    setSubscriptionValidation({});
    setSubscriptionTotalMembers(1);
    setTotalRemainingMembers(0);
    const newArray = subscription.subscription_members.map((user) => {
      return { ...user, status: 'active' };
    });
    setSubscription((prev) => ({ ...prev, subscription_members: newArray }));
  };

  const updateSubscription = async () => {
    if (showMembers) {
      return showUserNotification(
        intl.formatMessage({ id: 'remove_members_warning' }),
        'warning'
      );
    }
    const userCount =
      planAction === 'add'
        ? subscriptionTotalMembers + subscription.user_count
        : subscription.user_count - subscriptionTotalMembers;

    if (userCount === 0) {
      showUserNotification(
        intl.formatMessage({ id: 'not_remove_all' }),
        'warning'
      );
      return;
    }

    subscription.subscription_members.map((member) => {
      if (member.status === 'requested_to_remove') {
        handelRemoveMember(member);
      }
    });

    handleShowModal();
    let requestPayload = {
      subscription: {
        user_count: parseInt(userCount),
      },
    };
    try {
      const response = await fetch(
        `${CONSTANTS.baseUrl}/api/subscriptions/${subscription.id}.json`,
        {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestPayload),
        }
      );
      if (!response.ok) {
        showUserNotification(
          intl.formatMessage({ id: 'something_wrong' }),
          'error'
        );
        handleCloseModal();
        setErrorMessage(intl.formatMessage({ id: 'something_wrong' }));
        setOpenErrorModal(true);
        return;
      }
      const data = await response.json();
      if (data) {
        setSubscriptionTotalMembers(1);
        showUserNotification(
          intl.formatMessage({
            id:
              planAction === 'add'
                ? 'add_members_message'
                : 'remove_members_message',
          }),
          'success'
        );
        setOpenOptionsModal(false);
        fetchSubscriptionData();
      }
    } catch (error) {
      handleCloseModal();
      setErrorMessage(intl.formatMessage({ id: 'something_wrong' }));
      setOpenErrorModal(true);
    }
  };

  const updateSubscriptionValidation = (field, member) => {
    if (field === 'invitation') {
      const newArray = subscription.subscription_invitations.filter(
        (invitation) => invitation.invitation_id !== member.invitation_id
      );
      setSubscription({
        ...subscription,
        subscription_invitations: newArray,
      });
      if (totalRemainingMembers - 1) {
        setTotalRemainingMembers(totalRemainingMembers - 1);
      } else {
        setTotalRemainingMembers(totalRemainingMembers - 1);
        setShowMembers(false);
      }
    } else if (field === 'members') {
      const newArray = subscription.subscription_members.map((user) => {
        if (user.id === member.id) {
          return member;
        } else {
          return user;
        }
      });
      setSubscription({
        ...subscription,
        subscription_members: newArray,
      });
      if (member.status === 'active') {
        setTotalRemainingMembers(totalRemainingMembers + 1);
      } else if (totalRemainingMembers - 1) {
        setTotalRemainingMembers(totalRemainingMembers - 1);
      } else {
        setTotalRemainingMembers(totalRemainingMembers - 1);
        setShowMembers(false);
      }
    }
  };

  const handelRemoveMember = (member) => {
    if (member.id) {
      const payload = {
        subscription_members: {
          status: member.status,
        },
      };
      request(`/subscription_members/${member.id}`, payload, 'put')
        .then((res) => {
          const newArray = subscription.subscription_members.map((user) => {
            if (user.id === member.id) {
              return { ...user, status: res.data.status };
            } else {
              return user;
            }
          });
          setSubscription({
            ...subscription,
            subscription_members: newArray,
            remaining_count: subscription.remaining_count - 1,
          });

          showUserNotification(
            intl.formatMessage({ id: 'members_removed' }),
            'success'
          );
        })
        .catch((err) => {
          showUserNotification(
            intl.formatMessage({ id: 'something_wrong' }),
            'error'
          );
        });
    }
  };

  const handelRemoveInvitation = (id) => {
    console.log(id);

    if (id) {
      request(`/subscription_invitations/${id}`, null, 'delete')
        .then((res) => {
          const newArray = subscription.subscription_invitations.filter(
            (invitation) => invitation.invitation_id !== id
          );
          setSubscription({
            ...subscription,
            subscription_invitations: newArray,
            remaining_count: subscription.remaining_count - 1,
          });
          if (totalRemainingMembers - 1) {
            setTotalRemainingMembers(totalRemainingMembers - 1);
          } else {
            setTotalRemainingMembers(totalRemainingMembers - 1);
            setShowMembers(false);
          }
          showUserNotification(
            intl.formatMessage({ id: 'invitation_removed' }),
            'success'
          );
        })
        .catch((err) => {
          showUserNotification(
            intl.formatMessage({ id: 'something_wrong' }),
            'error'
          );
        });
    }
  };

  const addMembers = () => {
    const totalMember = subscriptionTotalMembers + 1;
    if (
      planAction === 'remove' &&
      totalMember <= subscription.remaining_count
    ) {
      setShowMembers(false);
      if (subscriptionValidation.can_change_plan) {
        setSubscriptionValidation({});
      }
      setSubscriptionTotalMembers(totalMember);
    } else {
      if (totalMember < subscription.user_count) {
        setSubscriptionTotalMembers(totalMember);
        setTotalRemainingMembers(totalRemainingMembers + 1);
        setShowMembers(true);
      }
    }
    if (planAction === 'add') {
      setShowMembers(false);
      setSubscriptionTotalMembers(totalMember);
    }
  };

  const removeMembers = () => {
    if (subscriptionTotalMembers > 1) {
      const totalMember = subscriptionTotalMembers - 1;
      if (
        totalMember <= subscription.remaining_count &&
        planAction === 'remove'
      ) {
        setShowMembers(false);
        const newArray = subscription.subscription_members.map((member) => {
          return { ...member, status: 'active' };
        });
        setSubscription((prev) => ({
          ...prev,
          subscription_members: newArray,
        }));
        if (subscriptionValidation.can_change_plan) {
          setSubscriptionValidation({});
        }
        setSubscriptionTotalMembers(totalMember);
        setTotalRemainingMembers(0);
      } else {
        setSubscriptionTotalMembers(totalMember);
        setTotalRemainingMembers(totalRemainingMembers - 1);
      }
      if (planAction === 'add') {
        setShowMembers(false);
        setSubscriptionTotalMembers(totalMember);
      }
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <div className={styles['container']}>
        <GroupButton handelAction={handelAction} planAction={planAction} />
        <TotalRemove
          addMembers={addMembers}
          removeMembers={removeMembers}
          totalCount={subscriptionTotalMembers}
          planAction={planAction}
          currentSubscription={subscription}
          subscriptionValidation={subscriptionValidation}
        />
        <TotalAdded
          addMembers={addMembers}
          removeMembers={removeMembers}
          totalCount={subscriptionTotalMembers}
          planAction={planAction}
          currentSubscription={currentSubscription}
        />
        {showMembers && (
          <div className={styles['warning-message']}>
            <FormattedMessage
              id='remove_members_warring'
              values={{
                remainingNumber: totalRemainingMembers,
              }}
            />
          </div>
        )}
        {showMembers &&
          (subscription.subscription_members.length > 0 ||
            subscription.subscription_invitations.length > 0) && (
            <Members
              subscription={subscription}
              updateSubscriptionValidation={updateSubscriptionValidation}
              handelRemoveInvitation={handelRemoveInvitation}
            />
          )}
      </div>
      {planAction === 'add' && (
        <SubscriptionButton
          onClickHandler={updateSubscription}
          titleKey='continue'
        />
      )}
      {planAction === 'remove' && (
        <SubscriptionButton
          onClickHandler={updateSubscription}
          titleKey='continue'
        />
      )}

      {openModal && (
        <ProcessModal open={openModal} messageKey={'payment_progress'} />
      )}
      {openErrorModal && (
        <ErrorModal
          message={errorMessage}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
        />
      )}
    </React.Fragment>
  );
};

export default ManageMembers;
