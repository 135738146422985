import React from 'react';
import IframeComponent from '../../components/Iframe/IframeComponent';

const Plans = (props) => {
  return (
    <IframeComponent
      title="Plans"
      source="https://www.wix.nettgrav.no/familyplan"
    />
  )
};

export default Plans;
