import React from 'react';
import { Modal, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import './Modal/Modal.css';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: 'maxContent',
    backgroundColor: 'white',
    padding: '16',
    borderRadius: '4px',
    outline: 'none',
  },
  modalBackground: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}));

const ProcessModal = ({ open, messageKey }) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      className={classes.modal}
      BackdropProps={{
        className: classes.modalBackground,
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.modalContent}>
        <div className='process-modal'>
          <span>
            <FormattedMessage id={messageKey} />
          </span>
          {<CircularProgress color='secondary' style={{ color: '#fea65a' }} />}
        </div>
      </div>
    </Modal>
  );
};

export default ProcessModal;
