import React, { useEffect } from 'react';
import SparkPost from './SparkPost.jsx';
import Loader from '../../../../../../components/Loader/Loader.jsx';
import styles from './SparksList.module.css';
import { FormattedMessage } from 'react-intl';
import useElementOnScreen from '../../../../../../hooks/useElementOnScreen.jsx';

const SparksList = ({
  sparksList,
  sparkCategories,
  loadMoreSparks,
  loading,
  user,
}) => {
  const [containerRef, isVisible] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 1,
  });

  useEffect(() => {
    if (isVisible && sparksList?.sparks.pageInfo.hasNextPage) {
      loadMoreSparks();
    }
  }, [isVisible]);

  return (
    <div>
      {sparksList?.sparks.edges &&
        sparksList?.sparks.edges.length > 0 &&
        sparksList?.sparks.edges.map(({ node }) => {
          return (
            <SparkPost
              key={node.id}
              spark={node}
              user={user}
              sparkCategories={sparkCategories}
            />
          );
        })}
      {sparksList?.sparks.edges.length === 0 && !loading && (
        <div className={styles['empty-grave-container']}>
          <div className={styles['empty-grave ']}>
            <FormattedMessage id='empty_sparks' />
          </div>
        </div>
      )}
      {!loading && <div ref={containerRef} />}
      {loading && <Loader />}
    </div>
  );
};

export default SparksList;
