import React from 'react';
import WarningMessage from '../Input/WarningMessage';
import GenderButton from './GenderButton';
import styles from './GenderButton.module.css';

const GenderGroup = ({ item }) => {
  return (
    <React.Fragment>
      <div className={styles['gender-container']}>
        {item.gendersList.map((gender, index) => (
          <GenderButton gender={gender} key={index} item={item} />
        ))}
      </div>
      {item.error && <WarningMessage messageKey={item.errorKey} />}
    </React.Fragment>
  );
};

export default GenderGroup;
