import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styles from './PostDetails.module.css';
import MusicTab from '../../../../img/MusicTab.svg';
import VideoTab from '../../../../img/VideoTab.svg';
import PictureTab from '../../../../img/PictureTab.svg';
import { Avatar, Button, Menu, MenuItem, Tab, Tabs } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  addCommentToMemory,
  addReplyToComment,
  deleteCommentFromMemory,
  deleteReplyFromComment,
  editCommentToMemory,
  editMemory as editMemoryRedux,
  editReplyToComment,
  removeMemory,
  goToChosenGrave,
  addCommentOnToggle,
  addComment,
  addProfilePhotoList,
  saveListOfPhotosFromCropper,
  setGraveUsersList,
} from '../../../../redux/actions';
import Fade from '@mui/material/Fade';
import Dots from '../../../../img/DotsMenu.svg';
import NextPhotoPointer from '../../../../img/OrangePointerNext.svg';
import PrevPhotoPointer from '../../../../img/OrangePointerPrev.svg';
import { request } from '../../../../service/request';
import MenuItemBlock from './icon/MenuItemBlock';
import MenuItemBlockLast from './icon/MenuItemBlockLast';
import EditPen from '../../../../img/EditPen.svg';
import MenuBin from '../../../../img/MenuBin.svg';
import VideoPost from './VideoPost';
import MusicPost from './MusicPost';
import TabIcon from './icon/MusicIcon';
import Comment from '../../../../components/Comments/Comment';
import CommentsArea from '../../../../components/Comments/CommentCreateForm';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import isEmpty from 'lodash.isempty';
import spinner from '../../../../service/Loading_animation.gif';
import BackPageButton from '../../../../components/BackPageButton/BackPageButton';
import OpenDeleteModal from '../MemoriesPosts/OpenDeleteModal';

const useStyle = makeStyles(() => ({
  rootIndicator: {
    backgroundColor: '#FEA65A',
  },
  rootFlexContainer: {
    display: 'flex !important',
    flexDirection: 'row',
    justifyContent: 'space-between !important',
    width: 'fit-content',
    margin: '0 auto',
  },
  tabItem: {
    margin: '0 auto',
  },
  large: {
    width: '40px',
    height: '40px',
  },
}));

const customStyles = {
  marginBottom: '60px',
};

const PostDetails = () => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const param = useParams();
  const location = useLocation();
  const userId = useSelector((state) => state.user.user.id);
  const [grave, setGrave] = useState();
  const [tabValue, setTabValue] = useState(0);
  const [menuAnchorElem, setMenuAnchorElem] = useState(null);
  const [currentPhoto, setCurrentPhoto] = useState(0);
  const [currentVideo, setCurrentVideo] = useState(0);
  const [amountOfPhotos, setAmountOfPhotos] = useState(0);
  const [commentsOpen, setCommentsOpen] = useState(true);
  const [user, setUser] = useState();
  const [newMemoryText, setNewMemoryText] = useState();
  const [photos, setPhotos] = useState([]);
  const [videos, setVideos] = useState([]);
  const [music, setMusic] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [post, setPost] = useState();
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState();
  const [openDeletedModal, setOpenDeletedModal] = useState(false);

  const photoParams = new URLSearchParams(location.search).get('photo');
  const videoParams = new URLSearchParams(location.search).get('video');
  const musicParams = new URLSearchParams(location.search).get('music');

  const handelOpenDeletedModal = () => setOpenDeletedModal(true);
  const closeDeleteModal = () => {
    setOpenDeletedModal(false);
    handleCloseMenu();
  };

  const userSlug =
    localStorage.getItem('user-slug') || sessionStorage.getItem('user-slug');

  useEffect(() => {
    request(`/users/${userSlug}`)
      .then((res) => {
        setCurrentUser(res.data.user);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [userSlug]);

  useEffect(() => {
    request(`/graves/${param.slug}`, null, 'get')
      .then((res) => {
        dispatch(goToChosenGrave(res.data));
        dispatch(addCommentOnToggle({}));

        if (res.data.grave_images && res.data.grave_images.length > 0) {
          const profilePhotos = res.data.grave_images
            .filter((image) => image.category === 'display')
            .map((el) => ({
              ...el,
              image_comments: el.image_comments?.map((com) => ({
                ...com,
                description: com.comment,
              })),
            }));
          if (res.data.memories && res.data.memories.length > 0) {
            dispatch(addComment(res.data.memories));
          }
          dispatch(addProfilePhotoList(profilePhotos));
          dispatch(saveListOfPhotosFromCropper(res.data.grave_images));
        }
        if (res.data.grave_users && res.data.grave_users.length > 0) {
          dispatch(setGraveUsersList(res.data.grave_users || []));
        }
        setGrave(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [param.grave_slug]);

  useEffect(() => {
    if (!isEmpty(grave)) {
      request(`/memories/${param.memory_id}`)
        .then((res) => {
          setPost(res.data);
          setLoading(false);
          refreshComments(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [grave]);

  useEffect(() => {
    if (!loading && post && Array.isArray(post.contents)) {
      // SET Text

      setNewMemoryText(post.contents[0].description);

      // SET Music
      const musicFromApi = post.contents.filter(
        (content) => content.category === 'audio'
      );

      musicFromApi.length > 0 ? setMusic(musicFromApi) : setMusic([]);

      // SET Photos
      const images = post.contents.filter(
        (content) => content.category === 'image'
      );
      if (images.length > 0) {
        setPhotos(images);
        setAmountOfPhotos(images.length);
      }

      // SET Videos
      const videoFromAPI = post.contents.filter(
        (content) => content.category === 'video'
      );
      videoFromAPI && videoFromAPI.length > 0
        ? setVideos(videoFromAPI)
        : setVideos([]);
    }
  }, [post]);

  useEffect(() => {
    if (videoParams && videos.length > 0) {
      const index = videos.findIndex(
        (video) => video.id.toString() === videoParams
      );
      if (index >= 1) {
        setCurrentVideo(index);
        setTabValue(1);
      } else {
        setCurrentVideo(0);
        setTabValue(1);
      }
    }
    if (photoParams && photos.length > 0) {
      const currentImage = photos.findIndex(
        (image) => image.id.toString() === photoParams
      );
      if (currentImage >= 1) {
        setCurrentPhoto(currentImage);
        setTabValue(0);
      } else {
        setCurrentPhoto(0);
        setTabValue(0);
      }
    }
    if (musicParams && music.length > 0) {
      setTabValue(2);
    }
  }, [photoParams, videoParams, musicParams, photos, videos, music]);

  const createComment = async (messageText) => {
    try {
      const formdata = new FormData();
      formdata.append('comment[description]', messageText);
      formdata.append('comment[memory_id]', post.id);
      await request(`/comments`, formdata, 'post');
      await refreshComments(post);
    } catch (err) {
      console.log(err);
    }
  };
  const refreshComments = async (post) => {
    try {
      const response = await request(`/comments?comment[memory_id]=${post.id}`);
      setPost({
        ...post,
        comments: response.data.comments ? response.data.comments : [],
      });
      dispatch(
        addCommentToMemory(
          post.id,
          response.data.comments ? response.data.comments : []
        )
      );
    } catch (err) {
      console.log(err);
    }
  };
  const editComment = async (comment, newValueOfComment) => {
    try {
      const formdata = new FormData();
      formdata.append('comment[description]', newValueOfComment);
      await request(`/comments/${comment.id}`, formdata, 'put');
      dispatch(
        editCommentToMemory(comment.memory_id, {
          ...comment,
          description: newValueOfComment,
        })
      );
      refreshComments(post);
    } catch (err) {
      console.log(err);
    }
  };
  const deleteComment = async (comment) => {
    try {
      await request(`/comments/${comment.id}`, null, 'delete');
      dispatch(deleteCommentFromMemory(comment.memory_id, comment.id));
      refreshComments(post);
    } catch (error) {
      console.log(error);
    }
  };

  const createReply = async (comment, replyText) => {
    try {
      const formdata = new FormData();
      formdata.append('reply[comment_id]', comment.id);
      formdata.append('reply[description]', replyText);
      const response = await request('/replies', formdata, 'post');
      //response.data.reply_id   need to be saved
      dispatch(
        addReplyToComment(comment.id, comment.memory_id, {
          id: response.data.reply_id,
          user_id: userId,
          description: replyText,
          comment_id: comment.id,
          can_edit: true,
          can_destroy: true,
        })
      );
      refreshComments(post);
    } catch (error) {
      console.log(error);
    }
  };
  const editReply = async (comment, reply, editedReplyText) => {
    const formdata = new FormData();
    formdata.append('reply[description]', editedReplyText);
    await request(`/replies/${reply.id}`, formdata, 'put');
    dispatch(
      editReplyToComment(comment.id, comment.memory_id, {
        ...reply,
        description: editedReplyText,
      })
    );
    refreshComments(post);
  };
  const deleteReply = async (comment, reply) => {
    await request(`/replies/${reply.id}`, null, 'delete');
    dispatch(deleteReplyFromComment(comment.id, comment.memory_id, reply.id));
    refreshComments(post);
  };

  const editMemory = async (e) => {
    try {
      e.preventDefault();
      const formdata = new FormData();
      formdata.append(
        'memory[contents_attributes][][description]',
        newMemoryText
      );
      formdata.append('memory[contents_attributes][][id]', post.contents[0].id);
      await request(`/memories/${post.id}`, formdata, 'put');
      dispatch(
        editMemoryRedux({
          ...post,
          contents: post.contents.map((el, i) =>
            i === 0 ? { ...el, description: newMemoryText } : el
          ),
        })
      );
      const updatePost = {
        ...post,
        contents: post.contents.map((el, i) =>
          i === 0 ? { ...el, description: newMemoryText } : el
        ),
      };
      setPost(updatePost);
      setIsEditing(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleOpenEdit = () => {
    setIsEditing(true);
    handleCloseMenu(null);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === 0) {
      navigate(
        `/deceased/${grave.slug}/memory/${post.id}?photo=${photos[currentPhoto].id}`
      );
    } else if (newValue === 1) {
      navigate(
        `/deceased/${grave.slug}/memory/${post.id}?video=${videos[currentVideo].id}`
      );
    } else if (newValue === 2) {
      navigate(
        `/deceased/${grave.slug}/memory/${post.id}?music=${music[0].id}`
      );
    }
  };

  const handleCloseMenu = () => setMenuAnchorElem(null);
  const handleOpenMenu = (e) => setMenuAnchorElem(e.currentTarget);

  const prevPhoto = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (currentPhoto === 0) {
      setCurrentPhoto(amountOfPhotos - 1);
      navigate(
        `/deceased/${grave.slug}/memory/${post.id}?photo=${
          photos[amountOfPhotos - 1].id
        }`
      );
    } else {
      setCurrentPhoto((prev) => prev - 1);
      navigate(
        `/deceased/${grave.slug}/memory/${post.id}?photo=${
          photos[currentPhoto - 1].id
        }`
      );
    }
  };
  const nextPhoto = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (currentPhoto === amountOfPhotos - 1) {
      setCurrentPhoto(0);
      navigate(
        `/deceased/${grave.slug}/memory/${post.id}?photo=${photos[0].id}`
      );
      return 0;
    } else {
      setCurrentPhoto((prev) => prev + 1);
      navigate(
        `/deceased/${grave.slug}/memory/${post.id}?photo=${
          photos[currentPhoto + 1].id
        }`
      );
    }
  };
  const toggleComments = () => setCommentsOpen(!commentsOpen);

  React.useEffect(() => {
    if (grave && grave?.grave_users)
      setUser(grave.grave_users.find((u) => u.user?.id === post.user_id));
  }, [post]);

  const timeSinceCreated = (created_at) => {
    if (created_at === null || created_at === undefined) {
      return '';
    }

    const createdDate = new Date(created_at);
    const currentDate = new Date();

    if (isNaN(createdDate.getTime())) {
      return '';
    }

    const timeDifference = currentDate - createdDate;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);

    if (minutes <= 1) {
      return 'Just now';
    } else if (minutes < 60) {
      return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
    } else {
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      const weeks = Math.floor(days / 7);

      if (hours < 24) {
        return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
      } else if (days < 7) {
        return `${days} day${days !== 1 ? 's' : ''} ago`;
      } else if (weeks < 4) {
        return `About ${weeks} week${weeks !== 1 ? 's' : ''} ago`;
      } else if (createdDate.getFullYear() === currentDate.getFullYear()) {
        const options = { day: 'numeric', month: 'long' };
        return createdDate.toLocaleDateString('en-US', options);
      } else {
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        return createdDate.toLocaleDateString('en-US', options);
      }
    }
  };

  return (
    <React.Fragment>
      {!loading &&
      !isEmpty(post) &&
      !isEmpty(grave) &&
      !isEmpty(currentUser) ? (
        <div className={styles['post-details']}>
          <BackPageButton
            backLink={`/deceased/${grave.slug}`}
            customStyles={customStyles}
          />
          <div className={styles['photo-post-modal']}>
            <div className={styles['photo-post-modal_image']}>
              {tabValue === 0 && photos.length > 0 && (
                <div className={styles['music-post_content-photo']}>
                  <img
                    loading='lazy'
                    src={photos[currentPhoto].file_url}
                    key={photos[currentPhoto].file_url}
                    alt='currentPhoto'
                    className={styles['photo-post_content-photo_photo']}
                  />

                  {photos.length > 1 && (
                    <React.Fragment>
                      <button
                        className={styles['buttons-prev']}
                        onClick={(event) => prevPhoto(event)}
                      >
                        <img
                          loading='lazy'
                          src={PrevPhotoPointer}
                          alt='PrevPhotoPointer'
                        />
                      </button>
                      <button
                        className={styles['buttons-next']}
                        onClick={(event) => nextPhoto(event)}
                      >
                        <img
                          loading='lazy'
                          src={NextPhotoPointer}
                          alt='PrevPhotoPointer'
                        />
                      </button>
                    </React.Fragment>
                  )}
                </div>
              )}
              {tabValue === 1 && videos.length > 0 && (
                <VideoPost
                  videos={videos}
                  post={post}
                  currentVideo={currentVideo}
                  setCurrentVideo={(index) => setCurrentVideo(index)}
                />
              )}
              {tabValue === 2 && music.length > 0 && (
                <div className={styles['music-post_content_in-modal']}>
                  <MusicPost music={music} />
                </div>
              )}
              {tabValue == 0 &&
                photos.length > 0 &&
                photos[currentPhoto].title && (
                  <div className={styles['video-post-user-title-container']}>
                    {user?.user?.user_avatar ? (
                      <Avatar
                        src={user?.user.user_avatar}
                        style={{
                          height: '60px',
                          width: '60px',
                        }}
                      />
                    ) : (
                      <Avatar
                        style={{
                          height: '60px',
                          width: '60px',
                        }}
                      >
                        {user.user?.full_name?.charAt(0)}
                      </Avatar>
                    )}
                    <div className={styles['title-on-post-container']}>
                      <div className={styles['title-on-post']}>
                        {photos[currentPhoto].title}
                      </div>
                      <div className={styles['uploading-details']}>
                        <span className={styles['uploaded-text']}>
                          Uploaded by
                        </span>
                        {user.user?.full_name}
                        {post.created_at && (
                          <span className={styles['post-create-date']}>
                            {timeSinceCreated(post.created_at)}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {tabValue == 1 &&
                videos.length > 0 &&
                videos[currentVideo].title && (
                  <div className={styles['video-post-user-title-container']}>
                    {user?.user?.user_avatar ? (
                      <Avatar
                        src={user?.user.user_avatar}
                        style={{
                          height: '60px',
                          width: '60px',
                        }}
                      />
                    ) : (
                      <Avatar
                        style={{
                          height: '60px',
                          width: '60px',
                        }}
                      >
                        {user.user?.full_name?.charAt(0)}
                      </Avatar>
                    )}
                    <div className={styles['title-on-post-container']}>
                      <div className={styles['title-on-post']}>
                        {videos[currentVideo].title}
                      </div>
                      <div className={styles['uploading-details']}>
                        <span className={styles['uploaded-text']}>
                          Uploaded by
                        </span>
                        {user.user?.full_name}
                        {post.created_at && (
                          <span className={styles['post-create-date']}>
                            {timeSinceCreated(post.created_at)}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                )}
            </div>
            <div className={styles['video-post-modal_content']}>
              {(photos.length > 0 || music.length > 0 || videos.length > 0) && (
                <div className={styles['music-post_content-category']}>
                  <Tabs
                    onChange={handleTabChange}
                    value={tabValue}
                    classes={{
                      indicator: classes.rootIndicator,
                      flexContainer: classes.rootFlexContainer,
                    }}
                  >
                    {photos.length > 0 && (
                      <Tab
                        value={0}
                        className={styles['music-post_content-category_tab']}
                        icon={<TabIcon img={PictureTab} />}
                        {...a11yProps(0)}
                      />
                    )}
                    {videos.length > 0 && (
                      <Tab
                        value={1}
                        className={styles['music-post_content-category_tab']}
                        icon={
                          <TabIcon
                            className={
                              styles['music-post_content-category_tab-center']
                            }
                            img={VideoTab}
                          />
                        }
                        {...a11yProps(1)}
                      />
                    )}
                    {music.length > 0 && (
                      <Tab
                        value={2}
                        className={styles['music-post_content-category_tab']}
                        icon={<TabIcon img={MusicTab} />}
                        {...a11yProps(2)}
                      />
                    )}
                  </Tabs>
                </div>
              )}
              <div className={styles['video-post-modal_content-user']}>
                {user.user?.user_avatar ? (
                  <Avatar src={user?.user.user_avatar} className={classes.large} />
                ) : (
                  <Avatar>{user.user?.full_name?.charAt(0)}</Avatar>
                )}
                <div className={styles['video-post-modal_content-user_info']}>
                  {user.user?.slug && (
                    <Link to={`/member/${user.user.slug}`}>
                      <span>{user.user?.full_name}</span>
                    </Link>
                  )}
                  <span>{user.relation?.name}</span>
                </div>
                {currentUser.id === post.user_id && (
                  <Button
                    onClick={handleOpenMenu}
                    className={styles['video-post-modal_content-user_menu']}
                  >
                    <img loading='lazy' src={Dots} alt='dots' />
                  </Button>
                )}
              </div>
              {!isEditing ? (
                <div className={styles['music-post_content-title_textarea']}>
                  {post.contents[0].description}
                </div>
              ) : (
                /* Added inline styles because of the mess with styling classes */
                <form
                  onSubmit={editMemory}
                  className={styles['music-post_content-title_form']}
                >
                  <textarea
                    value={newMemoryText}
                    placeholder='Skriv her'
                    onChange={(e) => setNewMemoryText(e.target.value)}
                    className={
                      styles['music-post_content-title_textarea_editing']
                    }
                  />
                  <div className={styles['grave-btn-container']}>
                    <div className={styles['grave-information_content-verify']}>
                      <button
                        type='button'
                        className={styles['rootDiscard']}
                        onClick={() => {
                          setIsEditing(false);
                        }}
                      >
                        Avbryt
                      </button>
                      <button className={styles['rootVerify']} type='submit'>
                        Lagre
                      </button>
                    </div>
                  </div>
                </form>
              )}
              <div className={styles['memories_comments-area-containers']}>
                <CommentsArea
                  createComment={createComment}
                  comments={post?.comments}
                />
                <div className={styles['video-post-modal_content-comments']}>
                  <span onClick={toggleComments}>
                    {post?.comments?.length > 0
                      ? post?.comments?.length
                      : post.comments_count}{' '}
                    kommentarer
                  </span>
                </div>
                {commentsOpen && (
                  <div className={styles['memories_comments-area_comments']}>
                    {post.comments?.length > 0 &&
                      post.comments?.map((comment) => (
                        <Comment
                          comment={comment}
                          key={comment.id}
                          onCommentEdit={(newValue) =>
                            editComment(comment, newValue)
                          }
                          onCommentDelete={() => deleteComment(comment)}
                          onReplyCreate={(replyText) =>
                            createReply(comment, replyText)
                          }
                          onReplyEdit={(reply, editedReplyText) =>
                            editReply(comment, reply, editedReplyText)
                          }
                          onReplyDelete={(reply) => deleteReply(comment, reply)}
                        />
                      ))}
                  </div>
                )}
              </div>
            </div>
            <Menu
              id='fade-menu'
              anchorEl={menuAnchorElem}
              keepMounted
              open={Boolean(menuAnchorElem)}
              onClose={handleCloseMenu}
              TransitionComponent={Fade}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <MenuItem onClick={handleOpenEdit}>
                <MenuItemBlock
                  image={EditPen}
                  title='Rediger'
                  description='Legg til, endre eller fjern tekst'
                />
              </MenuItem>
              <MenuItem onClick={handelOpenDeletedModal}>
                <MenuItemBlockLast
                  image={MenuBin}
                  title='Slett'
                  description='Legg til, endre eller fjern tekst'
                />
              </MenuItem>
            </Menu>
          </div>
        </div>
      ) : (
        <div className={styles['loadingSpinner']}>
          <img loading='lazy' src={spinner} alt='elastic' />
        </div>
      )}
      {openDeletedModal && (
        <OpenDeleteModal
          closeModal={closeDeleteModal}
          post={post}
          handleCloseMenu={handleCloseMenu}
          open={openDeletedModal}
          returnUrl={`/deceased/${grave.slug}`}
        />
      )}
    </React.Fragment>
  );
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default PostDetails;
