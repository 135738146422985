import UploadMusic from '../../../../../img/UploadMusic.svg';
import React, { useState } from 'react';
import X from '../../../../../img/X.svg';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AddByLink from './AddByLink';
import './MusicModal.css';
import { useDispatch, useSelector } from 'react-redux';
import { addCommentOnToggle } from '../../../../../redux/actions';

const useStyle = makeStyles(() => ({
  rootButton: {
    width: '277px',
    height: '52px',
    borderRadius: '6px',
    backgroundColor: '#F5F5F5',
  },
  rootButtonClicked: {
    width: '277px',
    height: '51px',
    background: '#FFFFFF',
    border: '1px solid #FEA65A',
    boxSizing: 'border-box',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.161)',
    borderRadius: '6px',
    '&:hover': {
      background: '#FFFFFF',
    },
  },
}));

const MusicModal = (props) => {
  const classes = useStyle();
  const [uploadBy, setUploadBy] = useState();
  const hideInput = React.useRef();
  const dispatch = useDispatch();
  const currentComment = useSelector((state) => state.grave.commentOnToggle);

  const openUploadByLink = () => setUploadBy(2);

  const clickOnHideInput = () => hideInput.current.click();

  const selectTheMusic = (e) => {
    if (e.target.files[0]) {
      const musicArray = currentComment.music ? currentComment.music : [];
      const selectedMusicArray = Array.from(e.target.files);
      const selectedMusic = [];
      const processMusic = (file, result) => {
        selectedMusic.push({
          file: file,
          music_url: result,
          id: Date.now().toString(),
        });
        if (selectedMusic.length === selectedMusicArray.length) {
          dispatch(
            addCommentOnToggle({
              ...currentComment,
              music: [...musicArray, ...selectedMusic],
            })
          );
          props.closeModal();
        }
      };

      selectedMusicArray.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (event) => processMusic(file, event.target.result);
        reader.readAsDataURL(file);
      });
    }
  };

  return (
    <div className='video-modal'>
      <div className='video-modal_content'>
        <div className='video-modal_content-header'>
          <span>Legg til musikkfil</span>
          <img loading='lazy' src={X} alt='x' onClick={props.closeModal} />
        </div>
        <div className='video-modal_content-nav'>
          <input
            type='file'
            accept='audio/*'
            multiple
            className='video-modal_content-nav_hide-input'
            ref={hideInput}
            onChange={selectTheMusic}
          />
          <Button
            className={
              uploadBy === 1 ? classes.rootButtonClicked : classes.rootButton
            }
            onClick={clickOnHideInput}
          >
            <img loading='lazy' src={UploadMusic} alt='UploadMusic' />
          </Button>
          {/* <Button className={uploadBy === 2 ? classes.rootButtonClicked : classes.rootButton} onClick={openUploadByLink}><img loading='lazy' src={UploadMusicLink} alt='UploadMusicLink'/></Button> */}
        </div>
        {uploadBy === 2 && <AddByLink closeModal={props.closeModal} />}
      </div>
    </div>
  );
};

export default MusicModal;
