import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { useSelector, useDispatch } from 'react-redux';
import styles from './VideoComment.module.css';
import DeleteIcon from '../../../../img/DeleteIcon.svg';
import { addCommentOnToggle } from '../../../../redux/actions';
import NextPhotoPointer from '../../../../img/OrangePointerNext.svg';
import PrevPhotoPointer from '../../../../img/OrangePointerPrev.svg';
import EditIcon from '../../../../img/EditIconWhite.svg';
import isEmpty from 'lodash.isempty';

const VideoComment = (props) => {
  const commentOnToggle = useSelector((state) => state.grave.commentOnToggle);
  const dispatch = useDispatch();
  const [canEdit, setCanEdit] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);

  const prevPhoto = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (currentPhotoIndex === 0) {
      setCurrentPhotoIndex(props.videos.length - 1);
    } else {
      setCurrentPhotoIndex(currentPhotoIndex - 1);
    }
  };

  const nextPhoto = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (currentPhotoIndex === props.videos.length - 1) {
      setCurrentPhotoIndex(0);
      return 0;
    } else {
      setCurrentPhotoIndex(currentPhotoIndex + 1);
    }
  };

  const removeVideo = (id) => {
    if (commentOnToggle.video && commentOnToggle.video.id === id) {
      dispatch(addCommentOnToggle({ ...commentOnToggle, video: null }));
    }
    if (
      commentOnToggle.embeddedVideo &&
      commentOnToggle.embeddedVideo.id === id
    ) {
      dispatch(addCommentOnToggle({ ...commentOnToggle, embeddedVideo: null }));
    }
  };

  const changeTitle = (value) => {
    if (commentOnToggle.video && !isEmpty(commentOnToggle.video)) {
      dispatch(
        addCommentOnToggle({
          ...commentOnToggle,
          video: { ...props.videos[currentPhotoIndex], title: value },
        })
      );
    } else if (
      commentOnToggle.embeddedVideo &&
      !isEmpty(commentOnToggle.embeddedVideo)
    ) {
      dispatch(
        addCommentOnToggle({
          ...commentOnToggle,
          embeddedVideo: { ...props.videos[currentPhotoIndex], title: value },
        })
      );
    }
  };

  return (
    <React.Fragment>
      {props.videos.length > 0 && (
        <div className={styles['grave-picture-gallery_photos-photo']}>
          <div className={styles['video-container']}>
            {props.videos[currentPhotoIndex].type === 'local' ? (
              <video controls key={props.videos[currentPhotoIndex].video}>
                <source src={props.videos[currentPhotoIndex].video} />
              </video>
            ) : (
              <ReactPlayer
                controls={true}
                url={props.videos[currentPhotoIndex].video}
                className={styles['video-comment_content-video']}
                width='auto'
                light={true}
              />
            )}
          </div>
          {!props.isUploading && (
            <button
              className={styles['delete-btn']}
              onClick={() => removeVideo(props.videos[currentPhotoIndex].id)}
            >
              <img loading='lazy' src={DeleteIcon} alt='Delete' />
            </button>
          )}
          {props.videos.length > 1 && (
            <React.Fragment>
              <button
                className={styles['buttons-prev']}
                onClick={(event) => prevPhoto(event)}
              >
                <img
                  loading='lazy'
                  src={PrevPhotoPointer}
                  alt='PrevPhotoPointer'
                />
              </button>
              <button
                className={styles['buttons-next']}
                onClick={(event) => nextPhoto(event)}
              >
                <img
                  loading='lazy'
                  src={NextPhotoPointer}
                  alt='PrevPhotoPointer'
                />
              </button>
            </React.Fragment>
          )}
        </div>
      )}
      {!isEmpty(props.videos[currentPhotoIndex].title) && (
        <div className={styles['input-wrapper']}>
          <input
            type='text'
            value={props.videos[currentPhotoIndex].title}
            placeholder='Bildetittel'
            onChange={(event) => changeTitle(event.target.value)}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default VideoComment;
