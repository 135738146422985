import React, { useState, useEffect } from 'react';
import { request, fetch_request } from '../../service/request';
import { useNavigate, useParams } from 'react-router-dom';
import showUserNotification from '../../components/UserNotification/showUserNotification';
import isEmpty from 'lodash.isempty';
import Loader from '../../components/Loader/Loader';
import NotFoundPage from '../404/404';
import UserHeader from './Components/UserHeader';
import styles from './NewUserProfile.module.css';
import { useSelector } from 'react-redux';
import GraveInviteModal from '../../components/GraveInviteModal/GraveInviteModal';
import SubscriptionInviteModal from '../../components/SubscriptionInviteModal/SubscriptionInviteModal';

const NewUserProfile = () => {
  const navigate = useNavigate();
  let { slug } = useParams();
  const loggedInUser = useSelector((state) => state.user.user);
  const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState({});
  const [graveInvitation, setGraveInvitation] = useState();
  const [openModal, setOpenModal] = useState(false);
  const currentLoggedInUser = localStorage.getItem('user-slug');

  const handelCloseModal = () => {
    setOpenModal(false);
  };

  const handelCloseSubscriptionModal = () => {
    setOpenSubscriptionModal(false);
  };

  const handelAction = (value) => {
    const requestOptions = {
      method: 'POST',
      redirect: 'follow',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    fetch_request(
      `/api/grave_invitations/${graveInvitation.id}/${value}`,
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.message) {
          showUserNotification('Invitasjonen er avslå', 'warning');
        } else {
          navigate(`/deceased/${graveInvitation.grave.slug}`);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setOpenModal(false);
  };

  useEffect(() => {
    if (slug) {
      const requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      setLoading(true);
      request(`/users/${slug}`, requestOptions, 'get')
        .then((response) => {
          setCurrentUser(response.data.user);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          showUserNotification(err.response.data.error || err.message, 'error');
          setLoading(false);
        });
    }
  }, [slug]);

  useEffect(() => {
    if (
      !isEmpty(loggedInUser) &&
      currentLoggedInUser === slug &&
      loggedInUser?.email
    ) {
      const requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      fetch_request(
        `/api/find_grave_invitations/${loggedInUser.email}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            setOpenModal(false);
          } else {
            setGraveInvitation(data);
            setOpenModal(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [loggedInUser]);

  useEffect(() => {
    if (
      !isEmpty(loggedInUser) &&
      loggedInUser.subscription_invitation &&
      slug === currentLoggedInUser
    ) {
      setOpenSubscriptionModal(true);
    }
  }, [loggedInUser]);

  if (isEmpty(currentUser) && !loading) {
    return <NotFoundPage />;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={styles['user-profile']}>
      <UserHeader user={currentUser} setCurrentUser={setCurrentUser} />
      {openModal && graveInvitation && (
        <GraveInviteModal
          onOpen={openModal}
          onClose={handelCloseModal}
          handelAction={handelAction}
          graveInvitation={graveInvitation}
          currentUser={loggedInUser}
        />
      )}
      {openSubscriptionModal && (
        <SubscriptionInviteModal
          onOpen={openSubscriptionModal}
          onClose={handelCloseSubscriptionModal}
          currentUser={loggedInUser}
        />
      )}
    </div>
  );
};

export default NewUserProfile;
