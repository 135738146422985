import {
  setBackgroundPhoto,
  setPhotosFromCropper,
  setProfilePhoto,
  setProfilePhotoList,
  setPhotoForCropping,
  setProfilePhotoForCropping,
  setDeathCertificationForUpload,
  addCommentToGravePhoto,
  editCommentToGravePhoto,
  removeCommentFromGravePhoto,
  addReplyToCommentToGravePhoto,
  editReplyToCommentToGravePhoto,
  removeReplyFromCommentToGravePhoto,
  currentGravePhotoIndex,
  currentDeceasedTabIndex,
  listOfTimelinesPhotos,
  editGravePhotoIndex,
  currentEventPhotoIndex,
  editEventPhotoIndex,
  currentEventVideoIndex,
} from '../actions';

const photoState = {
  currentGravePhoto: 0,
  editGravePhotoIndex: 0,
  currentDeceasedTabIndex: 0,
  photoForCropping: [],
  profilePhotoForCropping: {},
  backgroundPhoto: '',
  listOfPhotos: [],
  timeLinesPhotos: [],
  profilePhoto: '',
  listOfProfilePhotos: [],
  currentEventPhotoIndex: 0,
  currentEventVideoIndex: 0,
  editEventPhotoIndex: 0,
};

export const photoReducer = (state = photoState, action) => {
  const getArrayByPhotoCategory = (category) => {
    return category === 'display'
      ? state.listOfProfilePhotos
      : state.listOfPhotos;
  };
  const getArrayNameByPhotoCategory = (category) => {
    return category === 'display' ? 'listOfProfilePhotos' : 'listOfPhotos';
  };
  const category = action.payload?.photoCategory;

  switch (action.type) {
    case setBackgroundPhoto:
      return {
        ...state,
        backgroundPhoto: action.backgroundPhoto,
      };
    case currentEventPhotoIndex:
      return {
        ...state,
        currentEventPhotoIndex: action.payload,
      };
    case editEventPhotoIndex:
      return {
        ...state,
        editEventPhotoIndex: action.payload,
      };
    case setPhotosFromCropper:
      return {
        ...state,
        listOfPhotos: action.listOfPhotos,
      };
    case editGravePhotoIndex:
      return {
        ...state,
        editGravePhotoIndex: action.payload,
      };
    case setProfilePhotoList:
      return {
        ...state,
        listOfProfilePhotos: action.photos,
      };
    case listOfTimelinesPhotos:
      return {
        ...state,
        timeLinesPhotos: action.payload,
      };
    case setProfilePhoto:
      return {
        ...state,
        profilePhoto: action.photo,
      };
    case setPhotoForCropping:
      return {
        ...state,
        photoForCropping: action.payload,
      };
    case setProfilePhotoForCropping:
      return {
        ...state,
        profilePhotoForCropping: action.payload,
      };
    case setDeathCertificationForUpload:
      return {
        ...state,
        deathCertificationForUpload: action.payload,
      };
    case addCommentToGravePhoto:
      return {
        ...state,
        [getArrayNameByPhotoCategory(category)]: getArrayByPhotoCategory(
          category
        ).map((pp) =>
          pp.id === action.payload.photoId
            ? {
                ...pp,
                image_comments:
                  pp.image_comments && pp.image_comments.length > 0
                    ? [action.payload.comment, ...pp.image_comments]
                    : [action.payload.comment],
              }
            : pp
        ),
      };
    case editCommentToGravePhoto:
      return {
        ...state,
        [getArrayNameByPhotoCategory(category)]: getArrayByPhotoCategory(
          category
        ).map((pp) =>
          pp.id === action.payload.photoId
            ? {
                ...pp,
                image_comments: pp.image_comments.map((com) =>
                  com.id === action.payload.comment.id
                    ? action.payload.comment
                    : com
                ),
              }
            : pp
        ),
      };
    case removeCommentFromGravePhoto:
      return {
        ...state,
        [getArrayNameByPhotoCategory(category)]: getArrayByPhotoCategory(
          category
        ).map((pp) =>
          pp.id === action.payload.photoId
            ? {
                ...pp,
                image_comments: pp.image_comments.filter(
                  (com) => com.id !== action.payload.comment.id
                ),
              }
            : pp
        ),
      };
    case addReplyToCommentToGravePhoto:
      return {
        ...state,
        [getArrayNameByPhotoCategory(category)]: getArrayByPhotoCategory(
          category
        ).map((pp) =>
          pp.id === action.payload.photoId
            ? {
                ...pp,
                image_comments: pp.image_comments.map((com) =>
                  com.id === action.payload.commentId
                    ? {
                        ...com,
                        replies:
                          com.replies?.length > 0
                            ? [...com.replies, action.payload.reply]
                            : [action.payload.reply],
                      }
                    : com
                ),
              }
            : pp
        ),
      };
    case editReplyToCommentToGravePhoto:
      return {
        ...state,
        [getArrayNameByPhotoCategory(category)]: getArrayByPhotoCategory(
          category
        ).map((pp) =>
          pp.id === action.payload.photoId
            ? {
                ...pp,
                image_comments: pp.image_comments.map((com) =>
                  com.id === action.payload.commentId
                    ? {
                        ...com,
                        replies: com.replies.map((rep) =>
                          rep.id === action.payload.reply.id
                            ? action.payload.reply
                            : rep
                        ),
                      }
                    : com
                ),
              }
            : pp
        ),
      };
    case removeReplyFromCommentToGravePhoto:
      return {
        ...state,
        [getArrayNameByPhotoCategory(category)]: getArrayByPhotoCategory(
          category
        ).map((pp) =>
          pp.id === action.payload.photoId
            ? {
                ...pp,
                image_comments: pp.image_comments.map((com) =>
                  com.id === action.payload.commentId
                    ? {
                        ...com,
                        replies: com.replies.filter(
                          (rep) => rep.id !== action.payload.reply.id
                        ),
                      }
                    : com
                ),
              }
            : pp
        ),
      };
    case currentGravePhotoIndex:
      return {
        ...state,
        currentGravePhoto: action.payload,
      };
    case currentEventVideoIndex:
      return {
        ...state,
        currentEventVideoIndex: action.payload,
      };
    case currentDeceasedTabIndex:
      return {
        ...state,
        currentDeceasedTabIndex: action.payload,
      };
    default:
      return state;
  }
};
