import React from 'react';
import styles from './ManageMembers.module.css';
import { FormattedMessage } from 'react-intl';

const SwitchButton = ({ handelAction, titleKey, planAction, actionKey }) => {
  return (
    <div
      className={`${styles['switch-btn']} ${
        planAction === actionKey ? styles['active'] : ''
      } `}
      onClick={() => handelAction(actionKey)}
    >
      <div className={styles['action']}>
        <input type='checkbox' checked={planAction === actionKey} readOnly />
        <label>
          <FormattedMessage id={titleKey} />
        </label>
      </div>
    </div>
  );
};

export default SwitchButton;
