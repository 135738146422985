import React from 'react';
import './Footer.css';
import FooterLogo from './FooterLogo';
import Consciousness from './Consciousness';
import Features from './Features';
import Resources from './Resources';
import Contacts from './Contacts';

const Footer = () => {
  return (
    <div className='landing-footer'>
      <FooterLogo />
      <div className='footer-container'>
        <Consciousness />
        <Features />
        <Resources />
        <Contacts />
      </div>
    </div>
  );
};

export default Footer;
