import React from 'react';
import './HomepageScientistsThird.css';
import MainImage from '../../../../img/ScientistsThirdFirst.png';
import Magazine from '../../../../img/Magazine.png';
import MobileMainImage from '../../../../img/MobileScientistsBG.png';
import {Link} from "react-router-dom";

const HomepageScientistsThird = () => {

    return(
        <div className='homepage-scientists_third'
        >
            <div className="homepage-scientists_third-content">
                <img loading='lazy' src={MainImage} alt='MainImage' className='homepage-scientists_third-content_image homepage-scientists_third_img'/>
                <img loading='lazy' src={Magazine} alt='Magazine' className='homepage-scientists_third-content_magazine homepage-scientists_third_img'/>
                <img loading='lazy' src={MobileMainImage} alt='MobileMainImage' className='homepage-scientists_third-content_image-mobile homepage-scientists_third_img'/>
                <div className="homepage-scientists_third-content_orange-box">
                    <span>Bare forestill deg hvis vi, sammen, kunne gi vitensenter og forskere muligheten til å fokusere på å søke ny kunnskap, støttet av våre bidrag og alle Nettgravene.</span>
                </div>
                <div className="homepage-scientists_third-content_header">
                    <span className='homepage-scientists_third-content_header-main'>Å få universet til deg</span>
                    <span className='homepage-scientists_third-content_header-submain'>Nettgrav er det eneste sosiale nettverket på nett med en grav som grunnlag for alle våre sosiale relasjoner. Vårt mål er at kjærligheten vår forblir sterk i all sin tidløshet.</span>
                    <a href='http://teotc.com/' target="_blank" style={{color: "#FEA65A", textDecoration:"none", fontSize: "14px", fontFamily: "Roboto"}}>Les mer</a>
                </div>
            </div>
        </div>
    )
}

export default HomepageScientistsThird;