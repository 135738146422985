import React from 'react';
import PrimaryButton from '../../../../components/PrimaryButton';
import { FormattedMessage } from 'react-intl';
import styles from './UserAccount.module.css';

const Content = ({
  closeConfirmModal,
  handelAccountDeletion,
  isRequestedToDelete,
}) => {
  return (
    <div>
      <div className={styles['confirm-message']}>
        <FormattedMessage
          id={
            isRequestedToDelete()
              ? 'confirm_cancel_account'
              : 'confirm_delete_account'
          }
        />
      </div>
      <div className={styles['confirm-btn']}>
        <PrimaryButton
          onClick={closeConfirmModal}
          children={<FormattedMessage id='no' />}
          style={{
            background: '#fff',
            border: '1px solid #404D56',
            color: '#404D56',
            padding: '7px 42px',
          }}
        />
        <PrimaryButton
          onClick={handelAccountDeletion}
          children={<FormattedMessage id='yes' />}
          style={{
            padding: '8px 42px',
            background: isRequestedToDelete() ? '#404D56' : '#f23131',
          }}
        />
      </div>
    </div>
  );
};

export default Content;
