import React from 'react';
import styles from './PlanPaymentDetails.module.css';
import { FormattedMessage } from 'react-intl';

const PlanDetailsRow = ({ messageKey, value, style }) => {
  return (
    <div className={styles['total-price']}>
      <span className={styles['total-price-heading']} style={style}>
        <FormattedMessage id={messageKey} />
      </span>
      <span className={styles['total-price-monthly']} style={style}>
        {value}
      </span>
    </div>
  );
};

export default PlanDetailsRow;
