import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import GetDiscountTitles from './GetDiscountTitles';

const DiscountInfoList = ({ discountsList }) => {
  return (
    <Tooltip title={<GetDiscountTitles discountsList={discountsList} />}>
      <InfoIcon
        style={{ color: '#ea9449', fontSize: '18px', cursor: 'pointer' }}
      />
    </Tooltip>
  );
};

export default DiscountInfoList;
