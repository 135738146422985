import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import styles from './ClaimMessage.module.css';

const ClaimMessage = ({ handelOpenModal, isAcceptedUser, grave }) => {
  const navigate = useNavigate();

  const handelOnClick = () => {
    if (isAcceptedUser()) {
      navigate(`/grave/${grave.slug}/claim`);
    } else {
      handelOpenModal();
    }
  };

  return (
    <div className={styles['claim-message-container']}>
      <div className={styles['claim-message']}>
        <span className={styles['message']}>
          <FormattedMessage id='claim_grave_message' />
          <span className={styles['claim-link']} onClick={handelOnClick}>
            {' '}
            <FormattedMessage id='take_ownership' />
          </span>
        </span>
      </div>
    </div>
  );
};

export default ClaimMessage;
