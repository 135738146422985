import React, { useRef } from 'react';
import styles from './CropperModal.module.css';
import { makeStyles } from '@mui/styles';
import Cropper from 'react-cropper';
import { useDispatch, useSelector } from 'react-redux';
import { addPhotoForCropping } from '../../../../redux/actions';
import 'react-toastify/dist/ReactToastify.css';
import Pointer from '../../../../img/BackIcon.svg';

const useStyle = makeStyles(() => ({
  root: {
    backgroundColor: '#404d56',
    color: 'white',
    padding: '12px 42px',
    borderRadius: '10px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '19px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    textTransform: 'none',
  },
}));

const CropperModal = ({ photo, handelCloseCropper }) => {
  const photoForCropping = useSelector(
    (state) => state.photoReducer.photoForCropping
  );
  const cropperRef = useRef(null);
  const classes = useStyle();
  const dispatch = useDispatch();

  const getCropImage = () => {
    if (typeof cropperRef.current?.cropper !== 'undefined') {
      const croppedImage = cropperRef.current?.cropper
        .getCroppedCanvas()
        .toDataURL();
      const updatedImages = photoForCropping.map((image) =>
        image.id === photo.id ? { ...photo, file_url: croppedImage } : image
      );
      dispatch(addPhotoForCropping(updatedImages));
    }
    handelCloseCropper();
  };

  return (
    <div className={styles['crop-modal']}>
      <div
        className={styles['second-modal_content-go-back']}
        onClick={() => handelCloseCropper()}
      >
        <img loading='lazy' src={Pointer} alt='pinter' />
        <span>Tilbake</span>
      </div>
      <Cropper
        ref={cropperRef}
        className={styles['grave-picture-gallery_photos-cropper']}
        src={photo.file_url}
        viewMode={1}
        guides={true}
        zoomOnWheel={false}
        minCropBoxHeight={10}
        minCropBoxWidth={10}
        background={false}
        responsive={true}
        autoCropArea={1}
        aspectRatio={16 / 9}
        checkOrientation={false}
      />
      <div className={styles['button-container']}>
        <button className={styles['save-btn']} onClick={getCropImage}>
          Lagre
        </button>
      </div>
    </div>
  );
};

export default CropperModal;
