import { Dialog } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import HugModalBody from './HugModalBody';

const useStyle = makeStyles(() => ({
  rootDialog: {
    width: '100%',
    maxWidth: '423px !important',
    borderRadius: '10px !important',
    '@media (max-width: 800px)': {
      margin: '0px',
      width: '100%',
    },
  },
  rootScroolBody: {
    padding: '20px 0px !important',
    width: '100%',
  },
}));

const HugModal = ({ creator, open, onClose }) => {
  const classes = useStyle();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className='cropper-modal_modal'
      scroll='body'
      classes={{
        paper: classes.rootDialog,
        scrollBody: classes.rootScroolBody,
      }}
    >
      <HugModalBody creator={creator} onClose={onClose} />
    </Dialog>
  );
};

export default HugModal;
