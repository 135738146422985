import React from 'react';
import { SwipeableDrawer } from '@mui/material';
import { makeStyles } from '@mui/styles';
import X from '../../../../../../img/X.svg';

const useStyles = makeStyles(() => ({
  drawerContent: {
    position: 'relative',
    backgroundColor: 'white',
    borderRadius: '6px 6px 0px 0px',
    height: '80vh',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    padding: '12px',
    borderBottom: '1px solid #ddd',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '16px',
    position: 'sticky',
    top: 0,
    backgroundColor: 'white',
    borderRadius: '6px 6px 0px 0px',
    zIndex: 10,
  },
  content: {
    flex: 1,
    overflowY: 'auto',
    padding: '16px',
  },
  footer: {
    padding: '12px',
    borderTop: '1px solid #ddd',
  },
  closeButton: {
    cursor: 'pointer',
  },
}));

const Drawer = ({
  isOpen,
  closeDrawer,
  openDrawer,
  footer,
  children,
  title,
}) => {
  const classes = useStyles();
  return (
    <SwipeableDrawer
      anchor='bottom'
      open={isOpen}
      onClose={closeDrawer}
      onOpen={openDrawer}
      PaperProps={{
        style: {
          borderRadius: '8px 8px 0px 0px',
        },
      }}
    >
      <div className={classes.drawerContent}>
        <div className={classes.header}>
          {title}
          <img
            src={X}
            onClick={() => closeDrawer(false)}
            alt='close'
            className={classes.closeButton}
          />
        </div>
        <div className={classes.content}>{children}</div>
        {footer && <div className={classes.footer}>{footer}</div>}
      </div>
    </SwipeableDrawer>
  );
};

export default Drawer;
