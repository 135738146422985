import React, { useEffect, useState } from 'react';
import styles from './BillingCycle.module.css';
import BillingButton from './BillingButton';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { ButtonGroup } from '@mui/material';
import { CONSTANTS } from '../../../../service/constants';
import Loader from '../../../../components/Loader/Loader';
import { setSubscriptionPeriod } from '../../../../redux/actions';
import SaveButton from './SaveButton';

const BillingCycle = ({
  currentSubscription,
  fetchSubscriptionData,
  setOpenOptionsModal,
  paymentMethod,
}) => {
  const dispatch = useDispatch();
  const selectedPeriod = useSelector(
    (state) => state.paymentMethods.subscriptionPeriod
  );
  const [subscriptionPeriods, setSubscriptionPeriods] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchSubscriptionPeriods = () => {
    setLoading(true);
    axios
      .get(`${CONSTANTS.baseUrl}/api/subscription_periods`)
      .then((res) => {
        setSubscriptionPeriods(res.data);
        if (currentSubscription.period) {
          const currentPeriod = res.data.find(
            (period) => period.key === currentSubscription.period
          );
          dispatch(
            setSubscriptionPeriod(currentPeriod ? currentPeriod : res.data[0])
          );
        } else {
          dispatch(setSubscriptionPeriod(res.data[0]));
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchSubscriptionPeriods();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <div className={styles['container']}>
        <ButtonGroup
          variant='outlined'
          aria-label='Large button group'
          className={styles['group-button']}
        >
          {subscriptionPeriods.map((period) => (
            <BillingButton
              key={period.key}
              cycle={period}
              selectedPeriod={selectedPeriod}
            />
          ))}
        </ButtonGroup>
      </div>
      <div className={styles['save-container']}>
        <SaveButton
          selectedPeriod={selectedPeriod}
          currentSubscription={currentSubscription}
          fetchSubscriptionData={fetchSubscriptionData}
          setOpenOptionsModal={setOpenOptionsModal}
          paymentMethod={paymentMethod}
        />
      </div>
    </React.Fragment>
  );
};

export default BillingCycle;
