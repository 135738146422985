import React from 'react';
import styles from './ActiveGiftMembership.module.css';
import { FormattedMessage } from 'react-intl';
import { planKey } from '../../../service/PlanKey';

const ActiveGiftMembership = ({ membership }) => {
  return (
    <div className={styles['gift-membership-card']}>
      <div className={styles['plan-name']}>
        <FormattedMessage id={planKey(membership.plan_key)} />
      </div>
      <div className={styles['gifted-member-name']}>
        {membership.owner_name}
      </div>
      <div className={styles['price']}>
        <span>NOK {membership.plan_price}</span>/
        <FormattedMessage id='settings_month' />
      </div>
    </div>
  );
};

export default ActiveGiftMembership;
