import { gql } from '@apollo/client';

export const COMMENT_REPLIES_QUERY = gql`
  query CommentReplies($commentId: ID!, $first: Int, $after: String) {
    commentReplies(commentId: $commentId, first: $first, after: $after) {
      edges {
        node {
          id
          description
          repliesCount
          commentableType
          commentableId
          parentId
          user {
            id
            fullName
            slug
            displayPicture
          }
          createdAt
          updatedAt
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;
