import React from 'react';
import CustomModal from '../../../../components/CustomModal';
import { FormattedMessage } from 'react-intl';
import SetRelation from './SetRelation';

const UserConnectionModal = ({
  openModal,
  closeModal,
  relationsList,
  user,
  directionsList,
  onConfirm,
}) => {
  return (
    <CustomModal
      isOpen={openModal}
      children={
        <SetRelation
          user={user}
          relationList={relationsList}
          directionsList={directionsList}
          closeModal={closeModal}
          onConfirm={onConfirm}
        />
      }
      closeModal={closeModal}
      title={<FormattedMessage id='set_relation' />}
    />
  );
};

export default UserConnectionModal;
