import React from 'react';
import styles from './VippsAgreements.module.css';
import vippsLogo from '../../../../img/VippsLogo.svg';
import { FormattedMessage } from 'react-intl';

const VippsAgreements = ({ agreement }) => {
  return (
    <div className={styles.container}>
      <div className={styles['heading-container']}>
        <div className={styles['heading']}>
          <FormattedMessage id='settings_vippsAgreement' />
        </div>
      </div>
      <div className={styles['card-methods']}>
        <img
          src={vippsLogo}
          alt='card'
          style={{ height: 25 }}
          className={styles['logo']}
        />
        <span>+47{agreement.phone_number}</span>
        <span>{agreement.status}</span>
        <span>{agreement.agreement_id}</span>
      </div>
    </div>
  );
};

export default VippsAgreements;
