import React from 'react';
import PrimaryButton from '../../../components/PrimaryButton';
import { FormattedMessage } from 'react-intl';
import styles from './CreateMembership.module.css';

const GroupButtons = ({ onBack, onNext, backKey, nextKey, style }) => {
  return (
    <div className={styles['message-btn']} style={style}>
      <PrimaryButton
        onClick={onBack}
        children={<FormattedMessage id={backKey} />}
        style={{
          background: '#fff',
          border: '1px solid #404D56',
          color: '#404D56',
          padding: '7px 16px',
        }}
      />
      <PrimaryButton
        onClick={onNext}
        children={<FormattedMessage id={nextKey} />}
      />
    </div>
  );
};

export default GroupButtons;
