import React from 'react';
import SwitchButton from './SwitchButton';
import styles from './ManageMembers.module.css';

const GroupButton = ({ planAction, handelAction }) => {
  return (
    <div className={styles['group-container']}>
      <SwitchButton
        actionKey='add'
        titleKey='add_members'
        planAction={planAction}
        handelAction={handelAction}
      />
      <SwitchButton
        actionKey='remove'
        titleKey='remove_members'
        planAction={planAction}
        handelAction={handelAction}
      />
    </div>
  );
};

export default GroupButton;
