import React from 'react';
import './HomepageScientistsSecond.css';
import People from '../../../../img/ScientistsPeople.svg';
import BG from '../../../../img/ScientistsSecondBg.svg';

const HomepageScientistsSecond = () => {

    return(
        <div className='homepage-scientists_second'>
            <div className="homepage-scientists_second-content">
                <div className="homepage-scientists_second-content_text">
                    <span className='homepage-scientists_second-content_text-first'>Er du enig i at syv til ni tiår rundt solen vår føles for kort, før vi må forlate jorden?</span>
                    <span className='homepage-scientists_second-content_text-second'>Ja?</span>
                </div>
                <img loading='lazy' src={BG} alt='bg' className='homepage-scientists_second-content_background'/>
                <img loading='lazy' src={People} alt='People' className='homepage-scientists_second-content_image'/>
            </div>
        </div>
    )
}

export default HomepageScientistsSecond;