import React from 'react';
import { Avatar } from '@mui/material';
import styles from './Reactions.module.css';
import { useNavigate } from 'react-router-dom';
import SmallReactionIcon from '../../Components/SmallReactionIcon';

const ReactionDetails = ({ reactions, reactionList }) => {
  const navigate = useNavigate();
  return (
    reactions.length > 0 &&
    reactions.map((reaction) => {
      const selectedReaction = reactionList[reaction?.reactionType];
      return (
        <div className={styles['container']}>
          <Avatar
            src={reaction.avatar}
            className={styles['avatar']}
            onClick={() => navigate(`/member/${reaction.slug}`)}
          />
          <div
            className={styles['user-name']}
            onClick={() => navigate(`/member/${reaction.slug}`)}
          >
            {reaction.userName}
          </div>
          {selectedReaction && (
            <div className={styles['reaction']}>
              <SmallReactionIcon
                title={selectedReaction.title}
                icon={selectedReaction.icon}
                reactionType={selectedReaction.type}
                style={{ height: '8px', width: '8px' }}
              />
            </div>
          )}
        </div>
      );
    })
  );
};

export default ReactionDetails;
