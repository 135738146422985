import React from 'react';
import './LearnMoreThird.css';
import Claim from '../../../../img/LearnMoreThirdClaim.png';
import Bottom from '../../../../img/LearnMoreThirdBottom.svg';
import Upper from '../../../../img/LearnMoreThirdupper.svg';
import Certificate from '../../../../img/LearnMoreThirdCertificate.svg';
import Number from '../../../../img/LearnMoreTwo.svg';

const LearnMoreThird = (props) => {
    return(
        <div className='learn-more_third'>
            <div className="learn-more_third-content">
                <div className="learn-more_third-content_images">
                    <img loading='lazy' src={Claim} alt='Claim' className='learn-more_third-content_images-claim'/>
                    <img loading='lazy' src={Bottom} alt='Bottom' className='learn-more_third-content_images-bottom'/>
                    <img loading='lazy' src={Upper} alt='Upper' className='learn-more_third-content_images-upper'/>
                </div>
                <div className="learn-more_third-content_empty">
                    <img loading='lazy' src={Number} alt='Number'/>
                </div>
                <div className="learn-more_second-content_main-text third">
                    <div className="learn-more_second-content_main-text_header">
                        <img loading='lazy' src={Certificate} alt='Certificate' className='learn-more_second-content_main-text_header-image'/>
                        <span className='learn-more_second-content_main-text_header-title'>Graveiere </span>
                        <img loading='lazy' src={Number} alt='Number' className='learn-more_second-content_main-text_header-number'/>
                    </div>
                    <div className="learn-more_second-content_main-text_title">
                        <span>På administrasjonssiden kan graveiere legge til familiemedlemmer som administratorer. Administratorer kan også godkjenne innkommende relasjoner til graven.</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LearnMoreThird;