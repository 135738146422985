import './FirstStep.css';
import React, { useState, useEffect } from 'react';
import { fetch_request } from '../../../../../../service/request';
import BirthIcon from '../../../../../../img/Birth.svg';
import DeathIcon from '../../../../../../img/Death.svg';
import location from '../../../../../../img/Location.svg';
import spinner from '../../../../../../service/Loading_animation.gif';

const FirstStep = (props) => {
  const grave = props.grave;
  const [gravesList, setGravesList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    fetch_request(
      `/api/available_graves_for_connections?source_grave_id=${grave.id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setGravesList(data.graves);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const convertDate = (data) => {
    if (data)
      return `${data.slice(-2)}.${data.slice(-5, -3)}.${data.slice(-10, -6)}`;
    else return 'no info';
  };

  const handleGraveSelect = (item) => {
    props.setStep(2);
    props.setChosenGrave(item);
  };

  if (loading) {
    return (
      <div className='loadingSpinner'>
        <img loading='lazy' src={spinner} alt='elastic' />
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className='grave-connections-grid'>
        {gravesList &&
          gravesList.length !== 0 &&
          gravesList.map((item) => {
            return (
              <div className='grave-connections-grid-item'>
                {/* <div className='set-relation-and-claim-modal_display_image_wrapper'> */}
                <div className='grave-avatar_listing '>
                  {item.display_picture && (
                    <img
                      loading='lazy'
                      src={item.display_picture}
                      alt='grave_avatar'
                      className='grave_avatar'
                    />
                  )}
                </div>
                {/* </div> */}
                <div className='grave-content-name_span'>{item.name}</div>
                <div className='grave-info-dates'>
                  <span>
                    <img loading='lazy' src={BirthIcon} alt='birth' />{'   '}
                    {convertDate(item.date_of_birth)}
                  </span>
                  <span>
                    <img loading='lazy' src={DeathIcon} alt='death' />{'   '}
                    {convertDate(item.date_of_death)}
                  </span>
                </div>
                <div className='grave-info-relation'>
                  <span>Din {item.current_user_relation.relation}</span>
                </div>
                <div className='choose-grave'>
                  <button onClick={() => handleGraveSelect(item)}>Velg</button>
                </div>
              </div>
            );
          })}
      </div>
      {gravesList && gravesList.length === 0 && (
        <div className='no-graves'>
          <h1>
            Vi fant ingen nettgraver som kunne knyttes sammen {grave.name}
          </h1>
        </div>
      )}
    </React.Fragment>
  );
};

export default FirstStep;
