import { gql } from '@apollo/client';

export const addReactionCache = (cache, { data: { createReaction } }) => {
  const newReaction = createReaction?.reaction;
  if (newReaction) {
    cache.modify({
      fields: {
        sparks(existingSparks = { edges: [], pageInfo: {} }) {
          existingSparks.edges.forEach((edge) => {
            const nodeData = cache.readFragment({
              id: edge.node.__ref,
              fragment: gql`
                fragment SparkDetails on Spark {
                  id
                  reactionsCount
                  totalReactionsCount
                  reaction {
                    reactionType
                    id
                  }
                }
              `,
            });

            if (nodeData?.id === newReaction.reactableId) {
              const currentReactionsCount = nodeData.reactionsCount || {};

              const updatedReactionsCount = {
                ...currentReactionsCount,
                [newReaction.reactionType]:
                  (currentReactionsCount[newReaction.reactionType] || 0) + 1,
              };

              cache.writeFragment({
                id: edge.node.__ref,
                fragment: gql`
                  fragment UpdateSpark on Spark {
                    reactionsCount
                    totalReactionsCount
                    reaction
                  }
                `,
                data: {
                  reactionsCount: updatedReactionsCount,
                  totalReactionsCount: (nodeData.totalReactionsCount || 0) + 1,
                  reaction: {
                    reactionType: newReaction.reactionType,
                    id: newReaction.id,
                  },
                },
              });
            }
          });

          return existingSparks;
        },
      },
    });
  }
};
