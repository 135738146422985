import React, { useState } from 'react';
import Alert from '../../../../components/Alert/Alert';
import styles from './SubscriptionAlert.module.css';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@mui/material';
import { planKey } from '../../../../service/PlanKey';
import { SubscriptionChangesKey } from '../../../../service/constants';
import { request } from '../../../../service/request';
import showUserNotification from '../../../../components/UserNotification/showUserNotification';
import ProcessModal from '../../../Payment/atoms/ProcessModal';
import { formatDate } from '../../../../service/Utils';

const SubscriptionChanges = ({
  changes,
  currentSubscription,
  setSubscriptionChanges,
}) => {
  const intl = useIntl();
  const [openModal, setOpenModal] = useState(false);

  const handelChange = async (id) => {
    if (!currentSubscription && !id) return;
    try {
      const response = request(
        `/subscriptions/${currentSubscription.id}/subscription_changes/${id}`,
        null,
        'delete'
      );
      const updatedData = changes.filter((item) => item.id !== id);
      setSubscriptionChanges(updatedData);
      setOpenModal(false);
      showUserNotification(
        intl.formatMessage({ id: 'remove_subscription_change' }),
        'success'
      );
    } catch (error) {
      console.log(error);
      setOpenModal(false);
      showUserNotification(
        intl.formatMessage({ id: 'something_wrong' }),
        'error'
      );
    }
  };

  const getMessageKey = (change) => {
    if (change.attribute_name === SubscriptionChangesKey.period) {
      return {
        previous: <FormattedMessage id={change.previous_value} />,
        new: <FormattedMessage id={change.new_value} />,
        month: change.previous_value,
        newMonth: change.new_value,
      };
    } else if (change.attribute_name === SubscriptionChangesKey.product_id) {
      return {
        previous: (
          <FormattedMessage id={planKey(change.previous_product_key)} />
        ),
        new: <FormattedMessage id={planKey(change.new_product_key)} />,
      };
    } else {
      return {
        previous: change.previous_value,
        new: change.new_value,
      };
    }
  };

  return (
    <React.Fragment>
      <Alert style={{ backgroundColor: '#f6dfcb' }}>
        <div className={styles['container']}>
          <div className={styles['alert-title']}>
            <FormattedMessage id='subscription_changes_title' />
          </div>
          <div className={styles['sub-heading']}>
            <FormattedMessage
              id='subscription_changes_subTitle'
              values={{ date: formatDate(currentSubscription.period_end_date) }}
            />
          </div>
          {changes.map((change) => {
            return (
              <div className={styles['message']} key={change.id}>
                <div className={styles['message-alert']}>
                  <FormattedMessage
                    id={change.attribute_name}
                    values={getMessageKey(change)}
                  />
                </div>
                <div className={styles['alert-cta']}>
                  <Button
                    size='small'
                    onClick={() => {
                      setOpenModal(true);
                      handelChange(change.id);
                    }}
                  >
                    <FormattedMessage id='revert' />
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      </Alert>
      {openModal && (
        <ProcessModal
          open={openModal}
          messageKey='remove_subscription_loading'
        />
      )}
    </React.Fragment>
  );
};

export default SubscriptionChanges;
