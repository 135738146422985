import { InMemoryCache } from '@apollo/client';
import { log } from 'console';

const defaultEdges = {
  edges: [],
  pageInfo: { hasNextPage: false, endCursor: '' },
};

const deduplicateEdges = (existingEdges: any, incomingEdges: any) => {
  const seenIds = new Set(existingEdges.map((edge: any) => edge?.node?.__ref));
  const uniqueIncomingEdges = incomingEdges.filter((edge: any) => {
    if (!edge?.node?.__ref) {
      return false;
    }
    if (seenIds.has(edge.node.__ref)) {
      return false;
    }
    seenIds.add(edge.node.__ref);
    return true;
  });

  return [...existingEdges, ...uniqueIncomingEdges];
};

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        sparks: {
          keyArgs: false,
          merge(existing = defaultEdges, incoming) {
            const existingEdges = existing.edges ? [...existing.edges] : [];
            const incomingEdges = incoming.edges ? [...incoming.edges] : [];
            const edges = deduplicateEdges(existingEdges, incomingEdges);

            return {
              ...incoming,
              edges,
            };
          },
          read(existing) {
            return existing || defaultEdges;
          },
        },
        comments: {
          keyArgs: ['sparkId'],
          merge(existing = defaultEdges, incoming, { args, cache }) {
            console.log(args);
            if (args?.parentId) {
              const comment = existing.edges.find(
                (edge: any) => edge?.node?.__ref
              );
              console.log(comment);
            } else {
              const existingEdges = existing.edges ? [...existing.edges] : [];
              const incomingEdges = incoming.edges ? [...incoming.edges] : [];
              const edges = deduplicateEdges(existingEdges, incomingEdges);
              return {
                ...incoming,
                edges,
              };
            }
          },
          read(existing) {
            return existing || defaultEdges;
          },
        },
        commentReplies: {
          keyArgs: ['commentId'],
          merge(existing = defaultEdges, incoming) {
            const existingEdges = existing.edges ? [...existing.edges] : [];
            const incomingEdges = incoming.edges ? [...incoming.edges] : [];
            const edges = deduplicateEdges(existingEdges, incomingEdges);

            return {
              ...incoming,
              edges,
            };
          },
          read(existing) {
            return existing || defaultEdges;
          },
        },
      },
    },
  },
});

export default cache;
