import React from 'react';
import styles from './GraveUserForm.module.css';

const UserElement = ({ value, style, navigateTo }) => {
  return (
    <div
      className={styles['user-name']}
      style={style}
      onClick={() => {
        if (navigateTo) {
          navigateTo();
        }
      }}
    >
      {value}
    </div>
  );
};

export default UserElement;
