import React from 'react';
import MasterCard from '../../../../img/MasterCard.svg';
import VisaCard from '../../../../img/Visa.svg';
import './SelectPaymentMethod.css';
import { FormattedMessage } from 'react-intl';

const SelectPaymentMethod = ({
  handelSelectPayment,
  selectedPaymentMethod,
}) => {
  return (
    <div className='payment-methods'>
      <div className='payment-methods-heading'>
        <span>
          <FormattedMessage id='select_payment_method' />
        </span>
      </div>
      <div className='payment-methods-container'>
        <div
          className='payment-method'
          onClick={() => handelSelectPayment('vipps')}
        >
          <div className='select-method'>
            <input
              type='checkbox'
              checked={selectedPaymentMethod === 'vipps'}
            />
            <label>Vipps</label>
          </div>
          <div className='vipps'></div>
        </div>
        <div
          className='payment-method'
          onClick={() => handelSelectPayment('card')}
        >
          <div className='select-method'>
            <input type='checkbox' checked={selectedPaymentMethod === 'card'} />
            <label>Credit/Debit Card</label>
          </div>
          <div className='options'>
            <img src={MasterCard} alt='MasterCard' />
            <img src={VisaCard} alt='VisaCard' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectPaymentMethod;
