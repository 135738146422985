import React from 'react';
import { FormattedMessage } from 'react-intl';
import PrimaryButton from '../../../components/PrimaryButton';
import styles from './LoginForm.module.css';

const VerificationMessage = ({ onResend }) => {
  return (
    <div>
      <div className={styles['message']}>
        <FormattedMessage id='verification_message' />
      </div>
      <div className={styles['btn-container']}>
        <PrimaryButton
          children={<FormattedMessage id='resend_email' />}
          onClick={onResend}
        />
      </div>
    </div>
  );
};

export default VerificationMessage;
