import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { AddNewGrave, NotFoundPage } from './index';
import Layout from './Layout';
import Graves from '../containers/Graves/Graves';
import HomePage from './LandingPage/homepage/HomePage';
import About from './LandingPage/atoms/About';
import HomepageGrave from './LandingPage/atoms/HomepageGrave';
import HomepageInvestigate from './LandingPage/atoms/HomepageInvestigate';
import Features from './LandingPage/atoms/Features';
import HomepageScientists from './LandingPage/atoms/HomepageScientists';
import LearnMore from './LandingPage/atoms/LearnMore';
import HomepageFindGrave from './LandingPage/atoms/HomepageFindGrave';
import TermsConditions from './LandingPage/terms_conditions/Terms_Conditions';
import Settings from './Settings/Settings';
import NewUserProfile from './NewUserProfile/NewUserProfile';
import HowToFindGrave from './LandingPage/atoms/HowToFindGrave';
import HowToCreateGrave from './LandingPage/atoms/HowToCreateGrave';
import NewDeceasedPage from './NewDeceasedPage/NewDeceasedPage';
import Plans from './Wix/Plans';
import VippsPageAgreementPage from './VippsAgreementPage/VippsPageAgreementPage';
import GraveClaimPage from '../containers/NewDeceased/atoms/GraveClaimPage/GraveClaimPage';
import PostDetails from '../containers/NewDeceased/atoms/MemoriesPostDetails/PostDetails';
import HowToTakeGraveOwnership from './LandingPage/atoms/HowToTakeGraveOwnerShip';
import VippsAgreements from './VippsAgreements/VippsAgreements';
import FeaturesMemoryAlbum from './LandingPage/atoms/FeaturesMemoryAlbum';
import FeaturesArchive from './LandingPage/atoms/FeaturesArchive';
import FeaturesCommemorative from './LandingPage/atoms/FeaturesCommemorative';
import FeaturesLifeLine from './LandingPage/atoms/FeaturesLifeLine';
import TheEndOfTime from './LandingPage/atoms/TheEndOfTime';
import FreePlan from './LandingPage/atoms/FreePlan';
import ConsciousPlan from './LandingPage/atoms/ConsciousPlan';
import ConsciousPlus from './LandingPage/atoms/ConsciousPlus';
import AboutProduct from './LandingPage/atoms/AboutProduct';
import AccountConfirmation from './AccountConfirmation/AccountConfirmation';
import ResetPassword from './ResetPassword/ResetPassword';
import FeaturesAvailableSustainable from './LandingPage/atoms/FeatureAvailableSustainable';
import SendMessage from './LandingPage/atoms/SendMessage';
import FeaturesMaintenanceFree from './LandingPage/atoms/FeaturesMaintenanceFree';
import SignInPage from '../pages/Auth/SignInPage';
import Registration from '../pages/Auth/Registration';
import PlansPage from './PlansPage/PlansPage';
import NewPaymentPage from '../containers/Payment/NewPaymentPage';
import SettingsPersonalInformation from '../containers/NewSettings/atoms/SettingsPagePersonalInformation';
import ChangePassword from '../containers/NewSettings/atoms//ChangePassword';
import Notifications from '../containers/NewSettings/atoms//Notifications';
import GiftSubscription from '../containers/NewSettings/atoms/GiftSubscription/GiftSubscription';
import Subscriptions from '../containers/NewSettings/atoms/Subscriptions';
import PlaneMembers from '../containers/NewSettings/atoms/PlanMembers';
import PaymentMethods from '../containers/NewSettings/atoms/PaymentMethods';
import Contact from '../containers/NewSettings/atoms/Contact';
import Payments from '../pages/Payments/Payments';
import GraveMedia from '../containers/GraveMedia/GraveMedia';
import GraveAdministrator from '../containers/NewDeceased/atoms/GraveAdministrator/GraveAdministrator';
import LifeEventPage from '../containers/NewDeceased/atoms/LifeEventPage/LifeEventPage';
import AboutGraveInfo from '../containers/NewDeceased/atoms/About/AboutGraveInfo';
import DeceasedGraveWall from '../containers/NewDeceased/DeceasedGraveWall';
import UserAccount from '../containers/NewSettings/atoms/UserAccount/UserAccount';
import GraveInvitation from './GraveInvitation/GraveInvitation';

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/about' element={<About />} />
          <Route path='/about-product' element={<AboutProduct />} />
          <Route path='/graven' element={<HomepageGrave />} />
          <Route path='/invest' element={<HomepageInvestigate />} />
          <Route path='/funksjoner' element={<Features />} />
          <Route path='/find' element={<HowToFindGrave />} />
          <Route path='/create' element={<HowToCreateGrave />} />
          <Route path='/ownership' element={<HowToTakeGraveOwnership />} />
          <Route path='/scientists' element={<HomepageScientists />} />
          <Route path='/finn-en-grav' element={<HomepageFindGrave />} />
          <Route
            path='/finn-en-grav/:municipality'
            element={<HomepageFindGrave />}
          />
          <Route
            path='/finn-en-grav/:municipality/:cemetery'
            element={<HomepageFindGrave />}
          />
          <Route
            path='/finn-en-grav/:municipality/:cemetery/:slug'
            element={<Navigate to='/deceased/:slug' />}
          />
          <Route path='/graveier' element={<LearnMore />} />
          <Route path='/terms_conditions' element={<TermsConditions />} />
          <Route path='/abonnement' element={<Plans />} />
          <Route path='/auth/sign-in' element={<SignInPage />} />
          <Route path='/registration' element={<Registration />} />
          <Route path='/grave/:slug/claim' element={<GraveClaimPage />} />
          <Route path='/ny-grav' element={<AddNewGrave />} />
          <Route path='/settings' element={<Settings />}>
            <Route index element={<SettingsPersonalInformation />} />
            <Route path='contact' element={<Contact />} />
            <Route path='password' element={<ChangePassword />} />
            <Route path='payments' element={<Payments />} />
            <Route path='payment-methods' element={<PaymentMethods />} />
            <Route path='subscriptions' element={<Subscriptions />} />
            <Route path='plan-members' element={<PlaneMembers />} />
            <Route
              path='subscription-invitations'
              element={<GiftSubscription />}
            />
            <Route path='notifications' element={<Notifications />} />
            <Route path='account-information' element={<UserAccount />} />
          </Route>
          <Route path='/deceased/:slug' element={<NewDeceasedPage />}>
            <Route index element={<DeceasedGraveWall />} />
            <Route path='about' element={<AboutGraveInfo />} />
            <Route path='media' element={<GraveMedia />} />
            <Route path='administrators' element={<GraveAdministrator />} />
            <Route path='life-events' element={<LifeEventPage />} />
          </Route>
          <Route
            path='/deceased/:slug/memory/:memory_id'
            element={<PostDetails />}
          />

          <Route path='/member/:slug' element={<NewUserProfile />} />
          {/* <Route path='/user/:slug' element={<UserProfile />} /> */}
          <Route path='/graves' element={<Graves />} />
          <Route
            path='/vipps-payment-confirmation'
            element={<VippsPageAgreementPage />}
          />
          <Route path='/vipps-agreements' element={<VippsAgreements />} />
          <Route
            path='/features-minnealbum'
            element={<FeaturesMemoryAlbum />}
          />
          <Route
            path='/features-vedlikeholdsfri-grav'
            element={<FeaturesMaintenanceFree />}
          />
          <Route
            path='/features-tilgjengelig-bærekraftig'
            element={<FeaturesAvailableSustainable />}
          />
          <Route path='/features-verdiarkiv' element={<FeaturesArchive />} />
          <Route
            path='/features-minnetavlen'
            element={<FeaturesCommemorative />}
          />
          <Route path='/features-livslinjen' element={<FeaturesLifeLine />} />
          <Route path='/the-end-of-time-center' element={<TheEndOfTime />} />
          <Route path='/freeplan' element={<FreePlan />} />
          <Route path='/send-message' element={<SendMessage />} />
          <Route path='/consciousplan' element={<ConsciousPlan />} />
          <Route path='/consciousplus' element={<ConsciousPlus />} />
          <Route path='/grave/:slug/invitation' element={<GraveInvitation />} />
          <Route
            path='/account-confirmation'
            element={<AccountConfirmation />}
          />
          <Route path='/reset-password' element={<ResetPassword />} />
          <Route path='/pricing' element={<PlansPage />} />
          <Route path='/pricing/payment' element={<NewPaymentPage />} />
          <Route
            path='/sitemap'
            element={() => {
              window.location.replace(
                'https://nettgrav-sitemaps.s3.eu-central-1.amazonaws.com/sitemap.xml'
              );
              return null;
            }}
          />
          <Route
            path='/sitemap1'
            element={() => {
              window.location.replace(
                'https://nettgrav-sitemaps.s3.eu-central-1.amazonaws.com/sitemaps/sitemap1.xml'
              );
              return null;
            }}
          />
          <Route path='*' element={<NotFoundPage />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
};

export default AppRouter;
