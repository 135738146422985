import React from 'react';
import styles from './InputGroup.module.css';
import Input from '../Input/Input';

const InputGroup = ({ inputs }) => {
  return (
    <div className={styles['input-group']}>
      {inputs.map((input, index) => (
        <Input item={input} key={index} />
      ))}
    </div>
  );
};

export default InputGroup;
