import React from 'react';
import MunicipalityIndex from './Municipality/Index';
import CemeteryIndex from './Cemetery/Index';
import GraveIndex from './Grave/Index';

import './HomepageFindGraveThird.css';

import { useParams } from 'react-router-dom';

const HomepageFindGraveThird = () => {
  let { municipality, cemetery } = useParams();
  return (
    <>
      {cemetery ? (
        <GraveIndex cemeterySlug={cemetery} />
      ) : municipality ? (
        <CemeteryIndex municipalitySlug={municipality} />
      ) : (
        <MunicipalityIndex />
      )}
    </>
  );
};

export default HomepageFindGraveThird;
