import React from 'react';
import IframeComponent from '../../../components/Iframe/IframeComponent';

const FeaturesArchive = () => {
  return (
    <IframeComponent
      title='Features archive'
      source='https://www.wix.nettgrav.no/features-verdiarkiv'
    />
  );
};

export default FeaturesArchive;
