import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from '@mui/material';
import { request } from '../../service/request';
import styles from './Payments.module.css';
import { FormattedMessage } from 'react-intl';
import { PaymentType } from '../../service/constants';
import vippsLogo from '../../img/VippsLogo.svg';
import Visa from '../../img/Visa.svg';
import MaterCard from '../../img/MasterCard.svg';
import Status from '../../components/Status/Status';

const Payments = () => {
  const [payments, setPayments] = useState([]);
  const user = useSelector((state) => state.user.user || {});

  const fetchPayments = async () => {
    if (isEmpty(user)) return;

    try {
      const response = await request(`/users/${user.id}/payments`);
      setPayments(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPayments();
  }, [user]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${day} ${month} ${year}, ${hours}:${minutes}`;
  };

  const PaymentMethod = ({ payment }) => {
    let cardType = null;

    if (payment.card_type === 'visa') {
      cardType = Visa;
    } else if (payment.card_type === 'mastercard') {
      cardType = MaterCard;
    } else if (payment.payment_type === PaymentType.vipps) {
      cardType = vippsLogo;
    }

    return (
      <div className={styles['payment-value-container']}>
        {cardType && <img src={cardType} alt='card' />}
        {payment.payment_type === PaymentType.card && (
          <span>*******{payment.card_number}</span>
        )}
        {payment.payment_type === PaymentType.vipps && (
          <span>+47{payment.phone_number} </span>
        )}
        {payment.destroyed_at && (
          <Status
            statusKey='payment_method_inactive'
            style={{ fontSize: '16px' }}
          />
        )}
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className={styles['heading']}>
        <FormattedMessage id='settings_payments' />
      </div>
      {payments.length > 0 ? (
        <Box sx={{ overflowX: 'auto' }}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: '#e4f3fd' }}>
                  <TableCell>Date</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Payment Method</TableCell>
                  <TableCell>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {payments.map((payment, index) => (
                  <TableRow key={payment.id}>
                    <TableCell>{formatDate(payment.date)}</TableCell>
                    <TableCell>{payment.status}</TableCell>
                    <TableCell>
                      <PaymentMethod payment={payment} />
                    </TableCell>
                    <TableCell>
                      {payment.currency} {payment.amount}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ) : (
        <div className={styles['empty-message']}>
          <FormattedMessage id='settings_emptyPayments' />
        </div>
      )}
    </React.Fragment>
  );
};

export default Payments;
