import React, { useState } from 'react';
import AboutUser from './AboutUser';
import MenuForDesktop from './MenuForDesktop';
import MenuForMobile from './MenuForMobile';
import SparksSubMenu from './SparksSubMenu/SparksSubMenu';
import SparksSubMenuDesktop from './SparksSubMenu/SparksSubMenuDesktop';
import ProfileBackground from './ProfileBackground';
import styles from './UserHeader.module.css';

const UserHeader = ({ user, setCurrentUser }) => {
  const [selectedMenuKey, setSelectedMenuKey] = useState(1);
  return (
    <div className={styles['main-container']}>
      <ProfileBackground user={user} setCurrentUser={setCurrentUser} />
      <div className={styles['width-container']}>
        <div className={styles['padding-container']}>
          <AboutUser user={user} setCurrentUser={setCurrentUser} />
          {selectedMenuKey === 1 && (
            <React.Fragment>
              <SparksSubMenuDesktop user={user} />
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserHeader;
