import React from 'react';
import SubscriptionMember from '../Plans/SubscriptionMember/SubscriptionMember';

const Members = ({
  subscription,
  updateSubscriptionValidation,
  handelRemoveInvitation,
}) => {
  return (
    <SubscriptionMember
      subscription={subscription}
      updateSubscriptionValidation={updateSubscriptionValidation}
      handelRemoveInvitation={handelRemoveInvitation}
    />
  );
};

export default Members;
