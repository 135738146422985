import React from 'react';
import './HomepageGraveSecond.css';

import First from '../../../../img/HomepageSecondGraveIMage1.jpg';
import Second from '../../../../img/HomepageSecondGraveIMage2.jpg';

import {Link} from "react-router-dom";

import {useDispatch} from "react-redux";
import { setScreenRedux, setSearchInput } from '../../../../redux/actions';

const HomepageGraveSecond = (props) => {
    const dispatch = useDispatch();

    return(
        <div className='homepage-grave_second'>
            <div className="homepage-grave_second-content">
                <div className="homepage-grave_second-content_header">
                    <span className='homepage-grave_second-content_header-text'>Med Nettgrav får graven et egnet sted på nett</span>
                    <span className='homepage-grave_second-content_header-subtext'>Nettgrav er et privat og personlig sted for familien og de nærmeste pårørende.</span>
                </div>
                <div className="homepage-grave_second-content_main">
                    <div className="homepage-grave_second-content_main-first">
                        <div className="homepage-grave_second-content_main-first_text">
                            <span className="homepage-grave_second-content_main-first_text-header">Nettgrav.no i Norge</span>
                            <span className="homepage-grave_second-content_main-first_text-subheader">Alle Nettgraver er graver som er eller har vært på en gravlund. I dag er det ca 1,6 millioner gravlagte å besøke på nettgrav.no</span>
                            <Link to='/graves' className='homepage-grave_second-content_main-first_text-button' onClick={() => dispatch(setSearchInput(''))}>Finn en grav her</Link>
                        </div>
                        <div className="homepage-grave_second-content_main-first_images">
                            <div className='homepage-grave_second-content_main-first_images-grey'/>
                            <img loading='lazy' src={First} alt='First' className='homepage-grave_second-content_main-first_images-main'/>
                            <div className='homepage-grave_second-content_main-first_images-orange'/>
                        </div>
                    </div>
                    <div className="homepage-grave_second-content_main-first second">
                        <div className="homepage-grave_second-content_main-first_text">
                            <span className="homepage-grave_second-content_main-first_text-header">Opprette en ny gravlagt på nettgrav.no</span>
                            <span className="homepage-grave_second-content_main-first_text-subheader" style={{opacity:1}}>Hvert år dør omkring 43 000 mennesker i Norge. Graveier kan opprette Nettgrav og legge til sine på gravene ved å følge knappen under. </span>
                            <Link to='/ny-grav' className='homepage-grave_second-content_main-first_text-button second' onClick={()=>dispatch(setScreenRedux('new_grave'))}>Opprett ny Nettgrav</Link>
                        </div>
                        <div className="homepage-grave_second-content_main-first_images">
                            <div className='homepage-grave_second-content_main-first_images-grey second'/>
                            <img loading='lazy' src={Second} alt='First' className='homepage-grave_second-content_main-first_images-main'/>
                            <div className='homepage-grave_second-content_main-first_images-orange second'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomepageGraveSecond;
