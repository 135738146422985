import React, { useEffect, useState } from 'react';
import { request } from '../../service/request';
import { useNavigate } from 'react-router-dom';
import ClaimMessage from '../../containers/NewDeceased/atoms/ClaimMessageContainer/ClaimMessage';
import BackButton from '../../components/BackButton/BackButton';
import DeceasedTopHeader from '../../containers/NewDeceased/DeceasedTopHeader';
import SetSelfRelation from './Components/SetSelfRelation';
import ConnectRelationsModal from '../../containers/NewDeceased/atoms/ConnectRelationsModal/ConnectRelationsModal';
import SetSelfRelationPending from './Components/SetSelfRelationPending';
import PendingApproval from './Components/PendingApproval';
import SubscribedGraveInvite from './Components/SubscribedGraveInvite';
import showUserNotification from '../../components/UserNotification/showUserNotification';
import GraveInvitation from './Components/GraveInvitation';
import GraveInvitationPending from './Components/GraveInvitePending';
import DowngradedGraveUser from './Components/DowngradedGraveUser';
import SetSelfRelationSubscribed from './Components/SetSelfRelationSubscribed';
import ClaimQueueMessage from '../../containers/NewDeceased/atoms/ClaimMessageContainer/ClaimQueueMessage';
import styles from './ConnectRequest.module.css';

const ConnectRequest = ({ grave, isAuth, user, isClaimed, isAcceptedUser }) => {
  const graveInvitation = grave?.current_user_relation;
  const navigate = useNavigate();
  const [graveUser, setGraveUser] = useState(null);
  const [openRelationModal, setOpenRelationModal] = useState(false);
  useEffect(() => {
    let isGraveUser;
    if (Array.isArray(grave.grave_users)) {
      isGraveUser = grave.grave_users.find(
        (graveUser) => graveUser.user?.id === user.id
      );
    }
    if (isGraveUser) {
      setGraveUser(isGraveUser);
    }
  }, [grave, user]);

  const handelOpenModal = () => {
    setOpenRelationModal(true);
  };

  const handelCloseModal = () => {
    setOpenRelationModal(false);
  };

  const isAlreadySetRelation = () => {
    return (
      graveInvitation &&
      !graveInvitation?.invitation_accepted_at &&
      !graveInvitation?.invitation_token &&
      graveInvitation?.status !== 'accepted'
    );
  };

  const isAlreadyUser = () => {
    return (
      graveInvitation &&
      !graveInvitation?.invitation_accepted_at &&
      !graveInvitation?.invitation_token &&
      graveInvitation?.status === 'accepted'
    );
  };

  const isGraveInvitation = () => {
    return (
      graveInvitation &&
      graveInvitation?.invitation_token &&
      graveInvitation?.status !== 'accepted'
    );
  };

  const isGraveInvitationPending = () => {
    return (
      graveInvitation &&
      graveInvitation.invitation_accepted_at &&
      !graveInvitation?.invitation_token &&
      graveInvitation?.status !== 'accepted'
    );
  };

  const inClaimRequestQueue = () => {
    return isAuth && !grave.can_claim && grave.current_user_in_claim_queue;
  };

  const canRequestClaim = () => {
    return isAuth && grave.can_claim && !grave.current_user_in_claim_queue;
  };

  const confirmRelation = async (token, action) => {
    await request(`/grave_invitations/${token}/${action}`, {}, 'post')
      .then((res) => {
        if (action === 'accept' && user.is_subscribed) {
          window.location.reload();
        } else if (action === 'accept' && !user.is_subscribed) {
          localStorage.setItem('slug_for_redirect', grave.slug);
          navigate('/pricing/payment?payment_method_type=vipps');
        } else if (action === 'decline') {
          navigate(`/member/${user?.slug}`);
        }
      })
      .catch((error) => {
        showUserNotification(error.response.data.error || error.message);
        console.log(error);
      });
  };

  const handelGraveInvitation = (value) => {
    if (isGraveInvitation()) {
      confirmRelation(graveInvitation?.invitation_token, value);
    }
  };

  return (
    <div className={styles['deceased-connection']}>
      {/* <BackButton fallBackUrl={'/graves'} /> */}
      {canRequestClaim() && (
        <ClaimMessage
          grave={grave}
          handelOpenModal={handelOpenModal}
          isAcceptedUser={isAcceptedUser}
        />
      )}
      {inClaimRequestQueue() && <ClaimQueueMessage grave={grave} />}
      <DeceasedTopHeader
        isAuth={isAuth}
        user={user}
        grave={grave}
        isClaimed={isClaimed}
        handelOpenModal={handelOpenModal}
        isAcceptedUser={isAcceptedUser}
      />
      <div className={styles['set-relation-info']}>
        {isAlreadySetRelation() && !user?.is_subscribed && (
          <SetSelfRelationPending
            grave={grave}
            graveInvitation={graveInvitation}
          />
        )}
        {isAlreadySetRelation() && user?.is_subscribed && <PendingApproval />}
        {!graveInvitation && !user?.is_subscribed && (
          <SetSelfRelation grave={grave} handelOpenModal={handelOpenModal} />
        )}
        {!graveInvitation && user?.is_subscribed && (
          <SetSelfRelationSubscribed
            grave={grave}
            handelOpenModal={handelOpenModal}
          />
        )}
        {isGraveInvitation() && user?.is_subscribed && (
          <SubscribedGraveInvite
            grave={grave}
            graveInvitation={graveInvitation}
            handelGraveInvitation={handelGraveInvitation}
          />
        )}
        {isGraveInvitationPending() && !user?.is_subscribed && (
          <GraveInvitationPending
            grave={grave}
            graveInvitation={graveInvitation}
          />
        )}
        {isGraveInvitation() && !user?.is_subscribed && (
          <GraveInvitation
            grave={grave}
            graveInvitation={graveInvitation}
            handelGraveInvitation={handelGraveInvitation}
          />
        )}
        {isAlreadyUser() && !user?.is_subscribed && (
          <DowngradedGraveUser
            grave={grave}
            graveInvitation={graveInvitation}
          />
        )}
      </div>
      {openRelationModal && (
        <ConnectRelationsModal
          grave={grave}
          openRelationDialog={openRelationModal}
          handleCloseRelationDialog={handelCloseModal}
          user={user}
          graveUser={graveUser}
          graveInvitation={graveInvitation}
          isClaimed={isClaimed}
        />
      )}
    </div>
  );
};

export default ConnectRequest;
