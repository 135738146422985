import React from 'react';
import CalenderIcon from './CalenderIcon';
import styles from './Anniversary.module.css';
import AnniversaryTitle from './AnniversaryTitle';
import Timer from './Timer';
import ActionButtons from './ActionButtons';

const Anniversary = ({
  anniversary,
  setSelectedAnniversary,
  setOpenForm,
  deleteAnniversary,
  isPublic,
}) => {
  return (
    <div className={styles['container']}>
      <CalenderIcon />
      <div className={styles['sub-container']}>
        <AnniversaryTitle anniversary={anniversary} isPublic={isPublic} />
        {!isPublic && (
          <div className={styles['timer-btn-container']}>
            <Timer anniversary={anniversary} />
            <ActionButtons
              setOpenForm={setOpenForm}
              anniversary={anniversary}
              setSelectedAnniversary={setSelectedAnniversary}
              deleteAnniversary={deleteAnniversary}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Anniversary;
