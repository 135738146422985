import { combineReducers } from 'redux';
import { signInReducer } from './reducers/signIn_reducer';
import { searchReducer } from './reducers/search_reducer';
import { userReducer } from './reducers/user_reducer';
import { photoReducer } from './reducers/photo_reducer';
import { deceasedGraveReducer } from './reducers/grave_reducer';
import { userProfileReducer } from './reducers/user_profile_reducer';
import { sparksReducer } from './reducers/spark_reducer';

import {
  addRelationsToGrave,
  selectedSubscriptionPeriod,
  setListOfCountries,
  startRequest,
  finishRequest,
  setProducts,
  setPlan,
  RETURN_PAGE,
  paymentMethods,
  vippsPaymentMethods,
  currentSubscriptionPlan,
  currentSubscriptionData,
  invitationToken,
  invitationTokenErrorMessage,
  subscriptionCount,
  subscriptionTotalPrice,
  invitationTokenData,
  vippsChargedData,
  vippsAgreementData,
  setSubscriptionValidation,
  paymentMethod,
  showPaymentCard,
  selectedCardMethod,
  userBillingInformation,
  selectedVippsMethod,
  showVippsPaymentMethod,
  vippsPhoneNumber,
  subscriptionDiscount,
} from './actions';

const countriesState = {
  countries: [],
};

const countriesReducer = (state = countriesState, action) => {
  switch (action.type) {
    case setListOfCountries:
      return {
        ...state,
        countries: action.countries,
      };
    default:
      return state;
  }
};

const productsReducer = (state = [], action) => {
  switch (action.type) {
    case setProducts:
      return action.products;
    default:
      return state;
  }
};

const selectPlanReducer = (state = {}, action) => {
  switch (action.type) {
    case setPlan:
      return action.selectedPlan;
    default:
      return state;
  }
};

const currentSubscriptionState = {
  currentSubscriptionPlan: {},
  currentSubscriptionData: {},
};

const currentSubscriptionPlanReducer = (
  state = currentSubscriptionState,
  action
) => {
  switch (action.type) {
    case currentSubscriptionPlan:
      return { ...state, currentSubscriptionPlan: action.payload };
    case currentSubscriptionData:
      return { ...state, currentSubscriptionData: action.payload };
    default:
      return state;
  }
};

const relationsState = {
  relations: [],
};

const graveRelationsReducer = (state = relationsState, action) => {
  switch (action.type) {
    case addRelationsToGrave:
      return {
        ...state,
        relations: action.relations,
      };
    default:
      return state;
  }
};

const spinnerState = {
  isLoading: true,
};

const loadingSpinnerReducer = (state = spinnerState, action) => {
  switch (action.type) {
    case startRequest:
      return {
        isLoading: true,
      };
    case finishRequest:
      return {
        isLoading: false,
      };
    default:
      return state;
  }
};

const returnPageReducer = (state = { url: null }, action) => {
  switch (action.type) {
    case RETURN_PAGE:
      return {
        url: action.payload,
      };
    default:
      return state;
  }
};

const paymentMethodState = {
  paymentMethods: [],
  vippsPaymentMethods: [],
  selectedMethod: {},
  selectedVippsMethod: {},
  showVippsPaymentMethod: false,
  showCard: false,
  cardMethod: 'vipps',
  billingInformation: {},
  vippsPhoneNumber: '',
  subscriptionPeriod: {},
  subscriptionDiscount: {},
};
const setPaymentMethodsReducer = (state = paymentMethodState, action) => {
  switch (action.type) {
    case paymentMethods:
      return {
        ...state,
        paymentMethods: action.payload,
      };
    case subscriptionDiscount:
      return {
        ...state,
        subscriptionDiscount: action.payload,
      };
    case selectedSubscriptionPeriod:
      return {
        ...state,
        subscriptionPeriod: action.payload,
      };
    case vippsPaymentMethods:
      return {
        ...state,
        vippsPaymentMethods: action.payload,
      };
    case vippsPhoneNumber:
      return {
        ...state,
        vippsPhoneNumber: action.payload,
      };
    case paymentMethod:
      return {
        ...state,
        selectedMethod: action.payload,
      };
    case selectedVippsMethod:
      return {
        ...state,
        selectedVippsMethod: action.payload,
      };
    case showPaymentCard:
      return {
        ...state,
        showCard: action.payload,
      };
    case showVippsPaymentMethod:
      return {
        ...state,
        showVippsPaymentMethod: action.payload,
      };
    case selectedCardMethod:
      return {
        ...state,
        cardMethod: action.payload,
      };
    case userBillingInformation:
      return {
        ...state,
        billingInformation: action.payload,
      };
    default:
      return state;
  }
};

const InvitationToken = {
  subscriptionInvitationToken: '',
  subscriptionInvitationData: {},
  tokeErrorMessage: '',
};

const invitationTokenReducer = (state = InvitationToken, action) => {
  switch (action.type) {
    case invitationToken:
      return {
        ...state,
        subscriptionInvitationToken: action.payload,
      };
    case invitationTokenData:
      return {
        ...state,
        subscriptionInvitationData: action.payload,
      };
    case invitationTokenErrorMessage:
      return {
        ...state,
        tokeErrorMessage: action.payload,
      };
    default:
      return state;
  }
};

const subscriptionDetails = {
  subscriptionTotalPrice: 0,
  subscriptionTotalCount: 1,
};

const subscriptionReducer = (state = subscriptionDetails, action) => {
  switch (action.type) {
    case subscriptionCount:
      return {
        ...state,
        subscriptionTotalCount: action.payload,
      };
    case subscriptionTotalPrice:
      return {
        ...state,
        subscriptionTotalPrice: action.payload,
      };
    default:
      return state;
  }
};

const vippsData = {
  vippsAgreement: '',
  vippsCharged: {},
};

const vippsReducer = (state = vippsData, action) => {
  switch (action.type) {
    case vippsAgreementData:
      return {
        ...state,
        vippsAgreement: action.payload,
      };
    case vippsChargedData:
      return {
        ...state,
        vippsCharged: action.payload,
      };
    default:
      return state;
  }
};

const subscriptionValidationReducer = (state = {}, action) => {
  switch (action.type) {
    case setSubscriptionValidation:
      return action.payload;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  user: userReducer,
  auth: signInReducer,
  grave: deceasedGraveReducer,
  search: searchReducer,
  photoReducer: photoReducer,
  relations: graveRelationsReducer,
  countries: countriesReducer,
  spinnerLoad: loadingSpinnerReducer,
  products: productsReducer,
  selectedPlan: selectPlanReducer,
  userProfile: userProfileReducer,
  returnPage: returnPageReducer,
  paymentMethods: setPaymentMethodsReducer,
  currentSubscriptionPlan: currentSubscriptionPlanReducer,
  invitationToken: invitationTokenReducer,
  subscriptionDetails: subscriptionReducer,
  vipps: vippsReducer,
  subscriptionValidationData: subscriptionValidationReducer,
  spark: sparksReducer,
});

export default rootReducer;
