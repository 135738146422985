import React from 'react';
import styles from './Status.module.css';
import Tooltip from '@mui/material/Tooltip';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { useIntl } from 'react-intl';

const Status = ({ statusKey }) => {
  const intl = useIntl();
  return (
    <Tooltip title={intl.formatMessage({ id: statusKey })} placement='top'>
      <WarningAmberRoundedIcon
        style={{ color: '#d16202', cursor: 'pointer', fontSize: '18px' }}
      />
    </Tooltip>
  );
};

export default Status;
