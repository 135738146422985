import React from 'react';
import './HugModalBody.css';
import Image from '../../../../../../img/HugModalBodyImage.svg';
import X from '../../../../../../img/X.svg';
import { request } from '../../../../../../service/request';

const HugModalBody = ({creator, onClose}) => {
    const [userName, setUserName] = React.useState('');

    React.useEffect(() => {
        (async () => {
            try {
                const response = await request(`/users/${creator}`)
                setUserName(response.data.user.full_name);
            } catch(err) {
                console.log(err)
            }
        })();
    }, [])

    return(
        <div className='hug-modal-body'>
            <div className="hug-modal-body_content">
                <div className="hug-modal-body_content-image">
                    <img loading='lazy' src={Image} alt='' className='hug-modal-body_content-image_main'/>
                    <img loading='lazy' src={X} alt='' onClick={onClose} className='hug-modal-body_content-image_close'/>
                </div>
                <div className="hug-modal-body_content-text">
                    <span className="hug-modal-body_content-text_title">You just hugged<br/>{userName}</span>
                    <span className='hug-modal-body_content-text_subtitle'>The user will be notified that you hugged him!</span>
                </div>
            </div>
        </div>
    )
}

export default HugModalBody;