import React, { useEffect, useState } from 'react';
import CardInputs from '../../../Payment/atoms/NewCard/CardInputs';
import BillingInputs from '../../../Payment/atoms/NewCard/BillingInputs';
import isEmpty from 'lodash.isempty';
import PrimaryButton from '../../../../components/PrimaryButton/index';
import styles from './AddPaymentMethod.module.css';
import { FormattedMessage, useIntl } from 'react-intl';
import showUserNotification from '../../../../components/UserNotification/showUserNotification';
import { request } from '../../../../service/request';
import {
  useStripe,
  useElements,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import ProcessModal from '../../../Payment/atoms/ProcessModal';
import ErrorModal from '../../../Payment/atoms/ErrorModal/ErrorModal';
import PaymentMethods from '../../../Payment/atoms/PaymentMethods/PaymentMethods';
import AddNewCard from '../../../Payment/atoms/AddNewCard/AddNewCard';
import { PaymentType } from '../../../../service/constants';

const PaymentMethodForm = ({
  countries,
  close,
  user,
  fetchSubscriptionData,
  currentSubscription,
  cardPaymentMethods,
  setSelectedPaymentMethod,
  selectedPaymentMethod,
  updateSubscription,
  selectedMethod,
}) => {
  const intl = useIntl();
  const stripe = useStripe();
  const elements = useElements();
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openLoader, setOpenLoader] = useState(false);
  const [cardInputs, setCardInputs] = useState({
    cardNumber: '',
    Cvc: '',
    expiry: '',
  });
  const [nameOnCard, setNameOnCard] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(null);
  const [isValid, setIsValid] = useState(null);
  const [billingInformation, setBillingInformation] = useState({});
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    setShowForm(cardPaymentMethods.length === 0);
  }, [cardPaymentMethods]);

  useEffect(() => {
    const userPhoneNumber = `${user.country_code}${user.phone_number}`;

    setBillingInformation({
      ...billingInformation,
      fullName: user?.full_name ? user.full_name : '',
      billingAddress: user?.address ? user.address : '',
      email: user?.email ? user.email : '',
      country_id: user.country_id ? user.country_id : countries[0].id,
      postalCode: '',
      phoneNumber: userPhoneNumber ? userPhoneNumber : '',
    });
  }, [user, countries]);

  const areAllValuesFilled = (value) => {
    return Object.values(value).every((value) => value !== '');
  };

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }
    const card = elements.getElement(CardNumberElement);

    stripe
      .createPaymentMethod({
        type: 'card',
        card: card,
        billing_details: {
          name: nameOnCard,
        },
      })
      .then((result) => {
        stripePaymentMethodHandler(result);
      });
  };

  const stripePaymentMethodHandler = async (result) => {
    if (result.error) {
      setOpenLoader(false);
      setErrorMessage(result.error.message);
      setOpenErrorModal(true);
    } else {
      createPaymentMethod(result.paymentMethod);
    }
  };

  const onSave = async () => {
    if (
      !(
        areAllValuesFilled(billingInformation) && areAllValuesFilled(cardInputs)
      )
    ) {
      showUserNotification(
        intl.formatMessage({ id: 'filled_out_info' }),
        'error'
      );
      return;
    }
    setOpenLoader(true);
    handleSubmit();
  };

  const createPaymentMethod = async (paymentMethod) => {
    const payload = {
      payment: {
        subscription_id: currentSubscription.id,
        payment_method: {
          payment_type: paymentMethod.type,
          stripe_id: paymentMethod.id,
          country: paymentMethod.card.country,
          exp_month: paymentMethod.card.exp_month,
          exp_year: paymentMethod.card.exp_year,
          is_active: true,
          card_type: paymentMethod.card.brand,
          card_number: paymentMethod.card.last4,
        },
        billing_information: {
          name: billingInformation.fullName,
          email: billingInformation.email,
          phone_number: billingInformation.phoneNumber,
          address: billingInformation.billingAddress,
          postal_code: billingInformation.postalCode,
          country_id: billingInformation.country_id,
        },
      },
    };

    try {
      const response = await request(
        '/stripe/payment_methods',
        payload,
        'post'
      );

      if (response.error) {
        setOpenLoader(false);
        setErrorMessage(response.error);
        setOpenErrorModal(true);
        return;
      } else {
        fetchSubscriptionData();
        setOpenLoader(false);
        close();
      }
    } catch (error) {
      setOpenLoader(false);
      setErrorMessage(error.message);
      setOpenErrorModal(true);
    }
  };

  const handelSelectPaymentMethod = (value) => {
    setSelectedPaymentMethod(value);
    setShowForm(false);
  };

  const handelOnSave = () => {
    if (
      !isEmpty(selectedPaymentMethod) &&
      selectedPaymentMethod.payment_type === PaymentType.card
    ) {
      updateSubscription();
    } else if (showForm) {
      onSave();
    } else {
      showUserNotification(
        intl.formatMessage({ id: 'warning_payment_method' }),
        'warning'
      );
    }
  };

  return (
    <React.Fragment>
      {cardPaymentMethods.length > 0 &&
        cardPaymentMethods.map((paymentMethod) => (
          <PaymentMethods
            key={paymentMethod.id}
            method={paymentMethod}
            handelSelectPaymentMethod={handelSelectPaymentMethod}
            showPayment={selectedPaymentMethod.id === paymentMethod.id}
          />
        ))}
      {!showForm && cardPaymentMethods.length > 0 && (
        <AddNewCard
          handleShowCard={setShowForm}
          handleShowPayment={setSelectedPaymentMethod}
          showCard={showForm}
        />
      )}
      {showForm && (
        <React.Fragment>
          <div className={styles['payment-details']}>
            <div className={styles['card-information']}>
              <CardInputs
                cardInputs={cardInputs}
                setCardInputs={setCardInputs}
                nameOnCard={nameOnCard}
                setNameOnCard={setNameOnCard}
              />
            </div>
            {!isEmpty(billingInformation) && (
              <BillingInputs
                setBillingInformation={setBillingInformation}
                billingInformation={billingInformation}
                setIsValid={setIsValid}
                isValidEmail={isValidEmail}
                isValid={isValid}
                setIsValidEmail={setIsValidEmail}
                countries={countries}
              />
            )}
          </div>
        </React.Fragment>
      )}
      <div className={styles['btn-container']}>
        <PrimaryButton
          onClick={close}
          children={<FormattedMessage id='settings_cancel' />}
          style={{
            background: '#fff',
            border: '1px solid #404D56',
            color: '#404D56',
            padding: '7px 16px',
          }}
        />
        <PrimaryButton
          onClick={handelOnSave}
          children={<FormattedMessage id='auth_save' />}
        />
      </div>
      {openLoader && (
        <ProcessModal
          open={openLoader}
          messageKey='create_payment_method_waiting'
        />
      )}
      {openErrorModal && (
        <ErrorModal
          message={errorMessage}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
        />
      )}
    </React.Fragment>
  );
};

export default PaymentMethodForm;
