import React, { useEffect, useState } from 'react';
import styles from './SetRelation.module.css';
import { makeStyles } from '@mui/styles';
import isEmpty from 'lodash.isempty';
import Input from '../../../../../components/Input/Input';

const useStyle = makeStyles(() => ({
  rootMembers: {
    width: '191px',
    height: '40px',
    borderRadius: '10px',
    backgroundColor: '#F5F5F5',
    border: 'none',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#36434B',
    textTransform: 'none',
    '@media (max-width: 750px)': {
      width: '167px',
      height: '40px',
    },
  },
  rootMembersClicked: {
    width: '191px',
    height: '40px',
    borderRadius: '10px',
    backgroundColor: '#F5F5F5',
    border: '1px solid #FEA65A',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#36434B',
    textTransform: 'none',
    '@media (max-width: 750px)': {
      width: '167px',
      height: '40px',
    },
  },
  rootConfirm: {
    width: '171px',
    height: '37px',
    borderRadius: '10px',
    backgroundColor: '#FEA65A',
    color: '#fff',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#FEA65A',
    },
  },
  input: {
    padding: '10px !important',
  },
}));

const SetRelation = ({
  relation,
  reciprocalRelation,
  relationList,
  directionsList,
  handelRelation,
  handelReciprocalRelation,
}) => {
  const classes = useStyle();
  const [leftToRightRelation, setLeftToRightRelation] = useState({});
  const [rightToLeftRelation, setRightToLeftRelation] = useState({});

  useEffect(() => {
    if (!isEmpty(relation) && !isEmpty(reciprocalRelation)) {
      setLeftToRightRelation(relation);
      setRightToLeftRelation(reciprocalRelation);
    }
  }, []);

  const handleLeftToRightRelation = (event, value, reason) => {
    if (reason === 'clear') {
      setLeftToRightRelation({});
      handelRelation({});
    } else if (reason === 'selectOption') {
      setLeftToRightRelation(value);
      handelRelation(value);
    }
    setRightToLeftRelation({});
    handelReciprocalRelation({});
  };

  const handleRightToLeftRelation = (event, value, reason) => {
    if (reason === 'clear') {
      setRightToLeftRelation({});
      handelReciprocalRelation({});
    } else if (reason === 'selectOption') {
      setRightToLeftRelation(value);
      handelReciprocalRelation(value);
    }
  };

  const filterDirectionList = (selectedRelation) => {
    if (isEmpty(selectedRelation)) return [];

    let filteredList = [];

    directionsList.map((item) => {
      if (item.from_relation.id === selectedRelation.id) {
        filteredList.push(item.to_relation);
      }

      return false;
    });

    if (filteredList.length === 0) {
      return [selectedRelation];
    } else {
      return filteredList;
    }
  };

  let formInputs = [
    {
      label: 'i_am',
      type: 'autocomplete',
      placeholder: 'select_relationship',
      value: leftToRightRelation,
      options: relationList,
      autoComplete: 'off',
      error: false,
      errorKey: 'select_relation_error',
      disabled: false,
      action: handleLeftToRightRelation,
    },
    {
      label: 'buried_is_mine',
      type: 'autocomplete',
      placeholder: 'select_relationship',
      value: rightToLeftRelation,
      options: filterDirectionList(leftToRightRelation),
      autoComplete: 'off',
      error: false,
      errorKey: 'select_relation_error',
      disabled: false,
      action: handleRightToLeftRelation,
    },
  ];

  return (
    <div className={styles['input-element']}>
      {formInputs.map((item, index) => (
        <Input key={index} item={item} />
      ))}
      {/* <div className={styles['title']}>Velg din relasjon til gravlagte</div>
        <div className={styles['choose-relation']}>
          <Autocomplete
            id='relation-combo-box'
            options={relationList}
            classes={{ input: classes.input }}
            getOptionLabel={(option) => option.name}
            style={{ width: '99%' }}
            value={leftToRightRelation}
            renderInput={(params) => (
              <TextField placeholder='Velg relasjon' {...params} />
            )}
            onChange={handleLeftToRightRelation}
          />
        </div>
      </div>
      <div className={styles['input-element']}>
        <div className={styles['title']}>Velg gravlagtes relasjon til deg</div>
        <div className={styles['choose-relation']}>
          <Autocomplete
            id='relation-combo-box'
            options={filterDirectionList(leftToRightRelation)}
            classes={{ input: classes.input }}
            getOptionLabel={(option) => option.name || ''}
            value={rightToLeftRelation}
            style={{ width: '99%' }}
            disabled={isEmpty(leftToRightRelation)}
            renderInput={(params) => (
              <TextField placeholder='Velg relasjon' {...params} />
            )}
            onChange={handleRightToLeftRelation}
          />
        </div> */}
    </div>
  );
};

export default SetRelation;
