import React, { useState } from 'react';
import './Content.css'
import FirstStep from './ContentComponents/FirstStep'
import SecondStep from './ContentComponents/SecondStep'

const Content = (props) => {
  const step = props.step
  const [chosenGrave, setChosenGrave] = useState({});

  return(
    <div className="grave-connections-content">
      {
        step === 1 &&
        <FirstStep 
          grave={props.grave} 
          user={props.user} 
          setStep={props.setStep} 
          setChosenGrave={setChosenGrave}
        />
      }

      {
        step === 2 &&
        <SecondStep
          sourceGrave={props.grave}
          targetGrave={chosenGrave}
          handleCloseGraveConnectionDialog={props.handleCloseGraveConnectionDialog}
        />
      }
    </div>
  )
};

export default Content;