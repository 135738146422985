import React from 'react';
import { FormattedMessage } from 'react-intl';
import Features from '../../../containers/NewDeceased/atoms/ConnectRelationsModal/Features';
import styles from './SetSelfRelation.module.css';
import PrimaryButton from '../../../components/PrimaryButton';
import { useNavigate } from 'react-router-dom';

const GraveInvitationPending = ({ grave, graveInvitation }) => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className={styles['heading']}>
        <FormattedMessage id='complete_relation_setup' />
      </div>
      <div className={styles['sub-heading']}>
        <FormattedMessage
          id='already_accept_relation'
          values={{
            graveName: grave?.name,
            relation: (
              <FormattedMessage
                id={
                  graveInvitation.relation?.key
                    ? graveInvitation.relation?.key
                    : graveInvitation.relation?.name
                }
              />
            ),
          }}
        />
      </div>
      <div className={styles['sub-heading']}>
        <FormattedMessage id='finalize_membership' />
      </div>
      <Features />
      <div className={styles['sub-heading']}>
        <FormattedMessage id='click_below_to_complete' />
      </div>
      <div className={styles['btn-container']}>
        <PrimaryButton
          onClick={() => {
            localStorage.setItem('slug_for_redirect', grave.slug);
            navigate('/pricing/payment?payment_method_type=vipps');
          }}
          children={<FormattedMessage id='complete_payment' />}
        />
      </div>
    </React.Fragment>
  );
};

export default GraveInvitationPending;
