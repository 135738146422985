import React, { useEffect, useState } from 'react';
import Stepper from './atoms/Stepper';
import FirstStep from './atoms/FirstStep';
import SecondStep from './atoms/SecondStep';
import EndStep from './atoms/EndStep';
import { useDispatch, useSelector } from 'react-redux';
import { getListOfCountries } from '../../redux/actions';
import { request } from '../../service/request';
import { useNavigate } from 'react-router-dom';
import showUserNotification from '../../components/UserNotification/showUserNotification';
import { FormattedMessage } from 'react-intl';
import './AddStepper.css';

const AddStepper = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const countriesList = useSelector((state) => state.auth.countries);
  const [relationList, setRelationList] = useState([]);
  const [reasonOfPassing, setReasonOfPassing] = useState([]);
  const [firstStepData, setFirstStepData] = useState();
  const [secondStepData, setSecondStepData] = useState();
  const [allData, setAllData] = useState();

  // Get Countries
  useEffect(() => {
    if (countriesList.length === 0) {
      request('/countries')
        .then((res) => dispatch(getListOfCountries(res.data)))
        .catch((err) => console.log(err));
    }
  }, [dispatch, countriesList]);

  // Get Relations
  useEffect(() => {
    request('/relations')
      .then((res) => setRelationList(res.data))
      .catch((err) => console.log(err));
  }, []);

  //Get Reasons of death
  useEffect(() => {
    request(`/reason_of_deaths`)
      .then((res) => setReasonOfPassing(res.data.reasons))
      .catch((err) => console.log(err));
  }, []);

  const onNextStep = () => props.setStep(props.step + 1);

  const onFirstStepDone = (data) => {
    onNextStep();
    setFirstStepData(data);
    setAllData(data);
  };

  const onSecondStepDone = (data) => {
    onNextStep();
    setSecondStepData(data);
    setAllData((prev) => ({ ...prev, ...data }));
  };

  const onBackClick = () => props.setStep(props.step - 1);

  const onDoneClick = (data) => {
    props.handelIsLoading(true);
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    var formData = new FormData();
    formData.append('grave[name]', allData.name);
    formData.append(
      'grave[date_of_birth]',
      `${new Date(allData.selectedBirthDate).getDate()}/${
        new Date(allData.selectedBirthDate).getMonth() + 1
      }/${new Date(allData.selectedBirthDate).getFullYear()}`
    );
    formData.append(
      'grave[date_of_death]',
      `${new Date(allData.selectedDeathDate).getDate()}/${
        new Date(allData.selectedDeathDate).getMonth() + 1
      }/${new Date(allData.selectedDeathDate).getFullYear()}`
    );
    formData.append('grave[municipality_id]', data.municipality.id);
    formData.append('grave[reason_of_death_id]', allData.passing.id);
    formData.append('grave[gender]', allData.gender);
    formData.append('grave[country_id]', data.country.id);
    formData.append('grave[graveyard_id]', data.cemetery.id);
    if (allData.certificate) {
      formData.append(
        'grave[death_certificate_attributes][image]',
        allData.certificate
      );
      formData.append(
        'grave[death_certificate_attributes][user_id]',
        localStorage.getItem('id')
      );
    }
    formData.append(
      'grave[grave_users_attributes][0][relation_id]',
      allData.relation.id
    );
    formData.append(
      'grave[grave_users_attributes][0][reciprocal_relation_id]',
      allData.reciprocalRelation.id
    );
    formData.append(
      'grave[grave_users_attributes][0][user_id]',
      localStorage.getItem('id')
    );
    if (allData.passing.id === 11) {
      formData.append('grave[other_reason_of_death]', allData.otherPassing);
    }
    request('/graves/', formData, 'post', headers)
      .then((response) => {
        showUserNotification('Ny grav opprettet', 'success');
        props.handelIsLoading(false);
        navigate(`/deceased/${response.data.slug}`);
      })
      .catch((error) => {
        console.log('error', error);
        props.handelIsLoading(false);
        showUserNotification('New Grave is not created', 'warning');
      });
  };

  return (
    <div className='add-stepper'>
      <h1 className='new-grave-heading'>
        <FormattedMessage id='create_new_grave' />
      </h1>
      <Stepper step={props.step} />
      {props.step === 0 && (
        <FirstStep
          relationList={relationList}
          onNextClick={onFirstStepDone}
          firstStepData={firstStepData}
        />
      )}
      {props.step === 1 && (
        <SecondStep
          reasonOfPassing={reasonOfPassing}
          onNextClick={onSecondStepDone}
          onBackClick={onBackClick}
          secondStepData={secondStepData}
        />
      )}
      {props.step === 2 && (
        <EndStep onBackClick={onBackClick} onDoneClick={onDoneClick} />
      )}
    </div>
  );
};

export default AddStepper;
