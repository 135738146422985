import React, { useState } from 'react';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { addCommentOnToggle } from '../../../../../redux/actions';
import './VideoMobile.css';
import AddByLinkMobile from '../../../../../img/AddByLinkMobile.svg';
import Pointer from '../../../../../img/Pointer.svg';
import ReactPlayer from 'react-player';

const useStyle = makeStyles(() => ({
  rootCancel: {
    width: '180px',
    height: '55px',
    color: '#C9CACB',
    background: '#fff',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '19px',
    '&:hover': {
      background: '#fff',
    },
  },
  rootSave: {
    width: '180px',
    height: '55px',
    color: '#fff',
    background: '#FEA65A',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '19px',
    borderRadius: '10px',
    '&:hover': {
      background: '#fff',
    },
  },
}));

const VideoMobile = (props) => {
  const classes = useStyle();
  const [link, setLink] = useState('');
  const [headline, setHeadline] = useState('');
  const [description, setDescription] = useState('');
  const dispatch = useDispatch();
  const currentComment = useSelector((state) => state.grave.commentOnToggle);

  const saveVideoComment = () => {
    dispatch(
      addCommentOnToggle({
        ...currentComment,
        title: description,
        text: headline,
        video: link,
      })
    );
    props.closeModal();
  };
  return (
    <div className='video-modal_mobile'>
      <div className='music-modal_mobile-close'>
        <img
          loading='lazy'
          src={Pointer}
          alt='pointer'
          onClick={props.closeModal}
        />
        <span onClick={props.closeModal}>Go to add new post</span>
      </div>
      <div className='music-modal_mobile-content'>
        <div className='music-modal_mobile-content_header'>
          <img loading='lazy' src={AddByLinkMobile} alt='AddByLinkMobile' />
        </div>
        <div className='music-modal_mobile-content_input'>
          <span>Link on the video</span>
          <div>
            <input
              type='text'
              placeholder='https://'
              value={link}
              onChange={(e) => setLink(e.target.value)}
            />
          </div>
        </div>
        <div className='music-modal_mobile-content_subheader'>
          <span
            className={
              link
                ? 'add-by-link_header-subtitle disabled'
                : 'add-by-link_header-subtitle video'
            }
          >
            You can add video from sites such as <span>Youtube</span>,{' '}
            <span>Vimeo</span> and more.
          </span>
        </div>
        {link && (
          <div className='video-mobile_modal-content_video'>
            <span>Video</span>
            <ReactPlayer url={link} width='343px' height='186px' light={true} />
          </div>
        )}
        {/* {
                    link &&
                    <div className='video-mobile_modal-content_headline'>
                        <span>Headline</span>
                        <textarea value={headline} onChange={e => setHeadline(e.target.value)}/>
                    </div>
                }
                {
                    link &&
                    <div className='video-mobile_modal-content_description'>
                        <span>Description</span>
                        <textarea value={description} onChange={e => setDescription(e.target.value)}/>
                    </div>
                } */}
        {link && (
          <div className='music-modal_mobile-content_buttons'>
            <Button className={classes.rootCancel} onClick={props.closeModal}>
              Cancel
            </Button>
            <Button className={classes.rootSave} onClick={saveVideoComment}>
              Save
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoMobile;
