import React from 'react';
import CustomModal from '../../../components/CustomModal';
import { FormattedMessage } from 'react-intl';
import VerificationMessage from './VerificationMessage';

const VerificationModal = ({ open, close, onResend }) => {
  return (
    <div>
      <CustomModal
        isOpen={open}
        closeModal={close}
        title={<FormattedMessage id='verify_account' />}
        children={<VerificationMessage onResend={onResend} />}
        style={{ width: '600px' }}
      />
    </div>
  );
};

export default VerificationModal;
