import React, { useState, useEffect } from 'react';
import styles from './VippsAgreementPage.module.css';
import { useLocation, useParams } from 'react-router-dom';
import { fetch_request } from '../../service/request';
import VippsAgreement from '../../containers/VippsAgreement/VippsAgreement';
import LoadingAnimation from '../../service/Loading_animation.gif';
import showUserNotification from '../../components/UserNotification/showUserNotification';
import { style } from '@mui/system';

const VippsPageAgreementPage = () => {
  const location = useLocation();
  const [agreementDetails, setAgreementDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const params = useParams();

  useEffect(() => {
    if (params.userId) {
      const requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      fetch_request(
        `/api/users/${params.userId}/vipps_agreement`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.message) {
            setLoading(false);
            setMessage(data.message);
          } else {
            setAgreementDetails(data);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          showUserNotification('noe gikk galt, prøv igjen', 'error');
        });
    }
  });

  return (
    <div className={styles['vipps-agreement-page']}>
      <div className={styles['vippa-page-wrapper']}>
        <div className={styles['vipps-heading-container']}>
          <div className={styles['vipps-heading']}>Betalingsinformasjon</div>
        </div>
        {loading && (
          <div className={styles['laster']}>
            <img src={LoadingAnimation} alt='laster...' />
          </div>
        )}
        {!loading &&
        agreementDetails &&
        Object.keys(agreementDetails).length > 0 ? (
          <VippsAgreement agreement={agreementDetails} />
        ) : (
          <div className={styles['message']}>{message}</div>
        )}
      </div>
    </div>
  );
};

export default VippsPageAgreementPage;
