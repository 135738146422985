import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NextPhotoPointer from '../../../../../img/OrangePointerNext.svg';
import PrevPhotoPointer from '../../../../../img/OrangePointerPrev.svg';
import EmptyDot from '../../../../../img/EmptyDot.svg';
import styles from './SelectedVideos.module.css';
import OrangeDot from '../../../../../img/FilledDot.svg';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import {
  setEventPhotoIndex,
  setEditEventPhotoIndex,
  addLifeEventVideos,
  setEventVideoIndex,
  setSelectedEvent,
  setLifeEvents,
} from '../../../../../redux/actions';
import CloseIcon from '@mui/icons-material/Close';
import CropIcon from '@mui/icons-material/Crop';
import isEmpty from 'lodash.isempty';
import ReactPlayer from 'react-player';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Delete from '../../../../../img/DeleteCoverPhoto.svg';
import { request } from '../../../../../service/request';
import { Menu, MenuItem } from '@mui/material';
import showUserNotification from '../../../../../components/UserNotification/showUserNotification';
import ConformModal from '../../../../NewSettings/atoms/ConformDeleteModal';

const SelectedVideos = ({
  videos,
  event,
  currentEventVideoIndex,
  user,
  setOpenUpgradeModal,
}) => {
  const dispatch = useDispatch();
  const [touchStartX, setTouchStartX] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const allEvents = useSelector((state) => state.grave.lifeEvents);
  const selectedEvent = useSelector((state) => state.grave.selectedEvent);

  const handleClick = (event) => {
    if (user.is_subscribed) {
      setAnchorEl(event.currentTarget);
    } else {
      setOpenUpgradeModal(true);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const prevPhoto = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (currentEventVideoIndex === 0) {
      dispatch(setEventVideoIndex(videos.length - 1));
    } else {
      dispatch(setEventVideoIndex(currentEventVideoIndex - 1));
    }
  };

  const nextPhoto = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (currentEventVideoIndex === videos.length - 1) {
      dispatch(setEventVideoIndex(0));
      return 0;
    } else {
      dispatch(setEventVideoIndex(currentEventVideoIndex + 1));
    }
  };

  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchEnd = (e) => {
    const touchEndX = e.changedTouches[0].clientX;
    const touchDistance = touchEndX - touchStartX;

    const swipeThreshold = 50;

    if (Math.abs(touchDistance) > swipeThreshold) {
      if (touchDistance > 0) {
        prevPhoto();
      } else {
        nextPhoto();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchend', handleTouchEnd);

    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, [currentEventVideoIndex]);

  const handelStopPagination = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const deleteEventVideo = async () => {
    try {
      await request(
        `/life_event_videos/${videos[currentEventVideoIndex].id}`,
        null,
        'delete'
      );
      if (currentEventVideoIndex > 0) {
        dispatch(setEventVideoIndex(currentEventVideoIndex - 1));
      }
      const updateVideos = selectedEvent.life_event_videos.filter(
        (video) => video.id !== videos[currentEventVideoIndex].id
      );
      dispatch(
        setSelectedEvent({ ...selectedEvent, life_event_videos: updateVideos })
      );
      const updatedEvents = [...allEvents];
      const index = allEvents.findIndex(
        (event) => event.id === selectedEvent.id
      );
      updatedEvents.splice(index, 1, {
        ...selectedEvent,
        life_event_videos: updateVideos,
      });
      dispatch(setLifeEvents([...updatedEvents]));
      setOpenDeleteModal(false);
      showUserNotification('Videoen er slettet', 'success');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles['grave-picture-gallery_photos-photo']}>
      {videos.length > 0 && (
        <ReactPlayer
          url={
            currentEventVideoIndex === 0
              ? videos[0].file_url
              : videos[currentEventVideoIndex].file_url
          }
          className={styles['video-player']}
          controls
        />
      )}
      {videos.length > 1 && (
        <React.Fragment>
          <button
            className={`${styles['buttons-prev']} ${styles['show-buttons']}`}
            onClick={(event) => prevPhoto(event)}
          >
            <img loading='lazy' src={PrevPhotoPointer} alt='PrevPhotoPointer' />
          </button>
          <button
            className={`${styles['buttons-next']} ${styles['show-buttons']}`}
            onClick={(event) => nextPhoto(event)}
          >
            <img loading='lazy' src={NextPhotoPointer} alt='PrevPhotoPointer' />
          </button>
        </React.Fragment>
      )}
      {event.creator_id === user.id && (
        <div className={styles['action-container']}>
          <MoreHorizIcon
            className={styles['more-icon']}
            onClick={handleClick}
          />
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <MenuItem
              onClick={() => {
                setOpenDeleteModal(true);
                handleClose();
              }}
            >
              <div className='grave-picture-gallery_photos-details_menu-item'>
                <img loading='lazy' src={Delete} alt='Delete' />
                <span>Slett denne videoen</span>
              </div>
            </MenuItem>
          </Menu>
        </div>
      )}
      {openDeleteModal && (
        <ConformModal
          open={openDeleteModal}
          handleClose={() => setOpenDeleteModal(false)}
          deletePayment={deleteEventVideo}
          title='picture'
        />
      )}
    </div>
  );
};

export default SelectedVideos;
