import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { CONSTANTS } from '../../../service/constants';
import ProcessModal from '../../Payment/atoms/ProcessModal';
import Form from './Form';
import showUserNotification from '../../../components/UserNotification/showUserNotification';
import Messages from '../../../pages/AccountConfirmation/Messages';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import styles from './ResetForm.module.css';

const ResetForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const token = new URLSearchParams(location.search).get('token');
  const [loading, setLoading] = useState(true);
  const [isVerified, setIsVerified] = useState(false);

  const checkVerification = async () => {
    setLoading(true);

    axios
      .get(
        `${CONSTANTS.baseUrl}/api/forgot_passwords/validate_token?auth_token=${token}`
      )
      .then((response) => {
        if (response.data.success) {
          setLoading(false);
          setIsVerified(true);
        } else {
          showUserNotification(response.data.error, 'error');
        }
      })
      .catch((error) => {
        showUserNotification(
          error.response.data.error || error.message,
          'error'
        );
        setLoading(false);
        setIsVerified(false);
      });
  };

  useEffect(() => {
    if (token) {
      checkVerification();
    } else {
      navigate('/');
    }
  }, [token]);

  if (loading) {
    return (
      <ProcessModal open={loading} messageKey='account_verification_waiting' />
    );
  }

  return isVerified ? (
    <Form token={token} />
  ) : (
    <div className={styles['container']}>
      <WarningAmberRoundedIcon className={styles['error']} />
      <Messages
        headingKey='account_verification_heading_error'
        descriptionKey='account_verification_description_error'
      />
    </div>
  );
};

export default ResetForm;
