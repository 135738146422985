import React from 'react';
import './HomepageInvestigateThird.css';
import Flower from '../../../../img/InvestigateFlower.png';
import FirstCloud from '../../../../img/PinkCloudFirst.jpg';
import SecondCloud from '../../../../img/PinkCloudSecond.jpg';
import FirstCloudMobile from '../../../../img/FirstCloudMobile.png';
import SecondCoudMobile from '../../../../img/SecondCloudMobile.png';

const HomepageInvestigateThird = (props) => {
    return(
        <div className='homepage-investigate_third'>
            <div className="homepage-investigate_third-content">
                <img loading='lazy' src={FirstCloud} alt='FirstCloud' className='homepage-investigate_third-content_main-first'/>
                <img loading='lazy' src={SecondCloud} alt='SecondCloud' className='homepage-investigate_third-content_main-second'/>
                <img loading='lazy' src={FirstCloudMobile} alt='FirstCloudMobile' className='homepage-investigate_third-content_main-first_mobile'/>
                <img loading='lazy' src={SecondCoudMobile} alt='SecondCoudMobile' className='homepage-investigate_third-content_main-second_mobile'/>
                <img loading='lazy' src={Flower} alt='Flower' className='homepage-investigate_third-content_main-flower'/>
                <div className="homepage-investigate_third-content_main">
                    <div className="homepage-investigate_third-content_main-box first">
                        <span className='homepage-investigate_third-content_main-box_header'>Tenker du <span>langsiktig?</span></span>
                        <span className='homepage-investigate_third-content_main-box_title'>Vi leter etter en investor som naturlig tenker langt inn i fremtiden. Universet er uendelig, og så er vi også.</span>
                    </div>
                    <div className="homepage-investigate_third-content_main-box second">
                        <span className='homepage-investigate_third-content_main-box_header'>Våre <span>røtter</span></span>
                        <span className='homepage-investigate_third-content_main-box_title'>Nettgrav gjennom TEOTC er. Vi er, og slik vil det forbli.</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomepageInvestigateThird;