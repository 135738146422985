import React from 'react';
import SparkModal from '../../Components/SparkModal';
import { FormattedMessage } from 'react-intl';
import Comments from '../Comments';
import CommentInput from '../CommentInput/CommentInput';
import styles from '../CommentsDrawer/CommentsDrawer.module.css';
import SparkPost from '../../SparksList/SparkPost';

const CommentsModal = ({
  open,
  handelCloseModal,
  spark,
  handelCreateComment,
  user,
  sparkCategories,
  handelEditComment,
  handelDeleteComment,
}) => {
  return (
    <SparkModal
      isOpen={open}
      closeModal={handelCloseModal}
      title={
        <div className={styles['total-comments']}>
          {spark.commentsCount} <FormattedMessage id='comments' />
        </div>
      }
      footer={
        <CommentInput handelCreateComment={handelCreateComment} spark={spark} />
      }
      children={
        <div>
          <SparkPost
            spark={spark}
            user={user}
            sparkCategories={sparkCategories}
            renderSparkModal={true}
          />
          <Comments
            spark={spark}
            handelEditComment={handelEditComment}
            handelDeleteComment={handelDeleteComment}
            handelCreateComment={handelCreateComment}
          />
        </div>
      }
    />
  );
};

export default CommentsModal;
