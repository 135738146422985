import React, { useState } from 'react';
import styles from './ImagesTabs.module.css';
import EmbedImagesForm from '../EmbedImagesForm/EmbedImagesForm';
import UploadImagesForm from '../UploadImagesForm/UploadImagesForm';

const ImagesTabs = ({ onClose, openWarningModal }) => {
  return (
    <div>
      <div className={styles['tabs-container']}>
        <button className={styles['active-btn']}>LASTE OPP</button>
      </div>
      <UploadImagesForm onClose={onClose} openWarningModal={openWarningModal} />
    </div>
  );
};

export default ImagesTabs;
