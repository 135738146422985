import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './SetSelfRelation.module.css';
import PrimaryButton from '../../../components/PrimaryButton';

const totalFeatures = [1, 2, 3];

const SetSelfRelationSubscribed = ({ grave, handelOpenModal }) => {
  return (
    <React.Fragment>
      <div className={styles['heading']}>
        <FormattedMessage
          id='do_you_know'
          values={{ graveName: grave?.name }}
        />
      </div>
      <div className={styles['sub-heading']}>
        <FormattedMessage
          id='set_self_relation'
          values={{ graveName: grave?.name }}
        />
      </div>
      <div className={styles['sub-heading']}>
        <FormattedMessage id='set_relation_linked' />
      </div>
      <div>
        <ul className={styles['list']}>
          {totalFeatures.map((id) => (
            <li key={id} className={styles['list-item']}>
              <FormattedMessage id={`set_self_relation_${id}`} />
            </li>
          ))}
        </ul>
      </div>
      <div className={styles['sub-heading']}>
        <FormattedMessage id='set_self_relation_message' />
      </div>
      <div className={styles['sub-heading']}>
        <FormattedMessage id='click_button_below' />
      </div>
      <div className={styles['btn-container']}>
        <PrimaryButton
          onClick={handelOpenModal}
          children={<FormattedMessage id='set_relation' />}
        />
      </div>
    </React.Fragment>
  );
};

export default SetSelfRelationSubscribed;
