import React, { useState } from 'react';
import styles from './Relations.module.css';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/lab/AvatarGroup';
import InviteRelativesModal from '../InviteRelativesModal/InviteRelativesModal';
import { FormattedMessage } from 'react-intl';
import PrimaryButton from '../../../../components/PrimaryButton';

const Relations = (props) => {
  const { relationsArray, grave, user, isAcceptedUser } = props;
  const [openModal, setOpenModal] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const handelOpen = () => {
    if (relationsArray.length === 0) {
      return;
    }

    setOpenModal(true);
  };

  const handelClose = () => {
    setOpenModal(false);
  };

  return (
    <div className={styles['relations-section']}>
      <div className={styles['relation-container']}>
        <AvatarGroup
          max={3}
          className={styles['relations-avatar-group']}
          onClick={() => {
            setTabValue(0);
            handelOpen();
          }}
        >
          {relationsArray.length > 0 &&
            relationsArray.map((item, index) => {
              return (
                <Avatar
                  alt=''
                  src={item.user?.user_avatar}
                  key={index}
                  className={styles['avatar']}
                />
              );
            })}
        </AvatarGroup>

        {openModal && (
          <InviteRelativesModal
            onOpen={openModal}
            onClose={handelClose}
            grave={props.grave}
            tabValue={tabValue}
            isAcceptedUser={isAcceptedUser}
          />
        )}
      </div>
      <div
        className={styles['relations-title']}
        onClick={() => {
          setTabValue(0);
          handelOpen();
        }}
      >
        {relationsArray.length} Relasjoner
      </div>
      {grave.is_admin && user.is_subscribed && (
        <PrimaryButton
          onClick={() => {
            setTabValue(1);
            handelOpen();
          }}
          children={
            <div>
              <FormattedMessage id='invite' />
            </div>
          }
        />
      )}
    </div>
  );
};

export default Relations;
