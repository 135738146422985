import React, { useEffect } from 'react';
import Header from './Header';
import styles from './CreateMembership.module.css';
import InputForm from './InputForm';
import { fetch_request } from '../../../service/request';
import { useDispatch, useSelector } from 'react-redux';
import {
  setInvitationTokenErrorMessage,
  setSubscriptionInvitationData,
  subscriptionInvitationToken,
  setGraveInvitationToken,
} from '../../../redux/actions';
import { useNavigate, useLocation } from 'react-router-dom';
import showUserNotification from '../../../components/UserNotification/showUserNotification';
import Invitation from '../LoginForm/Invitation';
import axios from 'axios';
import { CONSTANTS } from '../../../service/constants';

const CreateMembership = ({ setUser, setHidePage }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const invitationToken = new URLSearchParams(location.search).get(
    'invitation_token'
  );
  const graveInvitation = new URLSearchParams(location.search).get('token');

  if (invitationToken) {
    dispatch(subscriptionInvitationToken(invitationToken));
  }

  useEffect(() => {
    if (graveInvitation) {
      axios
        .get(`${CONSTANTS.baseUrl}/api/grave_invitations/${graveInvitation}`)
        .then((response) => {
          const data = response.data;
          if (data.invitation_token) {
            dispatch(setGraveInvitationToken(data));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [graveInvitation]);

  useEffect(() => {
    if (invitationToken) {
      const requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      fetch_request(
        `/api/find_subscription_invitations/${invitationToken}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            showUserNotification(data.error, 'error');
            dispatch(setInvitationTokenErrorMessage(data.error));
            navigate('/registration');
          } else {
            dispatch(setSubscriptionInvitationData(data));
            dispatch(setInvitationTokenErrorMessage(''));
            if (data.user_slug) {
              navigate('/auth/sign-in');
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [invitationToken]);

  return (
    <div className={styles['container']}>
      <div className={styles['create-membership']}>
        <Invitation />
        <Header messageKey='create_new_account' headingKey='auth_welcome' />
        <InputForm setHidePage={setHidePage} setUser={setUser} />
      </div>
    </div>
  );
};

export default CreateMembership;
