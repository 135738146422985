import React from 'react';
import IframeComponent from '../../../components/Iframe/IframeComponent';

const ConsciousPlan = () => {
  return (
    <IframeComponent
      title='Conscious plan'
      source='https://www.wix.nettgrav.no/consciousplan'
    />
  );
};

export default ConsciousPlan;
