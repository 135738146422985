import React, { useState } from 'react';
import { Avatar } from '@mui/material';
import { useSelector } from 'react-redux';
import AutoComplete from '../../../../components/Input/AutoComplete';
import isEmpty from 'lodash.isempty';
import styles from './SetRelation.module.css';
import { FormattedMessage } from 'react-intl';
import PrimaryButton from '../../../../components/PrimaryButton';

const SetRelation = ({ user, relationList, directionsList, onConfirm }) => {
  const currentUser = useSelector((state) => state.user?.user) || {};
  const [relation, setRelation] = useState({});
  const [reciprocalRelation, setReciprocalRelation] = useState({});

  const handleOnSelectRelation = (event, value, reason) => {
    if (reason === 'clear') {
      setRelation({});
    } else if (reason === 'selectOption') {
      setRelation(value);
    }
    setReciprocalRelation({});
  };

  const handleOnSelectReciprocalRelation = (event, value, reason) => {
    if (reason === 'clear') {
      setReciprocalRelation({});
    } else if (reason === 'selectOption') {
      setReciprocalRelation(value);
    }
  };

  const filterDirectionList = (selectedRelation) => {
    if (isEmpty(selectedRelation)) return [];

    let filteredList = [];

    directionsList.map((item) => {
      if (item.from_relation.id === selectedRelation.id) {
        filteredList.push(item.to_relation);
      }

      return false;
    });

    if (filteredList.length === 0) {
      return [selectedRelation];
    } else {
      return filteredList;
    }
  };

  const relationInput = {
    placeholder: 'select_relationship',
    value: relation,
    options: relationList,
    autoComplete: 'off',
    error: false,
    errorKey: 'select_relation_error',
    disabled: false,
    action: handleOnSelectRelation,
  };

  const reciprocalRelationInput = {
    placeholder: 'select_relationship',
    value: reciprocalRelation,
    options: filterDirectionList(relation),
    autoComplete: 'off',
    error: false,
    errorKey: 'select_relation_error',
    disabled: false,
    action: handleOnSelectReciprocalRelation,
  };

  const isSubmitEnabled = () => {
    return !isEmpty(relation) && !isEmpty(reciprocalRelation);
  };

  return (
    <div>
      <div className={styles['container']}>
        <div className={styles['user-container']}>
          <Avatar src={currentUser?.avatar} className={styles['user-avatar']} />
          <div className={styles['user-name']}>{currentUser?.full_name}</div>
          <AutoComplete item={relationInput} />
        </div>
        <div className={styles['connection-container']}>
          {!isEmpty(relation) && (
            <div className={styles['relation']}>
              <FormattedMessage
                id={relation?.key ? relation.key : relation.name}
              />
            </div>
          )}
          <div className={styles['connection']}>
            <div className={styles['dot']} />
            <div className={styles['line']} />
            <div className={styles['fill-dot']} />
            <div />
          </div>
          <div className={styles['connection']}>
            <div className={styles['fill-dot']} />
            <div className={styles['line']} />
            <div className={styles['dot']} />
            <div />
          </div>
          {!isEmpty(reciprocalRelation) && (
            <div className={styles['relation']}>
              <FormattedMessage
                id={
                  reciprocalRelation?.key
                    ? reciprocalRelation?.key
                    : reciprocalRelation.name
                }
              />
            </div>
          )}
        </div>
        <div className={styles['user-container']}>
          <Avatar src={user?.avatar} className={styles['user-avatar']} />
          <div className={styles['user-name']}>{user?.full_name}</div>
          <AutoComplete item={reciprocalRelationInput} />
        </div>
      </div>
      <div className={styles['user-relation-detail']}>
        {isSubmitEnabled() && (
          <FormattedMessage
            id='relation_connection'
            values={{
              name: user?.full_name,
              relation: (
                <FormattedMessage
                  id={relation?.key ? relation.key : relation.name}
                />
              ),
              reciprocalRelation: (
                <FormattedMessage
                  id={
                    reciprocalRelation?.key
                      ? reciprocalRelation?.key
                      : reciprocalRelation.name
                  }
                />
              ),
            }}
          />
        )}
      </div>
      <div className={styles['btn-container']}>
        <PrimaryButton
          onClick={() => onConfirm(relation, reciprocalRelation)}
          children={<FormattedMessage id='auth_confirm' />}
          disabled={!isSubmitEnabled()}
        />
      </div>
    </div>
  );
};

export default SetRelation;
