import React, { useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import styles from './Categories.module.css';
import { Popover, MenuItem } from '@mui/material';
import isEmpty from 'lodash.isempty';
import { FormattedMessage } from 'react-intl';

const Categories = ({ sparkCategory, setSparkCategory, sparkCategories }) => {
  const [anchorElement, setAnchorElement] = useState(null);
  const open = Boolean(anchorElement);
  const id = open ? 'simple-popover' : undefined;

  const handleClose = () => {
    setAnchorElement(null);
  };

  const clickOnCategory = (category) => {
    setSparkCategory(category);
    handleClose();
  };
  return (
    <React.Fragment>
      <div
        className={styles['list']}
        onClick={(event) => setAnchorElement(event.currentTarget)}
      >
        <div className={styles['item']}>
          {!isEmpty(sparkCategory) ? (
            <FormattedMessage id={sparkCategory.name} />
          ) : (
            <FormattedMessage id='select_category' />
          )}
        </div>
        <ArrowDropDownIcon
          className={styles['icon']}
          style={{ rotate: open ? '180deg' : '' }}
        />
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorElement}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'bottom',
        }}
      >
        {sparkCategories &&
          sparkCategories.map((category) => (
            <MenuItem
              key={category.id}
              value={category.id}
              onClick={() => clickOnCategory(category)}
              className={styles['menu-item']}
            >
              <FormattedMessage id={category?.name} />
            </MenuItem>
          ))}
      </Popover>
    </React.Fragment>
  );
};

export default Categories;
