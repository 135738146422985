import React from 'react';
import styles from './TextArea.module.css';
import ContentEditable from '../../../../../../components/RichTextEditor/ContentEditable';

const TextArea = ({ setText, text }) => {
  return (
    <div className={styles['text-area']}>
      <ContentEditable
        onInput={(value) => setText(value)}
        value={text}
        editable={true}
        placeholderKey='text_area_placeholder'
        style={{
          maxHeight: 300,
          minHeight: 100,
          overflowY: 'auto',
        }}
      />
    </div>
  );
};

export default TextArea;
