import React from 'react';
import styles from './ReactionIcon.module.css';
import { Tooltip } from '@mui/material';

const ReactionIcon = ({
  placement = 'top',
  title,
  icon,
  reactionType,
  handelOnClick,
}) => {
  return (
    <Tooltip title={title} placement={placement}>
      <img
        src={icon}
        className={styles[reactionType]}
        alt={reactionType}
        onClick={() => handelOnClick(reactionType)}
      />
    </Tooltip>
  );
};

export default ReactionIcon;
