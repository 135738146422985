import React, { useState, useCallback } from 'react';
import Header from '../CreateMembership/Header';
import Input from '../../../components/Input/Input';
import GroupButtons from '../CreateMembership/GroupButtons';
import debounce from 'lodash.debounce';
import showUserNotification from '../../../components/UserNotification/showUserNotification';
import axios from 'axios';
import { CONSTANTS } from '../../../service/constants';
import { FormattedMessage, useIntl } from 'react-intl';
import CustomModal from '../../../components/CustomModal';
import styles from './LoginForm.module.css';
import ProcessModal from '../../Payment/atoms/ProcessModal';

const ForgotPassword = ({ setHideLoginForm }) => {
  const intl = useIntl();
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openProcessModal, setOpenProcessModal] = useState(false);

  const validateEmail = (email) => {
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
  };

  const debouncedValidate = useCallback(
    debounce((value) => {
      if (validateEmail(value)) {
        setIsValidEmail(false);
      } else {
        setIsValidEmail(true);
      }
    }, 500),
    []
  );

  const handleEmailChange = (value) => {
    setEmail(value);
    debouncedValidate(value);
  };

  const inputItem = {
    label: 'settings_billingEmail',
    type: 'email',
    placeholder: 'type_your_email',
    value: email,
    error: isValidEmail,
    errorKey: 'email_error',
    disabled: false,
    action: (value) => {
      handleEmailChange(value);
    },
  };

  const sendRestLink = (event) => {
    event.preventDefault();
    if (email.length === 0 || isValidEmail) {
      showUserNotification(intl.formatMessage({ id: 'email_error' }));
      return;
    }
    setOpenProcessModal(true);
    const data = {
      email,
    };

    const config = {
      redirect: 'follow',
      headers: {
        Accept: '*/*',
      },
    };
    axios
      .post(
        `${CONSTANTS.baseUrl}/api/generate_auth_token_for_forgot_passwords`,
        data,
        config
      )
      .then((response) => {
        setOpenProcessModal(false);
        setOpenModal(true);
        console.log(response.data);
      })
      .catch((err) => {
        setOpenProcessModal(false);
        showUserNotification(err.response.data.error || err.message, 'error');
      });
  };

  return (
    <React.Fragment>
      <Header
        headingKey='forgot_password_heading'
        messageKey='forgot_password_description'
      />
      <Input item={inputItem} />
      <GroupButtons
        backKey='auth_back'
        nextKey='forgot_password_button'
        onBack={() => setHideLoginForm(false)}
        onNext={sendRestLink}
      />
      {openModal && (
        <CustomModal
          title={<FormattedMessage id='forgot_password_title' />}
          isOpen={openModal}
          closeModal={() => setOpenModal(false)}
          children={
            <div className={styles['message']}>
              <FormattedMessage id='forgot_password_message' />
            </div>
          }
          style={{ width: '600px' }}
        />
      )}
      {openProcessModal && (
        <ProcessModal
          open={openProcessModal}
          messageKey='forgot_password_waiting'
        />
      )}
    </React.Fragment>
  );
};

export default ForgotPassword;
