import React from 'react';
import './HomepageScientistsFirst.css';
import MainImage from '../../../../img/ScientistsMainImage.png';
import MainImageMobile from '../../../../img/ScientistsMobileImage.png';

const HomepageScientistsFirst = () => {

    return (
        <div className='homepage-scientists_first'>

            <div className="homepage-scientists_first-content">
                <img src={MainImage} alt='MainImage' loading="eager" className='homepage-scientists_first-content_image homepage-scientists_first-image' />
                <img loading="eager" src={MainImageMobile} alt='MainImageMobile' className='homepage-scientists_first-content_image-mobile homepage-scientists_first-image' />
                <span className='homepage-scientists_first-content_text-header mobile'><span>Nettgrav</span> skal søke ny viten som er vårt ansvar til gravlagte og gjenlevende.</span>
                <div className="homepage-scientists_first-content_text">
                    <span className='homepage-scientists_first-content_text-header'><span>Nettgrav</span> skal søke ny viten som er vårt ansvar til gravlagte og gjenlevende.</span>
                    <span className='homepage-scientists_first-content_text-subheader'>Vi mener den beste måten å ivareta et liv som har levd, er ved at nettgrav.no støtter søken etter ny kunnskap.<br /><br />Ny kunnskap som kan bidrar til at livet blir trygt å leve for flere og at vi kan leve godt lengre.</span>

                </div>
            </div>
        </div>
    )
}

export default HomepageScientistsFirst;