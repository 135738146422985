import React from 'react';
import CustomModal from '../../../../../components/CustomModal';
import AnniversaryForm from './AnniversaryForm';

const AnniversaryFormModal = ({
  title,
  isOpen,
  onClose,
  onCreate,
  anniversary,
  handleUpdateAnniversary,
}) => {
  return (
    <CustomModal
      title={title}
      children={
        <AnniversaryForm
          onCreate={onCreate}
          anniversary={anniversary}
          handleUpdateAnniversary={handleUpdateAnniversary}
        />
      }
      isOpen={isOpen}
      closeModal={onClose}
      style={{ width: '500px' }}
    />
  );
};

export default AnniversaryFormModal;
