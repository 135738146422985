import React from 'react';
import './Graves.css';
import SubMenu from '../../components/SubMenu/SubMenu';
import { useLocation } from 'react-router-dom';
import { setSearchInput } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import AuthModal from '../../components/Modals/AuthModal';
import { useNavigate } from 'react-router-dom';

const Graves = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isAuth = useSelector((state) => state.auth.isAuthorized);
  const navigate = useNavigate();

  if (location.search) {
    const findGrave = new URLSearchParams(location.search).get('find-grave');
    findGrave && dispatch(setSearchInput(findGrave));
  }
  if (!isAuth) {
    return <AuthModal isOpen={true} handleClose={() => navigate('/')} />;
  }

  return (
    <div className='connected-graves-route'>
      <SubMenu />
    </div>
  );
};

export default Graves;
