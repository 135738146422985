import React from 'react';
import styles from './MediaTabs.module.css';

const MediaTabs = ({ item, tabId, setTabId }) => {
  const handelSelectedTab = () => {
    if (tabId === item.id) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div
      onClick={() => setTabId(item.id)}
      className={
        handelSelectedTab() ? styles['media-tab-selected'] : styles['media-tab']
      }
    >
      <button
        className={
          handelSelectedTab() ? styles['tab-btn-selected'] : styles['tab-btn']
        }
      >
        {item.title}
      </button>
    </div>
  );
};

export default MediaTabs;
