import React from 'react';
import CustomPhoneNumber from '../CustomPhoneNumber';
import AutocompleteAddress from '../AutoCompleteAddress/AutoCompleteAddress';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './NewCard.module.css';

const BillingInputs = ({
  billingInformation,
  setBillingInformation,
  isValidEmail,
  setIsValidEmail,
  setIsValid,
  isValid,
  countries,
}) => {
  const intl = useIntl();

  const validateEmail = (email) => {
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
  };

  const onNameChange = (event) => {
    const inputValue = event.target.value;
    const trimmedValue = inputValue
      .replace(/[0-9]/g, '')
      .replace(/[\"'@#!$%^&*()_+|~=`{}\[\]:;'<>?,.\/]/g, '');
    setBillingInformation({
      ...billingInformation,
      fullName: trimmedValue,
    });
  };

  const onPostCodeChange = (event) => {
    const code = event.target.value.replace(/[^\d()\-_]/g, '');

    setBillingInformation({
      ...billingInformation,
      postalCode: code,
    });
  };

  const onNumberChange = (value) => {
    setBillingInformation({
      ...billingInformation,
      phoneNumber: value,
    });
  };

  const onBillingAddressChange = (value) => {
    setBillingInformation({
      ...billingInformation,
      billingAddress: value,
    });
  };

  return (
    <div className={styles['billing-info']}>
      <div className={styles['billing-info-title']}>
        <span>
          <FormattedMessage id='settings_billingInfo' />
        </span>
      </div>
      <div className={styles['billing-details']}>
        <span>
          <FormattedMessage id='settings_fullName' />
        </span>
        <input
          type='text'
          required
          placeholder={intl.formatMessage({ id: 'settings_fullName' })}
          value={billingInformation.fullName}
          onChange={onNameChange}
        />
      </div>
      <div className={styles['billing-details']}>
        <span>
          <FormattedMessage id='billing_address' />
        </span>
        <AutocompleteAddress
          onBillingAddressChange={onBillingAddressChange}
          billingAddress={billingInformation.billingAddress}
        />
      </div>
      <div className={styles['billing-container']}>
        <div className={styles['billing-details-container']}>
          <span>
            <FormattedMessage id='settings_billingCountry' />
          </span>
          <span>
            <select
              className={styles['country-list']}
              value={billingInformation.country_id}
              onChange={(e) =>
                setBillingInformation({
                  ...billingInformation,
                  country_id: parseInt(e.target.value),
                })
              }
            >
              {countries.map((country) => {
                return (
                  <option value={country.id} key={country.id}>
                    {country.name}
                  </option>
                );
              })}
            </select>
          </span>
        </div>
        <div className={styles['billing-details-container-email']}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span className={styles['email-heading']}>
              <FormattedMessage id='settings_billingEmail' />
            </span>
            {isValidEmail && (
              <span style={{ color: 'red' }}>{isValidEmail}</span>
            )}
          </div>
          <input
            className={isValidEmail ? styles['error-email'] : styles['email']}
            type='email'
            placeholder={intl.formatMessage({ id: 'settings_billingEmail' })}
            value={billingInformation.email}
            onChange={(event) => {
              if (validateEmail(event.target.value)) {
                setIsValidEmail(null);
              } else {
                setIsValidEmail('Invalid email');
              }
              setBillingInformation({
                ...billingInformation,
                email: event.target.value,
              });
            }}
          />
        </div>
      </div>
      <div className={styles['billing-container']}>
        <div className={styles['billing-details-container']}>
          <span>
            <FormattedMessage id='settings_postCode' />
          </span>
          <input
            type='text'
            placeholder={intl.formatMessage({ id: 'settings_postCode' })}
            value={billingInformation.postalCode}
            onChange={onPostCodeChange}
          />
        </div>
        <div className={styles['billing-details-container-phoneNumber']}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span className={styles['phoneNumber-heading']}>
              <FormattedMessage id='settings_telephoneNumber' />
            </span>
            {isValid && <span style={{ color: 'red' }}>{isValid}</span>}
          </div>
          <CustomPhoneNumber
            setIsValid={setIsValid}
            isValid={isValid}
            userNumber={billingInformation.phoneNumber}
            onNumberChange={onNumberChange}
          />
        </div>
      </div>
      <div className={styles['user-message']}>
        <span>
          <FormattedMessage id='billing_info_message' />
        </span>
      </div>
    </div>
  );
};

export default BillingInputs;
