import React, { useRef } from 'react';
import CustomModal from '../../../../components/CustomModal';
import PrimaryButton from '../../../../components/PrimaryButton';
import Cropper from 'react-cropper';
import 'react-toastify/dist/ReactToastify.css';
import { FormattedMessage } from 'react-intl';
import { makeFileFromURL } from '../../../../service/makeFileFromBlob';
import styles from './CropperModal.module.css';

const CropperModal = ({
  openModal,
  closeModal,
  aspectRatio,
  image,
  onSave,
}) => {
  const cropperRef = useRef(null);

  const saveCroppedImage = async () => {
    const croppedImage = cropperRef.current?.cropper
      .getCroppedCanvas()
      .toDataURL();
    onSave(croppedImage);
  };

  return (
    <CustomModal
      isOpen={openModal}
      closeModal={closeModal}
      title={<FormattedMessage id='crop_image' />}
      children={
        <React.Fragment>
          <Cropper
            ref={cropperRef}
            className={styles['cropper']}
            src={image?.url}
            viewMode={1}
            guides={true}
            zoomOnWheel={false}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive={true}
            autoCropArea={1}
            aspectRatio={aspectRatio}
            checkOrientation={false}
          />
          <div className={styles['btn']}>
            <PrimaryButton
              onClick={saveCroppedImage}
              children={<FormattedMessage id='auth_save' />}
            />
            <PrimaryButton
              onClick={closeModal}
              children={<FormattedMessage id='settings_cancel' />}
            />
          </div>
        </React.Fragment>
      }
    />
  );
};

export default CropperModal;
