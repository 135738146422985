import React from 'react';
import PrimaryButton from '../../../../components/PrimaryButton';
import { FormattedMessage } from 'react-intl';

const style = {
  background: '#fff',
  border: '1px solid #404D56',
  color: '#404D56',
  padding: '8px 16px',
};

const SetRelationFooter = ({
  handleClose,
  user,
  handleSetRelation,
  buttonText,
  graveInvitation,
  graveUser,
  wantToClaim,
  handleClaimGrave,
}) => {
  const handleAccept = () => {
    if (wantToClaim) {
      handleClaimGrave();
    } else {
      handleSetRelation();
    }
  };

  const handleCancel = () => {
    handleClose();
  };

  const getSendButtonTextKey = () => {
    if (graveInvitation && graveInvitation?.status !== 'accepted') {
      return user.is_subscribed ? 'accept' : 'accept_or_pay';
    }

    if (graveUser && !user.is_subscribed) {
      return 'pay_and_reactivate';
    }

    return buttonText;
  };

  const getCancelButtonTextKey = () => {
    return graveInvitation && graveInvitation?.status !== 'accepted'
      ? 'decline'
      : 'settings_cancel';
  };

  return (
    <div className='submit-button-wrapper'>
      <PrimaryButton
        children={<FormattedMessage id={getCancelButtonTextKey()} />}
        onClick={handleCancel}
        style={style}
      />
      <PrimaryButton
        onClick={handleAccept}
        children={<FormattedMessage id={getSendButtonTextKey()} />}
      />
    </div>
  );
};

export default SetRelationFooter;
