import React, { useEffect, useState } from 'react';
import X from '../../../../img/X.svg';
import { Modal } from '@mui/material';
import { makeStyles } from '@mui/styles';
import styles from './InviteRelativesModal.module.css';
import InvitesRelatives from '../InviteRelatives/InviteRelatives';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { CONSTANTS } from '../../../../service/constants';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContainer: {
    position: 'relative',
    backgroundColor: 'white',
    borderRadius: '6px',
    outline: 'none',
    maxWidth: '700px',
    maxHeight: 'calc(100vh - 100px)',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    '@media (max-width :540px)': {
      width: '100% !important',
      borderRadius: '0px',
      overflowY: 'auto',
      maxHeight: 'calc(100vh - 40px)',
    },
  },
  header: {
    padding: 20,
    borderBottom: '1px solid #ddd',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 16,
    position: 'sticky',
    top: 0,
    backgroundColor: 'white',
    borderRadius: '6px 6px 0px 0px',
    zIndex: 10,
  },
  heading: {
    fontSize: 24,
    fontWeight: 500,
  },
  content: {
    flex: 1,
    overflowY: 'auto',
    padding: 20,
  },
  footer: {
    padding: 20,
    borderTop: '1px solid #ddd',
  },
  closeButton: {
    cursor: 'pointer',
    alignSelf: 'start',
  },
}));

const InviteRelativesModal = ({
  onOpen,
  onClose,
  grave,
  tabValue = 0,
  isAcceptedUser,
}) => {
  const classes = useStyles();
  const userSlug =
    localStorage.getItem('user-slug') || sessionStorage.getItem('user-slug');
  const isAuth = useSelector((state) => state.auth.isAuthorized);
  const [currentSubscription, setCurrentSubscription] = useState({});

  useEffect(() => {
    if (!isAuth) return;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        Accept: '*/*',
      },
      redirect: 'follow',
    };
    axios
      .get(`${CONSTANTS.baseUrl}/api/users/${userSlug}/subscription`, config)
      .then((res) => {
        if (res.data?.product) {
          setCurrentSubscription(res.data);
        }
      })
      .catch((err) => console.log(err));
  }, [isAuth, userSlug]);

  return (
    <Modal
      open={onOpen}
      onClose={onClose}
      className={classes.modal}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.modalContainer}>
        <div className={classes.header}>
          <div className={styles['workspace-content-header']}>
            <div className={classes.heading}>Relasjoner</div>
            <div className={classes.description}>
              Her ser du hvilke relasjoner som er satt på graven og muligheten
              til å invitere flere.
            </div>
          </div>
          <img
            loading='lazy'
            src={X}
            alt='x'
            onClick={onClose}
            className={classes.closeButton}
          />
        </div>
        <div className={classes.content}>
          <InvitesRelatives
            tabValue={tabValue}
            grave={grave}
            currentSubscription={currentSubscription}
            onClose={onClose}
            isAcceptedUser={isAcceptedUser}
          />
        </div>
      </div>
    </Modal>
  );
};

export default InviteRelativesModal;
