import React from 'react';
import styles from './CancelSubscription.module.css';
import { FormattedMessage } from 'react-intl';
import { planKey } from '../../../../service/PlanKey';
import { SubscriptionStatuses } from '../../../../service/constants';

const CancelSubscription = ({ subscription, setOpenCancelModal }) => {
  return (
    <div className={styles['cancel-border-container']}>
      <div className={styles['heading']}>
        <FormattedMessage id='settings_cancelSubscription' />
      </div>
      <button
        className={styles['cancel-subscription']}
        onClick={() => setOpenCancelModal(true)}
      >
        <FormattedMessage
          id={
            subscription.status === SubscriptionStatuses.CANCELLED
              ? 'Reactivate'
              : 'settings_cancelSubscription'
          }
        />
      </button>
    </div>
  );
};

export default CancelSubscription;
