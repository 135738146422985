import React from 'react';
import styles from './CreateMembership.module.css';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

const BottomMessage = ({ url, messageKey, textKey }) => {
  const navigate = useNavigate();
  return (
    <div className={styles['login-container']}>
      <FormattedMessage id={textKey} />
      <span className={styles['login-here']} onClick={() => navigate(url)}>
        <FormattedMessage id={messageKey} />
      </span>
    </div>
  );
};

export default BottomMessage;
