import React from 'react';
import DeleteImage from '../../../../img/DeleteImage.svg';
import { Button, Modal } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { request } from '../../../../service/request';
import { useDispatch, useSelector } from 'react-redux';
import { removeMemory } from '../../../../redux/actions';
import styles from './OpenDeleteModal.module.css';
import { useNavigate } from 'react-router-dom';

const useStyle = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '500px',
    height: 'auto',
    overflowY: 'auto',
    backgroundColor: '#fff',
    padding: '32px',
    borderRadius: 10,
    outline: 'none',
    '@media (max-width: 520px)': {
      width: '100%',
      overflow: 'auto',
    },
  },
  modalBackground: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  rootRemove: {
    width: '50%',
    border: 'none',
    backgroundColor: '#fff',
    borderRadius: '0px',
    borderBottomLeftRadius: '10px',
    height: '50px',
    color: '#eb5757',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    textTransform: 'none',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    // '@media (max-width: 750px)': {
    //   width: '170px',
    //   borderRadius: '10px',
    // },
  },
  rootCancel: {
    width: '50%',
    border: 'none',
    backgroundColor: '#FEA65A',
    borderRadius: '0px',
    borderBottomRightRadius: '10px',
    height: '50px',
    color: '#fff',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    textTransform: 'none',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#FEA65A',
    },
    // '@media (max-width: 750px)': {
    //   width: '170px',
    //   borderRadius: '10px',
    // },
  },
}));

const OpenDeleteModal = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyle();
  const deleteSuggestion = () => {
    try {
      request(`/memories/${props.post.id}`, null, 'delete').then(() => {
        dispatch(removeMemory(props.post.id));
        props.closeModal();
        props.handleCloseMenu();
        if (props?.returnUrl) {
          navigate(props.returnUrl);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      open={props.open}
      onClose={props.closeModal}
      className={classes.modal}
      BackdropProps={{
        className: classes.modalBackground,
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.modalContent}>
        <div className={styles['delete-virtue']}>
          <div className={styles['delete-virtue_content']}>
            <div className={styles['delete-virtue_content-header']}>
              <span>Slett Minnetavle innlegg</span>
            </div>
            <div className={styles['delete-virtue_content-main']}>
              <img loading='lazy' src={DeleteImage} alt='delete' />
              <span>
                <p>Bekreft sletting av Minnetavle innlegg?</p>
              </span>
            </div>
          </div>
          <div className={styles['delete-virtue_buttons']}>
            <Button
              variant='outlined'
              className={classes.rootRemove}
              onClick={deleteSuggestion}
            >
              Fjern
            </Button>
            <Button
              variant='contained'
              className={classes.rootCancel}
              onClick={() => props.closeModal()}
            >
              Avbryt
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default OpenDeleteModal;
