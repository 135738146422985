import React from 'react';
import AboutMenuItem from '../../About/components/AboutMenuItem';
import styles from './GraveMenuBar.module.css';
import { MemoryMenuItems } from '../../../../../service/constants';

const GraveMenuBar = (props) => {
  return (
    <div className={styles['menu-container']}>
      {Object.entries(MemoryMenuItems).map(([key, value]) => (
        <AboutMenuItem
          menuItem={value}
          key={key}
          indexKey={key}
          handelAboutMenuKey={props.handelAboutMenuKey}
          aboutMenuKey={props.aboutMenuKey}
        />
      ))}
    </div>
  );
};

export default GraveMenuBar;
