import React from 'react';
import styles from './ImagesList.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentGravePhotoIndex } from '../../../../redux/actions';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const ImagesList = ({ photo, photoIndex }) => {
  const dispatch = useDispatch();
  const currentPhoto = useSelector(
    (state) => state.photoReducer.currentGravePhoto
  );

  return (
    <LazyLoadImage
      src={photo.file_url}
      alt='Image'
      className={
        currentPhoto === photoIndex ? styles['selected-image'] : styles['image']
      }
      effect='blur'
      onClick={() => {
        dispatch(setCurrentGravePhotoIndex(photoIndex));
      }}
    />
  );
};

export default ImagesList;
