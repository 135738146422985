import React from 'react';
import './HeaderTabs.css';
import { useSelector } from 'react-redux';
import AuthorizedHeader from './AuthorizedHeader';
import UnAuthorizedHeader from './UnAuthorizedHeader';

const HeaderTabs = () => {
  const isAuth = useSelector((state) => state.auth.isAuthorized);

  return (
    <div className='menu-section'>
      {isAuth ? <AuthorizedHeader /> : <UnAuthorizedHeader />}
    </div>
  );
};

export default HeaderTabs;
