import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { CONSTANTS } from '../../service/constants';
import ProcessModal from '../../containers/Payment/atoms/ProcessModal';
import { useDispatch } from 'react-redux';
import {
  setUserId,
  setSubscriptionInvitationData,
  setSubscription,
  setConfirmSignIn,
} from '../../redux/actions';
import CheckIcon from '@mui/icons-material/Check';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import Messages from './Messages';
import styles from './AccountConfirmation.module.css';

const AccountConfirmation = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const token = new URLSearchParams(location.search).get('token');
  const [loading, setLoading] = useState(true);
  const [isVerified, setIsVerified] = useState(false);

  const getUserSlug = () => localStorage.getItem('user-slug');

  const createVerification = async () => {
    setLoading(true);
    const data = {
      token,
    };
    axios
      .post(`${CONSTANTS.baseUrl}/api/account_confirmations`, data)
      .then((response) => {
        setLoading(false);
        setIsVerified(true);
        if (response.data.success) {
          const userId = response.data.user.id;
          dispatch(setUserId(userId));
          dispatch(setSubscriptionInvitationData({}));
          dispatch(setSubscription(response.data.user.subscription));
          localStorage.setItem(
            'token',
            response.headers['x-authentication-token']
          );
          localStorage.setItem('auth', 'true');
          localStorage.setItem('user-slug', response.data.user.slug);
          dispatch(setConfirmSignIn());
        }
      })
      .catch((error) => {
        setLoading(false);
        setIsVerified(false);
      });
  };

  useEffect(() => {
    if (token) {
      createVerification();
    } else {
      navigate('/');
    }
  }, [token]);

  if (loading) {
    return (
      <ProcessModal open={loading} messageKey='account_verification_waiting' />
    );
  }

  if (getUserSlug()) {
    navigate(`/member/${getUserSlug()}`);
    return;
  }

  return isVerified ? (
    <div className={styles['container']}>
      <CheckIcon className={styles['icon']} />
      <Messages
        headingKey='account_verification_heading'
        descriptionKey='account_verification_description'
      />
    </div>
  ) : (
    <div className={styles['container']}>
      <WarningAmberRoundedIcon className={styles['error']} />
      <Messages
        headingKey='account_verification_heading_error'
        descriptionKey='account_verification_description_error'
      />
    </div>
  );
};

export default AccountConfirmation;
