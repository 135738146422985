import React, { useState } from 'react';
import styles from './Registration.module.css';
import CreateMembership from '../../containers/NewAuth/CreateMembership/CreateMembership';
import SideContainer from '../../containers/NewAuth/SideContainer/SideContainer';
import SuccessMessage from '../../containers/NewAuth/CreateMembership/SuccessMessage';
import isEmpty from 'lodash.isempty';

const Registration = () => {
  const [hidePage, setHidePage] = useState(false);
  const [user, setUser] = useState({});
  return (
    <React.Fragment>
      {hidePage && !isEmpty(user) ? (
        <div className={styles['container']}>
          <SuccessMessage
            name={user.name}
            email={user.email}
            setHidePage={setHidePage}
          />
        </div>
      ) : (
        <div className={styles['registration-page']}>
          <CreateMembership setHidePage={setHidePage} setUser={setUser} />
          <SideContainer />
        </div>
      )}
    </React.Fragment>
  );
};

export default Registration;
