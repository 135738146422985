import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import Physical from '../../../img/PhysicalGrave.svg';

const useStyle = makeStyles(() => ({
  rootTooltip: {
    backgroundColor: '#fff',
    boxShadow: '0px 4px 10px rgba(33, 32, 32, 0.18)',
    borderRadius: '6px',
    maxWidth: 'none',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '150%',
    color: '#36434B',
    boxSizing: 'border-box',
    padding: '19px 16px',
  },
  rootArrow: {
    color: '#fff',
  },
  root: {
    '& .MuiPaper-root': {
      background: 'transparent',
    },
    '& .MuiPaper-elevation24': {
      boxShadow: 'none',
    },
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'unset !important',
    },
  },
}));

const GraveTooltip = () => {
  const classes = useStyle();
  return (
    <Tooltip
      placement='top'
      title='Denne graven er kun tilgjengelig på nettgrav.no'
      arrow
      classes={{ tooltip: classes.rootTooltip, arrow: classes.rootArrow }}
    >
      <div className='grave-images_circle'>
        <img loading='lazy' src={Physical} alt='Physical' />
      </div>
    </Tooltip>
  );
};

export default GraveTooltip;
