import React from 'react';
import styles from './Comments.module.css';
import { Skeleton } from '@mui/material';

const CommentsLoading = ({ length = 3 }) => {
  return Array.from({ length }).map((_, index) => (
    <div key={index} className={styles['loading']}>
      <Skeleton animation='wave' variant='circular' width={45} height={40} />
      <Skeleton variant='text' sx={{ fontSize: '16px', width: '100%' }} />
    </div>
  ));
};

export default CommentsLoading;
