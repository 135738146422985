import React, { useState, useEffect } from 'react';
import styles from './GiftSubscription.module.css';
import { Tabs, Tab } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { fetch_request } from '../../../../service/request';
import axios from 'axios';
import { CONSTANTS, ProductKeys } from '../../../../service/constants';
import loadingAnimation from '../../../../service/Loading_animation.gif';
import PopupModal from '../../../Payment/atoms/Modal/Modal';
import GiftSubscriptionForm from '../GiftSubscriptionForm/GiftSubscriptionForm';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import showUserNotification from '../../../../components/UserNotification/showUserNotification';
import isEmpty from 'lodash.isempty';
import { FormattedMessage, useIntl } from 'react-intl';
import { planKey } from '../../../../service/PlanKey';

const useStyle = makeStyles(() => ({
  tabContainer: {
    marginBottom: '24px',
    display: 'flex',
    justifyContent: 'space-between',
    overflowX: 'auto',
  },
  indicator: {
    backgroundColor: '#985000 !important',
  },
  tab: {
    flex: 1,
    marginRight: '70px !important',
    color: '#985000 !important',
    fontFamily: 'Roboto',
    fontSize: '18px !important',
    fontWeight: '500 !important',
  },
}));

const GiftSubscription = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyle();
  const [modalOpen, setModalOpen] = useState(false);
  const [subscriptionInvitations, setSubscriptionInvitations] = useState([]);
  const [invitationId, setInvitationId] = useState('');
  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [remainingCount, setRemainingCount] = useState('');
  const [currentSubscriptionPlan, setCurrentSubscriptionPlan] = useState({});
  const [messageKey, setMessageKey] = useState('');

  const userSlug =
    localStorage.getItem('user-slug') || sessionStorage.getItem('user-slug');

  useEffect(() => {
    const show = location.state?.show;
    const key = location.state?.key;
    if (show && !isEmpty(key)) {
      setMessageKey(key);
      setModalOpen(true);
      navigate.replace({ state: undefined, key: '' });
    }
  }, [location, navigate]);

  useEffect(() => {
    setLoading(true);

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        Accept: '*/*',
      },
      redirect: 'follow',
    };
    axios
      .get(`${CONSTANTS.baseUrl}/api/users/${userSlug}/subscription`, config)
      .then((res) => {
        if (res.data) {
          setCurrentSubscriptionPlan(res.data);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (currentSubscriptionPlan.id) {
      const requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      fetch_request(
        `/api/subscription/${currentSubscriptionPlan.id}/subscription_invitations`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.errors) {
            showUserNotification('noe gikk galt, prøv igjen', 'error');
          } else {
            setSubscriptionInvitations(data.subscription_invitations);
            setRemainingCount(data.remaining_count);
          }
        })
        .catch((err) => {
          showUserNotification(err.data, 'error');
          console.log(err);
        });
    }
  }, [currentSubscriptionPlan]);

  const handelSubscriptionInvitations = (value) => {
    setSubscriptionInvitations([...value]);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handelInvitationId = (value) => {
    if (value === invitationId) {
      setInvitationId(null);
    } else {
      setInvitationId(value);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div className={styles['invitations']}>
      <div className={styles['invitations-content']}>
        <div className={styles['header']}>
          <span className={styles['heading']}>
            <FormattedMessage id='settings_giftInvitations' />
          </span>
          <span className={styles['sub_heading']}>
            <FormattedMessage id='settings_giftHeading' />
          </span>
        </div>
        {loading && isEmpty(currentSubscriptionPlan) && (
          <div className={styles['loading']}>
            <img src={loadingAnimation} alt='Loading....' />
          </div>
        )}
        {currentSubscriptionPlan.product?.key === ProductKeys.free &&
          !loading && (
            <div className={styles['zero-invitation']}>
              <div className={styles['zero-invitation-message']}>
                <FormattedMessage id='settings_giftFreeSubscription' />
              </div>
              <button
                className={styles['btn-invitation']}
                onClick={() => navigate('/pricing')}
              >
                <FormattedMessage id='settings_seeSubscriptions' />
              </button>
            </div>
          )}
        {!loading &&
        currentSubscriptionPlan.product?.key !== ProductKeys.free &&
        currentSubscriptionPlan?.status !== 'pending' ? (
          <>
            <div className={styles['plan-container']}>
              <div className={styles['plan-heading']}>
                <FormattedMessage
                  id='settings_youHave'
                  values={{ count: remainingCount }}
                />{' '}
                {Object.keys(currentSubscriptionPlan).length > 0 ? (
                  <FormattedMessage
                    id={planKey(currentSubscriptionPlan.product.key)}
                  />
                ) : (
                  ''
                )}{' '}
                <FormattedMessage id='settings_inviteAgain' />{' '}
                <Link className={styles['plan-link']} to={'/pricing'}>
                  <FormattedMessage id='settings_clickHere' />
                </Link>{' '}
                <FormattedMessage id='settings_addMore' />
              </div>
            </div>
            <div className={classes.tabContainer}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                variant='scrollable'
                id='id223223'
                aria-label='scrollable force tabs example'
                classes={{ indicator: classes.indicator }}
                style={{ width: '100%' }}
              >
                <Tab
                  label={intl.formatMessage({ id: 'settings_addMember' })}
                  {...a11yProps(0)}
                  className={classes.tab}
                />
                <Tab
                  label={intl.formatMessage({ id: 'settings_invitedMember' })}
                  {...a11yProps(1)}
                  className={classes.tab}
                />
              </Tabs>
            </div>
            {tabValue === 0 && (
              <React.Fragment>
                {remainingCount > 0 ? (
                  <GiftSubscriptionForm
                    subscriptionInvitations={subscriptionInvitations}
                    handelSubscriptionInvitations={
                      handelSubscriptionInvitations
                    }
                    invitation={{}}
                    invitationId={null}
                    subscriptionId={currentSubscriptionPlan.id}
                    disable={true}
                    handelInvitationId={handelInvitationId}
                    handleTabChange={() => setTabValue(1)}
                    handelRemainingCount={() =>
                      setRemainingCount(remainingCount - 1)
                    }
                    setCount={() => setRemainingCount(remainingCount + 1)}
                  />
                ) : (
                  <div>
                    <span className={styles['plan-heading']}>
                      <FormattedMessage
                        id='settings_youHave'
                        values={{ count: remainingCount }}
                      />{' '}
                      {Object.keys(currentSubscriptionPlan).length > 0 ? (
                        <FormattedMessage
                          id={planKey(currentSubscriptionPlan.product.key)}
                        />
                      ) : (
                        ''
                      )}{' '}
                      <FormattedMessage id='settings_inviteAgain' />{' '}
                      <Link className={styles['plan-link']} to={'/pricing'}>
                        <FormattedMessage id='settings_clickHere' />
                      </Link>{' '}
                      <FormattedMessage id='settings_addMore' />
                    </span>
                  </div>
                )}
              </React.Fragment>
            )}
            <div className={styles['invitations-list-wrapper']}>
              {tabValue === 1 && (
                <>
                  {subscriptionInvitations.length > 0 ? (
                    subscriptionInvitations.map((invitation) => {
                      return (
                        <div
                          className={styles['invitations-list']}
                          style={{
                            marginBottom: 24,
                            border: '1px solid rgb(221 225 229)',
                            borderRadius: 10,
                            padding: 24,
                          }}
                          key={invitation.id}
                        >
                          <GiftSubscriptionForm
                            subscriptionInvitations={subscriptionInvitations}
                            handelSubscriptionInvitations={
                              handelSubscriptionInvitations
                            }
                            invitation={invitation}
                            invitationId={invitationId}
                            subscriptionId={currentSubscriptionPlan.id}
                            disable={invitationId === invitation.id}
                            handelInvitationId={handelInvitationId}
                            handleTabChange={() => setTabValue(1)}
                            handelRemainingCount={() =>
                              setRemainingCount(remainingCount - 1)
                            }
                            setCount={() =>
                              setRemainingCount(remainingCount + 1)
                            }
                          />
                        </div>
                      );
                    })
                  ) : (
                    <div className={styles['empty-invitation-container']}>
                      <div className={styles['empty-invitation-wrapper']}>
                        <span>
                          <FormattedMessage id='settings_emptyGiftMembers' />
                        </span>
                        <button onClick={() => setTabValue(0)}>
                          <FormattedMessage id='settings_inviteTo' />
                        </button>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </>
        ) : (
          !loading &&
          currentSubscriptionPlan.product?.key !== ProductKeys.free &&
          currentSubscriptionPlan?.status && (
            <div className={styles['subscription-status']}>
              <FormattedMessage
                id='settings_pendingSubscription'
                values={{ status: currentSubscriptionPlan.status }}
              />
            </div>
          )
        )}
      </div>
      {!loading && modalOpen && currentSubscriptionPlan && (
        <PopupModal
          open={modalOpen}
          handleClose={closeModal}
          plan={currentSubscriptionPlan.product.key}
          messageKey={messageKey}
          userSlug={userSlug}
        />
      )}
    </div>
  );
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

export default GiftSubscription;
