import axios from 'axios';
import store from '../redux/store';
import { CONSTANTS } from './constants';

const request = async (endpoint, params = {}, method = 'get', headers = {}) => {
  const token = store.getState().auth.isAuthorized;
  const url = CONSTANTS.baseUrl + '/api' + endpoint;
  return axios({
    url,
    method,
    params: method === 'get' ? params : null,
    data: method !== 'get' ? params : null,
    headers: token
      ? {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
          Accept: '*/*',
          ...headers,
          'Access-Control-Allow-Origin': '*',
        }
      : {
          'Content-Type': 'application/json',
          Accept: '*/*',
          ...headers,
          'Access-Control-Allow-Origin': '*',
        },
  });
};

const fetch_request = (endpoint, requestOptions = null) => {
  if (requestOptions) {
    return fetch(CONSTANTS.baseUrl + endpoint, {
      ...requestOptions,
      headers: {
        Accept: '*/*',
        'Access-Control-Allow-Origin': '*',
        ...requestOptions.headers,
      },
    });
  } else {
    return fetch(CONSTANTS.baseUrl + endpoint, {
      method: 'get',
      redirect: 'follow',
      headers: {
        Accept: '*/*',
        'Access-Control-Allow-Origin': '*',
      },
    });
  }
};

export { request };
export { fetch_request };
