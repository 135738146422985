import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Coin from '../../../img/Coin.svg';
import PrimaryButton from '../../../components/PrimaryButton';
import FormatColorFillOutlinedIcon from '@mui/icons-material/FormatColorFillOutlined';
import { request } from '../../../service/request';
import { useDispatch } from 'react-redux';
import { setInformationAboutUser } from '../../../redux/actions';
import showUserNotification from '../../../components/UserNotification/showUserNotification';
import ColorPicker from './ColorPicker';
import styles from './UserHeader.module.css';
import UploadUserAvatar from './UploadUserAvatar';

const ProfileBackground = ({ user, setCurrentUser }) => {
  const dispatch = useDispatch();
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const [color, setColor] = useState(user?.background_color || '#E4F3FD');

  const saveBackgroundColor = () => {
    if (user?.background_color === color) return;
    const payload = {
      user: {
        background_color: color,
      },
    };
    request(`/users/${user.slug}`, payload, 'put')
      .then((response) => {
        setOpenColorPicker(false);
        dispatch(setInformationAboutUser(response.data));
        setCurrentUser((prev) => ({
          ...prev,
          background_color: response.data.user?.background_color,
        }));
      })
      .catch((error) => {
        setOpenColorPicker(false);
        showUserNotification(
          error.response.data.error || error.message,
          'error'
        );
      });
  };

  const isUserCurrentUser = () => {
    return (
      user.slug === localStorage.getItem('user-slug') ||
      user.slug === sessionStorage.getItem('user-slug')
    );
  };

  return (
    <div className={styles['container']} style={{ backgroundColor: color }}>
      <div className={styles['sub-container']}>
        {isUserCurrentUser() && (
          <div className={styles['first-name']}>
            <FormattedMessage id='hello' />, {user?.full_name}
          </div>
        )}
        {/* <div className={styles['coin-container']}>
          <img src={Coin} alt='coin' />
          <span>
            {user?.coins} <FormattedMessage id='coins' />
          </span>
        </div> */}
      </div>
      {isUserCurrentUser() && (
        <div className={styles['set-color']}>
          <PrimaryButton
            style={{
              backgroundColor: '#fff',
            }}
            onClick={() => setOpenColorPicker(true)}
            children={
              <FormatColorFillOutlinedIcon style={{ color: '#404D56' }} />
            }
          />
        </div>
      )}
      {openColorPicker && (
        <ColorPicker
          color={color}
          open={openColorPicker}
          close={() => {
            setOpenColorPicker(false);
            setColor(user.background_color);
          }}
          onColorPick={(value) => setColor(value)}
          saveBackgroundColor={saveBackgroundColor}
        />
      )}
    </div>
  );
};

export default ProfileBackground;
