import React from 'react';
import './HomepageInvestigate.css';
import HomepageInvestigateFirst from './Investigate/HomepageInvestigateFirst';
import HomepageInvestigateSecond from './Investigate/HomepageInvestigateSecond';
import HomepageInvestigateThird from './Investigate/HomepageInvestigateThird';
import HomepageInvestigateFourth from './Investigate/HomepageInvestigateFourth';
import HomepageInvestigateFifth from './Investigate/HomepageInvestigateFifth';
import HomepageInvestigateSixth from './Investigate/HomepageInvestigateSixth';

const ButtonMailto = ({ mailto, label, className }) => {
  return (
    <a
      href={mailto}
      // onClick={(e) => {
      //     window.location = mailto;
      //     e.preventDefault();
      // }}
      className={className}
    >
      {label}
    </a>
  );
};

const HomepageInvestigate = (props) => {
  return (
    <div className='homepage-investigate'>
      <HomepageInvestigateFirst />
      <HomepageInvestigateSecond />
      <HomepageInvestigateThird />
      <HomepageInvestigateFourth ButtonMailto={ButtonMailto} />
      <HomepageInvestigateFifth />
      <HomepageInvestigateSixth ButtonMailto={ButtonMailto} />
    </div>
  );
};

export default HomepageInvestigate;
