import React from 'react';
import { Modal } from '@mui/material';
import { makeStyles } from '@mui/styles';
import closeIcon from '../../../../img/CloseSmallIcon.svg';
import './DiscardModal.css';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    position: 'relative',
    width: '700px',
    backgroundColor: 'white',
    padding: '32px',
    borderRadius: '8px',
    outline: 'none',
  },
  modalBackground: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}));

const DiscardModal = ({ open, handleClose, title, onConfirm }) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modal}
      BackdropProps={{
        className: classes.modalBackground,
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.modalContent}>
        <div className='heading'>
          <FormattedMessage id='settings_cancel' />{' '}
          <FormattedMessage id={title} />
        </div>
        <button className='close-btn' onClick={() => handleClose(false)}>
          <img src={closeIcon} alt='close' />
        </button>
        <div className='discard-message'>
          <FormattedMessage id='discard_subheading' />
        </div>
        <div className='btn-container'>
          <div className='side-container'>
            <button className='discard-btn' onClick={() => handleClose(false)}>
              <FormattedMessage id='no' />
            </button>
            <button className='no-btn' onClick={() => onConfirm()}>
              <FormattedMessage id='yes' />
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DiscardModal;
