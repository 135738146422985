import React from 'react';
import IframeComponent from '../../../components/Iframe/IframeComponent';

const FreePlan = () => {
  return (
    <IframeComponent
      title='Free plan'
      source='https://www.wix.nettgrav.no/freeplan'
    />
  );
};

export default FreePlan;
