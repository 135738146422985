import React from 'react';
import styles from './TermsAndCondition.module.css';
import { FormattedMessage } from 'react-intl';

const TermsAndCondition = ({ user, isCheckedTerms, isChecked }) => {
  return (
    <div>
      <div className={styles['terms']}>
        <div className={styles['name']}>
          <FormattedMessage id='dear' /> {user ? user.full_name : ''}
        </div>
        <div className={styles['text']}>
          <FormattedMessage id='new_grave_first_description' />
        </div>
        <div className={styles['text']}>
          <FormattedMessage id='new_grave_second_description' />
        </div>
        <div className={styles['text']}>
          <FormattedMessage id='new_grave_third_description' />
        </div>
        <div className={styles['text']}>
          <FormattedMessage id='new_grave_fourth_description' />
        </div>
      </div>
      <div className={styles['accept-container']}>
        <input
          type='checkbox'
          onChange={() => isCheckedTerms(!isChecked)}
          checked={isChecked}
        />
        <div className={styles['label']}>
          <FormattedMessage id='new_grave_agree_terms' />
        </div>
      </div>
    </div>
  );
};

export default TermsAndCondition;
