import React from 'react';
import './HomepageGraveFourth.css';
import meaning from '../../../../img/GraveLastPhoto.jpg';
import meaningMobile from '../../../../img/GraveLastPhotoMobile.jpg';

const HomepageGraveFourth = (props) => {
    return(
        <div className='homepage-grave_fourth'>
            <div className="homepage-grave_fourth-content">
                <img loading='lazy' src={meaning} alt='meaning' className='homepage-grave_fourth-content_photo'/>
                <img loading='lazy' src={meaningMobile} alt='meaningMobile' className='homepage-grave_fourth-content_photo-mobile'/>
                <span className='homepage-grave_fourth-content_text'>Nettgrav.no - graven på nett</span>
            </div>
        </div>
    )
}

export default HomepageGraveFourth;