import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NextPhotoPointer from '../../../../img/OrangePointerNext.svg';
import PrevPhotoPointer from '../../../../img/OrangePointerPrev.svg';
import styles from './ImageSlider.module.css';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { setCurrentGravePhotoIndex } from '../../../../redux/actions';
import UploadImageModal from '../UploadImageModal/UploadImageModal';

const ImageSlider = (props) => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const photos = useSelector((state) => state.photoReducer.listOfPhotos);
  const currentPhotoIndex = useSelector(
    (state) => state.photoReducer.currentGravePhoto
  );
  const [touchStartX, setTouchStartX] = useState(null);
  const [touchEndX, setTouchEndX] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
  }, [currentPhotoIndex]);

  const handleImageLoad = () => {
    setLoading(false);
  };

  const prevPhoto = (event) => {
    setLoading(true);
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    const prevIndex = (currentPhotoIndex - 1 + photos.length) % photos.length;
    dispatch(setCurrentGravePhotoIndex(prevIndex));
  };

  const nextPhoto = (event) => {
    setLoading(true);
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    const nextIndex = (currentPhotoIndex + 1) % photos.length;
    dispatch(setCurrentGravePhotoIndex(nextIndex));
  };

  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEndX(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStartX && touchEndX) {
      const touchDistance = touchEndX - touchStartX;
      const swipeThreshold = 50;

      if (Math.abs(touchDistance) > swipeThreshold) {
        if (touchDistance > 0) {
          prevPhoto();
        } else {
          nextPhoto();
        }
      }
      setTouchStartX(null);
      setTouchEndX(null);
    }
  };

  const handleImageClick = (event) => {
    event.preventDefault();
    props.handleOpenPictureGalleryModal();
  };

  const handelOpenModal = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setOpenModal(true);
  };

  return (
    <React.Fragment>
      <div
        onClick={handleImageClick}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        className={styles['grave-picture-gallery_photos-photo']}
        style={{
          backgroundImage: photos[currentPhotoIndex]
            ? `url(${photos[currentPhotoIndex].file_url}})`
            : 'none',
        }}
      >
        <img
          className={styles['gallery_photos-photo']}
          loading='lazy'
          src={
            photos[currentPhotoIndex] ? photos[currentPhotoIndex].file_url : ''
          }
          onLoad={handleImageLoad}
          alt='gallery-image'
        />
        {loading && (
          <div className={styles['loading-image']}>
            <span>Loading....</span>
          </div>
        )}
        {photos.length > 1 && (
          <React.Fragment>
            <button
              className={styles['buttons-prev']}
              onClick={(event) => prevPhoto(event)}
            >
              <img
                loading='lazy'
                src={PrevPhotoPointer}
                alt='PrevPhotoPointer'
              />
            </button>
            <button
              className={styles['buttons-next']}
              onClick={(event) => nextPhoto(event)}
            >
              <img
                loading='lazy'
                src={NextPhotoPointer}
                alt='PrevPhotoPointer'
              />
            </button>
          </React.Fragment>
        )}
        {props.isAuth && (
          <div
            className={styles['upload-cover-image']}
            onClick={(event) => handelOpenModal(event)}
          >
            <CameraAltIcon style={{ color: '#fff' }} />
            <span className={styles['camera-title']}>Last opp minnebilde</span>
          </div>
        )}
      </div>
      {openModal && (
        <UploadImageModal
          onOpen={openModal}
          onClose={() => setOpenModal(false)}
          category='display'
          singleImage={true}
          title='Legg til minnebilde'
        />
      )}
    </React.Fragment>
  );
};

export default ImageSlider;
