import { saveToLocalStorage } from './store';

const localStorageMiddleware = (store) => (next) => (action) => {
  // Call the next middleware in the chain first
  const result = next(action);

  // Save the updated state to local storage
  const state = store.getState();
  saveToLocalStorage(state);

  return result;
};

export default localStorageMiddleware;
