import React from 'react';
import X from '../../../../../img/X.svg';
import './Header.css';

const Header = (props) => {
  const step = props.step;
  const handleCancelClick = () => props.handleCloseGraveConnectionDialog();
  return (
    <div className='grave-connections-header'>
      <span>
        {step === 1
          ? `Gravlagte du kan knytte sammen med ${props.grave.name}`
          : `Sett relasjon for å linke gravlagte`}
      </span>
      <img src={X} onClick={handleCancelClick} alt='' />
    </div>
  );
};

export default Header;
