import React from 'react';
import styles from './GenderButton.module.css';
import { FormattedMessage } from 'react-intl';

const GenderButton = ({ gender, item }) => {
  const active = gender === item.value;
  return (
    <button
      type='button'
      onClick={() => item.action(gender)}
      className={`${styles['gender-btn']} ${
        active ? styles['active'] : item.error ? styles['error'] : ''
      }`}
    >
      <FormattedMessage id={gender} />
    </button>
  );
};

export default GenderButton;
