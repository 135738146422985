import {
    setSearchInputType,
    setGravesFromApi,
    setGraveArray,
    updateSearchGraveObject,
    setSearchInputClick,
} from "../actions";

const searchState = {
    searchInput: '',
    searchArr: [],
    peopleArr: [],
    graveArray: [],
    isSearchInputClick: false
}

export const searchReducer = (state = searchState, action) => {
    switch (action.type) {
        case setSearchInputType:
            return {
                ...state,
                searchInput: action.searchInput,
            };
        case setSearchInputClick:
            return {
                ...state,
                isSearchInputClick: action.payload,
            };
        case setGravesFromApi:
            return {
                ...state,
                searchArr: action.graves
            }
        case setGraveArray:
            return {
                ...state,
                graveArray: action.graveArr
            }
        case updateSearchGraveObject:
            const newGraveArr = state.graveArray.map(item => {
                if (action.payload.grave.id === item.id) {
                    item.can_claim = false;
                    item.can_set_relations = false
                }
                return item;
            })
            return {
                ...state,
                graveArray: newGraveArr
            }
        default: return state;
    }
};
