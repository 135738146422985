import React from 'react';
import { SketchPicker } from 'react-color';
import CustomModal from '../../../components/CustomModal';
import { FormattedMessage } from 'react-intl';
import styles from './UserHeader.module.css';
import PrimaryButton from '../../../components/PrimaryButton';

const ColorPicker = ({
  color,
  open,
  close,
  onColorPick,
  saveBackgroundColor,
}) => {
  const handleColorChange = (newColor) => {
    onColorPick(newColor.hex);
  };

  return (
    <CustomModal
      title={<FormattedMessage id='set_color' />}
      isOpen={open}
      closeModal={close}
      children={
        <React.Fragment>
          <SketchPicker
            color={color}
            onChange={handleColorChange}
            className={styles['color-picker']}
          />
          <div className={styles['btn']}>
            <PrimaryButton
              onClick={saveBackgroundColor}
              children={<FormattedMessage id='auth_save' />}
            />
          </div>
        </React.Fragment>
      }
    />
  );
};

export default ColorPicker;
