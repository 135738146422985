import React from 'react';
import TextArea from '../CreateSparks/TextArea';
import PrimaryButton from '../../../../../../components/PrimaryButton';
import styles from './EditDescription.module.css';
import { FormattedMessage } from 'react-intl';

const EditDescription = ({
  description,
  setDescription,
  onCloseEdit,
  onSave,
}) => {
  return (
    <div className={styles['']}>
      <TextArea text={description} setText={setDescription} />
      <div className={styles['group-btn']}>
        <PrimaryButton
          onClick={onCloseEdit}
          children={<FormattedMessage id='settings_cancel' />}
          style={{
            background: '#fff',
            border: '1px solid #404D56',
            color: '#404D56',
            padding: '7px 16px',
          }}
        />
        <PrimaryButton
          onClick={onSave}
          children={<FormattedMessage id='auth_save' />}
        />
      </div>
    </div>
  );
};

export default EditDescription;
