import React, { useState, useEffect, useCallback } from 'react';
import styles from './Anniversaries.module.css';
import Header from '../GraveWall/Header';
import ExpandIcon from '../About/components/ExpandIcon';
import { request } from '../../../../service/request';
import Loader from '../../../../components/Loader/Loader';
import AnniversaryCard from './AnniversaryCard/AnniversaryCard';
import showUserNotification from '../../../../components/UserNotification/showUserNotification';
import { useSelector } from 'react-redux';

const Anniversaries = ({ hideMenu, grave }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [anniversaries, setAnniversaries] = useState([]);
  const [publicAnniversaries, setPublicAnniversaries] = useState([]);
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.user.user);

  const handleResize = () => {
    if (window.innerWidth >= 1020) {
      setIsExpanded(true);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isExpanded]);

  const fetchPublicAnniversaries = useCallback(async () => {
    if (!grave.id) return;
    setLoading(true);
    try {
      const newData = await request(`/public_anniversaries`);
      if (newData.data.public_anniversaries.length > 0) {
        setPublicAnniversaries(newData.data.public_anniversaries);
      } else {
        setPublicAnniversaries([]);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      showUserNotification(
        error.response.data.errors || error.message,
        'error'
      );
      setLoading(false);
    }
  }, [grave.id]);

  const fetchAnniversaries = useCallback(async () => {
    if (!grave.id) return;
    setLoading(true);
    try {
      const newData = await request(
        `/grave_anniversaries?grave_id=${grave.id}`
      );
      if (newData.data.grave_anniversaries.length > 0) {
        setAnniversaries(newData.data.grave_anniversaries);
      } else {
        setAnniversaries([]);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      showUserNotification(
        error.response.data.errors || error.message,
        'error'
      );
      setLoading(false);
    }
  }, [grave.id]);

  useEffect(() => {
    fetchAnniversaries();
    fetchPublicAnniversaries();
  }, [fetchAnniversaries, fetchPublicAnniversaries]);

  const getUser = () => {
    if (grave?.grave_users.length > 0) {
      const isGraveUser = grave?.grave_users.find(
        (graveUser) => graveUser.user?.id === user.id
      );
      if (isGraveUser) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const showExpandIcon = () => {
    return !hideMenu ? <ExpandIcon isExpanded={isExpanded} /> : null;
  };

  return (
    <div>
      <Header
        title='Merkedager'
        isExpanded={isExpanded}
        children={showExpandIcon()}
        setIsExpanded={setIsExpanded}
      />
      {loading && <Loader />}
      {isExpanded && !loading && (
        <div className={styles['anniversaries']}>
          <AnniversaryCard
            titleKey='public_anniversaries'
            isPublic={true}
            anniversaries={publicAnniversaries}
            grave={grave}
            isGraveUser={getUser()}
          />
          <AnniversaryCard
            titleKey='grave_anniversaries'
            anniversaries={anniversaries}
            setAnniversaries={setAnniversaries}
            isPublic={false}
            grave={grave}
            isGraveUser={getUser()}
          />
        </div>
      )}
    </div>
  );
};

export default Anniversaries;
