import React from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const ExpandIcon = ({ isExpanded }) => {
  return (
    <ArrowDropDownIcon
      style={{ transform: isExpanded ? 'rotate(-90deg)' : 'rotate(0)' }}
    />
  );
};

export default ExpandIcon;
