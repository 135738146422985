import React, { useEffect, useState } from 'react';
import {
  useStripe,
  useElements,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import { CONSTANTS } from '../../../../service/constants';
import { useDispatch, useSelector } from 'react-redux';
import showUserNotification from '../../../../components/UserNotification/showUserNotification';
import {
  setSubscriptionCount,
  updateUserSubscription,
  setReturnPage,
  setBillingInformation,
  setShowCardMethod,
  setPaymentMethod,
  setPaymentMethods,
  setSelectedCardMethod,
  setSubscriptionPeriod,
  setVippsPaymentMethods,
  setShowVippsPaymentMethod,
  setSelectedVippsMethod,
  setVippsPhoneNumber,
} from '../../../../redux/actions';
import ErrorModal from '../ErrorModal/ErrorModal';
import isEmpty from 'lodash.isempty';
import { getMessageKey } from '../../../../service/constants';
import { useIntl } from 'react-intl';
import CardInputs from '../NewCard/CardInputs';

const CardPayment = ({
  chosenPlan,
  submit,
  setSubmit,
  billingInformation,
  handleCloseModal,
  handelCardInputs,
  selectedPlan,
  currentSubscriptionPlan,
}) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const [errorMessage, setErrorMessage] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const productDetails = chosenPlan || {};
  const subscriptionPeriod = useSelector(
    (state) => state.paymentMethods.subscriptionPeriod
  );
  const subscriptionCount = useSelector(
    (state) => state.subscriptionDetails.subscriptionTotalCount
  );
  const currentSubscription = useSelector(
    (state) => state.currentSubscriptionPlan.currentSubscriptionData
  );
  const amount = useSelector(
    (state) => state.subscriptionDetails.subscriptionTotalPrice
  );

  const returnPage = useSelector((state) => state.returnPage);
  const dispatch = useDispatch();
  const [cardInputs, setCardInputs] = useState({
    cardNumber: '',
    Cvc: '',
    expiry: '',
  });
  const [nameOnCard, setNameOnCard] = useState('');

  const handleSubmit = async () => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    // event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const card = elements.getElement(CardNumberElement);

    stripe
      .createPaymentMethod({
        type: 'card',
        card: card,
        billing_details: {
          name: nameOnCard,
        },
      })
      .then((result) => {
        stripePaymentMethodHandler(result);
      });
  };

  const stripePaymentMethodHandler = async (result) => {
    if (result.error) {
      handleCloseModal();
      setErrorMessage(result.error.message);
      setOpenErrorModal(true);
    } else {
      try {
        const response = await stripeTokenHandler(result.paymentMethod, null);
        const json = await response.json();
        handleServerResponse(json);
      } catch (error) {
        handleCloseModal();
      }
    }
  };

  const handleServerResponse = (response) => {
    if (!response.success) {
      // Show error from server on payment form
      showUserNotification(response.message, 'error');
      handleCloseModal();
      setErrorMessage(response.message);
      setOpenErrorModal(true);
    } else if (response.requires_action) {
      // Use Stripe.js to handle required card action
      stripe
        .handleCardAction(response.payment_intent_client_secret)
        .then((result) => {
          handleStripeJsResult(result);
        });
    } else {
      setCardInputs((prev) => {
        return {
          ...prev,
          cardNumber: '',
          Cvc: '',
          expiry: '',
        };
      });
    }
  };

  const handleStripeJsResult = (result) => {
    if (result.error) {
      handleCloseModal();
      setErrorMessage(result.message);
      setOpenErrorModal(true);
      return;
    } else {
      // The card action has been handled
      // The PaymentIntent can be confirmed again on the server
      stripeTokenHandler(null, result.paymentIntent);
    }
  };

  const stripeTokenHandler = async (paymentMethod, paymentIntent) => {
    let requestPayload = {};

    const addMembers =
      !isEmpty(currentSubscription) &&
      currentSubscription.product.id === productDetails.id;
    if (addMembers && paymentMethod) {
      requestPayload = {
        payment: {
          payment_method_id: paymentMethod?.id,
          price: amount,
          user_count: subscriptionCount,
          product_id: productDetails.id,
          period: subscriptionPeriod.key,
          discount: subscriptionPeriod.discount,
          payment_method: {
            payment_type: paymentMethod.type,
            stripe_id: paymentMethod.id,
            country: paymentMethod.card.country,
            exp_month: paymentMethod.card.exp_month,
            exp_year: paymentMethod.card.exp_year,
            is_active: true,
            card_type: paymentMethod.card.brand,
            card_number: paymentMethod.card.last4,
          },
          billing_information: {
            name: billingInformation.fullName,
            email: billingInformation.email,
            phone_number: billingInformation.phoneNumber,
            address: billingInformation.billingAddress,
            postal_code: billingInformation.postalCode,
            country_id: billingInformation.country_id,
          },
        },
      };
    } else if (!addMembers && paymentMethod) {
      requestPayload = {
        payment: {
          payment_method_id: paymentMethod?.id,
          price: amount,
          user_count: subscriptionCount,
          product_id: productDetails.id,
          period: subscriptionPeriod.key,
          discount: subscriptionPeriod.discount,
          payment_method: {
            payment_type: paymentMethod.type,
            stripe_id: paymentMethod.id,
            country: paymentMethod.card.country,
            exp_month: paymentMethod.card.exp_month,
            exp_year: paymentMethod.card.exp_year,
            is_active: true,
            card_type: paymentMethod.card.brand,
            card_number: paymentMethod.card.last4,
          },
          billing_information: {
            name: billingInformation.fullName,
            email: billingInformation.email,
            phone_number: billingInformation.phoneNumber,
            address: billingInformation.billingAddress,
            postal_code: billingInformation.postalCode,
            country_id: billingInformation.country_id,
          },
        },
      };
    }

    try {
      const response = await fetch(
        `${CONSTANTS.baseUrl}/api/stripe/payments.json`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestPayload),
        }
      );
      if (!response.ok) {
        handleCloseModal();
        setErrorMessage(intl.formatMessage({ id: 'something_wrong' }));
        setOpenErrorModal(true);
        return;
      }

      const data = await response.json();

      if (data) {
        handelSubscription(data.payment_method_id);
      }
    } catch (error) {
      setErrorMessage(intl.formatMessage({ id: 'something_wrong' }));
      setOpenErrorModal(true);
      handleCloseModal();
    }
  };

  const handleRedirect = () => {
    let url = returnPage.url || '/settings/subscriptions';
    if (subscriptionCount > 1) {
      url = returnPage.url || '/settings/subscription-invitations';
    }
    let messageKey = getMessageKey(currentSubscriptionPlan, selectedPlan);
    if (url) {
      dispatch(setShowCardMethod(false));
      dispatch(setShowVippsPaymentMethod(false));
      dispatch(setBillingInformation({}));
      dispatch(setPaymentMethod({}));
      dispatch(setSelectedVippsMethod({}));
      dispatch(setVippsPaymentMethods([]));
      dispatch(setPaymentMethods([]));
      dispatch(setSelectedCardMethod('vipps'));
      dispatch(setReturnPage(null));
      dispatch(setVippsPhoneNumber(''));
      dispatch(setSubscriptionPeriod({}));
      dispatch(setSubscriptionCount(1));
    }
    navigate(url, { show: 1, key: messageKey });
  };

  const handelSubscription = async (paymentMethodId) => {
    const addMembers =
      !isEmpty(currentSubscription) &&
      currentSubscription.product.id === productDetails.id;
    if (addMembers) {
      updateSubscription(paymentMethodId);
    } else {
      createSubscription(paymentMethodId);
    }
  };

  const updateSubscription = async (paymentMethodId) => {
    const requestPayload = {
      subscription: {
        payment_method_id: paymentMethodId,
        user_count: parseInt(subscriptionCount),
        product_id: productDetails.id,
        period: subscriptionPeriod.key,
        action_type: 'add',
      },
    };
    try {
      const response = await fetch(
        `${CONSTANTS.baseUrl}/api/subscriptions/${currentSubscription.id}.json`,
        {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestPayload),
        }
      );

      if (!response.ok) {
        handleCloseModal();
        setErrorMessage(intl.formatMessage({ id: 'something_wrong' }));
        setOpenErrorModal(true);
        return;
      }
      const data = await response.json();
      if (data) {
        dispatch(updateUserSubscription(true));
        handleCloseModal();
        handleRedirect();
      }
    } catch (error) {
      setErrorMessage(intl.formatMessage({ id: 'something_wrong' }));
      handleCloseModal();
      setOpenErrorModal(true);
    }
  };

  const createSubscription = async (paymentMethodId) => {
    const requestPayload = {
      subscription: {
        payment_method_id: paymentMethodId,
        period: subscriptionPeriod.key,
        discount: subscriptionPeriod.discount,
        user_count: parseInt(subscriptionCount),
        product_id: productDetails.id,
      },
    };
    try {
      const response = await fetch(
        `${CONSTANTS.baseUrl}/api/subscriptions.json`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestPayload),
        }
      );
      if (!response.ok) {
        handleCloseModal();
        setErrorMessage(intl.formatMessage({ id: 'something_wrong' }));
        setOpenErrorModal(true);
      }

      const data = await response.json();
      if (data) {
        dispatch(updateUserSubscription(true));
        handleCloseModal();
        handleRedirect();
      }
    } catch (error) {
      handleCloseModal();
      setErrorMessage(intl.formatMessage({ id: 'something_wrong' }));
      setOpenErrorModal(true);
    }
  };

  const allEqual = (arr) => arr.every((v) => v === true);

  useEffect(() => {
    const values = Object.values(cardInputs);
    const result = allEqual(values);
    if (result && nameOnCard.trim() !== '') {
      handelCardInputs(true);
    } else {
      handelCardInputs(false);
    }
  }, [cardInputs, nameOnCard]);

  useEffect(() => {
    if (submit) {
      setSubmit(false);
      handleSubmit();
    }
  }, [submit]);

  return (
    <>
      <CardInputs
        cardInputs={cardInputs}
        setCardInputs={setCardInputs}
        nameOnCard={nameOnCard}
        setNameOnCard={setNameOnCard}
      />
      {openErrorModal && (
        <ErrorModal
          message={errorMessage}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
        />
      )}
    </>
  );
};

export default CardPayment;
