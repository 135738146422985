import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './SetSelfRelation.module.css';

const PendingApproval = () => {
  return (
    <React.Fragment>
      <div className={styles['heading']}>
        <FormattedMessage id='awaiting_owner_approval' />
      </div>
      <div className={styles['sub-heading']}>
        <FormattedMessage id='awaiting_approval_message' />
      </div>
    </React.Fragment>
  );
};

export default PendingApproval;
