import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import showUserNotification from '../../../components/UserNotification/showUserNotification';
import { request } from '../../../service/request';
import isEmpty from 'lodash.isempty';
import UploadDocumentFile from './UploadDocument/UploadDocument';
import TermsAndCondition from './TermsAndCondition/TermsAndCondition';
import Input from '../../../components/Input/Input';
import SelectDocument from '../../NewDeceased/atoms/GraveClaimPage/SelectDocument/SelectDocument';
import PrimaryButton from '../../../components/PrimaryButton';
import { FormattedMessage } from 'react-intl';
import './FirstStep.css';

const FirstStep = ({ firstStepData, onNextClick, relationList }) => {
  const user = useSelector((state) => state.user.user);
  const [directionsList, setDirectionsList] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    gender: '',
    fileType: null,
    certificate: null,
    relation: {},
    reciprocalRelation: {},
    isChecked: false,
  });

  const [formErrors, setFormErrors] = useState({
    name: false,
    gender: false,
    relation: false,
    reciprocalRelation: false,
  });

  useEffect(() => {
    if (firstStepData) {
      setFormData({
        ...formData,
        name: firstStepData.name,
        gender: firstStepData.gender,
        relation: firstStepData.relation,
        reciprocalRelation: firstStepData.reciprocalRelation,
        certificate: firstStepData.certificate,
        checked: firstStepData.checked,
        fileType: firstStepData.fileType,
      });
    }
  }, []);

  useEffect(() => {
    request('/relation_directions')
      .then((res) => {
        setDirectionsList(res.data?.list || []);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleOnSelectRelation = (event, value, reason) => {
    if (reason === 'clear') {
      setFormData((prev) => ({
        ...prev,
        relation: {},
      }));
    } else if (reason === 'selectOption') {
      setFormData((prev) => ({
        ...prev,
        relation: value,
      }));
      setFormErrors((prev) => ({
        ...prev,
        relation: false,
      }));
    }

    setFormData((prev) => ({
      ...prev,
      reciprocalRelation: {},
    }));
  };

  const handleOnSelectReciprocalRelation = (event, value, reason) => {
    if (reason === 'clear') {
      setFormData((prev) => ({
        ...prev,
        reciprocalRelation: {},
      }));
    } else if (reason === 'selectOption') {
      setFormData((prev) => ({
        ...prev,
        reciprocalRelation: value,
      }));
      setFormErrors((prev) => ({
        ...prev,
        reciprocalRelation: false,
      }));
    }
  };

  const onFileNameChange = (value) => {
    setFormData({ ...formData, fileType: value });
  };

  const onCertificateChange = (file) => {
    setFormData({ ...formData, certificate: file });
  };

  const filterDirectionList = (selectedRelation) => {
    if (isEmpty(selectedRelation)) return [];

    let filteredList = [];

    directionsList.map((item) => {
      if (item.from_relation.id === selectedRelation.id) {
        filteredList.push(item.to_relation);
      }

      return false;
    });

    if (filteredList.length === 0) {
      return [selectedRelation];
    } else {
      return filteredList;
    }
  };

  const validateForm = () => {
    const { name, gender, relation, reciprocalRelation } = formData;
    const errors = {
      name: !name,
      gender: !gender,
      relation: isEmpty(relation),
      reciprocalRelation: isEmpty(reciprocalRelation),
    };
    setFormErrors(errors);
    return !Object.values(errors).some((error) => error);
  };

  const onNext = () => {
    if (validateForm()) {
      onNextClick(formData);
    }
  };

  let formInputs = [
    {
      label: 'deceased_name',
      type: 'text',
      placeholder: 'deceased_placeholder',
      value: formData.name,
      autoComplete: 'off',
      error: formErrors.name,
      errorKey: 'enter_name_error',
      disabled: false,
      action: (value) => {
        if (value[0] !== ' ') {
          setFormData((prev) => ({
            ...prev,
            name: value
              .replace(/[0-9]/g, '')
              .replace(/[\"'@#!$%^&*()_+|~=`{}\[\]:;'<>?,.\/]/g, ''),
          }));
          setFormErrors((prev) => ({
            ...prev,
            name: false,
          }));
        }
      },
    },
    {
      label: 'deceased_gender',
      type: 'gender',
      gendersList: ['mann', 'kvinne', 'annen'],
      value: formData.gender,
      autoComplete: 'off',
      error: formErrors.gender,
      errorKey: 'select_gender_error',
      disabled: false,
      action: (value) => {
        setFormData((prev) => ({ ...prev, gender: value }));
        setFormErrors((prev) => ({
          ...prev,
          gender: false,
        }));
      },
    },
    {
      label: 'i_am',
      type: 'autocomplete',
      placeholder: 'select_relationship',
      value: formData.relation,
      options: relationList,
      autoComplete: 'off',
      error: formErrors.relation,
      errorKey: 'select_relation_error',
      disabled: false,
      action: handleOnSelectRelation,
    },
    {
      label: 'buried_is_mine',
      type: 'autocomplete',
      placeholder: 'select_relationship',
      value: formData.reciprocalRelation,
      options: filterDirectionList(formData.relation),
      autoComplete: 'off',
      error: formErrors.reciprocalRelation,
      errorKey: 'select_relation_error',
      disabled: false,
      action: handleOnSelectReciprocalRelation,
    },
  ];

  return (
    <div className='first-step'>
      {formInputs.map((item, index) => (
        <Input item={item} key={index} />
      ))}
      <SelectDocument
        selectedDocument={formData.fileType}
        handelSelectedDocument={onFileNameChange}
      />
      {formData.fileType && (
        <React.Fragment>
          <UploadDocumentFile
            handelSetDocumentFile={(value) => onCertificateChange(value)}
            selectedFile={formData.certificate}
          />
          <TermsAndCondition
            user={user}
            isChecked={formData.isChecked}
            isCheckedTerms={(value) =>
              setFormData({ ...formData, isChecked: value })
            }
          />
        </React.Fragment>
      )}
      <div className='next-button-container'>
        <PrimaryButton
          children={<FormattedMessage id='auth_next' />}
          onClick={onNext}
          style={{
            padding: '8px 24px',
          }}
        />
      </div>
    </div>
  );
};

export default FirstStep;
