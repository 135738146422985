import React, { useState } from 'react';
import styles from './VideoTabs.module.css';
import EmbedVideoForm from '../EmbedVideoForm/EmbedVideoForm';
import UploadVideoForm from '../UploadVideoForm/UploadVideoForm';

const VideoTabs = ({ onClose, openWarningModal }) => {
  const [tab, setTab] = useState(0);
  return (
    <div>
      <div className={styles['tabs-container']}>
        <button
          onClick={() => setTab(0)}
          className={tab === 0 ? styles['active-btn'] : styles['btn']}
        >
          LASTE OPP
        </button>
        <button
          onClick={() => setTab(1)}
          className={tab === 1 ? styles['active-btn'] : styles['btn']}
        >
          LEGG INN LINK
        </button>
      </div>
      {tab === 0 ? (
        <UploadVideoForm
          onClose={onClose}
          openWarningModal={openWarningModal}
        />
      ) : (
        <EmbedVideoForm onClose={onClose} openWarningModal={openWarningModal} />
      )}
    </div>
  );
};

export default VideoTabs;
