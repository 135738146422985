import { COMMENT_REPLIES_QUERY } from '../../Sparks/CommentRepliesQuery.ts';
import { COMMENTS_QUERY } from '../../Sparks/CommentQuery.ts';

export const removeCommentCache =
  (sparkId, parentId = null) =>
  (cache, { data: { destroyComment } }) => {
    if (destroyComment?.id) {
      if (!parentId) {
        const normalizedId = cache.identify({
          id: destroyComment.id,
          __typename: 'Comment',
        });

        cache.evict({ id: normalizedId });
        cache.gc();

        cache.modify({
          id: `Spark:${sparkId}`,
          fields: {
            commentsCount(existingCount = 0) {
              return existingCount - 1;
            },
            comments(existingComments = []) {
              const updatedComments = existingComments.filter(
                (comment) => comment.id === destroyComment.id
              );
              return updatedComments;
            },
          },
        });
        // const existingComments = cache.readQuery({
        //   query: COMMENTS_QUERY,
        //   variables: {
        //     sparkId: sparkId,
        //     first: 10,
        //   },
        // });
        // console.log('existingComments', existingComments);

        // const comments = existingComments.comments.edges.filter(
        //   ({ node }) => node.id !== destroyComment.id
        // );

        // console.log('Comments', comments);

        // const updatedComments = {
        //   ...existingComments,
        //   comments: {
        //     ...existingComments.comments,
        //     edges: [...comments],
        //   },
        // };

        // console.log('updatedComments', updatedComments);
        // console.log('sparkId', sparkId);

        // cache.writeQuery({
        //   query: COMMENTS_QUERY,
        //   variables: {
        //     sparkId: sparkId,
        //     first: 10,
        //   },
        //   data: updatedComments,
        // });
      } else {
        const normalizedId = cache.identify({
          id: destroyComment.id,
          __typename: 'Comment',
        });

        cache.evict({ id: normalizedId });
        cache.gc();

        cache.modify({
          id: `Spark:${sparkId}`,
          fields: {
            comments(existingComments = []) {
              const updatedComments = existingComments.forEach((comment) => {
                if (parentId === comment.id) {
                  return {
                    ...comment,
                    repliesCount: comment.repliesCount - 1,
                  };
                }
                return comment;
              });
              return updatedComments;
            },
          },
        });
        // const existingReplies = cache.readQuery({
        //   query: COMMENT_REPLIES_QUERY,
        //   variables: {
        //     commentId: parentId,
        //     first: 10,
        //   },
        // });

        // console.log('existingReplies', existingReplies);

        // const replies = existingReplies.commentReplies?.edges.filter(
        //   ({ node }) => node.id !== destroyComment.id
        // );

        // const updatedReplies = {
        //   ...existingReplies,
        //   commentReplies: {
        //     ...existingReplies.commentReplies,
        //     edges: [...replies],
        //   },
        // };
        // console.log('updatedReplies', updatedReplies);

        // cache.writeQuery({
        //   query: COMMENT_REPLIES_QUERY,
        //   variables: {
        //     commentId: parentId,
        //     first: 10,
        //   },
        //   data: updatedReplies,
        // });
      }

      cache.modify({
        id: `Spark:${sparkId}`,
        fields: {
          commentsCount(existingCount = 0) {
            return existingCount - 1;
          },
          comments(existingComments = []) {
            const updatedComments = existingComments.filter(
              (comment) => destroyComment.id === comment.id
            );
            return updatedComments;
          },
        },
      });
    }
  };
