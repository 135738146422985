import React, { useRef, useEffect, useState } from 'react';
import Loader from '../Loader/Loader';

const IframeComponent = (props) => {
  const [loading, setLoading] = useState(true);
  const iframeRef = useRef(null);
  const [iframeContentStyle, setIframeContentStyle] = useState({
    border: 'none',
    width: '100%',
    overflowY: 'hidden',
  });
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  useEffect(() => {
    function handleResize() {
      setDimensions(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  useEffect(() => {
    // Add event listener to receive messages from the iframe
    window.addEventListener('message', handleMessage);
    // Define the origin of your Wix website
    const wixWebsiteOrigin = 'https://www.wix.nettgrav.no';

    // Get the iframe element by ID
    const iframe = iframeRef.current;

    // Send a message to the iframe to request inner height when the component mounts
    iframe.addEventListener('load', () => {
      iframe.contentWindow.postMessage('getInnerHeight', wixWebsiteOrigin);
    });

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [iframeRef]);

  const handleMessage = (event) => {
    if (event.origin === 'https://www.wix.nettgrav.no') {
      const data = event.data;
      if (data.innerHeight) {
        // Update the state with the inner height
        setIframeContentStyle((prev) => {
          return { ...prev, height: data.innerHeight };
        });
        setLoading(false);
      }
    }
  };

  return (
    <React.Fragment>
      {/* {loading && <Loader />} */}
      <iframe
        title={props.title}
        src={props.source}
        style={
          props.customStyle && Object.keys(props.customStyle).length > 0
            ? props.customStyle
            : iframeContentStyle
        }
        allowFullScreen
        ref={iframeRef}
        key={dimensions}
      ></iframe>
    </React.Fragment>
  );
};

export default IframeComponent;
