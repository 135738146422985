import React from 'react';
import './Content.css';
import Card from './Card';
import NoneGravesImage from '../../../../../img/NoneGravesImage.svg';
import { FormattedMessage } from 'react-intl';

const Content = (props) => {
  const searchInput = props.searchInputValue || '';
  const list = props.list;

  return (
    <>
      {list && list.length === 0 ? (
        <div className='municipality-not-found'>
          <img src={NoneGravesImage} alt='not-found' />
          <span className='search-graves_none-text'>
            <FormattedMessage id='no_results' /> <span>"{searchInput}"</span>
          </span>
        </div>
      ) : (
        <div className='municipality-search-content'>
          {list &&
            list.map((item) => {
              return (
                <Card
                  name={item.name}
                  countryName={item.country_name || item.municipality_name}
                  url={`${props.url}/${item.slug}`}
                  logo={props.logo}
                />
              );
            })}
        </div>
      )}
    </>
  );
};

export default Content;
