import React from 'react';
import styles from './Paid.module.css';
import { FormattedMessage } from 'react-intl';
import { planKey } from '../../../../service/PlanKey';
import {
  ChangePlanKey,
  SubscriptionStatuses,
  SubscriptionAlertsKeys,
  SubscriptionChangesKey,
} from '../../../../service/constants';
import TitleRow from '../TitleRow/TitleRow';
import PaymentMethod from '../PaymentMethod/PaymentMethod';
import isEmpty from 'lodash.isempty';
import { formatDate } from '../../../../service/Utils';
import PlanDetailsRow from '../../../Payment/atoms/PlanPaymentDetails/PlanDetailsRow';
import DiscountInfoList from '../../../../components/DiscountsInfo/DiscountInfoList';

const PaidSubscription = ({
  currentSubscription,
  setOpenOptionsModal,
  action,
  setActionKey,
  paymentMethod,
  subscriptionChanges,
  subscriptionMethod,
  setOpenAddPaymentModal,
  discountsList,
}) => {
  const getLength = () => {
    return (
      <>
        <FormattedMessage id={currentSubscription.period} />
      </>
    );
  };

  const getSubscriptionChanges = (name) => {
    return subscriptionChanges.some((change) => change.attribute_name === name);
  };

  const isActiveSubscription = (subscriptionKey) => {
    const status = subscriptionMethod();
    const { status: currentStatus } = currentSubscription;
    const { PENDING_APPROVAL, ACTIVE, PAYMENT_DUE } = SubscriptionStatuses;

    const isSubscriptionActive =
      currentStatus === PENDING_APPROVAL ||
      currentStatus === ACTIVE ||
      currentStatus === PAYMENT_DUE;

    const isValidPaymentMethod =
      !isEmpty(paymentMethod) && !paymentMethod.destroyed_at;

    const isStatusValid =
      status &&
      status !== SubscriptionAlertsKeys.expired &&
      status !== SubscriptionAlertsKeys.stopped;

    return (
      isSubscriptionActive &&
      isValidPaymentMethod &&
      isStatusValid &&
      !getSubscriptionChanges(subscriptionKey)
    );
  };

  const isActionNull = (subscriptionKey) => {
    if (
      (action === null && !getSubscriptionChanges(subscriptionKey)) ||
      (action &&
        (action.key === SubscriptionAlertsKeys.active ||
          action.key === SubscriptionAlertsKeys.paymentDue) &&
        !getSubscriptionChanges(subscriptionKey))
    ) {
      return true;
    } else if (action && action.key === SubscriptionAlertsKeys.cancelled) {
      return action;
    } else {
      return false;
    }
  };

  return (
    <div className={styles['paid-container']}>
      <TitleRow
        value={<FormattedMessage id={planKey(currentSubscription.plan_key)} />}
        title={<FormattedMessage id='settings_subscription' />}
        action={isActionNull(SubscriptionChangesKey.product_id)}
        setOpenOptionsModal={setOpenOptionsModal}
        messageKey={ChangePlanKey.change_plan}
        setActionKey={setActionKey}
      />
      <TitleRow
        value={currentSubscription.user_count}
        title={<FormattedMessage id='users' />}
        action={isActiveSubscription(SubscriptionChangesKey.user_count)}
        setOpenOptionsModal={setOpenOptionsModal}
        messageKey={ChangePlanKey.change_members}
        setActionKey={setActionKey}
      />
      <TitleRow
        value={getLength()}
        title={<FormattedMessage id='billing_cycle' />}
        action={isActiveSubscription(SubscriptionChangesKey.period)}
        setOpenOptionsModal={setOpenOptionsModal}
        messageKey={ChangePlanKey.change}
        setActionKey={setActionKey}
      />

      <div className={styles['detail']}>
        <div className={styles['title']}>
          <FormattedMessage id='subscription_base_price' />:
        </div>
        <div className={styles['value']}>
          NOK {currentSubscription.subscription_base_price}
        </div>
      </div>
      <div className={styles['detail']}>
        <div className={styles['title']}>
          <FormattedMessage id='discount' />:
        </div>
        <div className={styles['value']}>
          {currentSubscription.discount_percentage}% (
          {currentSubscription.discount},- NOK){'  '}
          <DiscountInfoList discountsList={discountsList} />
        </div>
      </div>
      <div className={styles['detail']}>
        <div className={styles['title']}>
          <FormattedMessage id='total_price' />:
        </div>
        <div className={styles['value']}>
          NOK {currentSubscription.subscription_price}
        </div>
      </div>
      {!isEmpty(paymentMethod) && (
        <PaymentMethod
          paymentMethod={paymentMethod}
          currentSubscription={currentSubscription}
          dueDate={
            currentSubscription.period_end_date
              ? formatDate(currentSubscription.period_end_date)
              : ''
          }
          setOpenOptionsModal={setOpenOptionsModal}
          messageKey={ChangePlanKey.change_plan}
          setActionKey={setActionKey}
          action={action}
          setOpenAddPaymentModal={setOpenAddPaymentModal}
        />
      )}
    </div>
  );
};

export default PaidSubscription;
