import React from 'react';
import styles from './Tabs.module.css';
import VideoTab from '../../../../../img/VideoTab.svg';
import PictureTab from '../../../../../img/PictureTab.svg';

const Tabs = ({ tab, setTab }) => {
  return (
    <div className={styles['tabs-container']}>
      <img
        onClick={() => setTab(0)}
        src={PictureTab}
        loading='lazy'
        className={tab === 0 ? styles['active-btn'] : styles['btn']}
        alt='tabs'
      />
      <img
        onClick={() => setTab(1)}
        src={VideoTab}
        loading='lazy'
        className={tab === 1 ? styles['active-btn'] : styles['btn']}
        alt='tabs'
      />
    </div>
  );
};

export default Tabs;
