import React from 'react';
import styles from './TabButtons.module.css';

const TabButtons = ({ tab, tabItems, setTab }) => {
  return (
    <div className={styles['tabs-container']}>
      {tabItems.map((item, index) => {
        return (
          <button
            onClick={() => setTab(index)}
            className={tab === index ? styles['active-btn'] : styles['btn']}
          >
            {item.text}
          </button>
        );
      })}
    </div>
  );
};

export default TabButtons;
