import React, { useState, useEffect } from 'react';
import isEmpty from 'lodash.isempty';
import Input from '../../../components/Input/Input';
import PrimaryButton from '../../../components/PrimaryButton';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import './SecondStep.css';

const SecondStep = ({
  reasonOfPassing,
  onNextClick,
  onBackClick,
  secondStepData,
}) => {
  const [formData, setFormData] = useState({
    selectedBirthDate: null,
    selectedDeathDate: null,
    passing: {},
    otherPassing: '',
  });

  const [formErrors, setFormErrors] = useState({
    selectedBirthDate: false,
    selectedDeathDate: false,
    passing: false,
    otherPassing: false,
  });

  useEffect(() => {
    if (secondStepData) {
      setFormData((prev) => ({
        ...prev,
        selectedBirthDate: secondStepData.selectedBirthDate,
        selectedDeathDate: secondStepData.selectedDeathDate,
        passing: secondStepData.passing,
        otherPassing: secondStepData?.otherPassing
          ? secondStepData.otherPassing
          : '',
      }));
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  const validateForm = () => {
    const { selectedBirthDate, selectedDeathDate, passing, otherPassing } =
      formData;

    const errors = {
      selectedBirthDate: !selectedBirthDate || formErrors.selectedBirthDate,
      selectedDeathDate: !selectedDeathDate || formErrors.selectedDeathDate,
      passing: isEmpty(passing),
      otherPassing:
        (passing.reason === 'Others' ||
          passing.reason === 'Annen' ||
          passing.reason === 'Other') &&
        !otherPassing,
    };
    setFormErrors(errors);
    return !Object.values(errors).some((error) => error);
  };

  const onNext = () => {
    if (validateForm()) {
      onNextClick(formData);
    }
  };

  const handleOnSelectReason = (event, value, reason) => {
    if (reason === 'clear') {
      setFormData((prev) => ({
        ...prev,
        passing: {},
      }));
    } else if (reason === 'selectOption') {
      setFormData((prev) => ({
        ...prev,
        passing: value,
        otherPassing:
          value.reason === 'Others' ||
          value.reason === 'Annen' ||
          value.reason === 'Other'
            ? formData.otherPassing
            : '',
      }));
      setFormErrors((prev) => ({
        ...prev,
        passing: false,
      }));
    }
  };

  const isValidDate = (date, value, action) => {
    return action === 'death'
      ? date && dayjs(value).isBefore(dayjs(date))
      : date && dayjs(value).isAfter(dayjs(date));
  };

  const handelDateOfBirth = (value) => {
    if (value) {
      const validDate = isValidDate(formData.selectedDeathDate, value, 'birth');
      setFormData((prev) => ({ ...prev, selectedBirthDate: value }));
      setFormErrors((prev) => ({
        ...prev,
        selectedBirthDate: validDate,
        selectedDeathDate: validDate || !formData.selectedDeathDate,
      }));
    } else {
      setFormData((prev) => ({ ...prev, selectedBirthDate: value }));
      setFormErrors((prev) => ({
        ...prev,
        selectedBirthDate: false,
      }));
    }
  };

  const handelDateOfDeath = (value) => {
    if (value) {
      const validDate = isValidDate(formData.selectedBirthDate, value, 'death');
      setFormData((prev) => ({ ...prev, selectedDeathDate: value }));
      setFormErrors((prev) => ({
        ...prev,
        selectedDeathDate: validDate,
        selectedBirthDate: validDate || !formData.selectedBirthDate,
      }));
    } else {
      setFormData((prev) => ({ ...prev, selectedDeathDate: value }));
      setFormErrors((prev) => ({
        ...prev,
        selectedDeathDate: false,
      }));
    }
  };

  let formInputs = [
    {
      label: 'date_of_birth',
      type: 'datePicker',
      error: formErrors.selectedBirthDate,
      errorKey:
        formData.selectedBirthDate && formErrors.selectedBirthDate
          ? 'birth_date_error'
          : 'select_date_error',
      disableFuture: true,
      disablePast: false,
      value: formData.selectedBirthDate,
      action: (value) => {
        handelDateOfBirth(value);
      },
    },
    {
      label: 'date_of_death',
      type: 'datePicker',
      error: formErrors.selectedDeathDate,
      errorKey:
        formData.selectedDeathDate && formErrors.selectedDeathDate
          ? 'death_date_error'
          : 'select_date_error',
      disableFuture: true,
      disablePast: false,
      value: formData.selectedDeathDate,
      action: (value) => {
        handelDateOfDeath(value);
      },
    },
    {
      label: 'reason_of_death',
      type: 'autocomplete',
      placeholder: 'reason_of_death',
      value: formData.passing,
      options: reasonOfPassing,
      autoComplete: 'off',
      error: formErrors.passing,
      errorKey: 'select_reason_error',
      disabled: false,
      action: handleOnSelectReason,
    },
  ];

  if (
    formData.passing.reason === 'Others' ||
    formData.passing.reason === 'Annen' ||
    formData.passing.reason === 'Other'
  ) {
    formInputs.push({
      label: 'other_reason',
      type: 'text',
      placeholder: 'other_reason',
      value: formData.otherPassing,
      autoComplete: 'off',
      error: formErrors.otherPassing,
      errorKey: 'other_reason_error',
      disabled: false,
      action: (value) => {
        if (value[0] !== ' ') {
          setFormData((prev) => ({
            ...prev,
            otherPassing: value
              .replace(/[0-9]/g, '')
              .replace(/[\"'@#!$%^&*+|~=`{}\[\]:;'<>?,.\/]/g, ''),
          }));
          setFormErrors((prev) => ({
            ...prev,
            otherPassing: false,
          }));
        }
      },
    });
  }

  return (
    <div className='first-step'>
      {formInputs.map((item, index) => (
        <Input key={index} item={item} />
      ))}
      <div className='second-step-buttons'>
        <PrimaryButton
          children={<FormattedMessage id='auth_back' />}
          onClick={onBackClick}
          style={{
            background: '#fff',
            border: '1px solid #404D56',
            color: '#404D56',
            padding: '7px 24px',
          }}
        />
        <PrimaryButton
          children={<FormattedMessage id='auth_next' />}
          onClick={onNext}
          style={{
            padding: '8px 24px',
          }}
        />
      </div>
    </div>
  );
};

export default SecondStep;
