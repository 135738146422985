import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { SignInPage, NotFoundPage } from '../index';
import Registration from './Registration';
import { connect, useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  subscriptionInvitationToken,
  setGraveInvitationToken,
} from '../../redux/actions';

const AuthRouter = ({ component: Component, ...props }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const userSlug = localStorage.getItem('user-slug');
  const invitationToken = useSelector(
    (state) => state.invitationToken.subscriptionInvitationToken
  );

  const slugRedirect = localStorage.getItem('slug_for_redirect');

  useEffect(() => {
    sessionStorage.setItem('previousPath', location.pathname);
  }, [location.pathname]);

  const redirectToLastPage = () => {
    const { location, navigate } = props;
    const trimmedToken = invitationToken.trim();
    const previousPath = sessionStorage.getItem('previousPath');

    if (slugRedirect) {
      dispatch(setGraveInvitationToken({}));
      setTimeout(() => navigate(`/deceased/${slugRedirect}`), 1000);
    } else if (location.key) {
      if (trimmedToken === '') {
        if (
          previousPath &&
          (previousPath === '/registration' ||
            previousPath === '/auth/sign-in' ||
            previousPath === '/account-confirmation' ||
            previousPath === '/reset-password')
        ) {
          sessionStorage.removeItem('previousPath');
          navigate(`/member/${userSlug}`);
        } else {
          navigate(-1);
        }
      } else {
        dispatch(subscriptionInvitationToken(''));
        navigate(`/member/${userSlug}`);
      }
    } else {
      navigate(`/member/${userSlug}`);
    }
  };

  if (props.isAuthorized) {
    redirectToLastPage();
  }

  return (
    <React.Fragment>
      <Routes>
        <Route exact path='/auth/sign-in' element={<SignInPage />} />
        <Route exact path='/registration' element={<Registration />} />
        <Route element={<NotFoundPage />} />
      </Routes>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  isAuthorized: state.auth.isAuthorized,
});

export default connect(mapStateToProps)(AuthRouter);
