import React from 'react';
import styles from './AboutItem.module.css';

const ActionButton = (props) => {
  return (
    <div className={styles['action-btn']} onClick={props.onEdit}>
      <img src={props.icon} alt='Edit' />
      <button>{props.title}</button>
    </div>
  );
};

export default ActionButton;
