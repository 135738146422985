import React, { useState } from 'react';
import EditButton from '../../../../../components/EditButton/EditButton';
import DeleteIcon from '../../../../../img/DeleteRedIcon.svg';
import EditIcon from '../../../../../img/EditSmallIcon.svg';
import ConfirmDeleteModal from '../../../../NewSettings/atoms/ConformDeleteModal';
import styles from './Anniversary.module.css';

const ActionButtons = ({
  anniversary,
  setSelectedAnniversary,
  setOpenForm,
  deleteAnniversary,
}) => {
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const onConfirm = () => {
    setOpenConfirmModal(false);
    deleteAnniversary(anniversary.id);
  };

  return (
    <div className={styles['edit-btn-container']}>
      <EditButton
        icon={EditIcon}
        title='settings_edit'
        onClick={() => {
          setOpenForm(true);
          setSelectedAnniversary(anniversary);
        }}
      />
      <EditButton
        icon={DeleteIcon}
        title='delete'
        onClick={() => setOpenConfirmModal(true)}
      />
      {openConfirmModal && (
        <ConfirmDeleteModal
          open={openConfirmModal}
          handleClose={() => setOpenConfirmModal(false)}
          deletePayment={onConfirm}
          title='anniversary'
        />
      )}
    </div>
  );
};

export default ActionButtons;
