import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NextPhotoPointer from '../../../../../img/OrangePointerNext.svg';
import PrevPhotoPointer from '../../../../../img/OrangePointerPrev.svg';
import EmptyDot from '../../../../../img/EmptyDot.svg';
import styles from './SelectedVideos.module.css';
import OrangeDot from '../../../../../img/FilledDot.svg';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import {
  setEventPhotoIndex,
  setEditEventPhotoIndex,
  addLifeEventVideos,
} from '../../../../../redux/actions';
import CloseIcon from '@mui/icons-material/Close';
import CropIcon from '@mui/icons-material/Crop';
import isEmpty from 'lodash.isempty';
import ReactPlayer from 'react-player';
import showUserNotification from '../../../../../components/UserNotification/showUserNotification';

const SelectedVideos = () => {
  const dispatch = useDispatch();
  const inputFile = useRef();
  const videos = useSelector((state) => state.grave.lifeEventVideos);
  const [videoIndex, setVideoIndex] = useState(0);

  const [touchStartX, setTouchStartX] = useState(null);

  const prevPhoto = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (videoIndex === 0) {
      setVideoIndex(videos.length - 1);
    } else {
      setVideoIndex(videoIndex - 1);
    }
  };

  const nextPhoto = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (videoIndex === videos.length - 1) {
      setVideoIndex(0);
      return 0;
    } else {
      setVideoIndex(videoIndex + 1);
    }
  };

  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchEnd = (e) => {
    const touchEndX = e.changedTouches[0].clientX;
    const touchDistance = touchEndX - touchStartX;

    const swipeThreshold = 50;

    if (Math.abs(touchDistance) > swipeThreshold) {
      if (touchDistance > 0) {
        prevPhoto();
      } else {
        nextPhoto();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchend', handleTouchEnd);

    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, [videoIndex]);

  const handelChangeVideo = (event, index) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setVideoIndex(index);
  };

  const handelStopPagination = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const handelRemoveVideo = () => {
    if (videos[videoIndex]) {
      const id = videos[videoIndex].id;
      const newArray = videos.filter((video) => video.id !== id);
      if (videoIndex > 0) {
        setVideoIndex(videoIndex - 1);
      }
      dispatch(addLifeEventVideos(newArray));
    }
  };

  return (
    <div className={styles['grave-picture-gallery_photos-photo']}>
      {videos.length > 0 && (
        <ReactPlayer
          url={
            videoIndex === 0 ? videos[0].file_url : videos[videoIndex].file_url
          }
          className={styles['video-player']}
          controls
        />
      )}
      {videos.length > 1 && (
        <React.Fragment>
          <button
            className={`${styles['buttons-prev']} ${styles['show-buttons']}`}
            onClick={(event) => prevPhoto(event)}
          >
            <img loading='lazy' src={PrevPhotoPointer} alt='PrevPhotoPointer' />
          </button>
          <button
            className={`${styles['buttons-next']} ${styles['show-buttons']}`}
            onClick={(event) => nextPhoto(event)}
          >
            <img loading='lazy' src={NextPhotoPointer} alt='PrevPhotoPointer' />
          </button>
        </React.Fragment>
      )}
      <div className={styles['image-actions']}>
        <React.Fragment>
          <button onClick={() => handelRemoveVideo()}>
            <CloseIcon style={{ fontSize: '16px', color: '#404D56' }} />
          </button>
        </React.Fragment>
      </div>
    </div>
  );
};

export default SelectedVideos;
