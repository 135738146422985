import React from 'react';
import styles from './DiscountsInfo.module.css';
import DiscountInfoList from './DiscountInfoList';

const DiscountInfo = ({ discountsList, discount }) => {
  return (
    <span className={styles['discount-info']}>
      <DiscountInfoList discountsList={discountsList} />
      NOK {discount.toFixed(2)}
    </span>
  );
};

export default DiscountInfo;
