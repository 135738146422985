import React from 'react';
import { Modal } from '@mui/material';
import { makeStyles } from '@mui/styles';
import X from '../../img/X.svg';
import './AuthModal.css';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(( ) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    position: 'relative',
    padding: '32px',
    maxWidth: '600px',
    height: 'maxContent',
    backgroundColor: 'white',
    borderRadius: '10px',
    outline: 'none',
    '@media (max-width :720px)': {
      maxWidth: '100%',
      borderRadius: '0px',
    },
  },
  modalBackground: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}));

const AuthModal = (props) => {
  const isOpen = props.isOpen;
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Modal
      open={isOpen}
      className={classes.modal}
      BackdropProps={{
        className: classes.modalBackground,
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.modalContent}>
        <div className='downgrade-plan-prompt_body_subheading'>
          <FormattedMessage id='auth_modal_heading' />
          {props.title}
        </div>
        <img
          src={X}
          onClick={() => props.handleClose()}
          alt=''
          className='downgrade-plan-prompt_cancel'
        />
        <div className='downgrade-plan-prompt_body_content'>
          <span>
            <FormattedMessage id='auth_modal_description' />
          </span>
        </div>
        <div className='btn-container'>
          <div className='auth-modal_footer'>
            <button
              className='sign-in-options login_btn'
              onClick={() => navigate('/auth/sign-in')}
            >
              <FormattedMessage id='auth_login' />
            </button>

            <button
              className='sign-in-options register_btn'
              onClick={() => navigate('/registration')}
            >
              <FormattedMessage id='auth_register' />
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AuthModal;
