import { Button, Menu, MenuItem } from '@mui/material';
import React, { useRef, useState } from 'react';
import styles from './MemoriesButtonsGroup.module.css';
import Play from '../../../../img/Play.svg';
import NounPicture from '../../../../img/NounPicture.svg';
import NounMusic from '../../../../img/NounMusic.svg';
import { useDispatch, useSelector } from 'react-redux';
import { addCommentOnToggle } from '../../../../redux/actions';
import UploadPhoto from '../../../../img/UploadPhoto1.svg';
import MusicLinkMenu from '../../../../img/MusicLinkMenu.svg';
import ImageGallery from '../../../../img/ImageGallery.svg';
import YoutubeIcon from '../../../../img/youtube.svg';
import AudioIcon from '../../../../img/AudioIcon.svg';
import VideoIcon from '../../../../img/videoIcon.svg';

import showUserNotification from '../../../../components/UserNotification/showUserNotification';

const MemoriesButtonsGroup = (props) => {
  const bigFile = () =>
    showUserNotification(
      'Last opp en fil som ikke er større enn 5MB',
      'warning'
    );
  const inputFile = useRef();
  const currentComment = useSelector((state) => state.grave.commentOnToggle);
  const dispatch = useDispatch();
  const [anchorPhotoMenu, setAnchorPhotoMenu] = useState(null);
  const [anchorMusicMenu, setAnchorMusicMenu] = useState(null);
  const [achorVideoMenu, setAnchorVideoMenu] = useState(null);
  const isPostButtonDisabled = props.isPostButtonDisabled;
  const [openImageModal, setOpenImageModal] = useState(false);

  const handleOpenImageModal = () => setOpenImageModal(true);
  const handleCloseImageModal = () => setOpenImageModal(false);

  const handleOpenVideoMenu = (event) => {
    setAnchorVideoMenu(event.currentTarget);
  };

  const handleCloseVideoMenu = () => {
    setAnchorVideoMenu(null);
  };

  const handleOpenMusicMenu = (event) =>
    setAnchorMusicMenu(event.currentTarget);
  const handleCloseMusicMenu = () => setAnchorMusicMenu(null);

  const handleOpenPhotoMenu = (event) => {
    setAnchorPhotoMenu(event.currentTarget);
  };

  const handleClosePhotoMenu = () => {
    setAnchorPhotoMenu(null);
  };

  const clickOnHideInput = () => inputFile.current.click();

  // const selectTheImage = (e) => {
  //   const photosOnToogle = currentComment.photos ? currentComment.photos : [];
  //   if (e.target.files && e.target.files.length > 0) {
  //     const reader = new FileReader();
  //     const file = e.target.files[0];
  //     reader.readAsDataURL(file);
  //     reader.addEventListener('load', () => {
  //       if (file.size / (1024 * 1024) < 5) {
  //         const newPhoto = {
  //           image: reader.result,
  //           id: Date.now().toString(),
  //           file: file,
  //         };
  //         props.addPhoto(newPhoto);
  //         dispatch(
  //           addCommentOnToggle({
  //             ...currentComment,
  //             photos: [...photosOnToogle, newPhoto],
  //           })
  //         );
  //       } else {
  //         bigFile();
  //       }
  //     });
  //   }
  // };

  const selectTheImages = (e) => {
    const photosOnToogle = currentComment.photos ? currentComment.photos : [];
    if (e.target.files && e.target.files.length > 0) {
      const imagesArray = Array.from(e.target.files);
      const selectedImages = [];

      const processImage = (file, result) => {
        if (file.size / (1024 * 1024) <= 20) {
          selectedImages.push({
            image: result,
            id: Date.now().toString(),
            file: file,
          });
        } else {
          bigFile();
        }
        if (selectedImages.length === imagesArray.length) {
          props.addPhoto(selectedImages);
        }
      };

      imagesArray.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (event) => processImage(file, event.target.result);
        reader.readAsDataURL(file);
      });
    }
  };

  return (
    <div className={styles['memories-buttons']}>
      {/* <ToastContainer position='bottom-right' hideProgressBar={true} autoClose={3000}/> */}
      {!props.isUploading && (
        <div className={styles['memories-buttons-group']}>
          <input
            ref={inputFile}
            multiple
            type='file'
            className={styles['cropper-modal_content-input-image_image-input']}
            accept='image/jpeg, image/jpg, image/png'
            onChange={selectTheImages}
          />

          <div className={styles['memories-buttons-group_btn']}>
            <img
              loading='lazy'
              src={ImageGallery}
              alt='NounPicture'
              className={styles['memories-buttons-group_btn-video']}
              onClick={props.openImageModal}
            />
            <img
              loading='lazy'
              src={VideoIcon}
              alt='Play'
              onClick={props.openVideoModal}
              className={styles['memories-buttons-group_btn-video']}
            />
            <img
              loading='lazy'
              src={AudioIcon}
              alt='NounMusic'
              className={styles['memories-buttons-group_btn-video']}
              onClick={props.openMusicModal}
            />
            <Button
              onClick={handleOpenPhotoMenu}
              className={styles['memories-buttons-group_btn-video-mobile']}
            >
              <img loading='lazy' src={ImageGallery} alt='NounPicture' />
            </Button>
            <Button
              onClick={handleOpenVideoMenu}
              className={styles['memories-buttons-group_btn-video-mobile']}
            >
              <img loading='lazy' src={VideoIcon} alt='Play' />
            </Button>
            <Button
              onClick={handleOpenMusicMenu}
              className={styles['memories-buttons-group_btn-video-mobile']}
            >
              <img loading='lazy' src={AudioIcon} alt='NounMusic' />
            </Button>
            <Menu
              id='simple-menu'
              anchorEl={anchorPhotoMenu}
              keepMounted
              open={Boolean(anchorPhotoMenu)}
              onClose={handleClosePhotoMenu}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            >
              {/* <MenuItem>
              <div className={styles['photo-menu_simple-menu']}>
                <img loading='lazy' src={CreatePhoto} alt='create-photo'/>
                <span>Create photo</span>
              </div>
            </MenuItem> */}
              <MenuItem
                onClick={() => {
                  props.openImageModal();
                  handleClosePhotoMenu();
                }}
              >
                <div className={styles['photo-menu_simple-menu']}>
                  <img loading='lazy' src={UploadPhoto} alt='create' />
                  <span>Trykk her for å laste opp</span>
                </div>
              </MenuItem>
            </Menu>
            <Menu
              id='simple-menu1'
              anchorEl={anchorMusicMenu}
              keepMounted
              open={Boolean(anchorMusicMenu)}
              onClose={handleCloseMusicMenu}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            >
              <MenuItem
                onClick={() => {
                  props.openMusicModal();
                  handleCloseMusicMenu();
                }}
              >
                <div className={styles['photo-menu_simple-menu']}>
                  <img loading='lazy' src={UploadPhoto} alt='create' />
                  <span>Legg til musikk</span>
                </div>
              </MenuItem>
              {/* Doesn't work right now */}
              {/* <MenuItem onClick={() => {props.openMusicModalMobile(); handleCloseMusicMenu()}}>
              <div className={styles[]}'photo-menu_simple-menu'>
                <img loading='lazy' src={MusicLinkMenu} alt='create-photo'/>
                <span>add Music link</span>
              </div>
            </MenuItem> */}
            </Menu>
            <Menu
              id='simple-menu2'
              anchorEl={achorVideoMenu}
              keepMounted
              open={Boolean(achorVideoMenu)}
              onClose={handleCloseVideoMenu}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            >
              {/* <MenuItem>
              <div className={styles[]}'photo-menu_simple-menu'>
                <img loading='lazy' src={UploadPhoto} alt='create'/>
                <span>Upload Video</span>
              </div>
            </MenuItem> */}
              <MenuItem
                onClick={() => {
                  props.openVideoModal();
                  handleCloseVideoMenu();
                }}
              >
                <div className={styles['photo-menu_simple-menu']}>
                  <img loading='lazy' src={MusicLinkMenu} alt='create' />
                  <span>Legg til link</span>
                </div>
              </MenuItem>
            </Menu>
          </div>
          <button
            disabled={isPostButtonDisabled}
            className={styles['memories-buttons-group_post']}
            onClick={props.addPostToMemories}
          >
            Publiser
          </button>
        </div>
      )}
    </div>
  );
};

export default MemoriesButtonsGroup;
