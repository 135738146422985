import React from 'react';
import IframeComponent from '../../../components/Iframe/IframeComponent';

const FeaturesMaintenanceFree = () => {
  return (
    <IframeComponent
      title='Features Maintenance Free grave'
      source='https://www.wix.nettgrav.no/vedlikeholdsfri-grav'
    />
  );
};

export default FeaturesMaintenanceFree;
