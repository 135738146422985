import React from 'react';
import { Avatar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import './UserInfoHeader.css'
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Everyone from '../../../img/Everyone.svg';
import OnlyMe from '../../../img/OnlyMe.svg';
import {useNavigate} from 'react-router-dom'
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import InputLabel from '@mui/material/InputLabel';

const useStyles = makeStyles(( ) => ({
  large: {
    width:  (7),
    height:  (7),
    margin: "10px auto",
    border: "1px solid #D8D8D8",
  },
  small: {
    width:  (5),
    height:  (5),
    margin: "10px auto",
    border: "1px solid #D8D8D8",
  },
  iconMenu: {
    cursor: "pointer !important",
  },
}));

const UserInfoHeader = (props) => {
  const classes = useStyles();
  const author = props.author;
  const navigate = useNavigate();

  const handlePrivacyChange = (e) => {
    props.setPrivacy(e.target.value)
  }
  
  return(
    <div className="user-info-container">
        <div className="user-info_avatar-wrapper">
          <Avatar
            src={author.avatar}
            className={classes.small}
            variant="rounded"
            onClick={() => navigate(`/member/${author.slug}`)}
          />
        </div>
        <div className="user-info_name">
          <span>{author.full_name}</span>
        </div>
        {props.showPrivacy && 
          <div className="question-privacy">
            <FormControl className="privacy-question-box">
              <Select
                className='privacy-select'
                value={props.privacy}
                onChange={event => handlePrivacyChange(event)}
              >
                <MenuItem value='public'>
                  <img loading='lazy'
                    src={Everyone}
                    alt="Family Silhouette"
                    className='memories-header_right-icon'
                  />
                  Alle
                </MenuItem>
                <MenuItem value='relatives'>
                  <img loading='lazy'
                    src={OnlyMe}
                    alt="Family Silhouette"
                    className='memories-header_right-icon'
                    style ={{marginRight: "8px", marginLeft: "3px"}}
                  />
                  Privat
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        }
      </div>
  )
}

export default UserInfoHeader;