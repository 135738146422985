import React from 'react';
import IframeComponent from '../../../components/Iframe/IframeComponent';

const FeaturesMemoryAlbum = () => {
  return (
    <IframeComponent
      title='Features memory album'
      source='https://www.wix.nettgrav.no/features-minnealbum'
    />
  );
};

export default FeaturesMemoryAlbum;
