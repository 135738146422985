import React from 'react';
import styles from './TermsAndCondition.module.css';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

const TermsAndCondition = ({ user, isCheckedTerms, isChecked }) => {
  return (
    <div>
      <div className={styles['terms']}>
        <div className={styles['name']}>
          <FormattedMessage id='dear' /> {user ? user.full_name : ''}
        </div>
        <div className={styles['text']}>
          <FormattedMessage id='claim_grave_terms_1' />
        </div>
        <div className={styles['text']}>
          <FormattedMessage id='claim_grave_terms_2' />
        </div>
        <div className={styles['text']}>
          <FormattedMessage id='claim_grave_terms_3' />
        </div>
        <div className={styles['text']}>
          <FormattedMessage id='claim_grave_terms_4' />
        </div>
      </div>
      <div className={styles['accept-container']}>
        <input
          type='checkbox'
          onChange={() => isCheckedTerms(!isChecked)}
          checked={isChecked}
        />
        <div className={styles['label']}>
          <FormattedMessage id='i_agreed_claim' />
        </div>
      </div>
    </div>
  );
};

export default TermsAndCondition;
