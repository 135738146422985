import React from 'react'
import "./IconButton.css";

const IconButton = (props) => {

  return (
    <div className='icon-button' onClick={props.handleClick}>
      <img loading='lazy' src={props.icon} alt="icon-button" />
    </div>
  )
};

export default IconButton;