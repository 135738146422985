import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './BackButton.module.css';
import { FormattedMessage } from 'react-intl';
import BackIcon from '../../img/BackIcon.svg';

const BackButton = ({ fallBackUrl, style }) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div
      className={styles['container']}
      style={style}
      onClick={() => {
        if (location.key) {
          navigate(-1);
        } else {
          navigate(`${fallBackUrl}`);
        }
      }}
    >
      <img src={BackIcon} alt='back' />
      <span className={styles['title']}>
        <FormattedMessage id='auth_back' />
      </span>
    </div>
  );
};

export default BackButton;
