import { gql } from '@apollo/client';

export const removeReactionCache =
  (sparkId) =>
  (cache, { data: { destroyReaction } }) => {
    const removedReactionId = destroyReaction?.id;

    if (removedReactionId) {
      cache.modify({
        fields: {
          sparks(existingSparks = { edges: [], pageInfo: {} }) {
            const updatedEdges = existingSparks.edges.map((edge) => {
              const nodeData = cache.readFragment({
                id: edge.node.__ref,
                fragment: gql`
                  fragment SparkDetails on Spark {
                    id
                    reactionsCount
                    totalReactionsCount
                    reaction {
                      reactionType
                    }
                  }
                `,
              });

              if (nodeData?.id === sparkId) {
                const { reactionsCount = {}, reaction } = nodeData;
                const previousReactionType = reaction?.reactionType;

                if (
                  previousReactionType &&
                  reactionsCount[previousReactionType]
                ) {
                  const updatedReactionsCount = { ...reactionsCount };
                  updatedReactionsCount[previousReactionType] -= 1;

                  if (updatedReactionsCount[previousReactionType] === 0) {
                    delete updatedReactionsCount[previousReactionType];
                  }

                  const finalReactionsCount =
                    Object.keys(updatedReactionsCount).length > 0
                      ? updatedReactionsCount
                      : null;

                  cache.writeFragment({
                    id: edge.node.__ref,
                    fragment: gql`
                      fragment UpdateSpark on Spark {
                        id
                        reactionsCount
                        totalReactionsCount
                        reaction {
                          reactionType
                        }
                      }
                    `,
                    data: {
                      id: nodeData.id,
                      reactionsCount: finalReactionsCount,
                      totalReactionsCount: nodeData.totalReactionsCount - 1,
                      reaction: null,
                    },
                  });
                  return edge;
                }
              }

              return edge;
            });

            return {
              ...existingSparks,
              edges: updatedEdges,
            };
          },
        },
      });
    }
  };
