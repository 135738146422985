import React from 'react';
import { Link } from 'react-router-dom';
import NoneGravesImage from '../../../../../img/NoneGravesImage.svg';
import AddGraveImage from '../../../../../img/AddGraveImage.svg';
import Grave from '../../../../../containers/Search/atoms/Grave';
import { FormattedMessage } from 'react-intl';

const Content = (props) => {
  const searchInput = props.searchInputValue || '';

  return (
    <>
      {props.list && props.list.length === 0 ? (
        <>
          <div className='municipality-not-found'>
            <img src={NoneGravesImage} alt='not-found' />
            <span className='search-graves_none-text'>
              <FormattedMessage id='no_results' /> <span>"{searchInput}"</span>
            </span>
          </div>

          <Link to='/ny-grav' className='no-grave-found-link'>
            <img loading='lazy' src={AddGraveImage} alt='Opprett ny grav' />
            <span>Opprett ny grav</span>
          </Link>
        </>
      ) : (
        <div className='municipality-search-content'>
          {props.list &&
            props.list.map((item) => {
              return <Grave grave={item} style={{ width: 'auto' }} />;
            })}
        </div>
      )}
    </>
  );
};

export default Content;
