import React from 'react';
import CustomModal from '../../../../components/CustomModal';
import { FormattedMessage } from 'react-intl';
import styles from './GraveUserForm.module.css';

const WarningModal = ({ open, close }) => {
  return (
    <CustomModal
      isOpen={open}
      closeModal={close}
      style={{
        width: '530px',
      }}
      title={<FormattedMessage id='remove_all_grave_user' />}
      children={
        <div className={styles['warning-message']}>
          <FormattedMessage id='remove_all_grave_user_message' />
        </div>
      }
    />
  );
};

export default WarningModal;
