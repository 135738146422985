import React, { useEffect, useState, useCallback } from 'react';
import styles from './InviteRelativesForm.module.css';
import DiscardModal from '../DiscardPopupModal/DiscardModal';
import SavedPopupModal from '../SavedPopupModal/SavedPopupModal';
import { request } from '../../../../service/request';
import DeleteIcon from '../../../../img/DeleteRedIcon.svg';
import EditIcon from '../../../../img/EditSmallIcon.svg';
import ConformModal from '../../../NewSettings/atoms/ConformDeleteModal';
import showUserNotification from '../../../../components/UserNotification/showUserNotification';
import isEmpty from 'lodash.isempty';
import UpgradePlanModal from '../../../../components/Modals/UpgradePlanModal';
import { ProductKeys } from '../../../../service/constants';
import EditButton from '../../../../components/EditButton/EditButton';
import InputGroup from '../../../../components/InputGroup/InputGroup';
import debounce from 'lodash.debounce';
import { FormattedMessage } from 'react-intl';
import CopyInvitationLink from '../../../NewSettings/atoms/GiftSubscriptionForm/CopyInvitationLink/CopyInvitationLink';
import { font_end_env } from '../../../../service/constants';

const InviteRelativesForm = ({
  graveInvitation,
  graveInvitations,
  invitationId,
  grave,
  disable,
  handelInvitationId,
  handelGraveInvitations,
  handleTabChange,
  relationList,
  directionsList,
  currentSubscription,
  onClose,
}) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDiscardModal, setOpenDiscardModal] = useState(false);
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [copied, setCopied] = useState(false);
  const baseUrl = font_end_env();
  const [editRelation, setEditRelation] = useState({
    id: '',
    invitation_accepted_at: '',
    invitation_token: '',
    invited_at: '',
    grave: {
      id: '',
      name: '',
      slug: '',
      avatar: '',
    },
    inviting_user: {
      full_name: '',
      id: '',
      slug: '',
      user_avatar: '',
    },
    admin: {},
    reciprocal_relation: {},
    relation: {},
    user: {
      full_name: '',
      email: '',
      phone_number: '',
      id: '',
      slug: '',
      user_avatar: '',
    },
  });
  const [openGraveConnectionModal, setOpenGraveConnectionModal] =
    useState(false);

  const [formErrors, setFormErrors] = useState({
    reciprocal_relation: false,
    full_name: false,
    email: false,
    relation: false,
    admin: false,
  });

  const adminOptions = [
    {
      id: 1,
      key: 'administrator',
      name: 'Administrator',
    },
    { id: 2, key: 'normal', name: 'Normal' },
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      setCopied(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, [copied]);

  useEffect(() => {
    if (!isEmpty(graveInvitation)) {
      if (graveInvitation.admin) {
        setEditRelation({ ...graveInvitation, admin: adminOptions[0] });
      } else {
        setEditRelation({ ...graveInvitation, admin: adminOptions[1] });
      }
    }
  }, [graveInvitation]);

  const handleSelectRole = (event, value, reason) => {
    if (reason === 'clear') {
      setEditRelation((prev) => ({
        ...prev,
        admin: {},
      }));
    } else if (reason === 'selectOption') {
      setEditRelation((prev) => ({
        ...prev,
        admin: value,
      }));
      setIsChanged(true);
      setFormErrors((prev) => ({
        ...prev,
        admin: false,
      }));
    }
  };

  const handleOnSelectRelation = (event, value, reason) => {
    if (reason === 'clear') {
      setEditRelation((prev) => ({
        ...prev,
        relation: {},
      }));
    } else if (reason === 'selectOption') {
      setEditRelation((prev) => ({
        ...prev,
        relation: value,
      }));
      setFormErrors((prev) => ({
        ...prev,
        relation: false,
      }));
      setIsChanged(true);
    }

    setEditRelation((prev) => ({
      ...prev,
      reciprocal_relation: {},
    }));
  };

  const handleOnSelectReciprocalRelation = (event, value, reason) => {
    if (reason === 'clear') {
      setEditRelation((prev) => ({
        ...prev,
        reciprocal_relation: {},
      }));
    } else if (reason === 'selectOption') {
      setEditRelation((prev) => ({
        ...prev,
        reciprocal_relation: value,
      }));
      setIsChanged(true);
      setFormErrors((prev) => ({
        ...prev,
        reciprocal_relation: false,
      }));
    }
  };

  const deleteRelation = () => {
    if (editRelation?.id) {
      request(`/grave_invited_users/${editRelation.id}`, null, 'delete')
        .then((res) => {
          const newArray = graveInvitations.filter(
            (relation) => relation.id !== editRelation.id
          );
          handelGraveInvitations(newArray);
          setOpenDeleteModal(false);
          showUserNotification('invitasjonen er slettet', 'success');
        })
        .catch((err) => {
          showUserNotification(err.response.data.error || err.message, 'error');
        });
      setOpenDeleteModal(false);
    }
  };

  const closeDiscardModal = () => {
    setOpenDiscardModal(false);
    setIsChanged(false);
    if (Object.keys(graveInvitation).length > 0) {
      setEditRelation({ ...graveInvitation });
      handelInvitationId(null);
    } else {
      clearRelation();
      onClose();
      handelInvitationId(null);
    }
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const closeSaveModal = () => {
    setOpenSaveModal(false);
    handelInvitationId(null);
    handleTabChange();
  };

  const validateEmail = (email) => {
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
  };

  const debouncedValidate = useCallback(
    debounce((value) => {
      if (validateEmail(value)) {
        setIsValidEmail(false);
      } else {
        setIsValidEmail(true);
      }
    }, 500),
    []
  );

  const handleEmailChange = (value) => {
    setEditRelation({
      ...editRelation,
      user: {
        ...editRelation.user,
        email: value,
      },
    });
    if (value.length > 0) {
      setIsChanged(true);
    }
    setFormErrors((prev) => ({ ...prev, email: false }));
    debouncedValidate(value);
  };

  const validateForm = () => {
    const { user, admin, relation, reciprocal_relation } = editRelation;

    const errors = {
      full_name: !user?.full_name,
      email: !user?.email.length > 0 || isValidEmail,
      relation: isEmpty(relation),
      reciprocal_relation: isEmpty(reciprocal_relation),
      admin: isEmpty(admin),
    };
    setFormErrors(errors);
    return !Object.values(errors).some((error) => error);
  };

  const createNewRelative = async () => {
    if (invitationId) {
      const isAdmin = editRelation.admin.key === 'administrator';
      const inviteRelation = {
        grave_invitation: {
          full_name: editRelation.user?.full_name,
          email: editRelation.user?.email,
          admin: isAdmin,
          relation_id: editRelation.relation.id,
          reciprocal_relation_id: editRelation.reciprocal_relation.id,
          grave_id: grave.id,
        },
      };
      request(`/grave_invitations/${invitationId}`, inviteRelation, 'put')
        .then((res) => {
          setOpenSaveModal(true);
          handelGraveInvitations(
            graveInvitations.map((w) =>
              w.user_id === invitationId
                ? {
                    ...res.data,
                  }
                : w
            )
          );
        })
        .catch((err) => {
          showUserNotification(
            err.response.data.error || err.message,
            'warning'
          );
        });
    } else {
      if (!validateForm()) {
        return;
      }
      const isAdmin = editRelation.admin.key === 'administrator';
      const inviteRelation = {
        grave_invitation: {
          full_name: editRelation.user?.full_name,
          email: editRelation.user?.email,
          admin: isAdmin,
          relation_id: editRelation.relation.id,
          reciprocal_relation_id: editRelation.reciprocal_relation.id,
          grave_id: grave.id,
        },
      };
      request(`/grave_invitations`, inviteRelation, 'post')
        .then((res) => {
          handelGraveInvitations([res.data, ...graveInvitations]);
          setOpenSaveModal(true);
          clearRelation();
        })
        .catch((error) => {
          showUserNotification(
            error.response.data.error || error.message,
            'error'
          );
        });
    }
  };

  const clearRelation = () => {
    setEditRelation({
      id: '',
      invitation_accepted_at: '',
      invitation_token: '',
      invited_at: '',
      grave: {
        id: '',
        name: '',
        slug: '',
        avatar: '',
      },
      inviting_user: {
        full_name: '',
        id: '',
        slug: '',
        user_avatar: '',
      },
      admin: {},
      reciprocal_relation: {},
      relation: {},
      user: {
        full_name: '',
        email: '',
        phone_number: '',
        id: '',
        slug: '',
        user_avatar: '',
      },
    });
  };

  const filterDirectionList = (selectedRelation) => {
    if (isEmpty(selectedRelation)) return [];

    let filteredList = [];

    directionsList.map((item) => {
      if (item.from_relation.id === selectedRelation.id) {
        filteredList.push(item.to_relation);
      }

      return false;
    });

    if (filteredList.length === 0) {
      return [selectedRelation];
    } else {
      return filteredList;
    }
  };

  const getUserInformation = () => {
    if (editRelation.user?.email.trim() !== '' && !isValidEmail) {
      const userData = {
        email: editRelation.user?.email,
      };
      request('/users/find_by_email', userData, 'post')
        .then((res) => {
          if (res.data.full_name) {
            setEditRelation({
              ...editRelation,
              user: {
                ...editRelation.user,
                full_name: res.data.full_name,
              },
            });
            setFormErrors((prev) => ({ ...prev, full_name: false }));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  let formInputs = [
    {
      label: 'settings_billingEmail',
      type: 'email',
      placeholder: 'type_email',
      value: editRelation.user?.email,
      error: formErrors.email || isValidEmail,
      errorKey: 'email_error',
      disabled: editRelation?.invitation_token,
      onBlur: getUserInformation,
      action: (value) => {
        handleEmailChange(value);
      },
    },
    {
      label: 'invited_person_name',
      type: 'text',
      placeholder: 'invited_person_name',
      value: editRelation.user?.full_name,
      autoComplete: 'off',
      error: formErrors.full_name,
      errorKey: 'enter_name_error',
      disabled: !disable,
      action: (value) => {
        if (value[0] !== ' ') {
          setEditRelation((prev) => ({
            ...prev,
            user: {
              ...prev.user,
              full_name: value
                .replace(/[0-9]/g, '')
                .replace(/[\"'@#!$%^&*()_+|~=`{}\[\]:;'<>?,.\/]/g, ''),
            },
          }));
          setIsChanged(true);
          setFormErrors((prev) => ({
            ...prev,
            full_name: false,
          }));
        }
      },
    },

    {
      label: 'relation_to_grave',
      type: 'autocomplete',
      placeholder: 'select_relationship',
      value: editRelation.relation,
      options: relationList,
      autoComplete: 'off',
      error: formErrors.relation,
      errorKey: 'select_relation_error',
      disabled: !disable,
      action: handleOnSelectRelation,
    },
    {
      label: 'relation_to_person',
      type: 'autocomplete',
      placeholder: 'select_relationship',
      value: editRelation.reciprocal_relation,
      options: filterDirectionList(editRelation.relation),
      autoComplete: 'off',
      error: formErrors.reciprocal_relation,
      errorKey: 'select_relation_error',
      disabled: !disable,
      action: handleOnSelectReciprocalRelation,
    },
    {
      label: 'administrator_access',
      type: 'autocomplete',
      placeholder: 'select_role',
      value: editRelation.admin,
      options: adminOptions,
      autoComplete: 'off',
      error: formErrors.admin,
      errorKey: 'select_role_error',
      disabled: !disable,
      action: handleSelectRole,
    },
  ];

  const onEdit = () => {
    handelInvitationId(editRelation?.id);
  };

  return (
    <>
      {openGraveConnectionModal && (
        <UpgradePlanModal
          isOpen={openGraveConnectionModal}
          onClose={() => setOpenGraveConnectionModal(false)}
          body='inviting_body'
          title='upgrade_modal_heading'
        />
      )}
      <div>
        {!disable && (
          <div className={styles['grave-actions-container']}>
            <div className={styles['grave-information-edit-delete-container']}>
              <EditButton
                title='settings_edit'
                onClick={onEdit}
                icon={EditIcon}
              />
              <EditButton
                title='delete'
                onClick={() => setOpenDeleteModal(true)}
                icon={DeleteIcon}
              />
            </div>
          </div>
        )}
        <InputGroup inputs={[formInputs[0], formInputs[1]]} />
        <InputGroup inputs={[formInputs[2], formInputs[3]]} />
        <InputGroup inputs={[formInputs[4]]} />
        {editRelation?.invitation_token &&
          editRelation.invitation_token.trim() !== '' && (
            <div className={styles['Link-container']}>
              <div className={styles['link-wrapper']}>
                <div className={styles['link-sub-heading']}>
                  <FormattedMessage id='grave_invitation_shareLink' />
                </div>
                {copied && (
                  <div className={styles['copy-link']}>
                    <FormattedMessage id='settings_copyToClipboard' />
                  </div>
                )}
              </div>
              <CopyInvitationLink
                handelSetCopied={(value) => setCopied(value)}
                link={`${baseUrl}/grave/${grave?.slug}/invitation?token=${editRelation.invitation_token}`}
              />
            </div>
          )}
        {disable && (
          <div className={styles['grave-btn-container']}>
            <div className={styles['grave-information_content-verify']}>
              <button
                type='button'
                className={styles['rootDiscard']}
                onClick={() => {
                  if (isChanged) {
                    setOpenDiscardModal(true);
                  } else {
                    closeDiscardModal();
                  }
                }}
              >
                Avbryt
              </button>
              <button
                className={styles['rootVerify']}
                type='submit'
                onClick={() => {
                  if (
                    currentSubscription &&
                    currentSubscription.product.key === ProductKeys.free
                  ) {
                    setOpenGraveConnectionModal(true);
                  } else {
                    createNewRelative();
                  }
                }}
              >
                Lagre
              </button>
            </div>
          </div>
        )}
      </div>
      {openDiscardModal && (
        <DiscardModal
          open={openDiscardModal}
          handleClose={(value) => setOpenDiscardModal(value)}
          onConfirm={closeDiscardModal}
          title='relative'
        />
      )}
      {openSaveModal && (
        <SavedPopupModal
          open={openSaveModal}
          titleKey='relation'
          handleClose={closeSaveModal}
        />
      )}
      {openDeleteModal && (
        <ConformModal
          title='relative'
          open={openDeleteModal}
          handleClose={closeDeleteModal}
          deletePayment={deleteRelation}
        />
      )}
    </>
  );
};

export default InviteRelativesForm;
