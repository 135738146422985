import React, { useState, useEffect } from 'react';
import CustomModal from '../../../../components/CustomModal';
import { FormattedMessage } from 'react-intl';
import SelectedMethod from './SelectedMethod';
import { PaymentType } from '../../../../service/constants';
import { request } from '../../../../service/request';

const AddPaymentMethod = ({
  open,
  close,
  user,
  countries,
  fetchSubscriptionData,
  currentSubscription,
  handleCreatePaymentMethod,
  paymentMethod,
  agreementHash,
}) => {
  const [selectedMethod, setSelectedMethod] = useState(PaymentType.vipps);
  const [vippsPaymentMethods, setVippsPaymentMethods] = useState([]);
  const [cardPaymentMethods, setCardPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({});

  const userSlug =
    localStorage.getItem('user-slug') || sessionStorage.getItem('user-slug');

  const fetchPaymentMethods = async () => {
    if (userSlug) {
      try {
        request(`/users/${userSlug}/payment_methods`).then((res) => {
          const vippsMethods = res.data.filter(
            (method) => method.payment_type === PaymentType.vipps
          );
          const cards = res.data.filter(
            (method) => method.payment_type === PaymentType.card
          );
          if (cards.length > 0) {
            setCardPaymentMethods(cards);
          } else {
            setCardPaymentMethods([]);
          }
          if (vippsMethods.length > 0) {
            setVippsPaymentMethods(vippsMethods);
          } else {
            setVippsPaymentMethods([]);
          }
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    fetchPaymentMethods();
  }, [userSlug]);

  useEffect(() => {
    if (!paymentMethod.destroyed_at) {
      setSelectedPaymentMethod(paymentMethod);
    }
  }, [paymentMethod]);

  return (
    <React.Fragment>
      <CustomModal
        isOpen={open}
        closeModal={close}
        style={{ width: '800px' }}
        title={<FormattedMessage id='add_new_paymentMethod' />}
        children={
          <SelectedMethod
            handleCreatePaymentMethod={handleCreatePaymentMethod}
            selectedMethod={selectedMethod}
            setSelectedMethod={setSelectedMethod}
            user={user}
            close={close}
            countries={countries}
            currentSubscription={currentSubscription}
            vippsPaymentMethods={vippsPaymentMethods}
            cardPaymentMethods={cardPaymentMethods}
            fetchSubscriptionData={fetchSubscriptionData}
            currentPaymentMethod={paymentMethod}
            selectedPaymentMethod={selectedPaymentMethod}
            setSelectedPaymentMethod={setSelectedPaymentMethod}
            agreementHash={agreementHash}
          />
        }
      />
    </React.Fragment>
  );
};

export default AddPaymentMethod;
