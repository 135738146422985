import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './Header.module.css';
import Categories from '../Components/Categories';
import Skeleton from '@mui/material/Skeleton';

const Header = ({
  sparkCategories,
  setSparkCategory,
  sparkCategory,
  loading,
}) => {
  return (
    <div className={styles['container']}>
      <div className={styles['header']}>
        <div className={styles['title']}>
          <FormattedMessage id='sparks' />
        </div>
        {loading ? (
          <Skeleton
            animation='wave'
            height={10}
            width='80%'
            style={{ marginBottom: 6 }}
          />
        ) : (
          <Categories
            sparkCategories={sparkCategories}
            sparkCategory={sparkCategory}
            setSparkCategory={setSparkCategory}
          />
        )}
      </div>
      <div className={styles['description']}>
        <FormattedMessage id='sparks_description' />
      </div>
    </div>
  );
};

export default Header;
