import React, { useEffect, useState } from 'react';
import styles from './GraveWall.module.css';
import GraveNote from '../About/GraveNote';
import GraveNotesModal from '../GraveNotes/GraveNotesModal/GraveNotesModal';
import { useDispatch } from 'react-redux';
import { addMemory, setSubscription } from '../../../../redux/actions';
import { request } from '../../../../service/request';
import MemoryWall from './MemoryWall';
import Anniversaries from '../Anniversaries/Anniversaries';

const GraveWall = (props) => {
  const { user, grave, isAuth } = props;
  const aboutMenuKey = Number(props.aboutMenuKey);
  const dispatch = useDispatch();
  const [openNotesModal, setOpenNotesModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleCloseNotesModal = () => setOpenNotesModal(false);
  const handleOpenNotesModal = () => setOpenNotesModal(true);

  const fetchGraveMemories = async () => {
    if (!grave.id) return;
    setLoading(true);
    try {
      const newData = await request(`/memories?memory[grave_id]=${grave.id}`);
      if (newData.data.memories.length > 0) {
        dispatch(addMemory(newData.data.memories));
        dispatch(setSubscription(newData.data.subscription));
      } else {
        dispatch(addMemory([]));
        dispatch(setSubscription(newData.data.subscription));
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGraveMemories();
  }, [grave.id]);

  return (
    <div className={styles['grave-wall']}>
      {openNotesModal && (
        <GraveNotesModal
          onOpen={openNotesModal}
          onClose={handleCloseNotesModal}
          grave={grave}
        />
      )}

      {props.hideMenu && aboutMenuKey ? (
        <React.Fragment>
          <div className={styles['grave-notes']}>
            <GraveNote
              notes={grave.notes}
              canEdit={grave.can_edit}
              onEdit={handleOpenNotesModal}
            />
          </div>
          <div className={styles['grave-wall-box-container']}>
            {aboutMenuKey === 1 && (
              <MemoryWall
                isAuth={isAuth}
                grave={grave}
                user={user}
                loading={loading}
                hideMenu={props.hideMenu}
              />
            )}
            {aboutMenuKey === 2 && (
              <Anniversaries hideMenu={props.hideMenu} grave={grave} />
            )}
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className={styles['grave-notes']}>
            <GraveNote
              notes={grave.notes}
              canEdit={grave.can_edit}
              onEdit={handleOpenNotesModal}
            />
          </div>
          <div className={styles['grave-wall-container-mobile']}>
            {/* ----------------memories for mobile screen  */}
            <MemoryWall
              isAuth={isAuth}
              grave={grave}
              user={user}
              loading={loading}
              hideMenu={props.hideMenu}
            />
          </div>
          <div className={styles['grave-wall-container-mobile']}>
            <Anniversaries hideMenu={props.hideMenu} grave={grave} />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default GraveWall;
