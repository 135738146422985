import React, { useEffect, useState } from 'react';
import styles from './GraveInformation.module.css';
import { Menu, MenuItem } from '@mui/material';
import OnlyMe from '../../../../img/OnlyMe.svg';
import FamilySilhouette from '../../../../img/FamilySilhouette.svg';
import Everyone from '../../../../img/Everyone.svg';
import { fetch_request, request } from '../../../../service/request';
import DiscardModal from '../DiscardPopupModal/DiscardModal';
import SavedPopupModal from '../SavedPopupModal/SavedPopupModal';
import { goToChosenGrave } from '../../../../redux/actions';
import showUserNotification from '../../../../components/UserNotification/showUserNotification';
import { useDispatch } from 'react-redux';
import Input from '../../../../components/Input/Input';
import InputGroup from '../../../../components/InputGroup/InputGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ProcessModal from '../../../Payment/atoms/ProcessModal';
import PrimaryButton from '../../../../components/PrimaryButton';
import { FormattedMessage } from 'react-intl';

const GraveInformation = ({
  graveInformation,
  closeModal,
  isChanged,
  handelIsChanged,
  openDiscardModal,
  handelDiscardModal,
}) => {
  const dispatch = useDispatch();
  const [openProcessModal, setOpenProcessModal] = useState(false);
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const cemeteryBox = graveInformation?.plot_number?.split(/\.|:/);
  const [plotField, setPlotField] = useState(cemeteryBox ? cemeteryBox[1] : '');
  const [plotRow, setPlotRow] = useState(cemeteryBox ? cemeteryBox[2] : '');
  const [plotGrave, setPlotGrave] = useState(cemeteryBox ? cemeteryBox[3] : '');
  const [reasonDeathList, setReasonDeathList] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [municipalitiesList, setMunicipalitiesList] = useState([]);
  const [cemetaryList, setCemeteryList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [graveData, setGraveData] = useState({
    name: '',
    nickName: '',
    birthName: '',
    gender: '',
    locationOfBirth: '',
    locationOfDeath: '',
    reasonDeath_id: '',
    plotCemetery: '',
    timeOfBirth: '',
    timeOfDeath: '',
    dateOfBirth: '',
    dateOfDeath: '',
    privacy: '',
    country_id: '',
    municipality_id: '',
    cemetary_id: '',
  });

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (value) => {
    setGraveData({ ...graveData, privacy: value });
    handleClose();
  };

  let graveInputs = [
    {
      label: 'settings_name',
      type: 'text',
      placeholder: 'settings_name',
      value: graveData.name,
      error: false,
      disabled: false,
      action: (value) => {
        if (value[0] !== ' ') {
          handelIsChanged(true);
          setGraveData({
            ...graveData,
            name: value
              .replace(/[0-9]/g, '')
              .replace(/[\"'@#!$%^&*()_+|~=`{}\[\]:;'<>?,.\/]/g, ''),
          });
        }
      },
    },
    {
      label: 'nick_name',
      type: 'text',
      placeholder: 'nick_name',
      value: graveData.nickName,
      error: false,
      disabled: false,
      action: (value) => {
        if (value[0] !== ' ') {
          handelIsChanged(true);
          setGraveData({
            ...graveData,
            nickName: value
              .replace(/[0-9]/g, '')
              .replace(/[\"'@#!$%^&*()_+|~=`{}\[\]:;'<>?,.\/]/g, ''),
          });
        }
      },
    },
    {
      label: 'birth_name',
      type: 'text',
      placeholder: 'birth_name',
      value: graveData.birthName,
      error: false,
      disabled: false,
      action: (value) => {
        if (value[0] !== ' ') {
          handelIsChanged(true);
          setGraveData({
            ...graveData,
            birthName: value
              .replace(/[0-9]/g, '')
              .replace(/[\"'@#!$%^&*()_+|~=`{}\[\]:;'<>?,.\/]/g, ''),
          });
        }
      },
    },
    {
      label: 'settings_gender',
      type: 'gender',
      gendersList: ['mann', 'kvinne', 'annen'],
      value: graveData.gender,
      autoComplete: 'off',
      error: false,
      errorKey: 'auth_notSame',
      disabled: false,
      action: (value) => {
        handelIsChanged(true);
        setGraveData({ ...graveData, gender: value });
      },
    },
    {
      label: 'date_of_birth',
      type: 'datePicker',
      error: false,
      disableFuture: false,
      disablePast: true,
      value: graveData.dateOfBirth,
      action: (value) => {
        handelIsChanged(true);
        setGraveData({ ...graveData, dateOfBirth: value });
      },
    },
    {
      label: 'date_of_death',
      type: 'datePicker',
      error: false,
      disableFuture: false,
      disablePast: true,
      value: graveData.dateOfDeath,
      action: (value) => {
        handelIsChanged(true);
        setGraveData({ ...graveData, dateOfDeath: value });
      },
    },
    {
      label: 'time_of_birth',
      type: 'timePicker',
      value: graveData.timeOfBirth,
      action: (value) => {
        handelIsChanged(true);
        setGraveData({ ...graveData, timeOfBirth: value });
      },
    },
    {
      label: 'time_of_death',
      type: 'timePicker',
      value: graveData.timeOfDeath,
      action: (value) => {
        handelIsChanged(true);
        setGraveData({ ...graveData, timeOfDeath: value });
      },
    },
    {
      label: 'place_of_birth',
      type: 'text',
      placeholder: 'place_of_birth',
      value: graveData.locationOfBirth,
      error: false,
      disabled: false,
      action: (value) => {
        if (value[0] !== ' ') {
          handelIsChanged(true);
          setGraveData({
            ...graveData,
            locationOfBirth: value,
          });
        }
      },
    },
    {
      label: 'place_of_death',
      type: 'text',
      placeholder: 'place_of_death',
      value: graveData.locationOfDeath,
      error: false,
      disabled: false,
      action: (value) => {
        if (value[0] !== ' ') {
          handelIsChanged(true);
          setGraveData({
            ...graveData,
            locationOfDeath: value,
          });
        }
      },
    },
    {
      label: 'cause_of_death',
      type: 'select',
      placeholder: 'cause_of_death',
      list: reasonDeathList,
      value: graveData.reasonDeath_id,
      error: false,
      disabled: false,
      action: (value) => {
        handelIsChanged(true);
        setGraveData({ ...graveData, reasonDeath_id: value });
      },
    },
    {
      label: 'country',
      type: 'select',
      placeholder: 'country',
      list: countriesList,
      value: graveData.country_id,
      error: false,
      disabled: false,
      action: (value) => {
        handelIsChanged(true);
        setGraveData({ ...graveData, country_id: value });
      },
    },
    {
      label: 'municipality',
      type: 'select',
      placeholder: 'municipality',
      list: municipalitiesList,
      value: graveData.municipality_id,
      error: false,
      disabled: false,
      action: (value) => {
        handelIsChanged(true);
        setGraveData({ ...graveData, municipality_id: value });
      },
    },
    {
      label: 'cemetery',
      type: 'select',
      placeholder: 'cemetery',
      list: cemetaryList,
      value: graveData.cemetary_id,
      error: false,
      disabled: false,
      action: (value) => {
        handelIsChanged(true);
        setGraveData({ ...graveData, cemetary_id: value });
      },
    },
    {
      label: 'grave_number',
      type: 'text',
      placeholder: 'grave_number',
      value: graveData.plotCemetery,
      error: false,
      disabled: false,
      action: (value) => {
        if (value[0] !== ' ') {
          handelIsChanged(true);
          setGraveData({
            ...graveData,
            plotCemetery: value,
          });
        }
      },
    },
  ];

  useEffect(() => {
    const getFromBEstring = graveInformation
      ? graveInformation.reason_of_death
        ? graveInformation.reason_of_death
        : ''
      : '';
    const getReasonId = reasonDeathList.filter(
      (reason) => reason.reason === getFromBEstring
    )[0]?.id;
    setGraveData({
      ...graveData,
      reasonDeath_id: getReasonId,
      name: graveInformation?.name || '',
      nickName:
        graveInformation?.grave_additional_names?.find(
          (name) => name.name_type === 'Nickname'
        )?.name || '',
      birthName:
        graveInformation?.grave_additional_names?.find(
          (name) => name.name_type === 'Birthname'
        )?.name || '',
      gender: graveInformation?.gender || '',
      locationOfBirth: graveInformation?.location_of_birth || '',
      locationOfDeath: graveInformation?.location_of_death || '',
      plotCemetery: cemeteryBox ? cemeteryBox[0] : '',
      timeOfBirth: graveInformation?.time_of_birth || '',
      timeOfDeath: graveInformation?.time_of_death || '',
      dateOfBirth: graveInformation?.date_of_birth || '',
      dateOfDeath: graveInformation?.date_of_death || '',
      privacy: graveInformation?.privacy || '',
      country_id: graveInformation?.country_id || '',
      municipality_id: graveInformation?.municipality?.id || '',
      cemetary_id: graveInformation?.graveyard?.id || '',
    });
  }, [reasonDeathList, graveInformation]);

  const slug = graveInformation.slug;

  const closeSaveModal = () => {
    setOpenSaveModal(false);
    closeModal();
  };

  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      Accept: '*/*',
    },
  };

  useEffect(() => {
    fetch_request('/api/reason_of_deaths', {
      ...requestOptions,
      headers: {
        ...requestOptions.headers,
        Authorization: `Bearer ${localStorage.token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setReasonDeathList(data.reasons);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    fetch_request('/api/countries', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setCountriesList(data.countries);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    fetch_request(
      `/api/municipalities?country_id=${graveData.country_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setMunicipalitiesList(data.municipalities);
      })
      .catch((err) => console.log(err));
  }, [graveData.country_id]);

  useEffect(() => {
    if (graveData.municipality_id) {
      fetch_request(
        `/api/graveyards?graveyard[municipality_id][]=${graveData.municipality_id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          setCemeteryList(data.graveyards);
        })
        .catch((err) => console.log(err));
    }
  }, [graveData.municipality_id]);

  const submitData = () => {
    setOpenProcessModal(true);
    const additionalNames = [
      { name_type: 'Birthname', name: graveData.birthName },
      { name_type: 'Nickname', name: graveData.nickName },
    ];
    const payload = {
      grave: {
        name: graveData.name,
        gender: graveData.gender,
        time_of_birth: graveData.timeOfBirth,
        time_of_death: graveData.timeOfDeath,
        date_of_birth: graveData.dateOfBirth,
        location_of_birth: graveData.locationOfBirth,
        date_of_death: graveData.dateOfDeath,
        location_of_death: graveData.locationOfDeath,
        reason_of_death_id: graveData.reasonDeath_id,
        grave_additional_names_attributes: additionalNames.map(
          (additionalName) => ({
            name_type: additionalName.name_type,
            name: additionalName.name,
          })
        ),
        country_id: graveData.country_id,
        municipality_id: graveData.municipality_id,
        graveyard_id: graveData.cemetary_id,
        plot_number: `${graveData.plotCemetery}:${plotField}:${plotRow}:${plotGrave}`,
      },
    };

    if (graveData.privacy !== null) {
      payload.grave.privacy_attributes = { setting: graveData.privacy };
    }
    request(`/graves/${slug}`, payload, 'put')
      .then((res) => {
        setOpenProcessModal(false);
        setOpenSaveModal(true);
        dispatch(goToChosenGrave(res.data));
      })
      .catch((err) => {
        setOpenProcessModal(false);
        showUserNotification(err.response.data.error || err.message, 'warning');
      });
  };

  const onCancel = () => {
    if (isChanged) {
      handelDiscardModal(true);
    } else {
      closeModal();
    }
  };

  return (
    <>
      <div className={styles['grave-information']}>
        <div className={styles['grave-information_content']}>
          <Input item={graveInputs[0]} />
          <Input item={graveInputs[1]} />
          <Input item={graveInputs[2]} />
          <Input item={graveInputs[3]} />
          <InputGroup inputs={[graveInputs[4], graveInputs[5]]} />
          <InputGroup inputs={[graveInputs[6], graveInputs[7]]} />
          <InputGroup inputs={[graveInputs[8], graveInputs[9]]} />
          <Input item={graveInputs[10]} />
          <InputGroup
            inputs={[graveInputs[11], graveInputs[12], graveInputs[13]]}
          />
          <div
            className={styles['privacy']}
            onClick={(event) => setAnchorEl(event.currentTarget)}
          >
            <span>
              {graveData.privacy
                ? graveData.privacy
                : 'Velg hvem som kan finne graven i søket:'}
            </span>
            <ArrowDropDownIcon />
          </div>
          <Menu
            id='long-menu'
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            elevation={3}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem value='public' onClick={() => handleChange('public')}>
              <img
                loading='lazy'
                src={Everyone}
                alt='Family Silhouette'
                className={styles['memories-header_right-icon']}
              />
              Alle
            </MenuItem>
            <MenuItem
              value='relatives'
              onClick={() => handleChange('relatives')}
            >
              <img
                loading='lazy'
                src={FamilySilhouette}
                alt='Family Silhouette'
                className={styles['memories-header_right-icon']}
              />
              Familie
            </MenuItem>
            <MenuItem value='private' onClick={() => handleChange('private')}>
              <img
                loading='lazy'
                src={OnlyMe}
                alt='Family Silhouette'
                className={styles['memories-header_right-icon']}
                style={{ marginLeft: '3px' }}
              />
              Privat
            </MenuItem>
          </Menu>
          <div className={styles['grave-btn-container']}>
            <PrimaryButton
              onClick={onCancel}
              children={<FormattedMessage id='settings_cancel' />}
              style={{
                background: '#fff',
                border: '1px solid #404D56',
                color: '#404D56',
                padding: '7px 16px',
              }}
            />
            <PrimaryButton
              onClick={submitData}
              children={<FormattedMessage id='auth_save' />}
            />
          </div>
        </div>
      </div>
      {openDiscardModal && (
        <DiscardModal
          open={openDiscardModal}
          handleClose={handelDiscardModal}
          onConfirm={closeModal}
          title='information'
        />
      )}
      {openSaveModal && (
        <SavedPopupModal
          open={openSaveModal}
          titleKey='basic_information'
          handleClose={closeSaveModal}
        />
      )}
      {openProcessModal && (
        <ProcessModal
          open={openProcessModal}
          messageKey='grave_info_updating'
        />
      )}
    </>
  );
};

export default GraveInformation;
