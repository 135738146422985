import React from 'react';
import './HomepageInvestigateFourth.css';
import FirstFlower from '../../../../img/FirstFlowers.png';

const HomepageInvestigateFourth = ({ButtonMailto}) => {
    return(
        <div className='homepage-investigate_fourth'>
            <div className="homepage-investigate_fourth-content">
                <img loading='lazy' src={FirstFlower} alt='FirstFlower' className='homepage-investigate_fourth-content_first'/>
                <img loading='lazy' src={FirstFlower} alt='FirstFlower' className='homepage-investigate_fourth-content_second'/>
                <div className="homepage-investigate_fourth-content_header">
                    <span className='homepage-investigate_fourth-content_header-main'>Ved å ta <span>ansvar</span>, forbedrer vi stadig for å levere det beste</span>
                    <span className='homepage-investigate_fourth-content_header-submain'>Å jobbe med Nettgrav og TEOTC skal være spennende og kreativt. Det er viktig for oss at partnere er trygge, noe som betyr å være transparente, inkluderende og sikre på at vi alltid tar ansvaret for å skape noe stort.</span>
                    <ButtonMailto className='homepage-investigate_fourth-content_header-contact' label="Kontakt" mailto="mailto:arekamark@nettgrav.no" />
                </div>
            </div>
        </div>
    )
}

export default HomepageInvestigateFourth;