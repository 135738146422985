import React, { useState, useEffect } from 'react';
import styles from './GraveInterests.module.css';
import isEmpty from 'lodash.isempty';
import Header from './components/Header';
import ActionButton from './components/ActionButton';
import itemStyles from '../About/components/AboutItem.module.css';
import EditSmallIcon from '../../../../img/EditSmallIcon.svg';

const GraveInterests = ({ item, onEdit, title, emptyMessage, canEdit }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [hideEdit, setHideEdit] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1020) {
        setIsExpanded(true);
        setHideEdit(false);
      } else {
        setHideEdit(true);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isExpanded, hideEdit]);
  return (
    <div div className={itemStyles['container-wrapper']}>
      <Header
        title={title}
        hideEdit={hideEdit}
        setIsExpanded={setIsExpanded}
        isExpanded={isExpanded}
        children={
          canEdit && (
            <ActionButton
              onEdit={onEdit}
              title='Redigere'
              icon={EditSmallIcon}
            />
          )
        }
      />
      {isExpanded && (
        <React.Fragment>
          {hideEdit && canEdit && (
            <div className={itemStyles['edit-btn']}>
              <ActionButton
                onEdit={onEdit}
                title='Redigere'
                icon={EditSmallIcon}
              />
            </div>
          )}
          {!isEmpty(item) && item.length > 0 ? (
            <div className={styles['interests-container']}>
              {item.map((interest) => {
                return (
                  <div className={styles['interest']} key={interest.id}>
                    <div className={styles['interest-title']}>
                      {interest.name}
                    </div>
                    {interest?.description && <p>{interest.description}</p>}
                  </div>
                );
              })}
            </div>
          ) : (
            <div className={styles['empty-grave-info']}>
              <span className={styles['empty-message']}>{emptyMessage}</span>
              {canEdit && (
                <button className={styles['add-btn']} onClick={onEdit}>
                  Add {title}
                </button>
              )}
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default GraveInterests;
